import { EditorValue } from "react-rte";
import { ContentBlock, EditorState } from "draft-js";
import React, {
  useState,
  useRef,
  useEffect,
  FC,
  Suspense,
  ReactNode,
} from "react";

let rtePromise: undefined | Promise<any>;
let createValueFromString:
  | undefined
  | ((markup: string, format: string, options?: any) => EditorValue);

const RichTextEditor = React.lazy(() => {
  rtePromise = import("react-rte").then((rte) => {
    createValueFromString = (rte as any).createValueFromString;
    return rte;
  });
  return rtePromise;
});

/**
 * Helper hook for lazily loading rich text editor. This has some
 * complications because:
 *
 * 1) They don't export all their types, so we need to duplicate
 *   many of them.
 * 2) We need to use the function createValueFromString to set or
 *   initial state, but of course that function doesn't exist yet
 *
 * To use the hook, do:
 *
 * const { value, setValue, RichTextEditor } = useLazyRTE(someString)
 *
 * return (
 *   <RichTextEditor
 *     value={value}
 *     onChange={setValue}
 *     // whatever other props you desire
 *   />
 * )
 */
export const useLazyRTE = (initialValue = "") => {
  const [value, setValue] = useState<EditorValue | null>(
    createValueFromString ? createValueFromString(initialValue, "html") : null
  );
  const hasInitialized = useRef(!!createValueFromString);
  useEffect(() => {
    if (rtePromise && !hasInitialized.current) {
      hasInitialized.current = true;
      rtePromise.then((rte) => {
        setValue(rte.createValueFromString(initialValue, "html"));
      });
    }
  });
  return { value, setValue, RichTextEditor: LazyRichTextEditor };
};

type GroupName =
  | "INLINE_STYLE_BUTTONS"
  | "BLOCK_TYPE_BUTTONS"
  | "LINK_BUTTONS"
  | "BLOCK_TYPE_DROPDOWN"
  | "HISTORY_BUTTONS"
  | "IMAGE_BUTTON";

const display: GroupName[] = [
  "INLINE_STYLE_BUTTONS",
  "BLOCK_TYPE_BUTTONS",
  "BLOCK_TYPE_DROPDOWN",
  "HISTORY_BUTTONS",
];

export const defaultToolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display,
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Blockquote", style: "blockquote" },
  ],
};

interface StyleConfig {
  label: string;
  style: string;
  className?: string;
}

type StyleConfigList = StyleConfig[];

type ChangeHandler = (value: EditorValue) => any;

type GetControlState = (key: string) => string | undefined;

type SetControlState = (key: string, value: string) => void;

type CustControlFunc = (
  set: SetControlState,
  get: GetControlState,
  state: EditorState
) => ReactNode;

interface ToolbarConfig {
  display: GroupName[];
  extraProps?: object;
  INLINE_STYLE_BUTTONS: StyleConfigList;
  BLOCK_TYPE_DROPDOWN: StyleConfigList;
  BLOCK_TYPE_BUTTONS: StyleConfigList;
}

interface LazyRichTextEditorProps {
  className?: string;
  toolbarClassName?: string;
  editorClassName?: string;
  value: EditorValue | null;
  onChange?: ChangeHandler;
  placeholder?: string;
  customStyleMap?: { [style: string]: { [key: string]: any } };
  handleReturn?: (event: object) => boolean;
  customControls?: ReactNode | CustControlFunc[];
  readOnly?: boolean;
  disabled?: boolean; // Alias of readOnly
  toolbarConfig?: ToolbarConfig;
  blockStyleFn?: (block: ContentBlock) => string | undefined;
  autoFocus?: boolean;
  keyBindingFn?: (event: object) => string | undefined;
  rootStyle?: object;
  editorStyle?: object;
  toolbarStyle?: object;
}

/**
 * Wrapper around Rich Text Editor which includes a suspense placeholder
 */
const LazyRichTextEditor: FC<LazyRichTextEditorProps> = (props) => {
  return (
    <Suspense
      fallback={
        <div
          className="AppFontSizeNormal anim_fadeInOut"
          style={{
            opacity: "0",
            width: "100%",
            height: "8em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Arial, Verdana, Geneva, Tahoma, sans-serif",
          }}
        >
          <strong>LOADING...</strong>
        </div>
      }
    >
      <RichTextEditor toolbarConfig={defaultToolbarConfig} {...props} />
    </Suspense>
  );
};
