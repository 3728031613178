import React, { FC, useState, useEffect } from "react";
import "../css/order.css";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import { CategoryProps } from "./catImgPortrait";
import cat1_img from "../../../img/cat1_img.jpg";
import { srcSetString } from "../../Main/main";

const Category2: FC<CategoryProps> = React.memo(
  ({ index, setCatIndex, catIndex, category }) => {
    const store = useStore();
    const { theme } = store;
    const [imageInfo, setImageInfo] = useState<{
      loaded: boolean;
      error: boolean;
    }>({ loaded: false, error: false });
    useEffect(() => {
      setImageInfo({ loaded: false, error: false });
    }, [category.imageUrl]);
    return (
      <React.Fragment>
        {theme.template.category === "noImgM_noImgL" ? (
          /* UPDATED 7/30/2020
          USED WHEN:
          - SETTINGS ARE: LANDSCAPE TEXT / PORTRAIT TEXT 
          - APP IS IN LANDSCAPE VIEW
          */
          <div
            id={catIndex.toString()}
            onClick={() => {
              setCatIndex(index);
            }}
            className="sTcategoryItemText"
            style={{
              textAlign: "left",
              margin: "0.5em",
              textDecoration: catIndex === index ? "underline" : "",
            }}
          >
            {category.name}
          </div>
        ) : (
          /* UPDATED 7/30/2020
          USED WHEN:
          - SETTINGS ARE: LANDSCAPE IMGS / PORTRAIT TEXT 
          - APP IS IN LANDSCAPE VIEW
          */
          <div key={index} className="sTcategoryBar boxShadow1">
            <div
              onClick={() => {
                setCatIndex(index);
              }}
              style={{
                opacity: imageInfo.loaded ? 1 : 0,
                transition: "all 0.5s linear 0s",
                marginLeft: 0,
                marginBottom: "1em",
              }}
              className="sTcategoryItemPicL"
            >
              <div>
                <img
                  src={!imageInfo.error ? category?.imageUrl : cat1_img}
                  srcSet={srcSetString(category.imageUrls)}
                  sizes="25vw"
                  alt={category?.name}
                  onLoad={() => {
                    if (!imageInfo.error) {
                      setImageInfo({
                        loaded: true,
                        error: false,
                      });
                    }
                  }}
                  onError={(e) => {
                    setImageInfo({
                      loaded: true,
                      error: true,
                    });
                  }}
                  style={{
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    verticalAlign: "middle",
                  }}
                />
              </div>
              <div
                style={{
                  transition: "all 0.1s linear 0s",
                  fontWeight: catIndex === index ? "bold" : "normal",
                  width: "100%",
                  textAlign: "left",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                }}
                className="sTcategoryText"
              >
                <Icon name="menu_open" fill="black" />
                &nbsp;{category.name}
              </div>
            </div>
          </div>
        )}
        {/* UPDATED 7/30/2020
        USED WHEN:
          - SETTINGS ARE: LANDSCAPE TEXT / PORTRAIT TEXT 
          - APP IS IN PORTRAIT VIEW
        */}
        <div
          onClick={() => {
            setCatIndex(index);
          }}
          style={{
            borderRadius: "5px",
            border:
              catIndex === index ? `0.5em solid ${theme.mainColor}` : undefined,
            backgroundColor: catIndex === index ? "white" : theme.mainColor,
            transition: "all 0.1s linear 0s",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: index === 0 ? 0 : undefined,
          }}
          className="sTorderItem1 AppFontSizeSmall sTcategoryItemPic"
        >
          <Icon
            name="category"
            fill={catIndex === index ? theme.mainColor : theme.color}
          />
          <div
            style={{
              transition: "all 0.1s linear 0s",
              fontWeight: catIndex === index ? "bold" : "normal",
              color: catIndex === index ? theme.mainColor : undefined,
            }}
            className="sTcategoryImageText"
          >
            {category.name}
          </div>
        </div>
      </React.Fragment>
    );
  }
);

export default Category2;
