import React, { useState, FC } from "react";
import useCart from "../../CartManager/useCart";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import useLockDimensions from "../../Main/useLockDimensions";
import ModalVeil from "../../Modal/modalVeil";
import { ModalProps } from "../../Modal/modalManager";
import { useTranslation } from "react-i18next";
import { hexToRgbString } from "../../../utilities/colorUtils";
export interface STrenameMealModalProps extends ModalProps {
  suborderId: string;
}

const STrenameMealModal: FC<STrenameMealModalProps> = React.memo(
  ({ suborderId, closeModal }) => {
    const lockDimensions = useLockDimensions();
    const { suborders, setSuborderName } = useCart();
    const suborder = suborders[suborderId];
    const origName = (suborder && suborder.name) || "Meal";
    const store = useStore();
    const { color, mainColorDark } = store.theme;
    const [name, setName] = useState("");
    function toUpper(str: string) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join(" ");
    }
    const onOk = (e?: React.MouseEvent) => {
      if (e) e.preventDefault();
      if (name !== "") {
        setSuborderName(suborder, toUpper(name));
      }
      closeModal();
    };
    const onKeyUp = (e: React.KeyboardEvent) => {
      if (e.keyCode === 13) {
        onOk();
      }
    };
    const { t } = useTranslation();
    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            color: color,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader"
            style={{
              backgroundColor: mainColorDark,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                color: color,
              }}
            >
              <Icon name="edit" fill={color} />
              <div>&nbsp;{t("store.meal.rename")}</div>
            </div>
            <br />
            <div className="AppFontSizeSmall" style={{ color: color }}>
              {origName}
            </div>
            <div className="AppFontSizeSmall" style={{ color: color }}>
              <span style={{ fontWeight: "bold" }}></span>
            </div>
          </div>
          <div className="sTmodalContent">
            <div>
              <input
                maxLength={20}
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                onKeyUp={onKeyUp}
                placeholder="Enter new meal name"
                onFocus={() => lockDimensions(true, "mealInstructions")}
              />
            </div>
          </div>
          <div
            className="sTmodalFooter AppFontSizeSmall"
            style={{
              backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
            }}
          >
            <div
              onClick={closeModal}
              className="sTmodalFooterItem AppFontSizeSmall"
              style={{ color: color }}
            >
              {t("common.cancel")}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: color,
              }}
              onClick={onOk}
              className="sTmodalFooterItem AppFontSizeSmall"
            >
              {t("common.submit")}
            </div>
          </div>
        </div>
        <ModalVeil onClick={closeModal} />
      </React.Fragment>
    );
  }
);

export default STrenameMealModal;
