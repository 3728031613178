import React, { FC } from "react";
import {
  createListOption,
  validateListOption,
} from "../CustomOptions/listOption";
import { IconName } from "../../Icon/IconName";
import InputFieldButton, {
  DBinputFieldButtonProps,
} from "../Components/dBinputFieldButton";
import { useTranslation } from "react-i18next";
import uuid from "uuid/v4";
import {
  optionType,
  Option,
  IncompleteOption,
  isListOption,
  isNumberOption,
} from "../../../database/option";
import { TFunction } from "i18next";
import { createNumberOption } from "../CustomOptions/numberOption";
import useModal from "../../Modal/useModal";
import DBeditCustomOptionModal from "../Modals/dBeditCustomOption";
import { DASHBOARD_CATALOG } from "../../../utilities/constants/routes";
import DashboardButton from "../Components/dbButton";

export const optionFactory = (
  type: optionType,
  name: string = "",
  optionId?: string
): Option | IncompleteOption => {
  if (type === optionType.LIST) {
    return createListOption(name, optionId);
  } else if (type === optionType.NUMBER) {
    return createNumberOption(name, optionId);
  } else {
    return {
      name,
      type: optionType.NONE_YET,
      optionId: optionId || uuid(),
      required: false,
    };
  }
};

export const validateOption = (
  option: Option | IncompleteOption,
  index: number,
  t: TFunction
) => {
  if (!option.name) {
    return t("dashboard.catalog.customInputErrorOptionName", {
      number: index + 1,
    });
  }
  if (option.type === optionType.NONE_YET) {
    return t("dashboard.catalog.customInputErrorOptionType", {
      name: option.name,
    });
  } else if (option.type === optionType.LIST) {
    return validateListOption(option, t);
  } else {
    console.warn("unsupported option type");
  }
  return;
};

/**
 * Returns true if the option is required and only has one choice
 */
export const foregoneConclusion = (option?: Option) => {
  if (
    isListOption(option) &&
    option.required &&
    Object.keys(option.items).length === 1
  ) {
    return true;
  }
  return false;
};

export interface CustomOptionProps {
  option: Option;
  label: string;
  onEdit: (option: Option) => void;
  onDelete: () => void;
}

const CustomOption: FC<CustomOptionProps> = ({
  option,
  label,
  onEdit,
  onDelete,
}) => {
  const showModal = useModal();
  const { t } = useTranslation();

  let text: string | undefined;
  let icon: IconName;
  let name = option.name || t("dashboard.catalog.unnamedCustomInput");
  if (isListOption(option)) {
    text = t("dashboard.catalog.numberOfOptions", {
      count: Object.keys(option.items).length,
    });
    icon = "done";
  } else if (isNumberOption(option)) {
    icon = "done";
  } else {
    console.warn("unsupported option type", option);
    icon = "error";
  }
  const buttonProps: DBinputFieldButtonProps = {
    style: {},
    icon,
    text: (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ textAlign: "left", padding: "0.5em" }}>
            <div style={{ wordBreak: "break-word", fontWeight: "bold" }}>
              {name}
            </div>
            {text && <div className="AppFontSizeSmall">{text}</div>}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DashboardButton
            text={t("common.edit")}
            style={{ color: "black" }}
            theme="white"
            icon="edit"
            iconColor="black"
            onClick={() => {
              showModal(
                DBeditCustomOptionModal,
                {
                  itemName: label,
                  initialOption: option,
                  onSubmit: onEdit,
                },
                DASHBOARD_CATALOG + "/setOptions"
              );
            }}
          />
          <DashboardButton
            text={t("common.remove")}
            style={{ color: "black" }}
            theme="white"
            icon="delete_forever"
            iconColor="black"
            onClick={onDelete}
          />
        </div>
      </React.Fragment>
    ),
  };

  return (
    <div style={{ padding: "0 0.25em", marginTop: "0.25em" }}>
      <InputFieldButton {...buttonProps} />
    </div>
  );
};

export default CustomOption;
