import React, { useContext, useState, useEffect, FC } from "react";
import authUserContext from "./authUserContext";
import { FirebaseContext } from "../../Firebase";
import { Store } from "../../customization/storeContext";

export interface UserAndMetadata {
  user: firebase.User | null;
  isCustomer: boolean;
  isOwner: boolean;
  isAdmin: boolean;
  isSuperuser: boolean;
  adminLocation: string;
}

export const nullUser: UserAndMetadata = {
  user: null,
  isCustomer: true,
  isOwner: false,
  isAdmin: false,
  isSuperuser: false,
  adminLocation: "",
};

export interface AuthUserProviderProps {
  store: Store;
}

const AuthUserProvider: FC<AuthUserProviderProps> = ({ children, store }) => {
  const firebase = useContext(FirebaseContext);
  const [authUser, setAuthUser] = useState(nullUser);
  useEffect(
    () =>
      firebase.auth.onAuthStateChanged(async (user) => {
        if (user === null) {
          setAuthUser(nullUser);
        } else {
          user.getIdTokenResult().then(({ claims }) => {
            const isOwner = claims.owner;
            const isSuperuser = claims.superuser;
            setAuthUser({
              //ADD LOCATIONID AS TO ADMINS TO ONLY ALLOW RETRIEVAL
              //OF THEIR LOCATION'S ORDERS
              user,
              isCustomer:
                !claims.superuser &&
                !claims.owner &&
                !claims.admin &&
                !claims.employee,
              isOwner,
              isAdmin:
                isOwner ||
                store.locations.some((loc) => loc.id === claims.admin),
              isSuperuser: claims.superuser,
              // TODO: support owner interacting with multiple locations
              adminLocation:
                isOwner || isSuperuser ? store.locations[0].id : claims.admin,
            });
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <authUserContext.Provider value={authUser}>
      {children}
    </authUserContext.Provider>
  );
};

export default AuthUserProvider;
