import React, { FC, useState } from "react";
import { ModalProps } from "../../Modal/modalManager";
import { useTranslation } from "react-i18next";
import ModalVeil from "../../Modal/modalVeil";
import { NumberOptionSelection } from "../../../database/cart";
import useTheme from "../../../customization/useTheme";
import Icon from "../../Icon/icon";
import { optionType, isNumberOption } from "../../../database/option";
import useStore from "../../../customization/useStore";
import useLockDimensions from "../../Main/useLockDimensions";
import useToast from "../../Main/useToast";
import { TOASTDURATION_ERROR } from "../../../utilities/constants/appConstants";
import Dinero from "dinero.js";
import { hexToRgbString } from "../../../utilities/colorUtils";
export interface STNumberSelectModalProps extends ModalProps {
  optionId: string;
  initialSelection?: NumberOptionSelection;
  onSubmit: (selection: NumberOptionSelection) => void;
  source?: "dashboard" | "store";
}

const STNumberSelectModal: FC<STNumberSelectModalProps> = ({
  optionId,
  initialSelection,
  onSubmit,
  closeModal,
  source,
}) => {
  const { t } = useTranslation();
  const lockDimensions = useLockDimensions();
  const toast = useToast();
  const { mainColor, color, iconColor, mainColorDark } = useTheme();
  const { options } = useStore();
  const option = options[optionId];
  const [selection, setSelection] = useState<NumberOptionSelection>(
    initialSelection || {
      optionId,
      type: optionType.NUMBER,
      value: isNumberOption(option) ? option.defaultValue : 0,
    }
  );

  if (!isNumberOption(option)) {
    return null;
  }

  const isRound =
    Math.round(selection.value / option.increment) * option.increment ===
    selection.value;

  const handleSubmit = () => {
    if (selection.value < option.minValue) {
      toast({
        message: `Choose a number ${option.minValue} or greater`,
        className: "sTthemeAlert1b",
        duration: TOASTDURATION_ERROR,
      });
      return;
    } else if (selection.value > option.maxValue) {
      toast({
        message: `Choose a number ${option.maxValue} or less`,
        className: "sTthemeAlert1b",
        duration: TOASTDURATION_ERROR,
      });
      return;
    }

    let sel = selection;
    if (!isRound) {
      toast({
        message: `We've rounded your selection to the nearest ${option.increment} ${option.unit}`,
        color: source !== "dashboard" ? color : undefined,
        backgroundColor: mainColor,
        duration: TOASTDURATION_ERROR,
      });
      sel = {
        ...selection,
        value:
          Math.round(selection.value / option.increment) * option.increment,
      };
    }

    onSubmit(sel);
    closeModal();
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "block",
          color: source !== "dashboard" ? color : undefined,
        }}
        className={
          source !== "dashboard" ? "sTmodal boxShadow5" : "dBmodal boxShadow5"
        }
      >
        <div
          className={
            source !== "dashboard"
              ? "sTmodalHeader"
              : "dBmodalHeader dBthemeBGcolor2"
          }
          style={{
            backgroundColor: source !== "dashboard" ? mainColorDark : undefined,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              name="input"
              fill={source !== "dashboard" ? color : "white"}
            />
            <div style={{ color: source !== "dashboard" ? color : undefined }}>
              &nbsp;{t("store.orders.chooseYour")}&nbsp;{option.name}
            </div>
          </div>
        </div>
        <div
          className="sTmodalContent"
          style={{
            padding: "1em 0.25em",
            color: source === "dashboard" ? iconColor : "black",
          }}
        >
          {!!option.price &&
            `${Dinero({ amount: option.price }).toFormat()} ${
              option.unit
                ? ` ${t("store.orders.per")} ${option.priceDenominator} ${
                    option.unit
                  }`
                : ` ${t("store.orders.each")}`
            }`}
          <input
            type="number"
            placeholder={t("dashboard.customFields.pricePlaceholder")}
            onFocus={() => lockDimensions(true)}
            defaultValue={selection.value}
            onChange={(event) => {
              setSelection({
                ...selection,
                value: Number(event.target.value),
              });
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                handleSubmit();
              }
            }}
          />
        </div>
        <div
          className={
            source !== "dashboard"
              ? "sTmodalFooter AppFontSizeSmall"
              : "dBmodalFooter AppFontSizeSmall"
          }
          style={{
            backgroundColor:
              source !== "dashboard"
                ? `rgba(${hexToRgbString(mainColorDark)},0.7)`
                : undefined,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              color: source !== "dashboard" ? color : undefined,
            }}
            onClick={closeModal}
            className="sTmodalFooterItem"
          >
            {t("common.cancel")}
          </div>
          <div
            style={{
              fontWeight: "bold",
              color: source !== "dashboard" ? color : undefined,
            }}
            onClick={handleSubmit}
            className="sTmodalFooterItem"
          >
            {t("common.ok")}
          </div>
        </div>
      </div>
      <ModalVeil onClick={closeModal} />
    </React.Fragment>
  );
};

export default STNumberSelectModal;
