import React, { FC } from "react";
import { PageTemplate } from "../../../database/storeLocation";
import RichTextPage from "./richTextPage";

export interface PageTemplateLoaderProps {
  template: PageTemplate;
}

const PageTemplateLoader: FC<PageTemplateLoaderProps> = ({ template }) => {
  // In the future there will be multiple templates, but at the moment there's only one.
  return <RichTextPage template={template} />;
};

export default PageTemplateLoader;
