import React, { useState, useEffect, useMemo, Suspense } from "react";
import WebFont from "webfontloader";
import Firebase, { FirebaseContext } from "../../Firebase";
import "./App.css";
import Main from "../Main/main";
import { BrowserRouter } from "react-router-dom";
import CartManager from "../CartManager/cartManager";
import AuthUserProvider from "../AuthUserProvider/authUserProvider";
import PaymentProvider from "../PaymentProvider/paymentProvider";
// import loadImage from "../../utilities/loadImage";
import StoreContext, {
  StoreLocationContext,
  Store,
  PreviewContext,
} from "../../customization/storeContext";
import { RawStore } from "../../database/rawStore";
import { StoreLocation } from "../../database/storeLocation";
import { swOffline } from "./serviceWorker";
import { initDinero } from "../../utilities/orderProcessing";
import { WEBFONTS } from "../../utilities/constants/appConstants";
import Maintenance from "../Store/Pages/maintenance";
swOffline();

/**
 * Recursively look through the location for any properties named
 * appFont or fontFamily and aggregate them into an array.
 */
const findFontFamilies = (
  candidate: any,
  families: string[] = []
): string[] => {
  if (typeof candidate === "object" && candidate !== null) {
    Object.entries(candidate).forEach(([key, val]) => {
      if (
        (key === "appFont" || key === "fontFamily") &&
        typeof val === "string"
      ) {
        families.push(val);
      } else {
        findFontFamilies(val, families);
      }
    });
  }
  return families;
};

/**
 * Load all necessary fonts that are found in the theme
 */
const loadFonts = (location: StoreLocation) => {
  const googleFontFamilies = findFontFamilies(location).filter(
    (family) => !WEBFONTS.includes(family)
  );

  if (googleFontFamilies.length === 0) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    WebFont.load({
      google: {
        families: googleFontFamilies,
      },
      active: resolve,
      inactive: reject,
    });
  });
};

export interface LocationPreview {
  location: StoreLocation;
  returnUrl: string;
}

const firebaseInstance = new Firebase();
//console.log("Current URL", window.location.host.split(".")[0]);
const App = () => {
  const [restaurant, setRestaurant] = useState<RawStore | null>(null);
  const [location, setLocation] = useState<StoreLocation | null>(null);
  const [preview, setPreview] = useState<LocationPreview | null>(null);
  const [assetsPreloaded, setAssetsPreloaded] = useState(false);

  useEffect(
    () => {
      (async () => {
        await firebaseInstance.isReady();
        firebaseInstance.firestore
          .collection("stores")
          .doc("store")
          .onSnapshot(async (storeDoc) => {
            if (storeDoc.exists) {
              let storeData = storeDoc.data() as RawStore;
              setRestaurant(storeData);
              if (storeData.locations.length === 1) {
                const locationId = storeData.locations[0].id;
                //IF ONLY ONE LOCATION, RETRIEVE ALL DATA
                firebaseInstance.firestore
                  .collection("stores")
                  .doc("store")
                  .collection("locations")
                  .doc(locationId)
                  .onSnapshot(
                    async (locationDoc) => {
                      const locationData = locationDoc.data() as StoreLocation;
                      initDinero(locationData);
                      setLocation(locationData);
                      try {
                        await Promise.all([loadFonts(locationData)]);
                      } catch (ex) {
                        console.error("error preloading", ex);
                      } finally {
                        setAssetsPreloaded(true);
                      }
                    },
                    (err) => {
                      console.error("Location data could not be loaded.", err);
                    }
                  );
              } else {
                //IF MULTIPLE LOCATIONS, PROMPT USER
                //TO PICK LOCATION DURING ORDER OR MENU CLICK
              }
            } else {
              console.error("Restaurant data could not be loaded.");
            }
          });
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fullStore: Store | null = useMemo(() => {
    if (!restaurant || !location) {
      return null;
    }
    if (preview) {
      return {
        ...restaurant,
        ...preview.location,
        isPreview: true,
        previewReturnUrl: preview.returnUrl,
        locationId: restaurant.locations[0].id,
      };
    } else {
      return {
        ...restaurant,
        ...location,
        isPreview: false,
        previewReturnUrl: "",
        locationId: restaurant.locations[0].id,
      };
    }
  }, [location, preview, restaurant]);

  const loadingIndicator = (
    <div
      className="AppFontSizeNormal anim_fadeInOut"
      style={{
        opacity: "0",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Arial, Verdana, Geneva, Tahoma, sans-serif",
      }}
    >
      <strong>LOADING...</strong>
    </div>
  );

  if (!fullStore || !assetsPreloaded || !location) {
    return loadingIndicator;
  }

  if (location._maintenance) {
    return <Maintenance store={fullStore} />;
  }

  // i18n can trigger this suspense if we preload all assets before languages
  return (
    <Suspense fallback={loadingIndicator}>
      <FirebaseContext.Provider value={firebaseInstance}>
        <AuthUserProvider store={fullStore}>
          <BrowserRouter>
            <StoreLocationContext.Provider value={location}>
              <PreviewContext.Provider value={setPreview}>
                <StoreContext.Provider value={fullStore}>
                  <PaymentProvider>
                    <CartManager>
                      <Main />
                    </CartManager>
                  </PaymentProvider>
                </StoreContext.Provider>
              </PreviewContext.Provider>
            </StoreLocationContext.Provider>
          </BrowserRouter>
        </AuthUserProvider>
      </FirebaseContext.Provider>
    </Suspense>
  );
};

export default App;
