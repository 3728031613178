import React, { useState, FC } from "react";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import useLockDimensions from "../../Main/useLockDimensions";
import ModalVeil from "../../Modal/modalVeil";
import { useTranslation } from "react-i18next";
import { ModalProps } from "../../Modal/modalManager";
import { CatalogItem_Database } from "../../../database/catalogItem";
import { hexToRgbString } from "../../../utilities/colorUtils";
export interface STinstructionsModalProps extends ModalProps {
  item: CatalogItem_Database;
  initialInstructions: string;
  onSubmit: (instructions: string) => void;
  source?: "dashboard" | "store";
}

const STinstructionsModal: FC<STinstructionsModalProps> = React.memo(
  ({ closeModal, item, initialInstructions, onSubmit, source }) => {
    const lockDimensions = useLockDimensions();
    const store = useStore();
    const { t } = useTranslation();

    const { mainColorDark, color } = store.theme;

    const [instructions, setInstructions] = useState(initialInstructions);

    const handleSubmit = () => {
      onSubmit(instructions);
      closeModal();
    };
    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            color: source !== "dashboard" ? color : undefined,
          }}
          className={
            source !== "dashboard" ? "sTmodal boxShadow5" : "dBmodal boxShadow5"
          }
        >
          <div
            className={
              source !== "dashboard"
                ? "sTmodalHeader"
                : "dBmodalHeader dBthemeBGcolor2"
            }
            style={{
              backgroundColor:
                source !== "dashboard" ? mainColorDark : undefined,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                color: source !== "dashboard" ? color : undefined,
              }}
            >
              <Icon
                name="add_note3"
                fill={source !== "dashboard" ? color : "white"}
              />
              <div>&nbsp;{t("store.orders.addNoteTitle")}</div>
            </div>
            <br />
            <div
              className="AppFontSizeSmall"
              style={{ color: source !== "dashboard" ? color : undefined }}
            >
              {item.name}
            </div>
            <div
              className="AppFontSizeSmall"
              style={{ color: source !== "dashboard" ? color : undefined }}
            >
              <span style={{ fontWeight: "bold" }}>
                {t("store.orders.addNoteSubTitle")}
              </span>
            </div>
          </div>
          <div className="sTmodalContent">
            <div>
              <textarea
                maxLength={200}
                id="instructions"
                name="instructions"
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    handleSubmit();
                  }
                }}
                placeholder={t("store.orders.addNotePlaceholder")}
                onFocus={() => lockDimensions(true, "ST Instructions")}
                rows={4}
              />
            </div>
          </div>
          <div
            className={
              source !== "dashboard"
                ? "sTmodalFooter AppFontSizeSmall"
                : "dBmodalFooter AppFontSizeSmall"
            }
            style={{
              backgroundColor:
                source !== "dashboard"
                  ? `rgba(${hexToRgbString(mainColorDark)},0.7)`
                  : undefined,
            }}
          >
            <div
              style={{
                opacity: instructions.length > 0 ? 1 : 0,
                transition: "all 0.5s ease 0s",
                color: source !== "dashboard" ? color : undefined,
              }}
              onClick={() => setInstructions("")}
              className={
                source !== "dashboard"
                  ? "sTmodalFooterItem"
                  : "dBmodalFooterItem"
              }
            >
              {t("common.clear")}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: source !== "dashboard" ? color : undefined,
              }}
              onClick={handleSubmit}
              className={
                source !== "dashboard"
                  ? "sTmodalFooterItem"
                  : "dBmodalFooterItem"
              }
            >
              {t("common.ok")}
            </div>
          </div>
        </div>
        <ModalVeil onClick={closeModal} />
      </React.Fragment>
    );
  }
);

export default STinstructionsModal;
