import React from "react";
import { useHistory } from "react-router-dom";
import "../css/home.css";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import { useTranslation } from "react-i18next";
import { menuItemKeys, defaultConfigs } from "../menuItems";
import { IconName } from "../../Icon/IconName";
import { ORDER } from "../../../utilities/constants/routes";

const Home = React.memo(() => {
  const history = useHistory();
  const store = useStore();
  const { t } = useTranslation();
  const { theme, homePageOptions } = store;
  const menuConfigs = homePageOptions?.menuConfig ?? {};
  const { mainColorDark, mainColorLight, color } = theme;

  const handleNav = (route: string) => {
    history.push(route, "fromHome");
  };
  const orderIcon: IconName =
    menuConfigs[menuItemKeys.ORDER]?.icon ??
    defaultConfigs[menuItemKeys.ORDER].icon;
  return (
    <React.Fragment>
      <div className="sThomeOuterWrap">
        <div className="sThomeHeader"></div>
        <div>&nbsp;</div>
        {theme.sloganCenter && (
          <div
            style={{
              width: "100%",
              height: "20em",
              display: "flex",
              alignItems: "center",
              textShadow: "0 1px 1px #111",
              justifyContent: "center",
              fontWeight: "bold",
              fontFamily: theme.sloganCenter.fontFamily,
              fontSize: theme.sloganCenter.fontSize
                ? `${theme.sloganCenter.fontSize}em`
                : undefined,
            }}
          >
            {theme.sloganCenter.text}
          </div>
        )}
        <div className="sT_anim_moveLeftMed" style={{ marginBottom: "2em" }}>
          <div
            className="AppFontSizeBig1"
            style={{
              textShadow: "0 1px 1px #111",
              userSelect: "none",
              fontWeight: "bold",
              color: "#FFF",
              fontFamily:
                typeof theme.slogan === "string"
                  ? undefined
                  : theme.slogan.fontFamily,
              fontSize:
                typeof theme.slogan === "string"
                  ? undefined
                  : `${theme.slogan.fontSize}em`,
            }}
          >
            {typeof theme.slogan === "string"
              ? theme.slogan
              : theme.slogan.text}
          </div>
          <div className="">
            <div
              onClick={() => {
                handleNav(ORDER);
              }}
              style={{
                backgroundColor: mainColorDark,
                color: color,
                margin: "0.5em auto 1em auto",
              }}
              className="sTbutton"
            >
              <div
                style={{
                  backgroundColor: mainColorLight,
                }}
                className="sTbuttonIcon"
              >
                <Icon name={orderIcon} fill={color} />
              </div>
              <span
                className="sTbuttonText"
                style={{
                  fontFamily: homePageOptions?.mainButton?.fontFamily,
                  fontSize: homePageOptions?.mainButton?.fontSize
                    ? `${homePageOptions.mainButton.fontSize}em`
                    : undefined,
                }}
              >
                {homePageOptions?.mainButton?.text ?? t("store.home.orderNow")}
              </span>
              <div />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default Home;
