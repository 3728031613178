import React, { FC } from "react";
import { foregoneConclusion } from "../Dashboard/Inventory/customOption";
import {
  OptionSelection,
  NumberOptionSelection,
  ListOptionSelection,
  UnitOptionSelection,
} from "../../database/cart";
import useStore from "../../customization/useStore";
import {
  isListOption,
  isNumberOption,
  isUnitOption,
  NumberOption,
  ListOption,
  ListOptionItem,
  UnitOption,
  Option,
  optionType,
} from "../../database/option";
import STNumberSelectModal from "./Modals/sTnumberSelect";
import STUnitSelectModal from "./Modals/sTunitSelect";
import ListSelectModal from "./Modals/sTlistSelect";
import useModal from "../Modal/useModal";
import Icon from "../Icon/icon";
import useToast from "../Main/useToast";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export interface OptionButtonProps {
  optionId: string;
  selection?: OptionSelection;
  onSelectionChanged: (selection: OptionSelection) => void;
  style?: React.CSSProperties;
  color?: string;
  source: "dashboard" | "store";
  allowClick: boolean;
}

const listOptionSummary = (
  option: ListOption,
  t: TFunction,
  selection?: ListOptionSelection
) => {
  const selectedItems: ListOptionItem[] = [];
  if (selection) {
    option.items.forEach((item) => {
      if (selection.selectedItems[item.id]) {
        selectedItems.push(item);
      }
    });
  }
  if (selectedItems.length === 0) {
    return (
      <React.Fragment>
        <div className="AppFontSizeExtraSmall">{option.name}</div>
        <div style={{ fontWeight: "bold" }}>
          {t("common.choose").toUpperCase()}
        </div>
      </React.Fragment>
    );
  } else if (selectedItems.length === 1) {
    return (
      <React.Fragment>
        <div className="AppFontSizeExtraSmall">{option.name}</div>
        <div style={{ fontWeight: "bold" }}>{selectedItems[0].description}</div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="AppFontSizeExtraSmall">{option.name}</div>
        <div style={{ fontWeight: "bold" }}>Multiple</div>
      </React.Fragment>
    );
  }
};

const numberOptionSummary = (
  option: NumberOption,
  selection?: NumberOptionSelection
) => {
  if (selection) {
    let text = (
      <React.Fragment>
        <div className="AppFontSizeExtraSmall">{option.name}</div>
        <div style={{ fontWeight: "bold" }}>
          {selection.value}
          {option.unit && <span>&nbsp;{option.unit}</span>}
        </div>
      </React.Fragment>
    );

    return text;
  } else {
    return "";
  }
};

const unitOptionSummary = (
  option: UnitOption,
  selection?: UnitOptionSelection
) => {
  if (selection) {
    let text = (
      <div
        style={{ fontWeight: "bold" }}
      >{`${selection.value} ${option.name}`}</div>
    );
    return text;
  } else {
    return <div style={{ fontWeight: "bold" }}>{`Choose ${option.name}`}</div>;
  }
};

const getSummary = (
  option: Option | undefined,
  selection: OptionSelection | undefined,
  t: TFunction
) => {
  if (!option || !selection || option.type !== selection.type) {
    return null;
  } else if (isListOption(option)) {
    return (
      listOptionSummary(option, t, selection as ListOptionSelection) ||
      option.name
    );
  } else if (isNumberOption(option)) {
    return (
      numberOptionSummary(option, selection as NumberOptionSelection) ||
      option.name
    );
  } else if (isUnitOption(option)) {
    return (
      unitOptionSummary(option, selection as UnitOptionSelection) || option.name
    );
  }
  return null;
};

/**
 * Button for items and product pages to edit option selections
 */
const OptionButton: FC<OptionButtonProps> = ({
  optionId,
  selection,
  onSelectionChanged,
  style,
  color,
  source,
  allowClick,
}) => {
  const showModal = useModal();
  const { options, theme } = useStore();
  color = color || theme.color;
  const option = options[optionId];
  const { t } = useTranslation();
  let text = getSummary(option, selection, t);
  const toast = useToast();

  const onClick = !allowClick
    ? () => {}
    : () => {
        if (!selection || foregoneConclusion(option)) {
          if (option?.type === "list")
            toast({
              message: `${t("store.orders.thisItemFeatures")} ${
                option.name
              }... ${option?.items[0].description}`,
              color: color,
              backgroundColor: theme.mainColor,
            });
          return;
        }
        if (selection.type === optionType.LIST) {
          showModal(ListSelectModal, {
            optionId,
            initialSelection: selection,
            onSubmit: onSelectionChanged,
            source,
          });
        } else if (selection.type === optionType.NUMBER) {
          showModal(STNumberSelectModal, {
            optionId,
            initialSelection: selection,
            onSubmit: onSelectionChanged,
            source,
          });
        } else if (selection.type === optionType.UNIT) {
          showModal(STUnitSelectModal, {
            optionId,
            initialSelection: selection,
            onSubmit: onSelectionChanged,
            source,
          });
        }
      };
  return (
    <div
      onClick={onClick}
      style={{
        textAlign: "center",
        flexDirection: source === "store" ? undefined : "column",
        backgroundColor:
          source === "store"
            ? // !selection || foregoneConclusion(option)
              //   ? theme.mainColorLight
              //   :
              theme.mainColorDark
            : "",
        margin: source === "store" ? undefined : "0 0.25em",
        padding: source === "store" ? "0.25em" : "0 0.25em",
        borderRadius: "5px",
      }}
      className="sTCustomActionBtn"
    >
      {source === "dashboard" && <Icon name="input" fill={color} />}
      <div
        className="sTorderCustomInputBtn AppFontSizeSmall"
        style={{
          color: color,
          ...style,
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default OptionButton;
