import React, { useState, useEffect, FC } from "react";
import "../Dashboard/css/global.css";
import Icon from "../Icon/icon";
import { useHistory } from "react-router-dom";

export interface ToastProps {
  message?: string;
  duration?: number;
  // Any string is allowed for the classname, but this gives us some autocomplete
  className?:
    | "dBthemeAlert1b"
    | "dBthemeToast"
    | "sTthemeAlert1b"
    | (string & {});
  onHide?: () => void;
  color?: string;
  backgroundColor?: string;
  iconColor?: string;
  bottom?: string;
  clickAction?: string;
  link?: { url: string; text: string };
  veil?: { active: boolean; dismissable: boolean };
}

const Toast: FC<ToastProps> = ({
  message,
  children,
  duration = 4300,
  onHide,
  color: colorProp,
  backgroundColor: backgroundColorProp,
  bottom,
  className,
  iconColor,
  clickAction,
  link,
  veil,
}) => {
  if (className?.includes("dB")) {
    className += " AppDefaultFont";
  }
  let history = useHistory();
  let color = !className ? colorProp || "black" : undefined;
  let backgroundColor = !className ? backgroundColorProp || "white" : undefined;
  iconColor = !className ? iconColor || color || "black" : "white";
  const [visible, setVisible] = useState(true);
  const [veilActive, setVeilActive] = useState(veil?.active);
  useEffect(() => {
    const id = setTimeout(() => setVisible(false), duration);
    return () => clearTimeout(id);
  }, [duration]);
  useEffect(() => {
    if (!visible && typeof onHide === "function") {
      const id = setTimeout(() => onHide(), 1000);
      return () => clearTimeout(id);
    }
  }, [visible, onHide]);

  const style = {
    bottom: bottom ? "0" : "",
    top: bottom ? "" : "0.25em",
    opacity: visible ? 1 : 0,
  };
  return duration > 0 ? (
    <React.Fragment>
      {children && (
        <div
          className="sT_anim_fadeIn_0300"
          style={{
            display: veilActive ? undefined : "none",
            opacity: 0,
            backgroundColor: "rgba(0,0,0,0.7)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1000,
          }}
          onClick={
            veil?.dismissable
              ? () => {
                  setVeilActive(false);
                  setVisible(false);
                }
              : () => {}
          }
        ></div>
      )}
      <div
        className={
          !visible
            ? `modalToast sT_anim_fadeOut_0300 ${className}`
            : `modalToast sT_anim_fadeIn_0300 ${className}`
        }
        style={style}
      >
        <div
          className="modalToastText"
          style={{
            backgroundColor,
            color,
            position: "relative",
          }}
        >
          <div
            style={{
              height: "100%",
              padding: "0.5em",
              textAlign: "justify",
              lineHeight: "1.25em",
              width: message ? undefined : "100%",
            }}
          >
            {children ? children : message}
            {link && (
              <div>
                &nbsp;
                <a
                  href={link.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  {link.text}
                </a>
              </div>
            )}
          </div>
          {!children && (
            <div>
              <div
                className="AppFontSizeSmall"
                onClick={() => {
                  setVeilActive(false);
                  setVisible(false);
                }}
                style={{
                  position: message ? undefined : "absolute",
                  right: message ? undefined : 0,
                  padding: "0.5em",
                  backgroundColor: backgroundColor,
                  flexBasis: message ? "10%" : undefined,
                }}
              >
                <Icon name="close" fill={iconColor} />
              </div>
              {clickAction && (
                <div
                  className="AppFontSizeSmall"
                  onClick={() => {
                    history.push(clickAction);
                    setVisible(false);
                  }}
                  style={{
                    padding: "0.5em",
                    backgroundColor: backgroundColor,
                    flexBasis: "10%",
                  }}
                >
                  <Icon name="done" fill={iconColor} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

export default Toast;
