import { Message, MessageThread } from "../database/message";
import { useState, useContext, useEffect, useMemo } from "react";
import { FirebaseContext } from "../Firebase";
import usePrevious from "./usePrevious";

export const byTimeSent = (a: Message, b: Message) =>
  a.timeSent.toMillis() - b.timeSent.toMillis();

export const useMessageThread = (
  threadId?: string | null,
  initialThread?: MessageThread
) => {
  const firebase = useContext(FirebaseContext);
  const [state, setState] = useState({
    thread: initialThread,
    loading: !!threadId,
  });
  useEffect(() => {
    if (threadId) {
      const unsubscribe = firebase.firestore
        .collection("stores")
        .doc("store")
        .collection("messageThreads")
        .doc(threadId)
        .onSnapshot(
          (orderDoc) => {
            const thread = orderDoc.data() as MessageThread | undefined;
            // console.log("got thread", thread);
            setState({
              thread,
              loading: false,
            });
          },
          (error) => {
            console.log("error getting message thread", error);
            setState({
              thread: undefined,
              loading: false,
            });
          }
        );
      return unsubscribe;
    } else {
      setState({
        thread: undefined,
        loading: false,
      });
    }
  }, [firebase.firestore, threadId]);

  const value = useMemo(() => {
    const messages = state.thread
      ? Object.values(state.thread.messages).sort(byTimeSent)
      : [];
    return {
      thread: state.thread,
      loading: state.loading,
      messages,
    };
  }, [state.loading, state.thread]);

  return value;
};

/**
 * Listens for changes to the messages, and marks as read any messages from the
 * other participant.
 */
export const useMarkReadMessages = (
  threadId: string | undefined | null,
  messages: Message[],
  reader: "admin" | "customer"
) => {
  const firebase = useContext(FirebaseContext);

  const otherParticipant = reader === "admin" ? "customer" : "admin";
  const unreadMessages = useMemo(
    () => messages.filter((m) => m.from === otherParticipant && !m.read),
    [messages, otherParticipant]
  );

  const prevMessages = usePrevious(messages);
  useEffect(() => {
    if (threadId && messages !== prevMessages && unreadMessages.length > 0) {
      console.log("UPDATING MESSAGES TO READ.");
      const update: firebase.firestore.UpdateData = {};
      if (reader === "admin") {
        // Mark the entire thread as read
        update.read = true;
      }
      // Mark the messages as read
      unreadMessages.forEach((message) => {
        update[`messages.${message.messageId}.read`] = true;
      });
      firebase.firestore
        .collection("stores")
        .doc("store")
        .collection("messageThreads")
        .doc(threadId)
        .update(update)
        .catch((error) => {
          console.log("ERROR UPDATING MESSAGES TO READ:", error);
        });
    }
  }, [
    prevMessages,
    messages,
    unreadMessages,
    firebase.firestore,
    threadId,
    reader,
  ]);

  return unreadMessages;
};
