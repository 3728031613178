import { useState, useEffect, useCallback } from "react";

export interface StorageOptions<T> {
  key: string;
  defaultValue: T;
  serialize?: (value: T) => string;
  deserialize?: (str: string) => T;
}

export const defaultSerializer = (value: any) => JSON.stringify(value);
export const defaultDeserializer = (str: string) => JSON.parse(str);

/**
 * Similar to useState, but the value is also mirrored to session storage.
 * On initial load, if the value is in session storage that value will be used.
 * Thereafter, any change will be written out to session storage.
 */
export const useStateWithSessionStorage = <T>(options: StorageOptions<T>) => {
  return useStateWithGenericStorage(options, sessionStorage);
};

/**
 * Similar to useState, but the value is also mirrored to local storage.
 * On initial load, if the value is in local storage that value will be used.
 * Thereafter, any change will be written out to local storage.
 */
export const useStateWithLocalStorage = <T>(options: StorageOptions<T>) => {
  return useStateWithGenericStorage(options, localStorage);
};

const useStateWithGenericStorage = <T>(
  options: StorageOptions<T>,
  storageInterface: Storage
) => {
  const {
    key,
    defaultValue,
    serialize = defaultSerializer,
    deserialize = defaultDeserializer,
  } = options;

  const [value, setValue] = useState<T>(() => {
    try {
      const fromStorage = storageInterface.getItem(key);
      return fromStorage ? deserialize(fromStorage) : defaultValue;
    } catch {
      return defaultValue;
    }
  });

  useEffect(() => {
    storageInterface.setItem(key, serialize(value));
  }, [key, serialize, storageInterface, value]);

  const remove = useCallback(() => {
    storageInterface.removeItem(key);
  }, [key, storageInterface]);

  return [value, setValue, remove] as const;
};
