import React, { FC } from "react";
import Icon from "../../Icon/icon";
import { IconName } from "../../Icon/IconName";
import ModalVeil from "../../Modal/modalVeil";

export interface ModalTemplateProps {
  title: string;
  icon?: IconName;
  subTitle?: string;
  subTitle2?: string | React.ReactElement;
  content: React.ReactElement;
  buttons: React.ReactElement;
  onVeilClick: () => void;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  footerStyle?: React.CSSProperties;
  alert?: boolean;
}

const ModalTemplate: FC<ModalTemplateProps> = ({
  title,
  icon = "no_icon",
  subTitle,
  subTitle2,
  content,
  buttons,
  onVeilClick = () => {},
  style,
  footerStyle,
  alert,
}) => {
  return (
    <React.Fragment>
      <div
        style={{ display: "block", zIndex: 500, ...style }}
        className="dBmodal boxShadow5"
      >
        <div
          className={
            alert
              ? "dBmodalHeader dBthemeAlert1"
              : "dBmodalHeader dBthemeBGcolor2"
          }
        >
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
            }}
          >
            <span className="AppFontSizeSmall">
              <Icon name={icon} fill="#FFF" />
            </span>
            &nbsp;{title}
          </div>
          {subTitle && (
            <div
              className="AppFontSizeSmall"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ fontWeight: "bold", paddingLeft: "0.25em" }}>
                {subTitle}
              </span>
            </div>
          )}
          {subTitle2 && (
            <div className="AppFontSizeSmall">
              <span style={{ fontWeight: "bold", paddingLeft: "0.25em" }}>
                {subTitle2}
              </span>
            </div>
          )}
        </div>
        <div className="dBmodalContent" style={{ color: "black" }}>
          {content}
        </div>
        <div style={footerStyle} className="dBmodalFooter AppFontSizeSmall">
          {buttons}
        </div>
      </div>
      <ModalVeil onClick={onVeilClick} />
    </React.Fragment>
  );
};

export default ModalTemplate;
