export const TOASTDURATION_ERROR = 8000;
export const WEBFONTS = [
  //WEB SAFE FONTS
  "Arial",
  "Courier New",
  "Times New Roman",
  "Georgia",
  "Impact",
  "Comic Sans MS",
  "Trebuchet MS",
  "Helvetica",
  "Arial-black",
  "Garamond",
  "Verdana",
  "Bookman Old Style",
  "Palatino",
  "Times",
  "Courier",
];
