import { useRef, useEffect, FC } from "react";
import { createPortal } from "react-dom";

/**
 * Renders content into the modal-root element. See app.tsx.
 *
 * If this becomes our standard way of doing modals, i'd rename
 * this to just Modal. It's only called ModalPortal because there
 * another component named modal already.
 *
 * Usage:
 *
 * <ModalPortal>
 *   <div>Put anything at all inside ModalPortal</div>
 *   <CustomComponentsAreFine/>
 * </ModalPortal>
 */

const ModalPortal: FC = ({ children }) => {
  const element = useRef(document.getElementById("modal-root"));
  useEffect(() => {
    element.current = document.getElementById("modal-root");
  });
  return element.current ? createPortal(children, element.current) : null;
};

export default ModalPortal;
