export const ABOUT = "/about";
export const ACCOUNT = "/account";
export const CART = "/cart";
export const CHECKOUT = "/checkout";
export const CONTACT = "/contact";
export const DASHBOARD = "/dashboard";
export const DASHBOARD_ORDERS = "/dashboard/orders";
export const DASHBOARD_CATALOG = "/dashboard/catalog";
export const DASHBOARD_MESSAGES = "/dashboard/messages";
export const DASHBOARD_PROMOTIONS = "/dashboard/promotions";
export const DASHBOARD_SALES = "/dashboard/sales";
export const DASHBOARD_SETTINGS = "/dashboard/settings";
export const DASHBOARD_TRACK = "/dashboard/track";
export const FAVORITES = "/favorites";
export const FAVORITES_PRODUCT = "/favorites/product";
export const GUEST = "/guest";
export const HOME = "/home";
export const ORDER = "/order";
export const ORDER_PRODUCT = "/order/product";
export const PAYMENTAUTH = "/paymentauth";
export const PROMOTIONS = "/promotions";
export const STORE = "/";
export const SUPER_USER = "/superuser";
export const TRACK = "/track";
export const SIGNIN = "/s";

//MODAL ROUTES
export const ML_XENDITIFRAME = "/xiframe";
export const ML_MEALRENAME = "/rename";
export const ML_MEALDELETE = "/delete";
export const ML_ITEMINSTRUCT = "/instructions";
export const ML_DELIVERY = "/delivery";
export const ML_GUESTINFO = "/guest";
export const ML_ORDERDATE = "/date";
export const ML_CONTACT = "/contact";
export const ML_PAYSUCCESS = "/paysuccess";
export const ML_ACCEMAIL = "/accountemail";
export const ML_ACCPASS = "/accountpassword";
export const ML_ACCADDRESS = "/accountaddress";
export const ML_ACCNAME = "/accountname";
export const ML_ACCPHONE = "/accountphone";
export const ML_PHONE = "/phone";
export const ML_ITEMCHECK = "/checkitem";
export const ML_ITEMADD = "/additem";
export const ML_EDITDUE = "/editdue";
export const ML_EDITSTATUS = "/editstatus";
export const ML_ORDEREDIT = "/editorder";
export const ML_SIGNIN = "/signin";
export const ML_CHARGE = "/charge";
export const ML_SELECT = "/select";
export const ML_PAYINPROGRESS = "/payinprogress";
export const ML_AUTHREQUEST = "/authrequest";
export const ML_CANCELPAYAUTH = "/cancelauth";
export const ML_RECEIPT = "/receipt";
