import React, { useState, useCallback, FC, useEffect, useRef } from "react";
import * as R from "ramda";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { ModalContext } from "./useModal";
import ModalPortal from "./modalPortal";
import * as History from "history";

export interface ModalBackgroundState {
  background: History.Location;
}

export interface ModalProps {
  closeModal: CloseModal;
}

export type ShowModal = <Props extends ModalProps>(
  component: React.ComponentType<Props>,
  props: Omit<Props, "closeModal">,
  path?: string,
  replace?: boolean
) => CloseModal;

export type CloseModal = (shouldGoBack?: boolean | React.MouseEvent) => void;

let id = 0;

interface ModalConfig<T extends ModalProps> {
  uniqueId: number;
  component: React.ComponentType<T>;
  path: string;
  props: T;
}

const ModalManager: FC = React.memo(({ children }) => {
  const history = useHistory();
  const location = useLocation<ModalBackgroundState>();
  const background = location.state && location.state.background;
  const [modals, setModals] = useState<ModalConfig<any>[]>([]);

  useEffect(() => {
    // When we load the page, if there's a background state, that indicates we had
    // a modal before refreshing. Modal state doesn't persist through refresh, so we
    // need to close the modal, which means routing to the background state.
    if (background) {
      history.replace(background);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unmountedRef = useRef(false);
  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const redirectRef = useRef<{
    path?: string;
    replace?: boolean;
    goBack?: boolean;
  }>();
  const showModal = useCallback(
    function <Props extends ModalProps>(
      component: React.ComponentType<Props>,
      props: Omit<Props, "closeModal">,
      path: string = "/modal",
      replace: boolean = false
    ) {
      // Only set shouldGoBack to false if you plan to do custom routing
      //   For example, the login modal does a redirect.
      const closeModal: CloseModal = (shouldGoBack = true) => {
        if (unmountedRef.current) {
          // We've unmounted, likely because of logging into an admin,
          //   which forces lazy loading of the dashboard.
          return;
        }
        setModals((prev) => {
          const i = prev.indexOf(config);
          const newModals = R.remove(i, 1, prev);
          if (newModals.length === 0 && shouldGoBack !== false) {
            // Can't do a redirect in the middle of a set state, so remember the
            //   params and do it in a useEffect after the render finishes
            redirectRef.current = { goBack: true };
          }
          return newModals;
        });
      };
      const config: ModalConfig<Props> = {
        uniqueId: ++id,
        component,
        path,
        props: {
          ...props,
          closeModal,
        } as Props,
      };
      setModals((prev) => {
        const alreadyOnModal =
          prev[0] &&
          matchPath(location.pathname, {
            path: prev[0].path,
            exact: true,
          });
        if (alreadyOnModal) {
          // Append the modal to the config, but don't change the route.
          return [...prev, config];
        } else {
          // Start a new modal stack, and route to it.
          // Can't do a redirect in the middle of a set state, so remember the
          //   params and do it in a useEffect after the render finishes
          redirectRef.current = { path, replace };
          return [config];
        }
      });

      return closeModal;
    },
    [location]
  );

  useEffect(() => {
    if (redirectRef.current) {
      const { replace, path, goBack } = redirectRef.current;
      redirectRef.current = undefined;
      if (goBack) {
        history.goBack();
      } else {
        const action = replace ? "replace" : "push";
        history[action](path || "", {
          background: background || location,
        });
      }
    }
  });

  return (
    <ModalContext.Provider value={showModal}>
      <React.Fragment>
        {children}
        <ModalPortal>
          {modals.map((config, i) => {
            const { component: Component, props, uniqueId } = config;
            const match = matchPath(location.pathname, {
              // If there's a stack of modals, we only use the path from the first.
              // We've had bugs with multi-modal back nav, so we decided that any
              // extra modals are just treated as part of the first modal.
              path: modals[0].path,
              exact: true,
            });
            return (
              <div
                key={uniqueId}
                style={
                  match && i === modals.length - 1
                    ? undefined // Top modal. Display it.
                    : { display: "none " } // Background modal. Keep it mounted, but hide it
                }
              >
                <Component {...props} />
              </div>
            );
          })}
        </ModalPortal>
      </React.Fragment>
    </ModalContext.Provider>
  );
});

export default ModalManager;
