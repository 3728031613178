import React, { useState, useEffect, useContext, FC, useCallback } from "react";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import ModalVeil from "../../Modal/modalVeil";
import { ModalProps } from "../../Modal/modalManager";
import { FirebaseContext } from "../../../Firebase";
import useSpinner from "../../Main/useSpinner";
import { PaymentStatus } from "../../../utilities/httpsCallables/types";
import { useTranslation } from "react-i18next";
import { hexToRgbString } from "../../../utilities/colorUtils";

interface STpayThirdPartyModalProps extends ModalProps {
  paymentMethodString: string;
  successAction: () => void;
  tempId: string;
}

const STpayThirdPartyModal: FC<STpayThirdPartyModalProps> = React.memo(
  ({ closeModal, paymentMethodString, successAction, tempId }) => {
    // console.log("sTpayThirdParty.tsx", tempId);
    const store = useStore();
    const { color, mainColorDark } = store.theme;
    const showSpinner = useSpinner();
    const { t } = useTranslation();
    // Resolved will immediately close the modal, so need to track in state
    const [status, setStatus] = useState<Exclude<PaymentStatus, "resolved">>(
      "pending"
    );
    const doClose = useCallback(
      (resolved: boolean) => {
        closeModal(false);
        if (resolved) {
          successAction();
        }
      },
      [closeModal, successAction]
    );
    const firebase = useContext(FirebaseContext);

    useEffect(() => {
      const hideSpinner = showSpinner();
      try {
        const unsubscribe = firebase.firestore
          .collection("stores")
          .doc("store")
          .collection("temp")
          .doc(tempId)
          .onSnapshot((tempDoc) => {
            const tempData = tempDoc.data();
            if (tempData) {
              if (tempData.status === "resolved") {
                console.log("BANCONTACT PAYMENT SUCCESSFUL.");
                hideSpinner();
                doClose(true);
              } else {
                setStatus(tempData.status);
                if (tempData.status === "pending") {
                  console.log("BANCONTACT PAYMENT PENDING.");
                } else {
                  console.log("BANCONTACT PAYMENT FAILED.");
                  hideSpinner();
                }
              }
            } else {
              setStatus("failed");
              hideSpinner();
            }
          });
        return unsubscribe;
      } catch (error) {
        console.log("ERROR LISTENING FOR TEMP FLAG CHANGE", error);
        hideSpinner();
      }
    }, [doClose, firebase.firestore, showSpinner, tempId]);
    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            color: color,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader"
            style={{ backgroundColor: mainColorDark }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                color: color,
              }}
            >
              <Icon name="information" fill={color} />
              <div>
                &nbsp;
                {status === "pending" ? "Payment In Progress" : "Order Failed"}
              </div>
            </div>
            <br />
            <div
              className="AppFontSizeSmall"
              style={{ fontWeight: "bold", color: color }}
            ></div>
            <div
              className="AppFontSizeSmall"
              style={{ fontWeight: "bold", color: color }}
            >
              {status === "pending"
                ? `Communicating with ${paymentMethodString}...`
                : "Something went wrong..."}
            </div>
          </div>
          <div className="sTmodalContent">
            <div style={{ padding: "2em 1em", color: "black" }}>
              {status === "pending" ? (
                <span>
                  {t("store.checkout.payment.standBy", {
                    method: paymentMethodString,
                  })}
                </span>
              ) : (
                <span>{t("store.checkout.payment.decline")}</span>
              )}
            </div>
          </div>
          {status !== "pending" && (
            <div
              className="sTmodalFooter AppFontSizeSmall"
              style={{
                backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
              }}
            >
              <div
                //USER WILL BE ROUTED TO EITHER TRACK OR MENU
                //BECAUSE OF THE REDIRECT IN CHECKOUT.JS
                onClick={() => doClose(false)}
                className="sTmodalFooterItem AppFontSizeSmall"
                style={{ color: color }}
              >
                {t("common.ok")}
              </div>
            </div>
          )}
        </div>
        <ModalVeil onClick={() => {}} />
      </React.Fragment>
    );
  }
);

export default STpayThirdPartyModal;
