import React, { FC, useState, useEffect } from "react";
import "../css/order.css";
import cat1_img from "../../../img/cat1_img.jpg";
import { Category_Database } from "../../../database/catalog";
import useStore from "../../../customization/useStore";
import { srcSetString } from "../../Main/main";
import Icon from "../../Icon/icon";

export interface CategoryProps {
  index: number;
  catIndex: number;
  setCatIndex: (index: number) => void;
  category: Category_Database;
}

const Category1: FC<CategoryProps> = React.memo(
  ({ index, setCatIndex, catIndex, category }) => {
    // console.log("INDEX", index);
    const store = useStore();
    const { theme } = store;
    const [imageInfo, setImageInfo] = useState<{
      loaded: boolean;
      error: boolean;
    }>({
      loaded: false,
      error: false,
    });
    useEffect(() => {
      setImageInfo({ loaded: false, error: false });
    }, [category.imageUrl]);

    return (
      <React.Fragment>
        {/* UPDATED 7/30/2020
          USED WHEN:
          - SETTINGS ARE: LANDSCAPE TEXT / PORTRAIT IMGS 
          - APP IS IN LANDSCAPE VIEW
          */}
        {theme.template.category === "imgM_noImgL" ? (
          <div
            id={catIndex.toString()}
            onClick={() => {
              setCatIndex(index);
            }}
            className="sTcategoryItemText"
            style={{
              textAlign: "left",
              margin: "0.5em",
              textDecoration: catIndex === index ? "underline" : "",
            }}
          >
            {category.name}
          </div>
        ) : (
          /* UPDATED 7/30/2020
          USED WHEN:
          - SETTINGS ARE: LANDSCAPE IMGS / PORTRAIT IMGS 
          - APP IS IN LANDSCAPE VIEW
          */
          <div
            key={index}
            className="sTcategoryBar boxShadow1"
            style={{ marginBottom: "1em" }}
          >
            <div
              onClick={() => {
                setCatIndex(index);
              }}
              style={{
                opacity: imageInfo.loaded ? 1 : 0,
                transition: "all 0.5s linear 0s",
                marginLeft: 0,
              }}
              className="sTcategoryItemPicL"
            >
              <div className="sTcatBarImg" style={{}}>
                <img
                  src={!imageInfo.error ? category?.imageUrl : cat1_img}
                  srcSet={srcSetString(category.imageUrls)}
                  sizes="25vw"
                  alt={category.name}
                  onLoad={() => {
                    if (!imageInfo.error) {
                      setImageInfo({
                        loaded: true,
                        error: false,
                      });
                    }
                  }}
                  onError={(e) => {
                    setImageInfo({
                      loaded: true,
                      error: true,
                    });
                  }}
                  style={{
                    verticalAlign: "middle",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                />
              </div>
              <div
                style={{
                  transition: "all 0.1s linear 0s",
                  fontWeight: catIndex === index ? "bold" : "normal",
                  width: "100%",
                  textAlign: "left",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                }}
                className="sTcategoryText"
              >
                <Icon name="menu_open" fill="black" />
                &nbsp;{category.name}
              </div>
            </div>
          </div>
        )}
        {/* UPDATED 7/30/2020
          USED WHEN:
          - SETTINGS ARE: LANDSCAPE IMGS / PORTRAIT IMGS 
          - APP IS IN PORTRAIT VIEW
          */}
        <div
          onClick={() => {
            setCatIndex(index);
          }}
          style={{
            overflow: "hidden",
            transition: "all 0.5s linear 0s",
            opacity: imageInfo.loaded ? 1 : 0,
            position: "relative",
            marginLeft: index === 0 ? 0 : undefined,
          }}
          className="sTorderItem1 AppFontSizeSmall sTcategoryItemPic sTcatBarImg boxShadow1"
        >
          <img
            src={!imageInfo.error ? category?.imageUrl : cat1_img}
            srcSet={srcSetString(category.imageUrls)}
            sizes="12em"
            alt=""
            onLoad={() => {
              if (!imageInfo.error) {
                setImageInfo({
                  loaded: true,
                  error: false,
                });
              }
            }}
            onError={(e) => {
              setImageInfo({
                loaded: true,
                error: true,
              });
            }}
            style={{
              objectFit: "cover",
              // imageInfo.orientation === "landscape" ? "cover" : "contain",
              objectPosition: category.imageZoom
                ? `${category.imageZoom.x}% center`
                : "center",
              height: "12em" /* CHANGING THIS AFFECTS SRCSET DOWNLOADS */,
              maxWidth: "100%",
              borderRadius: "5px",
              verticalAlign: "middle",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              transition: "all 0.1s linear 0s",
              fontWeight: catIndex === index ? "bold" : "normal",
            }}
            className="sTcategoryImageText sTblackFaded5"
          >
            {category.name}
          </div>
        </div>
      </React.Fragment>
    );
  }
);

export default Category1;
