export const swOffline = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/sw.js")
      .then((reg) => {
        //THE ORDER OF THE SERVICE WORKER REGISTRATION WILL VARY.
        //SOMETIMES IT WILL SHOW BEING REGISTERED
        //AFTER IT WAS ACTIVATED. THIS IS NORMAL BEHAVIOR.
        // console.log("serviceWorker registered", reg);
      })
      .catch((error) => {
        //console.log("serviceWorker not registered.", error);
      });
  } else {
    //INDICATE NO BROWSER SUPPORT TO THE USER?
  }
};
