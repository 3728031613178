import React, { FC } from "react";
import { PageTemplate } from "../../../database/storeLocation";
import "../css/about.css";
import Dompurify from "dompurify";

interface RichTextPageProps {
  template: PageTemplate & { type: "richText" };
}

const RichTextPage: FC<RichTextPageProps> = ({ template }) => {
  return (
    <div
      className="sTaboutOuterWrap"
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <div className="sTaboutHeader"></div>
      <div className="sTaboutContentsWrap">
        <div className="sTaboutContents2">
          <div
            className="sTaboutTextWrap"
            dangerouslySetInnerHTML={{
              __html: Dompurify.sanitize(template.content.trim()),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default RichTextPage;
