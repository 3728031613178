import React, { FC, useContext, useState } from "react";
import * as R from "ramda";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import ModalVeil from "../../Modal/modalVeil";
import { ModalProps } from "../../Modal/modalManager";
import { useTranslation } from "react-i18next";
import useToast from "../../Main/useToast";
import * as APPCONSTANTS from "../../../utilities/constants/appConstants";
import {
  Order,
  paymentRechargeStatus as paymentRechargeStatusType,
} from "../../../database/order";
import { FirebaseContext } from "../../../Firebase";
import { orderRechargeUpdate } from "../../../utilities/httpsCallables/httpsCallables";
import useSpinner from "../../Main/useSpinner";
import Dinero from "dinero.js";
import { memoizedGetTotals } from "../../../utilities/orderProcessing";
import { hexToRgbString } from "../../../utilities/colorUtils";
interface STauthCancelModalProps extends ModalProps {
  redirect: () => void;
  order: Order;
  paymentIntentId: string;
}

const STauthCancelModal: FC<STauthCancelModalProps> = React.memo(
  ({ closeModal, order, redirect, paymentIntentId }) => {
    const store = useStore();
    const firebase = useContext(FirebaseContext);
    const showSpinner = useSpinner();
    const { t } = useTranslation();
    const { mainColorDark, color } = store.theme;
    const toast = useToast();
    //REVERT ADJUSTMENT BACK TO THE LAST ONE BEFORE THE NEW ORDER EDIT
    const [adjustment] = useState(
      Dinero({ amount: order.totalAdjustmentLast || 0 })
    );
    const handleSubmit = async () => {
      const orderNew = R.clone(order);
      Object.values(orderNew.suborders).forEach((suborder) => {
        suborder.items = R.filter((item) => {
          return !item.awaitingUpcharge;
        }, suborder.items);
      });
      const originalTotal = Dinero({ amount: order.total });
      const { totalTax, preTax, taxTable } = memoizedGetTotals(
        orderNew.suborders,
        store
      );
      const newSubtotal = preTax.add(adjustment);
      const newTotal = newSubtotal.add(totalTax);
      const totalDiff = newTotal.subtract(originalTotal);
      const type: "refund" | "upcharge" =
        totalDiff.getAmount() < 0 ? "refund" : "upcharge";
      const hideSpinner = showSpinner({ lag: "none" });
      try {
        await orderRechargeUpdate(firebase, {
          orderId: order.orderId,
          locationId: store.locationId,
          tax: totalTax.getAmount(),
          taxTable: R.map((tax) => tax.getAmount(), taxTable),
          total: newTotal.getAmount(),
          amount: Math.abs(totalDiff.getAmount()),
          chargeChange: type,
          emailFromSystem: store.emailFromSystem,
          emailTo: store.emailTo,
          customerEmail: order.customerEmail,
          allowMeals: store.allowMeals,
          suborders: orderNew.suborders,
          invoiceCode: order.invoiceCode,
          currencyObjectFromOrder: order.currency,
          adjustmentReason: order.totalAdjustmentReason || "",
          adjustmentAmount: order.totalAdjustmentLast || 0,
          paymentAuthSent: "delete",
          paymentRechargeStatus: paymentRechargeStatusType.complete_declined,
          adjustmentLast: order.totalAdjustmentLast || 0,
          userId: order.userId,
          url: `${window.location.protocol}//${window.location.host}`,
        });
        closeModal(false);
        redirect();
      } catch (error) {
        toast({
          message: t("toast.systemError"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
        console.log("ERROR UPDATING ORDER", error);
      } finally {
        hideSpinner();
      }
    };
    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            color: color,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader"
            style={{
              backgroundColor: mainColorDark,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                color: color,
              }}
            >
              <Icon name="warning" fill={color} />
              <div>&nbsp;{t("store.paymentAuth.cancelCharge")}</div>
            </div>
            <br />
            <div
              className="AppFontSizeSmall"
              style={{ fontWeight: "bold", color: color }}
            >
              {t("store.paymentAuth.confirmCancel")}
            </div>
            <div className="AppFontSizeSmall"></div>
          </div>
          <div className="sTmodalContent">
            <div style={{ padding: "2em 1em", color: "black" }}>
              {t("store.paymentAuth.confirmCancelText")}
            </div>
          </div>
          <div
            className="sTmodalFooter AppFontSizeSmall"
            style={{
              backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
            }}
          >
            <div
              onClick={closeModal}
              className="sTmodalFooterItem AppFontSizeSmall"
              style={{ color: color }}
            >
              {t("store.paymentAuth.cancelCancelButton")}
            </div>
            <div
              onClick={async () => {
                try {
                  await handleSubmit();
                } catch (error) {
                  console.log("Error canceling the extra charge:", error);
                  toast({
                    message: t("toast.systemError"),
                    className: "sTthemeAlert1b",
                    duration: APPCONSTANTS.TOASTDURATION_ERROR,
                  });
                }
              }}
              className="sTmodalFooterItem AppFontSizeSmall"
              style={{ color: color, fontWeight: "bold" }}
            >
              {t("store.paymentAuth.confirmCancelButton")}
            </div>
          </div>
        </div>
        <ModalVeil onClick={closeModal} />
      </React.Fragment>
    );
  }
);

export default STauthCancelModal;
