import React, { useEffect, FC } from "react";
import {
  Switch,
  Route,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";
import useStore from "../../../customization/useStore";
import PaymentForm from "../Checkout/paymentForm";
import "../css/checkout.css";
import SectionUserSO from "../Checkout/sectionUserSO";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import useAccountInfo from "../../Main/useAccountInfo";
import moment from "moment";
import { nextTimeOpen, isOpenU } from "../../../utilities/checkStoreOpen";
import { useStateWithSessionStorage } from "../../../utilities/useStateWithStorage";
import { CHECKOUT } from "../../../utilities/constants/routes";
import CheckoutOptions from "../Checkout/checkoutOptions";
import ShippingOptions from "../Checkout/shippingOptions";
import {
  StoreLocation,
  LocationAddress,
} from "../../../database/storeLocation";
import usePrevious from "../../../utilities/usePrevious";
import { Store } from "../../../customization/storeContext";
import { memoizedGetTotals } from "../../../utilities/orderProcessing";
import useCart from "../../CartManager/useCart";
import { useTranslation } from "react-i18next";

export interface CheckoutProps {}

export interface GuestDetails {
  email: string;
  phone: string;
}

export type DeliveryOption = "PICKUP" | "DELIVERY" | "SHIPPING";

export const shippingOnly = (store: StoreLocation) => {
  const shippingEnabled = store.delivery.shipping?.enabled;
  const deliveryEnabled =
    typeof store.delivery.delivery === "boolean"
      ? store.delivery.delivery
      : store.delivery.delivery.enabled;
  const pickupEnabled = store.delivery.pickup;
  return shippingEnabled && !deliveryEnabled && !pickupEnabled;
};

/**
 * Calculates the path for the second step. If the store only supports
 * shipping, then they need to enter shipping info before proceeding
 * to payment. Else, go to payment first, and then can opt in to shipping
 */
export const secondCheckoutStep = (store: StoreLocation) => {
  return shippingOnly(store) ? `${CHECKOUT}/shipping` : `${CHECKOUT}/payment`;
};

const defaultDeliveryOption = (store: StoreLocation) => {
  return shippingOnly(store) ? "SHIPPING" : "PICKUP";
};

const defaultAddress = (): LocationAddress => ({
  city: "",
  countryCode: "",
  stateCode: "",
  streetLine1: "",
  streetLine2: "",
  streetLine3: "",
  zip: "",
});

const defaultRequestedTime = (store: Store) => {
  const earliest = moment().add(store.orderIncrement + 1, "minutes");
  if (isOpenU(earliest, store)) {
    return earliest;
  }
  return nextTimeOpen(earliest || moment(), store);
};

const serializeMoment = (m: moment.Moment) => m.format("x");
const deserializeMoment = (str: string) => moment(str, "x");

const Checkout: FC<CheckoutProps> = React.memo(() => {
  const { t } = useTranslation();
  const accountInfo = useAccountInfo();
  const { user } = useAuthUser();
  const store = useStore();
  const history = useHistory();
  const location = useLocation();
  const { suborders } = useCart();
  const cartTotals = memoizedGetTotals(suborders, store);

  const [guestDetails, setGuestDetails] = useStateWithSessionStorage<
    GuestDetails
  >({
    key: "guestDetails",
    defaultValue: { email: "", phone: "" },
  });

  const [deliveryOption, setDeliveryOption] = useStateWithSessionStorage<
    DeliveryOption
  >({
    key: "deliveryOption",
    defaultValue: defaultDeliveryOption(store),
  });

  const [addressInfo, setAddressInfo] = useStateWithSessionStorage({
    key: "addressInfo",
    defaultValue: defaultAddress(),
  });

  const [requestedTime, setRequestedTime] = useStateWithSessionStorage({
    key: "requestedTime",
    defaultValue: defaultRequestedTime(store),
    serialize: serializeMoment,
    deserialize: deserializeMoment,
  });

  const prevUser = usePrevious(user);
  useEffect(() => {
    if (prevUser && !user && location.pathname !== CHECKOUT) {
      // User logged out. Clear their info, and go back to first step
      setDeliveryOption(defaultDeliveryOption(store));
      setAddressInfo(defaultAddress());
      setGuestDetails({
        email: "",
        phone: "",
      });
      console.log("User logged out; redirect to first step");
      history.replace(CHECKOUT);
    } else if (prevUser === null && user && location.pathname === CHECKOUT) {
      // User logged in. send them to the second step
      console.log("User logged in; redirect to second step");
      history.replace(secondCheckoutStep(store));
    }
  }, [
    history,
    location.pathname,
    prevUser,
    setAddressInfo,
    setDeliveryOption,
    setGuestDetails,
    store,
    user,
  ]);

  useEffect(() => {
    const validateTime = () => {
      if (requestedTime < moment().add(store.orderIncrement, "minutes")) {
        // If they sit on the checkout screen for a while the requested time may get
        //    to close to the current time. When that happens, we bump it forward
        setRequestedTime(defaultRequestedTime(store));
      }
    };

    // Validate immediately, unless we have a weird configuration which would cause
    //   an infinite loop of validate/render/validate/render, etc.
    if (store.orderIncrement > 0) {
      validateTime();
    }
    // In addition, validate as time passes.
    let id = setInterval(validateTime, 10000);

    return () => clearInterval(id);
  }, [requestedTime, setRequestedTime, store]);
  return (
    <div className="sTcheckoutOuterWrap sTthemeBGcolorSoft">
      <div className="sTcheckoutHeader"></div>
      {cartTotals.itemCount === 0 ? (
        <div className="sTcheckoutContentsWrap">
          <div className="sTcheckoutContents2">
            <div
              className=""
              style={{
                margin: "auto",
                color: "black",
              }}
            >
              {t("store.cart.emptyCart")}
            </div>
          </div>
        </div>
      ) : (
        <Switch>
          <Route path={`${CHECKOUT}/shipping`} component={ShippingOptions} />
          <Route
            path={`${CHECKOUT}/payment`}
            render={() => (
              <div className="sTcheckoutContentsWrap">
                <div className="sTcheckoutContents2">
                  <CheckoutOptions
                    deliveryOption={deliveryOption}
                    setDeliveryOption={setDeliveryOption}
                    addressInfo={addressInfo}
                    setAddressInfo={setAddressInfo}
                    guestDetails={guestDetails}
                    setGuestDetails={setGuestDetails}
                    requestedTime={requestedTime}
                    setRequestedTime={setRequestedTime}
                  />
                  <PaymentForm
                    deliveryOption={deliveryOption}
                    accountInfo={accountInfo}
                    addressInfo={addressInfo}
                    requestedTime={requestedTime}
                    guestDetails={guestDetails}
                  />
                </div>
              </div>
            )}
          />
          <Route
            path={CHECKOUT}
            render={() =>
              user ? (
                // They're signed in, so they bypass the guest/login screen
                <Redirect to={secondCheckoutStep(store)} />
              ) : (
                <SectionUserSO
                  guestDetails={guestDetails}
                  setGuestDetails={setGuestDetails}
                />
              )
            }
          />
        </Switch>
      )}
    </div>
  );
});

export default Checkout;
