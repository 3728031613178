import React from "react";
import Icon from "../../Icon/icon";
import { Communication } from "../../../database/storeLocation";
import { TFunction } from "i18next";
import { useHistory } from "react-router-dom";
import StoreButton from "../Components/stButton";
import { ACCOUNT } from "../../../utilities/constants/routes";

type Options = {
  storeCommunication: Communication;
  notifyPush: boolean;
  notifySms: boolean;
  email?: string;
  phone?: string;
  t: TFunction;
};
const CommSettings = ({
  storeCommunication,
  notifyPush,
  notifySms,
  email,
  phone,
  t,
}: Options) => {
  const history = useHistory();
  return (
    <div
      className="AppFontSizeSmall"
      style={{
        backgroundColor: "white",
        color: "black",
        borderRadius: "5px",
        boxShadow: "0 2px 2px 0 rgba(0,0,0,.24), 0 2px 2px 0 rgba(0,0,0,.12)",
      }}
    >
      <div style={{ padding: "0.5em" }}>
        <div style={{ marginBottom: "0.5em" }}>{email}</div>
        {
          //STORE ALLOWS SMS BUT NO CUSTOMER PHONE AVAILABLE
          ((storeCommunication.useSMS && notifySms && phone === "") ||
            //STORE USES SMS BUT CUSTOMER TURNED OFF SMS
            (storeCommunication.useSMS && !notifySms) ||
            //STORE USES PUSH BUT CUSTOMER TURNED OFF PUSH
            (storeCommunication.usePush && !notifyPush)) && (
            <div style={{ fontWeight: "bold" }}>
              {t("store.contactUs.youCanAlso")}
            </div>
          )
        }
        {storeCommunication.useSMS && notifySms ? (
          phone === "" && (
            <div style={{ paddingLeft: "0.25em" }}>
              {t("store.contactUs.addPhone")}
            </div>
          )
        ) : (
          <div style={{ paddingLeft: "0.25em" }}>
            {t("store.contactUs.turnOnSMS")}
          </div>
        )}
        {storeCommunication.usePush && !notifyPush && (
          <div style={{ paddingLeft: "0.25em" }}>
            {t("store.contactUs.turnOnPush")}
          </div>
        )}
        {storeCommunication.useSMS && notifySms && phone !== "" && (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              paddingLeft: "0.25em",
            }}
            className="AppFontSizeExtraSmall"
          >
            <Icon name="thumb_up" />
            &nbsp;
            {t("store.contactUs.receivingSMS")}
          </div>
        )}
        {storeCommunication.usePush && notifyPush && (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              paddingLeft: "0.25em",
            }}
            className="AppFontSizeExtraSmall"
          >
            <Icon name="thumb_up" />
            &nbsp;
            {t("store.contactUs.receivingPush")}
          </div>
        )}
      </div>
      <StoreButton
        text={t("store.contactUs.viewAccount")}
        style={{ color: "black" }}
        theme="white left"
        iconColor="black"
        icon="settings2"
        onClick={() => {
          history.replace(`${ACCOUNT}`);
        }}
      />
    </div>
  );
};

export default CommSettings;
