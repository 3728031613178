import { OptionCollection, optionType, Option } from "../database/option";
import { CatalogItem_Database } from "../database/catalogItem";
import {
  CartItem,
  OptionSelection,
  ListOptionSelection,
  NumberOptionSelection,
  UnitOptionSelection
} from "../database/cart";
import notNull from "./notNull";
import { useState, useEffect } from "react";
import usePrevious from "./usePrevious";
import * as R from "ramda";
import useStore from "../customization/useStore";

export const getDefaultSelections = (
  options: OptionCollection,
  catalogItem?: CatalogItem_Database,
  cartItem?: CartItem
): OptionSelection[] => {
  if (cartItem && cartItem.optionSelections) {
    // If the item is in the cart, use the options they have selected
    return cartItem.optionSelections;
  } else if (catalogItem && catalogItem.options) {
    // Otherwise, use the default options form the catalog
    return catalogItem.options
      .map(optionRef => {
        const option = options[optionRef.optionId];
        if (option) {
          return getDefaultSelectionForOption(option);
        }
        return null;
      })
      .filter(notNull);
  } else {
    return [];
  }
};

export const getDefaultSelectionForOption = (
  option: Option
): OptionSelection => {
  switch (option.type) {
    case optionType.LIST: {
      const selectedItems: { [id: string]: boolean } = {};
      option.items.forEach(optionItem => {
        if (optionItem.selectedByDefault) {
          selectedItems[optionItem.id] = true;
        }
      });
      const selection: ListOptionSelection = {
        type: optionType.LIST,
        optionId: option.optionId,
        selectedItems
      };
      return selection;
    }
    case optionType.NUMBER: {
      const selection: NumberOptionSelection = {
        type: optionType.NUMBER,
        optionId: option.optionId,
        value: option.defaultValue
      };
      return selection;
    }
    case optionType.UNIT: {
      const selection: UnitOptionSelection = {
        type: optionType.UNIT,
        optionId: option.optionId,
        value: option.defaultValue
      };
      return selection;
    }
  }
};

/**
 * Creates state for selections, starting with defaults and resetting
 * to defaults if new data is published
 */
export const useSelectedOptions = (
  itemId: string,
  cartItem?: CartItem
): [
  OptionSelection[],
  React.Dispatch<React.SetStateAction<OptionSelection[]>>
] => {
  const { items, options } = useStore();
  const catalogItem = items[itemId];
  const [selectedOptions, setSelectedOptions] = useState(() =>
    getDefaultSelections(options, catalogItem, cartItem)
  );

  const previousOptions = usePrevious(options);
  const previousCatalogItem = usePrevious(catalogItem);
  useEffect(() => {
    if (
      previousOptions &&
      previousCatalogItem &&
      (options !== previousOptions || catalogItem !== previousCatalogItem)
    ) {
      // We may have new options. Check in greater detail
      const relevantOptions = (catalogItem?.options ?? []).map(
        optionRef => options[optionRef.optionId]
      );
      const previousRelevantOptions = (previousCatalogItem?.options ?? []).map(
        optionRef => previousOptions[optionRef.optionId]
      );
      if (!R.equals(relevantOptions, previousRelevantOptions)) {
        // We *do* have new options. Set new defaults
        setSelectedOptions(
          getDefaultSelections(options, catalogItem, cartItem)
        );
      }
    }
  }, [cartItem, catalogItem, options, previousCatalogItem, previousOptions]);

  return [selectedOptions, setSelectedOptions];
};
