import React from "react";
import {
  DatabaseCartItem,
  isListOptionSelection,
  isNumberOptionSelection,
  isUnitOptionSelection
} from "../../../database/cart";
import useStore from "../../../customization/useStore";
import Icon from "../../Icon/icon";
import { AccountInfo } from "../../Main/main";
import {
  isListOption,
  isNumberOption,
  isUnitOption
} from "../../../database/option";

export interface TrackingDetailCartItemProps {
  cartItem: DatabaseCartItem;
  count: number;
  onFavoriteClicked: (itemId: string) => void;
  accountInfo: AccountInfo;
}

const TrackingDetailCartItem: React.FC<TrackingDetailCartItemProps> = ({
  cartItem,
  count,
  onFavoriteClicked,
  accountInfo
}) => {
  const { items, theme, options } = useStore();
  const { iconColor } = theme;
  const catalogItem = items[cartItem.itemId];

  if (!catalogItem) {
    return null;
  }
  return (
    <div className="">
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          fontWeight: "bold",
          textAlign: "left",
          textDecoration: cartItem.unable ? "line-through" : ""
        }}
      >
        {catalogItem.name}
        &nbsp;
        <span
          className="sTtoggleOrLinkButton AppFontSizeSmall"
          onClick={() => onFavoriteClicked(cartItem.itemId)}
        >
          &nbsp;
          <Icon
            name={
              accountInfo.favorites &&
              accountInfo.favorites[cartItem.itemId] &&
              accountInfo.favorites[cartItem.itemId]["fav"]
                ? "favorite"
                : "unfavorite"
            }
            fill={iconColor}
          />
        </span>
      </div>
      <div
        className="AppFontSizeSmall"
        style={{ textAlign: "left", display: "flex" }}
      >
        Count:&nbsp;<strong>{count}</strong>
      </div>

      {cartItem.instructions && cartItem.instructions?.length > 0 && (
        <div className="AppFontSizeSmall" style={{ textAlign: "left" }}>
          Instructions: &nbsp;<strong>{cartItem.instructions}</strong>
        </div>
      )}
      <div className="AppFontSizeSmall">
        {cartItem.optionSelections &&
          cartItem.optionSelections.map(selection => {
            const option = options[selection.optionId];

            if (isListOption(option) && isListOptionSelection(selection)) {
              if (Object.keys(selection.selectedItems).length === 0) {
                return null;
              } else {
                return (
                  <div key={selection.optionId} style={{ display: "flex" }}>
                    <div>
                      {`${option.name}:`}
                      &nbsp;
                    </div>
                    {Object.keys(selection.selectedItems).map(id => {
                      let optionItem = option.items.find(
                        item => item.id === id
                      );
                      return (
                        optionItem && (
                          <div
                            key={id}
                            style={{
                              fontWeight: "bold",
                              paddingRight: "0.5em"
                            }}
                          >
                            {`${optionItem.description}`}
                          </div>
                        )
                      );
                    })}
                  </div>
                );
              }
            } else if (
              isNumberOption(option) &&
              isNumberOptionSelection(selection)
            ) {
              return (
                <div key={selection.optionId} style={{ display: "flex" }}>
                  {`${option.name}: ${selection.value} ${option.unit || ""}`}
                </div>
              );
            } else if (
              isUnitOption(option) &&
              isUnitOptionSelection(selection)
            ) {
              return (
                <div key={selection.optionId} style={{ display: "flex" }}>
                  {`${selection.value} ${option.name}`}
                </div>
              );
            }
            return null;
          })}
      </div>
    </div>
  );
};

export default TrackingDetailCartItem;
