import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../../../Firebase";
import cat1_img from "../../../img/cat1_img.jpg";
import useStore from "../../../customization/useStore";
import Icon from "../../Icon/icon";
import toggleFavorite from "../../../utilities/toggleFavorite";
import "../css/order.css";
import useToast from "../../Main/useToast";
import useSpinner from "../../Main/useSpinner";
import { useTranslation } from "react-i18next";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { ItemProps } from "./itemOrderBtns";
import { useHistory, useLocation } from "react-router-dom";
import { useSelectedOptions } from "../../../utilities/defaultSelections";
import useItemPriceLabels from "./useItemPriceLabels";
import OptionButton from "../optionButton";
import { srcSetString } from "../../Main/main";

/**
 * Displays an item in the catalog, without buttons for interacting.
 * Instead, those buttons will be available on the product page
 */
const Item = React.memo(
  React.forwardRef<HTMLDivElement, ItemProps>(
    ({ cartItem, categoryName, itemId, accountInfo, index, source }, ref) => {
      const store = useStore();
      const { theme, items } = store;
      const catalogItem = items[itemId];
      const { user } = useAuthUser();
      const showSpinner = useSpinner();
      const firebase = useContext(FirebaseContext);
      const { mainColor, color, iconColor } = theme;
      const toast = useToast();
      const { t } = useTranslation();
      const history = useHistory();
      const location = useLocation();

      const [selectedOptions] = useSelectedOptions(itemId, cartItem);
      const [added, setAdded] = useState(false);
      function databaseAction(itemId: string) {
        if (!catalogItem) return;
        if (user) {
          toggleFavorite({
            firebase,
            showSpinner,
            accountInfo,
            itemId,
            user,
          });
        } else {
          toast({
            message: t("store.orders.startFavoring"),
            color: color,
            backgroundColor: mainColor,
          });
        }
      }

      useEffect(() => {
        if (added) {
          let timer = setTimeout(() => setAdded(false), 300);
          return () => clearTimeout(timer);
        }
      }, [added]);

      const { priceLabel, priceExtraLabel } = useItemPriceLabels({
        catalogItem,
        selectedOptions,
        count: 1,
        isCart: false,
      });
      const [imageInfo, setImageInfo] = useState<{
        loaded: boolean;
        error: boolean;
      }>({ loaded: false, error: false });
      const itemImage = catalogItem?.imageUrl;
      useEffect(() => {
        setImageInfo({ loaded: false, error: false });
      }, [itemImage]);

      if (!catalogItem) {
        return null;
      }

      /**
       * MAKE SURE TO TEST BOTH SCENARIOS:
       * 1. THEME WITH ITEM IMAGES DISPLAYED
       * 2. THEME WITH ITEM IMAGES NOT DISPLAYED
       *    -> theme.template.item === "noImgNoOrder"
       *
       */
      return (
        <div
          ref={ref}
          onClick={() => {
            sessionStorage.setItem("itemId", itemId);
            history.push(
              `${location.pathname}/product/${catalogItem.itemId}`,
              source
            );
          }}
          className={
            theme.template.item === "noImgNoOrder"
              ? "sTorderItemBar2 boxShadow1"
              : "sTorderItemBar boxShadow1"
          }
          style={{
            transition: "all 0.3s linear 0s",
            minHeight:
              theme.template.item === "noImgNoOrder" ? "auto" : undefined,
          }}
        >
          <div className="sTorderItemBarWrap1">
            {theme.template.item !== "noImgNoOrder" && (
              <div className="sTitemItemPic">
                <img
                  src={!imageInfo.error ? catalogItem.imageUrl : cat1_img}
                  srcSet={srcSetString(catalogItem.imageUrls)}
                  sizes="7em"
                  alt={catalogItem.name}
                  onLoad={() => {
                    if (!imageInfo.error) {
                      setImageInfo({
                        loaded: true,
                        error: false,
                      });
                    }
                  }}
                  onError={(e) => {
                    setImageInfo({
                      loaded: true,
                      error: true,
                    });
                  }}
                  style={{
                    objectFit: "cover",
                    objectPosition: catalogItem.imageZoom
                      ? `${catalogItem.imageZoom.x}% center`
                      : "center",
                    height: "100%",
                    maxHeight: "7em",
                    maxWidth: "100%",
                    borderRadius: "5px",
                    verticalAlign: "middle",
                    opacity: imageInfo.loaded ? 1 : 0,
                    transition: "all 0.3s linear 0s",
                  }}
                />
              </div>
            )}
            <div
              className="sTorderItemBarContents"
              style={{
                width:
                  theme.template.item === "noImgNoOrder" ? "100%" : undefined,
                transition: "opacity 0.5s ease 0s",
                minHeight: theme.template.item === "noImgNoOrder" ? "0" : "",
              }}
            >
              <div style={{ padding: "0 0.25em" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    transition: "all 0.5s ease 0s",
                    wordBreak: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {catalogItem.name}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ maxWidth: "100%" }}>
                    <div
                      className="AppFontSizeSmall"
                      style={{
                        marginLeft: "0.25em",
                        color: catalogItem.unavailable ? "red" : "",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {catalogItem.unavailable
                        ? "Unavailable, check back soon!"
                        : categoryName}
                    </div>
                  </div>
                </div>
                <div
                  onClick={(e) => {
                    databaseAction(catalogItem.itemId);
                    e.stopPropagation();
                  }}
                  className="sTfavIconBar"
                  style={{ width: "2em", height: "2em" }}
                >
                  <Icon
                    name={
                      accountInfo.favorites &&
                      accountInfo.favorites[catalogItem.itemId] &&
                      accountInfo.favorites[catalogItem.itemId]["fav"]
                        ? "favorite"
                        : "unfavorite"
                    }
                    fill={iconColor}
                  />
                </div>
                <div style={{ lineHeight: "1em" }}>
                  <div
                    className=""
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {priceLabel}
                  </div>
                  <div className="AppFontSizeSmall">{priceExtraLabel}</div>
                </div>
              </div>
            </div>
          </div>
          <div style={{}} className="sTorderCustomInputBtnWrap">
            {catalogItem.options &&
              catalogItem.options.map((optionRef, i) => {
                const selection = selectedOptions.find(
                  (selection) => selection.optionId === optionRef.optionId
                );
                return (
                  <OptionButton
                    key={optionRef.optionId}
                    optionId={optionRef.optionId}
                    selection={selection}
                    onSelectionChanged={() => {}}
                    source="store"
                    allowClick={false}
                  />
                );
              })}
          </div>
        </div>
      );
    }
  )
);

export default Item;
