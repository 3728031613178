import { createContext } from "react";
import { StoreLocation } from "../database/storeLocation";
import { RawStore } from "../database/rawStore";
import { LocationPreview } from "../components/App/App";

export type Store = RawStore &
  StoreLocation & {
    locationId: string;
    isPreview: boolean;
    previewReturnUrl: string;
  };

// Restaurant Context contains data which is a merging
//  of the raw restaurant data, with location data.
const StoreContext = createContext<Store>({} as Store);

// In some places, we need the location data on its own.
//   StoreLocationContext makes that available.
export const StoreLocationContext = createContext<StoreLocation>(
  {} as StoreLocation
);

// During editing we need to be able to override the store
// location for preview purposes.
export const PreviewContext = createContext<
  React.Dispatch<React.SetStateAction<LocationPreview | null>>
>(() => {});

export default StoreContext;
