import { useContext } from "react";
import StoreContext from "./storeContext";

/**
 * Since its common for presentation components to only care about the theme part of
 * the restaurant customization, this hook is a convenience for plucking the theme.
 */
export default () => {
  const { theme } = useContext(StoreContext);
  return theme;
};
