import { createContext, PropsWithChildren } from "react";
import { ToastProps } from "../Toast/toast";
import { AccountInfo } from "./main";
import { SpinnerProps } from "../Spinner/spinner";

/**
 * Optional values for augmenting the global spinner.
 * isOpen and dBnavMenu are not needed, because main.tsx handles thos
 */
export type ShowSpinnerProps = Omit<SpinnerProps, "isOpen" | "dBnavMenuFull">;
/**
 * Function for displaying the global spinner
 */
export type ShowSpinner = (props?: ShowSpinnerProps) => () => void;

interface MainContextType {
  lockDimensions: (lock: boolean, source?: string) => void;
  setToastActive: (props: PropsWithChildren<ToastProps>) => void;
  showSpinner: ShowSpinner;
  accountInfo: AccountInfo;
}

export const MainContext = createContext<MainContextType>(
  {} as MainContextType
);
