import React, { useContext } from "react";
import Icon from "../../Icon/icon";
import { Order, orderStatus } from "../../../database/order";
import getOrderIdStrings from "../../../utilities/getOrderIdString";
import useToast from "../../Main/useToast";
import useCart from "../../CartManager/useCart";
import useModal from "../../Modal/useModal";
import STSendMessageModal from "../Modals/sTsendMessage";
import STMessageThreadModal from "../Modals/sTmessageThread";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { TRACK, CONTACT, ML_PHONE } from "../../../utilities/constants/routes";
import STaccPhoneModal from "../Modals/sTaccPhone";
import useStore from "../../../customization/useStore";
import { AccountInfo } from "../../Main/main";
import { FirebaseContext } from "../../../Firebase";
import useSpinner from "../../Main/useSpinner";
import changeNotifyPrefs from "../../../utilities/changeNotifyPrefs";
import toggleFavorite from "../../../utilities/toggleFavorite";
import TrackingDetailCartItem from "./trackingDetailCartItem";
import { trackingStatusIcons, trackingStatusStrings } from "./trackingSummary";
import { useTranslation } from "react-i18next";
import * as APPCONSTANTS from "../../../utilities/constants/appConstants";
import { useMessageThread } from "../../../utilities/useMessages";
import { groupEquivalent } from "../../../utilities/orderProcessing";
import { useHistory } from "react-router-dom";
import { PAYMENTAUTH } from "../../../utilities/constants/routes";
import { Message } from "../../../database/message";

export interface TrackingDetailProps {
  order: Order;
  accountInfo: AccountInfo;
}

const TrackingDetail: React.FC<TrackingDetailProps> = ({
  order,
  accountInfo,
}) => {
  const { t } = useTranslation();
  const { theme, address, allowMeals, communication } = useStore();
  const { mainColor, color, iconColor } = theme;
  const history = useHistory();
  const toast = useToast();
  const showSpinner = useSpinner();
  const showModal = useModal();
  const { restoreCart } = useCart();
  const { user, isSuperuser, isAdmin, isOwner } = useAuthUser();
  const firebase = useContext(FirebaseContext);
  const orderIdStrings = getOrderIdStrings(order.orderId);

  const { thread } = useMessageThread(order.messageThreadId);

  const pushOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.currentTarget.value === "YES";
    changeNotifyPrefs({
      firebase,
      showSpinner,
      element: "push",
      newValue,
      pushTokens: accountInfo.pushTokens,
      smsPref: accountInfo.notifySms,
      pushPref: accountInfo.notifyPush,
      toast,
      t,
      authUser: user,
    });
  };
  const smsOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.currentTarget.value === "YES";
    if (newValue && accountInfo.phone === "") {
      showModal(STaccPhoneModal, {
        source: "sms",
      });
    } else {
      changeNotifyPrefs({
        firebase,
        showSpinner,
        element: "sms",
        newValue,
        pushTokens: accountInfo.pushTokens,
        smsPref: accountInfo.notifySms,
        pushPref: accountInfo.notifyPush,
        toast,
        t,
        authUser: user,
      });
    }
  };
  function onFavoriteClicked(itemId: string) {
    if (user) {
      toggleFavorite({
        firebase,
        showSpinner,
        accountInfo,
        itemId,
        user,
      });
    }
  }
  // console.log("ORDER CHANGED", order);
  return (
    <div
      className="sTtrackCardWrap boxShadow1 sT_anim_fadeIn_0303"
      style={{ opacity: 0, zIndex: 2 }} //ZINDEX NEEDED FOR OVERLAPPING EMPTY MESSAGE
    >
      <div style={{ backgroundColor: "rgba(0,0,0,0.12)" }}>
        <div className="sTtrackCardHeader" style={{ alignItems: "flex-end" }}>
          <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Icon
                  name={
                    order.paymentRechargeStatus === "pendingauth"
                      ? "warning"
                      : trackingStatusIcons[order.status] || "report"
                  }
                  fill={
                    order.paymentRechargeStatus !== "pendingauth"
                      ? iconColor
                      : "red"
                  }
                />
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  &nbsp;
                  {order.paymentRechargeStatus !== "pendingauth"
                    ? t(
                        trackingStatusStrings[order.status] ||
                          "store.track.unknownStatus"
                      )
                    : t("store.track.awaitingPayment")}
                </div>
              </div>
              <div className="AppFontSizeSmall">
                {t("common.id")}:&nbsp;
                <span style={{ fontWeight: "bold", fontFamily: "arial" }}>
                  {orderIdStrings.mainId}
                </span>
                &nbsp;{t("common.subId")}:&nbsp;
                <span style={{ fontWeight: "bold", fontFamily: "arial" }}>
                  {orderIdStrings.subId}
                </span>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              if (
                order.status === orderStatus.ready ||
                order.status === orderStatus.intransit ||
                order.status === orderStatus.delivered
              ) {
                const failureMessage = restoreCart(order.suborders);
                if (failureMessage) {
                  toast({
                    message: failureMessage,
                    className: "sTthemeAlert1b",
                    duration: APPCONSTANTS.TOASTDURATION_ERROR,
                  });
                } else {
                  toast({
                    message: t("store.track.reoderConfirmation"),
                    color: color,
                    backgroundColor: mainColor,
                  });
                }
              } else if (order.messageThreadId) {
                showModal(
                  STMessageThreadModal,
                  {
                    threadId: order.messageThreadId,
                    initialThread: thread,
                  },
                  `${TRACK}${CONTACT}`
                );
              } else {
                showModal(
                  STSendMessageModal,
                  {
                    orderId: order.orderId,
                  },
                  `${TRACK}${CONTACT}`
                );
              }
            }}
            className="sTtoggleOrLinkButton AppFontSizeSmall"
            style={{ width: "5em" }}
          >
            <Icon
              name={
                order.status === orderStatus.ready ||
                order.status === orderStatus.intransit ||
                order.status === orderStatus.delivered
                  ? "redo"
                  : thread &&
                    Object.values(thread.messages).some(
                      (message: Message) =>
                        message.from === "admin" && !message.read
                    )
                  ? "message_unread"
                  : "message"
              }
              fill={iconColor}
            />
            {order.status === orderStatus.ready ||
            order.status === orderStatus.intransit ||
            order.status === orderStatus.delivered
              ? t("store.track.reorderButton")
              : t("common.message").toUpperCase()}
          </div>
        </div>
        <div className="sTtrackDetails">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div
                className="AppFontSizeSmall"
                style={{
                  paddingTop: "0.5em",
                  color:
                    order.paymentRechargeStatus === "pendingauth"
                      ? "red"
                      : iconColor,
                }}
              >
                {order.paymentRechargeStatus === "pendingauth"
                  ? t("store.track.newCharge")
                  : order.status === orderStatus.inqueue ||
                    order.status === orderStatus.inprogress
                  ? order.delivery.delivery
                    ? t("store.track.estimatedTransit")
                    : t("store.track.estimatedPickup")
                  : order.status === orderStatus.intransit
                  ? t("store.track.estimatedDelivery")
                  : order.status === orderStatus.ready ||
                    order.status === orderStatus.delivered
                  ? t("store.track.completedOn")
                  : order.delivery.delivery && t("store.track.orderedOn")}
              </div>
              <div style={{ fontWeight: "bold" }}>
                {order.paymentRechargeStatus === "pendingauth" ? (
                  <div
                    onClick={() => {
                      history.push(PAYMENTAUTH, {
                        order: order,
                      });
                    }}
                    style={{
                      textDecoration: "underline",
                      marginBottom: "1em",
                      color: "red",
                    }}
                  >
                    {t("store.track.reviewAndAuthorize")}
                  </div>
                ) : order.status !== orderStatus.ready &&
                  order.status !== orderStatus.intransit &&
                  order.status !== orderStatus.delivered ? (
                  order.delivery.timePlanned.toDate().toLocaleString([], {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                ) : (
                  order.delivery.timeCompleted!.toDate().toLocaleString([], {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                )}
              </div>
            </div>
            {order.status === orderStatus.delivered && order.messageThreadId && (
              <div
                style={{ width: "5em" }}
                className="sTtoggleOrLinkButton AppFontSizeSmall"
                onClick={() => {
                  order.messageThreadId &&
                    showModal(
                      STMessageThreadModal,
                      {
                        threadId: order.messageThreadId,
                        initialThread: thread,
                      },
                      `${TRACK}${CONTACT}`
                    );
                }}
              >
                <Icon name="message" />
                {t("common.message")}
              </div>
            )}
          </div>
          {order.status !== orderStatus.delivered && (
            <React.Fragment>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <div className="AppFontSizeSmall" style={{}}>
                    {t("store.account.pushNotifications")}&nbsp;
                  </div>
                  <div style={{ position: "relative", fontWeight: "bold" }}>
                    <div
                      style={{
                        fontSize: "1.2em",
                        textDecoration: "underline",
                      }}
                    >
                      {accountInfo.notifyPush ? "YES" : "NO"}
                    </div>
                    <select
                      name="pushOption"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        opacity: 0,
                        height: "1.3em",
                        width: "3em",
                      }}
                      value={accountInfo.notifyPush ? "YES" : "NO"}
                      onChange={(e) => {
                        !isAdmin && !isSuperuser && !isOwner
                          ? pushOptionChange(e)
                          : toast({
                              message: t(
                                "dashboard.settings.communication.adminNotifications"
                              ),
                              className: "sTthemeAlert1b",
                              duration: APPCONSTANTS.TOASTDURATION_ERROR,
                            });
                      }}
                    >
                      {firebase.pushSupported && (
                        <option value="YES">{t("common.yes")}</option>
                      )}
                      <option value="NO">{t("common.no").toUpperCase()}</option>
                    </select>
                  </div>
                </div>

                {communication.useSMS && (
                  <div style={{ marginLeft: "1em" }}>
                    <div className="AppFontSizeSmall" style={{}}>
                      {t("store.account.smsNotifications")}&nbsp;
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontWeight: "bold",
                        marginRight: "1em",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            fontSize: "1.2em",
                            textDecoration: "underline",
                          }}
                        >
                          {accountInfo.notifySms ? "YES" : "NO"}
                        </div>
                        <div
                          onClick={
                            accountInfo.notifySms
                              ? () => {
                                  showModal(
                                    STaccPhoneModal,
                                    {
                                      source: "sms",
                                    },
                                    `${TRACK}${ML_PHONE}`
                                  );
                                }
                              : () => {}
                          }
                          className="AppFontSizeSmall"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "11em",
                            width: "11em",
                            textDecoration: "underline",
                            color: iconColor,
                            fontWeight: "bold",
                            marginLeft: "1em",
                          }}
                        >
                          {accountInfo.notifySms ? accountInfo.phone : ""}
                        </div>
                      </div>
                      <select
                        name="smsOption"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          opacity: 0,
                          height: "1.3em",
                          width: "1.7em",
                        }}
                        value={accountInfo.notifySms ? "YES" : "NO"}
                        onChange={(e) => {
                          !isAdmin && !isSuperuser && !isOwner
                            ? smsOptionChange(e)
                            : toast({
                                message: t(
                                  "dashboard.settings.communication.adminNotifications"
                                ),
                                className: "sTthemeAlert1b",
                                duration: APPCONSTANTS.TOASTDURATION_ERROR,
                              });
                        }}
                      >
                        <option value="YES">{t("common.yes")}</option>
                        <option value="NO">
                          {t("common.no").toUpperCase()}
                        </option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
          {order.status !== orderStatus.delivered ? (
            <React.Fragment>
              <div
                className="AppFontSizeSmall"
                style={{
                  color: iconColor,
                }}
              >
                {order.delivery.delivery
                  ? t("common.deliveryAddress")
                  : t("common.pickupAddress")}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    maxWidth: "14em",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {!order.delivery.delivery ? (
                    <React.Fragment>
                      <div
                        style={{
                          maxWidth: "14em",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <span>{address.streetLine1.toUpperCase()}</span>
                        {address.streetLine2 && (
                          <span>&nbsp;{address.streetLine2.toUpperCase()}</span>
                        )}
                        {address.streetLine3 && (
                          <span>&nbsp;{address.streetLine3.toUpperCase()}</span>
                        )}
                      </div>
                      <div
                        style={{
                          maxWidth: "14em",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <span>{address.city.toUpperCase()}</span>
                        {address.stateCode && (
                          <span>
                            &nbsp;
                            {t(
                              `common.state.${address.stateCode}`
                            ).toUpperCase()}
                          </span>
                        )}
                        <span>&nbsp;{address.zip}</span>
                      </div>
                    </React.Fragment>
                  ) : (
                    order.customerAddress.toUpperCase()
                  )}
                </div>
                <div
                  onClick={
                    !order.delivery.delivery
                      ? () =>
                          window.location.assign(
                            `https://www.google.com/maps/search/?api=1&query=${address.googleMaps}`
                          )
                      : () =>
                          window.location.assign(
                            `https://www.google.com/maps/search/?api=1&query=${encodeURI(
                              order.customerAddress
                            )}`
                          )
                  }
                  className="sTtoggleOrLinkButton AppFontSizeSmall"
                  style={{ width: "5em" }}
                >
                  <Icon name="directions" fill={iconColor} />
                  <div
                    className="AppFontSizeSmall"
                    style={{ color: iconColor }}
                  >
                    {order.delivery.delivery ? "VERIFY" : "DIRECTIONS"}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <br />
          )}
        </div>
      </div>
      {Object.values(order.suborders).map((suborder) => {
        return (
          <div
            style={{
              padding: "0 0.5em 0.5em 0.5em",
              backgroundColor: "white",
            }}
            key={suborder.suborderId}
          >
            {allowMeals && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    marginTop: "0.5em",
                  }}
                >
                  {suborder.name.toUpperCase()}
                </div>
              </div>
            )}
            {groupEquivalent(suborder.items).map((group) => (
              <TrackingDetailCartItem
                key={group[0].cartItemId}
                cartItem={group[0]}
                count={group.length}
                onFavoriteClicked={onFavoriteClicked}
                accountInfo={accountInfo}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default TrackingDetail;
