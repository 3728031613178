import memoize from "memoize-one";

export const constructUrl = memoize(
  (routes: string[], parameters: string[], values: string[]) => {
    //1. CREATE MAIN PART E.G. 'http://localhost:3000'
    let url = `${window.location.href.split("/")[0]}//${window.location.host}`;
    //2. ADD THE ROUTES E.G. /checkout/hello
    routes.forEach(route => {
      url += `/${route}`;
    });
    //3. ADD THE `?` NEEDED TO APPEND PARAMETERS
    url += `?`;
    //4. ADD THE PARAMETERS ENDING WITH A '&'
    parameters.forEach((parameter, index) => {
      url += `${parameter}=${values[index]}&`;
    });
    //5. REMOVE THE LAST CHARACTER. IF PARAMS WERE PROVIDED IT WILL REMOVE
    //   THE '&' THAT CAME WITH THE LAST PARAM. IF NO PARAMS WERE PROVIDED
    //   THE LAST '/' FROM THE ROUTE WILL BE REMOVED
    if (
      url.charAt(url.length - 1) === "/" ||
      url.charAt(url.length - 1) === "&"
    )
      url = url.substring(0, url.length - 1);

    return url; //E.G. http://localhost:3000/checkout/hello?t1=value1&t2=value2
  }
);

export const getUrlParams = memoize((url: string) => {
  //E.G. http://localhost:3000/checkout/bart?t1=hellothere1&t2=hellothere2
  let paramValues: string[] = [];
  let paramNames: string[] = [];
  let chunks: string[] = [];
  url
    .split("&")
    //NOW WE HAVE
    //["http://localhost:3000/checkout/bart?t1=hellothere1", "t2=hellothere2"]
    .forEach(part => {
      chunks = part.split("=");
      //NOW CHUNKS WILL BE:
      //  FIRST LOOP: ["http://localhost:3000/checkout/bart?t1", "hellothere1"]
      //  SECOND LOOP: ["t2","hellothere2"]

      //NOW WE LOOK AT THE FIRST PART OF THE ARRAY, THE NAME, AND
      //TAKE EVERYTHING OUT AFTER THE CHARACTER '?'
      //  FIRST LOOP: indexOf("?") + 1 WILL BE 36
      //  SECOND LOOOP: (no '?' FOUND): indexOf("?") + 1 WILL BE 0
      paramNames.push(chunks[0].substring(chunks[0].indexOf("?") + 1));
      //PARAM NAMES WILL HAVE ["t1","t2"]

      //AND WE PUSH THE SECOND POSITION, WHICH IS THE PARAM VALUE,
      //INTO PARAMS ARRAY
      paramValues.push(chunks[1]);
      //PARAM VALUES WILL HAVE ["hellothere1", "hellothere2"]
    });

  return { paramValues, paramNames };
});
