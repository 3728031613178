import React, { FC } from "react";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import ModalVeil from "../../Modal/modalVeil";
import { ModalProps } from "../../Modal/modalManager";
import { useTranslation } from "react-i18next";
import useChargeAdjustments from "../../../utilities/chargeAdjustments";
import GenerateInvoice from "../../../utilities/generateInvoice";
import { Order } from "../../../database/order";
import getOrderIdStrings from "../../../utilities/getOrderIdString";
import { hexToRgbString } from "../../../utilities/colorUtils";
interface STreceiptModalProps extends ModalProps {
  order: Order;
}

const STreceiptModal: FC<STreceiptModalProps> = React.memo(
  ({ closeModal, order }) => {
    const store = useStore();
    const { t } = useTranslation();
    const { mainColorDark, color } = store.theme;
    const {
      reason,
      setReason,
      adjustment,
      setAdjustment,
      hasChanged,
      totalTax,
      taxTable,
      newSubtotal,
      totalDiff,
      type,
      originalTotal,
    } = useChargeAdjustments(order);
    const orderIdStrings = getOrderIdStrings(order.orderId);

    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            color: color,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader"
            style={{
              backgroundColor: mainColorDark,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                color: color,
              }}
            >
              <Icon name="receipt" fill={color} />
              <div>&nbsp;{t("dashboard.orderReceipt.orderReceiptTitle")}</div>
            </div>
            <br />
            <div
              className="AppFontSizeSmall"
              style={{ fontWeight: "bold", color: color }}
            >
              {t("dashboard.orderReceipt.orderReceiptSubtitle")}
            </div>
            <div className="AppFontSizeSmall">
              {t("common.id")}:&nbsp;
              <span style={{ fontWeight: "bold" }}>
                {orderIdStrings.mainId}
              </span>
              &nbsp;{t("common.subId")}:&nbsp;
              <span style={{ fontWeight: "bold" }}>{orderIdStrings.subId}</span>
            </div>
          </div>
          <div className="sTmodalContent" style={{ color: "black" }}>
            <GenerateInvoice
              suborders={order.suborders}
              allowAdjustment
              paymentRechargeStatus={order.paymentRechargeStatus}
              hasChanged={hasChanged}
              totalDiff={totalDiff}
              subtotal={newSubtotal}
              totalOrig={originalTotal}
              tax={totalTax}
              taxTable={taxTable}
              adjustment={adjustment}
              setAdjustment={setAdjustment}
              reason={reason}
              setReason={setReason}
              showDetails={true}
              source={"store"}
              type={type}
              totalAdjustmentAmount={order.totalAdjustmentAmount || 0}
            />
          </div>
          <div
            className="sTmodalFooter AppFontSizeSmall"
            style={{
              backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
            }}
          >
            <div
              onClick={closeModal}
              className="sTmodalFooterItem AppFontSizeSmall"
              style={{ color: color }}
            >
              {t("common.close")}
            </div>
          </div>
        </div>
        <ModalVeil onClick={closeModal} />
      </React.Fragment>
    );
  }
);

export default STreceiptModal;
