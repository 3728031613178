import React, { FC } from "react";
import Icon from "../../Icon/icon";
import { IconName } from "../../Icon/IconName";

interface StoreSelectProps {
  className?: string;
  style?: React.CSSProperties;
  text: string;
  textStyle?: React.CSSProperties;
  icon: IconName | undefined;
  iconColor: string | undefined;
  expandIcon: boolean;
  value?: any;
  onChange: ((event: React.ChangeEvent<HTMLSelectElement>) => void) | undefined;
  selectStyle?: React.CSSProperties;
}

const StoreSelect: FC<StoreSelectProps> = ({
  className,
  style,
  text,
  textStyle,
  icon,
  iconColor,
  expandIcon,
  value,
  onChange,
  selectStyle,
  children,
}) => {
  return (
    <div
      style={{
        position: "relative",
        ...style,
      }}
    >
      <div
        className={className}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon && (
            <React.Fragment>
              <Icon name={icon} fill={iconColor} />
              &nbsp;
            </React.Fragment>
          )}
          <div style={{ ...textStyle }}>{text}</div>
        </div>
        {expandIcon && <Icon name="expand_more" />}
      </div>
      <select
        onClick={(e) => e.stopPropagation()}
        style={{ ...selectStyle }}
        value={value}
        onChange={onChange}
      >
        {children}
      </select>
    </div>
  );
};

export default StoreSelect;
