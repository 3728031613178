import React, {
  FC,
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from "react";
import * as R from "ramda";
import { CatalogApiContext, CatalogApi } from "./catalogApiContext";
import { Catalog_ClientSide } from "../../../database/catalog";
import {
  useGetOptions,
  useGetItems,
  useGetCatalogs,
} from "../../../utilities/useGetCollection";
import { FirebaseContext } from "../../../Firebase";
import {
  convertCatalogFromDatabase,
  convertCatalogToDatabase,
  convertItemFromDatabase,
  convertItemToDatabase,
} from "../../../utilities/typeConversions";
import useToast from "../../Main/useToast";
import { useTranslation } from "react-i18next";
import { TOASTDURATION_ERROR } from "../../../utilities/constants/appConstants";
import { CatalogItem_ClientSide } from "../../../database/catalogItem";
import { deleteUndefineds } from "../../../utilities/deleteUndefineds";
import { notUndefined } from "../../../utilities/notNull";
import useStore from "../../../customization/useStore";
import { StoreLocation } from "../../../database/storeLocation";
import { StoreLocationContext } from "../../../customization/storeContext";
import useSpinner from "../../Main/useSpinner";
/**
 * Manages the editing of catalog data. This is done at the top level of the app
 * and made available via context so that data can be persisted when changing
 * away from the catalog pages.
 */
const CatalogApiProvider: FC = (props) => {
  const toast = useToast();
  const { t } = useTranslation();
  const firebase = useContext(FirebaseContext);
  const store = useStore();
  const locationInDb = useContext(StoreLocationContext);
  const showSpinner = useSpinner();
  /**
   * Listen to all the relevant data in the database.
   */
  const optionsInDb = useGetOptions();
  const itemsInDb = useGetItems();
  const catalogsInDb = useGetCatalogs();

  /**
   * Tracks whether the admin has made a change. Note that automatic changes
   * do not modify this.
   */
  const [userHasEdited, setUserHasEdited] = useState(false);

  /**
   * Convert the items to client side ones. These are easier to work
   * with since we don't have to constantly be tracking down items and options
   * by their ids.
   */
  const items = useMemo(() => {
    if (optionsInDb && itemsInDb) {
      const result: Record<string, CatalogItem_ClientSide> = {};
      Object.values(itemsInDb).forEach((item) => {
        result[item.itemId] = convertItemFromDatabase(item, optionsInDb);
      });
      return result;
    } else {
      return {};
    }
  }, [itemsInDb, optionsInDb]);

  /**
   * Copies of the items which are currently being edited.
   */
  const [itemsUnderEdit, setItemsUnderEdit] = useState<
    Record<string, CatalogItem_ClientSide>
  >({});

  /**
   * items marked for deletion
   */
  const [itemsToDelete, setItemsToDelete] = useState<string[]>([]);

  /**
   * WHEN THE ADMIN UPLOADS A NEW CATALOG IMAGE (CATEGORY / ITEM), THE
   * IMAGE AND ITS VARIETY OF WIDTHS ARE UPLOADED TO STORAGE IMMEDIATELY
   *
   * WE HAVE TO WAIT TO DELETE THE OLD (REPLACED) IMAGES OUT OF STORAGE
   * AFTER THE ADMIN HITS THE "SAVE" BUTTON AS TO ENABLE A REVERT IF NEEDED.
   *
   * ONCE THE ADMIN SAVES THE CHANGES AFTER UPLOADING THE NEW IMAGE,
   * WE CAN USE THE FOLLOWING STATE VARIABLE TO LOOP OVER THE OLD IMAGES
   * AND DELETE THEM OUT OF STORAGE
   */
  const [imagesToDeleteOld, setImagesToDeleteOld] = useState<string[]>([]);
  const [imagesToDeleteNew, setImagesToDeleteNew] = useState<string[]>([]);

  /**
   * Merging the data that's being edited with the data that is not.
   * That way, the catalog page can ask for any catalog or item and it
   * will find it, but it will have live data for those that are not
   * being edited, and locally modified data for those that are.
   */
  const mergedItems = useMemo(
    () =>
      R.omit(itemsToDelete, {
        ...items,
        ...itemsUnderEdit,
      }),
    [items, itemsToDelete, itemsUnderEdit]
  );

  const catalogs = useMemo(() => {
    if (optionsInDb && catalogsInDb && itemsInDb) {
      const result: Record<string, Catalog_ClientSide> = {};
      Object.values(catalogsInDb).forEach((catalog) => {
        result[catalog.catalogId] = convertCatalogFromDatabase(
          catalog,
          mergedItems
        );
      });
      return result;
    } else {
      return {};
    }
  }, [catalogsInDb, itemsInDb, mergedItems, optionsInDb]);

  const [catalogsUnderEdit, setCatalogsUnderEdit] = useState<
    Record<string, Catalog_ClientSide>
  >({});

  const [catalogsToDelete, setCatalogsToDelete] = useState<string[]>([]);

  const mergedCatalogs: Record<string, Catalog_ClientSide> = useMemo(
    () =>
      R.omit(catalogsToDelete, {
        ...catalogs,
        ...catalogsUnderEdit,
      }),
    [catalogs, catalogsToDelete, catalogsUnderEdit]
  );

  const setCatalog = useCallback(
    (
      catalog: Catalog_ClientSide,
      filesToDelete?: { oldFiles: string[]; newFiles: string[] },
      initiatedByUser = true
    ) => {
      if (filesToDelete) {
        setImagesToDeleteOld((prev) => [...prev, ...filesToDelete.oldFiles]);
        setImagesToDeleteNew((prev) => [...prev, ...filesToDelete.newFiles]);
      }
      setCatalogsUnderEdit((prev) => ({
        ...prev,
        [catalog.catalogId]: catalog,
      }));
      if (initiatedByUser) {
        setUserHasEdited(true);
      }
    },
    []
  );

  const setItem = useCallback(
    (
      item: CatalogItem_ClientSide,
      filesToDelete?: { oldFiles: string[]; newFiles: string[] },
      initiatedByUser = true
    ) => {
      if (filesToDelete) {
        setImagesToDeleteOld((prev) => [...prev, ...filesToDelete.oldFiles]);
        setImagesToDeleteNew((prev) => [...prev, ...filesToDelete.newFiles]);
      }

      setItemsUnderEdit((prev) => ({
        ...prev,
        [item.itemId]: item,
      }));
      if (initiatedByUser) {
        setUserHasEdited(true);
      }
    },
    []
  );

  const deleteCatalog = useCallback(
    (catalogId: string, initiatedByUser = true) => {
      setCatalogsToDelete((prev) => {
        if (prev.includes(catalogId)) {
          return prev;
        }
        return [...prev, catalogId];
      });
      setCatalogsUnderEdit((prev) => {
        const newCatalogs: Record<string, Catalog_ClientSide> = R.omit(
          [catalogId],
          prev
        );
        if (Object.keys(mergedCatalogs).length === 1) {
          // We're deleting the last category, so we need to add one to replace it
          const id = firebase.firestore
            .collection("stores")
            .doc("store")
            .collection("catalogs")
            .doc().id;
          newCatalogs[id] = {
            catalogId: id,
            name: "",
            categories: [],
          };
        }
        return newCatalogs;
      });
      if (initiatedByUser) {
        setUserHasEdited(true);
      }
    },
    [firebase.firestore, mergedCatalogs]
  );

  const deleteItem = useCallback(
    (itemId: string, initiatedByUser = true) => {
      setItemsToDelete((prev) => {
        if (prev.includes(itemId)) {
          return prev;
        }
        return [...prev, itemId];
      });
      setItemsUnderEdit((prev) => {
        if (prev[itemId]) {
          return R.omit([itemId], prev);
        }
        return prev;
      });
      // Update any catalog that was using this item
      setCatalogsUnderEdit((prev) => {
        const modifiedCatalogs: Record<string, Catalog_ClientSide> = {};
        Object.values(mergedCatalogs).forEach((catalog) => {
          let modified = false;
          let modifiedCatalog: Catalog_ClientSide = {
            ...catalog,
            categories: catalog.categories.map((category) => ({
              ...category,
              items: category.items
                .map((item) => {
                  if (item.itemId === itemId) {
                    modified = true;
                    return undefined;
                  }
                  return item;
                })
                .filter(notUndefined),
            })),
          };
          if (modified) {
            modifiedCatalogs[catalog.catalogId] = modifiedCatalog;
          }
        });

        if (Object.keys(modifiedCatalogs).length > 0) {
          return {
            ...prev,
            ...modifiedCatalogs,
          };
        } else {
          return prev;
        }
      });
      if (initiatedByUser) {
        setUserHasEdited(true);
      }
    },
    [mergedCatalogs]
  );

  const [activeCatalogIdOverride, setActiveCatalogIdOverride] = useState<
    string | null
  >(null);

  const activeCatalogId = activeCatalogIdOverride ?? store.catalog.catalogId;

  useEffect(() => {
    // Need to always have one catalog as active, so if we lose that (eg, due
    // do deleting), pick the first one and make it active.
    const activeCatalog = mergedCatalogs[activeCatalogId];
    if (!activeCatalog) {
      const firstId = Object.values(mergedCatalogs)[0]?.catalogId;
      if (firstId !== undefined) {
        setActiveCatalogIdOverride(firstId);
      }
    }
  }, [activeCatalogId, mergedCatalogs]);

  const revert = useCallback(
    (adminSaveAction = true) => {
      setCatalogsUnderEdit({});
      setCatalogsToDelete([]);
      setItemsUnderEdit({});
      setItemsToDelete([]);
      if (adminSaveAction) {
        setActiveCatalogIdOverride(null);
        console.log("REVERTING NEW IMAGES:", imagesToDeleteNew);
        if (imagesToDeleteNew.length > 0) {
          /**
           * IF THE ADMIN REVERTS CHANGES AFTER NEW IMAGE UPLOADS
           * THE NEWLY UPLOADED IMAGES NEED TO BE DELETED OUT OF STORAGE
           */
          const storageRef = firebase.getStorage();
          for (const file of imagesToDeleteNew) {
            const imageRef = storageRef.ref().child(`/images/${file}`);
            //NO NEED TO AWAIT THIS PROMISE AS WE DONT NEED TO HALT THE UI
            //FOR IMAGE DELETIONS
            imageRef
              .delete()
              .then(function () {
                console.log("DELETION OF NEW FILE SUCCESSFUL:", file);
              })
              .catch(function (error) {
                console.log("DELETE FAILED", error);
              });
          }
        }
      }
      setImagesToDeleteNew([]);
      setImagesToDeleteOld([]);
      setUserHasEdited(false);
    },
    [imagesToDeleteNew, firebase]
  );

  const save = useCallback(async () => {
    const hideSpinner = showSpinner({ lag: "none" });
    try {
      const promises: Promise<void>[] = [];
      Object.values(catalogsUnderEdit).forEach((catalog) => {
        promises.push(
          firebase.firestore
            .collection("stores")
            .doc("store")
            .collection("catalogs")
            .doc(catalog.catalogId)
            .set(deleteUndefineds(convertCatalogToDatabase(catalog)))
        );
      });

      Object.values(itemsUnderEdit).forEach((item) => {
        promises.push(
          firebase.firestore
            .collection("stores")
            .doc("store")
            .collection("items")
            .doc(item.itemId)
            .set(deleteUndefineds(convertItemToDatabase(item)))
        );

        item.options.forEach((option) => {
          promises.push(
            firebase.firestore
              .collection("stores")
              .doc("store")
              .collection("options")
              .doc(option.optionId)
              .set(deleteUndefineds(option))
          );
        });

        //TODO: need a way to delete options that are no longer there
      });

      itemsToDelete.forEach((itemId) => {
        promises.push(
          firebase.firestore
            .collection("stores")
            .doc("store")
            .collection("items")
            .doc(itemId)
            .delete()
        );
      });

      catalogsToDelete.forEach((catalogId) => {
        promises.push(
          firebase.firestore
            .collection("stores")
            .doc("store")
            .collection("catalogs")
            .doc(catalogId)
            .delete()
        );
      });

      if (imagesToDeleteOld.length > 0) {
        /**
         * WHEN THE ADMIN UPLOADS A NEW CATALOG IMAGE (CATEGORY / ITEM), WE
         * DELETE THE OLD (REPLACED) IMAGES OUT OF STORAGE
         *
         * THIS IS CURRENTLY POSSIBLE AS EVERY CATEGORY AND ITEM HAS THEIR
         * OWN IMAGE FILE (IMAGES ARE CURRENTLY NOT SHARED)
         *
         */
        const storageRef = firebase.getStorage();
        for (const file of imagesToDeleteOld) {
          const imageRef = storageRef.ref().child(`/images/${file}`);
          //NO NEED TO AWAIT THIS PROMISE AS WE DONT NEED TO HALT THE UI
          //FOR IMAGE DELETIONS
          imageRef
            .delete()
            .then(function () {
              console.log("DELETETION OF OLD FILE SUCCESSFUL:", file);
            })
            .catch(function (error) {
              console.log("DELETE FAILED", error);
            });
        }
      }

      await Promise.all(promises);

      revert(false);
      toast({
        message: t("dashboard.toast.successSave"),
        className: "dBthemeToast",
        duration: 2000,
      });
    } catch (err) {
      if (err.code === "permission-denied") {
        toast({
          message: t("common.permissionDenied"),
          className: "dBthemeAlert1",
          duration: TOASTDURATION_ERROR,
        });
      } else {
        console.log("ERROR SAVING", err);
        toast({
          message: t("toast.systemError"),
          className: "dBthemeAlert1",
          duration: TOASTDURATION_ERROR,
        });
      }
    } finally {
      hideSpinner();
    }
  }, [
    catalogsToDelete,
    catalogsUnderEdit,
    firebase,
    itemsToDelete,
    itemsUnderEdit,
    revert,
    showSpinner,
    t,
    toast,
    imagesToDeleteOld,
  ]);

  const publish = useCallback(async () => {
    if (!catalogsInDb || !itemsInDb || !optionsInDb) {
      // still loading
      return;
    }

    const activeCatalogInDb = catalogsInDb[activeCatalogId];
    if (!activeCatalogInDb) {
      return;
    }

    try {
      const newLocation: StoreLocation = {
        ...locationInDb,
        catalog: {
          ...activeCatalogInDb,
        },
        items: {},
        options: {},
      };
      for (let category of activeCatalogInDb.categories) {
        for (let itemRef of category.items) {
          const catalogItem = itemsInDb[itemRef.itemId];
          if (catalogItem) {
            newLocation.items[catalogItem.itemId] = catalogItem;
            for (let optionRef of catalogItem.options ?? []) {
              const option = optionsInDb[optionRef.optionId];
              if (option) {
                newLocation.options[option.optionId] = option;
              }
            }
          }
        }
      }

      await firebase.firestore
        .collection("stores")
        .doc("store")
        .collection("locations")
        .doc(store.locationId)
        .set(newLocation);

      setActiveCatalogIdOverride(null);
      toast({
        message: t("dashboard.toast.successPublish"),
        className: "dBthemeToast",
        duration: 2000,
      });
    } catch (err) {
      if (err.code === "permission-denied") {
        toast({
          message: t("common.permissionDenied"),
          className: "dBthemeAlert1",
          duration: TOASTDURATION_ERROR,
        });
      } else {
        toast({
          message: t("toast.systemError"),
          className: "dBthemeAlert1",
          duration: TOASTDURATION_ERROR,
        });
      }
    }
  }, [
    activeCatalogId,
    catalogsInDb,
    firebase.firestore,
    itemsInDb,
    locationInDb,
    optionsInDb,
    store.locationId,
    t,
    toast,
  ]);

  const dirty =
    userHasEdited &&
    (catalogsToDelete.length > 0 ||
      itemsToDelete.length > 0 ||
      Object.values(catalogsUnderEdit).length > 0 ||
      Object.values(itemsUnderEdit).length > 0);

  const noCatalogs = Boolean(
    catalogsInDb && Object.keys(catalogsInDb).length === 0
  );

  const publishNeeded = useMemo(() => {
    if (!optionsInDb || !itemsInDb || !catalogsInDb || noCatalogs) {
      // still loading, or they havn't created their first catalog
      return false;
    }

    let catalogChanged = !R.equals(
      catalogsInDb[activeCatalogId],
      store.catalog
    );
    if (catalogChanged) {
      return true;
    }

    for (let category of catalogsInDb[activeCatalogId].categories) {
      for (let itemRef of category.items) {
        const catalogItem = store.items[itemRef.itemId];
        const itemChanged = !R.equals(catalogItem, itemsInDb[itemRef.itemId]);
        if (itemChanged) {
          return true;
        }
        const options = catalogItem?.options;
        if (options) {
          for (let optionRef of options) {
            const option = store.options[optionRef.optionId];
            const optionChanged = !R.equals(
              option,
              optionsInDb[optionRef.optionId]
            );
            if (optionChanged) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }, [
    activeCatalogId,
    catalogsInDb,
    itemsInDb,
    noCatalogs,
    optionsInDb,
    store.catalog,
    store.items,
    store.options,
  ]);

  useEffect(() => {
    if (dirty) {
      const callback = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = "";
      };
      window.addEventListener("beforeunload", callback);
      return () => window.removeEventListener("beforeunload", callback);
    }
  }, [dirty]);

  const value: CatalogApi = useMemo(
    () => ({
      catalogs: mergedCatalogs,
      setCatalog,
      deleteCatalog,
      activeCatalogId,
      setActiveCatalogId: setActiveCatalogIdOverride,
      items: mergedItems,
      setItem,
      deleteItem,
      options: optionsInDb || {},
      dirty,
      loading: !optionsInDb || !itemsInDb || !catalogsInDb,
      noCatalogs,
      publishNeeded,
      revert,
      save,
      publish,
    }),
    [
      activeCatalogId,
      catalogsInDb,
      deleteCatalog,
      deleteItem,
      dirty,
      itemsInDb,
      mergedCatalogs,
      mergedItems,
      noCatalogs,
      optionsInDb,
      publish,
      publishNeeded,
      revert,
      save,
      setCatalog,
      setItem,
    ]
  );

  return (
    <CatalogApiContext.Provider value={value}>
      {props.children}
    </CatalogApiContext.Provider>
  );
};

export default CatalogApiProvider;
