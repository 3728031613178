import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../utilities/useQuery";
import { FirebaseContext } from "../../../Firebase";

export default () => {
  const firebase = useContext(FirebaseContext);
  const query = useQuery();
  const history = useHistory();
  const redirect = query.get("redirect");
  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged((user) => {
      if (!user) {
        history.replace(`/s?redirect=${redirect}`);
      } else {
        history.replace(redirect || "");
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
