import React, { FC } from "react";
import useStore from "../customization/useStore";
import { finalItemPrice, itemPricePerUnit } from "./orderProcessing";
import useLockDimensions from "../components/Main/useLockDimensions";
import {
  SuborderCollection,
  Suborder,
  isListOptionSelection,
  isNumberOptionSelection,
  isUnitOptionSelection,
} from "../database/cart";
import Dinero from "dinero.js";
import { isListOption, isNumberOption, isUnitOption } from "../database/option";
import { useTranslation } from "react-i18next";
import { paymentRechargeStatus as paymentRechargeStatusType } from "../database/order";
import useToast from "../components/Main/useToast";
export interface GenerateInvoiceProps {
  suborders: SuborderCollection;
  allowAdjustment: boolean;
  paymentRechargeStatus: paymentRechargeStatusType | undefined;
  //chargeOption: string;
  //source: string;
  reason: string;
  setReason?: React.Dispatch<React.SetStateAction<string>>;
  adjustment: Dinero.Dinero;
  subtotal: Dinero.Dinero;
  tax: Dinero.Dinero;
  taxTable: Record<string, Dinero.Dinero>;
  totalOrig: number;
  totalDiff: Dinero.Dinero;
  setAdjustment?: React.Dispatch<React.SetStateAction<Dinero.Dinero>>;
  hasChanged: boolean;
  type: "refund" | "upcharge";
  showDetails: boolean;
  totalAdjustmentAmount: number;
  source: string;
}

const GenerateInvoice: FC<GenerateInvoiceProps> = ({
  suborders,
  allowAdjustment,
  paymentRechargeStatus,
  subtotal,
  totalOrig,
  tax,
  taxTable,
  reason,
  setReason,
  adjustment,
  setAdjustment,
  hasChanged,
  totalDiff,
  showDetails,
  totalAdjustmentAmount,
  source,
}) => {
  // console.log("generateInvoice.js");
  const store = useStore();
  const { allowMeals, items, options } = store;
  const lockDimensions = useLockDimensions();
  const { t } = useTranslation();
  const toast = useToast();
  return (
    <React.Fragment>
      {showDetails &&
        Object.values(suborders).map((suborder: Suborder, index) => (
          <React.Fragment key={index}>
            {allowMeals && (
              <div style={{ color: "black", padding: "0.5em" }}>
                {suborder.name}
              </div>
            )}

            <table className="dBordersReceiptTable AppFontSizeSmall">
              <tbody>
                {Object.values(suborder.items).map((cartItem, index2) => {
                  const catalogItem = items[cartItem.itemId];
                  const priceInfo = itemPricePerUnit(
                    cartItem.itemId,
                    store,
                    cartItem.optionSelections
                  );
                  return (
                    <React.Fragment key={index2}>
                      <tr
                        style={{
                          textDecoration: cartItem.unable
                            ? "line-through"
                            : undefined,
                          color: cartItem.unable ? "slategrey" : undefined,
                        }}
                      >
                        <th
                          colSpan={2}
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {catalogItem
                            ? `${
                                catalogItem.name
                              } (${priceInfo.tax.toFormat()} tax at ${
                                priceInfo.taxPercentage
                              }%)`
                            : "Deleted Item"}
                        </th>
                        {/* <td>&nbsp;</td> */}
                        <th
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {finalItemPrice(
                            cartItem.itemId,
                            store,
                            cartItem.optionSelections
                          ).toFormat()}
                        </th>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: "left",
                            padding: "0 0.25em",
                            borderBottom: "1px solid black",
                          }}
                        >
                          {cartItem.optionSelections &&
                            cartItem.optionSelections.map((selection) => {
                              const option = options[selection.optionId];
                              if (
                                isListOption(option) &&
                                isListOptionSelection(selection)
                              ) {
                                return (
                                  <div key={option.optionId}>
                                    <div
                                      style={{ textDecoration: "underline" }}
                                    >{`${option.name}:`}</div>
                                    {option.items.map((item) => {
                                      const isSelected =
                                        selection.selectedItems[item.id];
                                      return (
                                        isSelected && (
                                          <div
                                            key={item.id}
                                            style={{ padding: "0 0.25em" }}
                                          >
                                            {`${item.description}`}
                                          </div>
                                        )
                                      );
                                    })}
                                  </div>
                                );
                              } else if (
                                isNumberOption(option) &&
                                isNumberOptionSelection(selection)
                              ) {
                                return (
                                  <div key={option.optionId}>
                                    <div>
                                      <span
                                        style={{
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {option.name}
                                      </span>
                                      : {selection.value}{" "}
                                      {option.unit ? option.unit : ""}
                                    </div>
                                  </div>
                                );
                              } else if (
                                isUnitOption(option) &&
                                isUnitOptionSelection(selection)
                              ) {
                                return (
                                  <div key={option.optionId}>
                                    <div
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {selection.value} {option.name}
                                    </div>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
                {
                  <React.Fragment>
                    <tr
                      style={{
                        opacity:
                          allowAdjustment &&
                          (adjustment.getAmount() !== 0 ||
                            reason.trim().length > 0)
                            ? "1"
                            : "0",
                        transition: "all 0.3s linear 0s",
                      }}
                    >
                      <th
                        colSpan={2}
                        style={{
                          textAlign: "left",
                          wordBreak: "break-word",
                          borderBottom:
                            reason.length > 0 || adjustment.getAmount() !== 0
                              ? "1px solid black"
                              : "",
                        }}
                      >
                        {reason}
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          borderBottom:
                            reason.trim().length > 0 ||
                            adjustment.getAmount() !== 0
                              ? "1px solid black"
                              : "",
                          wordBreak: "break-word",
                        }}
                      >
                        {adjustment.toFormat()}
                      </th>
                    </tr>
                  </React.Fragment>
                }
                <tr>
                  <td colSpan={3}>&nbsp;</td>
                </tr>
                <tr>
                  <td></td>
                  <td style={{ textAlign: "right", wordBreak: "break-word" }}>
                    {t("common.subtotal")}
                  </td>
                  <td style={{ textAlign: "right", wordBreak: "break-word" }}>
                    {subtotal.toFormat()}
                  </td>
                </tr>
                {Object.entries(taxTable).map(([taxRate, tax]) => (
                  <tr key={taxRate}>
                    <td></td>
                    <td style={{ textAlign: "right" }}>Tax (at {taxRate}%)</td>
                    <td style={{ textAlign: "right" }}>{tax.toFormat()}</td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      wordBreak: "break-word",
                    }}
                  >
                    {t("common.total")}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      wordBreak: "break-word",
                      minWidth: "9em",
                    }}
                  >
                    {subtotal.add(tax).toFormat()}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td
                    style={{
                      textAlign: "right",
                      opacity:
                        totalDiff.getAmount() !== 0 ||
                        hasChanged ||
                        paymentRechargeStatus ===
                          paymentRechargeStatusType.incomplete
                          ? 1
                          : 0,
                      transition: "opacity 0.3s linear 0.5s",
                    }}
                  >
                    {t("common.originalTotal")}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      wordBreak: "break-word",
                      opacity:
                        totalDiff.getAmount() !== 0 ||
                        hasChanged ||
                        paymentRechargeStatus ===
                          paymentRechargeStatusType.incomplete
                          ? 1
                          : 0,
                      transition: "opacity 0.3s linear 0.5s",
                    }}
                  >
                    {Dinero({ amount: totalOrig }).toFormat()}
                  </td>
                </tr>
              </tbody>
            </table>
            {source === "dashboard" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  color: "black",
                }}
              >
                <div style={{ margin: "0 0.25em" }}>
                  <div
                    className="AppFontSizeSmall"
                    style={{
                      color: "black",
                      margin: "0.25em 0.25em",
                      padding: "0 0.25em",
                    }}
                  >
                    {
                      t("dashboard.charges.applyRefund")
                      //t("dashboard.charges.applyAdditional")
                    }
                  </div>
                  <input
                    type="text"
                    style={{ margin: "0" }}
                    placeholder={
                      t("dashboard.charges.refundReason")
                      //t("dashboard.charges.adjustmentReason")
                    }
                    defaultValue={reason}
                    onChange={(e) =>
                      setReason && setReason(e.currentTarget.value)
                    }
                    onFocus={() =>
                      lockDimensions(true, "Adjust invoice reason")
                    }
                  />
                </div>
                <div style={{ margin: "0 0.25em" }}>
                  <div
                    className="AppFontSizeSmall"
                    style={{
                      color: "black",
                      margin: "0.25em 0.25em",
                      padding: "0 0.25em",
                    }}
                  >
                    &nbsp;
                  </div>
                  <input
                    type="number"
                    style={{ margin: "0" }}
                    placeholder={
                      t("dashboard.charges.refundAmount")
                      //t("dashboard.charges.adjustmentAmount")
                    }
                    defaultValue={adjustment.toUnit()}
                    onChange={(e) => {
                      let value = Number(e.currentTarget.value);
                      // Convert to cents
                      value = Math.floor(
                        value * Math.pow(10, Dinero.defaultPrecision)
                      );
                      //TODO REMOVE THIS LINE BELOW AS THIS IS A TEMPORARY
                      //SOLUTION TO NOT ALLOW ANY UPCHARGES BECAUSE WE CURRENLTY
                      //ONLY STORE THE ORIGINAL PAYMENTTOKEN.

                      //IN THE NEW SOLUTION EVERY ITEM WILL HAVE A PAYMENTOKEN
                      //STORED WITH IT AS TO KNOW WHICH PAYMENT TOKEN TO USE
                      //FOR APPLYING REFUNDS.

                      //IT TURNS OUT EVERY UPCHARGE (BY ADDING AN ITEM OR
                      //BY MANUAL UPCHARGE) RESULTS IN AN ADDITIONAL PAYMENT
                      //TOKEN THAT NEEDS TO BE USED FOR APPLYING REFUNDS
                      if (value > 0) value = value * -1;
                      if (
                        Math.abs(value) <=
                        Dinero({ amount: totalOrig }).getAmount()
                      ) {
                        setAdjustment &&
                          setAdjustment(Dinero({ amount: value }));
                      } else {
                        setAdjustment &&
                          setAdjustment(Dinero({ amount: totalOrig * -1 }));
                        toast({
                          message: t("dashboard.charges.maxRefundReached"),
                          className: "dBthemeToast",
                        });
                      }
                    }}
                    onFocus={() => {
                      lockDimensions(true, "Adjust invoice amount");
                    }}
                  />
                </div>
              </div>
            ) : (
              <div style={{ margin: "0.5em 0" }}>&nbsp;</div>
            )}
          </React.Fragment>
        ))}
      <div
        className="AppFontSizeSmall"
        style={{
          display: totalDiff.getAmount() === 0 ? "none" : "",
          color: "black",
          margin: "0.5em 0 0 0",
          textAlign: "center",
        }}
      >
        {paymentRechargeStatus === paymentRechargeStatusType.pendingauth &&
        adjustment.getAmount() === totalAdjustmentAmount
          ? t("dashboard.charges.awaitingAuthentication")
          : t("dashboard.charges.newCharge")}
      </div>
      <div
        className="AppFontSizeBig2"
        style={{
          margin: "0 0 0.5em 0",
          fontSize: totalDiff.getAmount() === 0 ? "1%" : "",
          opacity: totalDiff.getAmount() === 0 ? 0 : 1,
          height: totalDiff.getAmount() === 0 ? 0 : "",
          textAlign: "center",
          color: "black",
          transition: "font-size 0.3s linear 0s, opacity 0.3s linear 0.5s",
        }}
      >
        <strong>{totalDiff.toFormat()}</strong>
      </div>
    </React.Fragment>
  );
};

export default GenerateInvoice;
