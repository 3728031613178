import { FirebaseContext } from "../Firebase";
import { useState, useContext, useEffect } from "react";
import { MessageThread } from "../database/message";
import useSpinner from "../components/Main/useSpinner";
import useAuthUser from "../components/AuthUserProvider/useAuthUser";

export interface Options {
  /**
   * string userId will filter to just those with that id.
   * undefined userId will not filter
   * null userId (for non-signedin users) will return zero results
   */
  userId?: string | null;
  omitResolved?: boolean;
}

const byLatestMessageTime = (a: MessageThread, b: MessageThread) => {
  return latestMessageTime(b) - latestMessageTime(a);
};

const latestMessageTime = (thread: MessageThread) => {
  let time = 0;
  Object.values(thread.messages).forEach((message) => {
    time = Math.max(message.timeSent.toMillis(), time);
  });
  return time;
};

export const useMessageThreads = (options: Options = {}) => {
  const { userId, omitResolved = true } = options;
  const [threads, setThreads] = useState<MessageThread[] | null>(null);
  const firebase = useContext(FirebaseContext);
  const showSpinner = useSpinner();
  const { adminLocation } = useAuthUser();

  useEffect(() => {
    if (userId === null) {
      setThreads([]);
      return;
    }

    const hideSpinner = showSpinner();
    let ref:
      | firebase.firestore.Query<firebase.firestore.DocumentData>
      | firebase.firestore.CollectionReference<
          firebase.firestore.DocumentData
        > = firebase.firestore
      .collection("stores")
      .doc("store")
      .collection("messageThreads");
    if (userId) {
      ref = ref.where("userId", "==", userId);
    }
    if (omitResolved) {
      ref = ref.where("resolved", "==", false);
    }
    if (adminLocation) {
      ref = ref.where("locationId", "==", adminLocation);
    }
    const unsubscribe = ref.onSnapshot(
      (snapshot) => {
        const result: MessageThread[] = [];
        snapshot.forEach((doc) => {
          result.push(doc.data() as MessageThread);
        });
        // console.log("got threads", result);
        result.sort(byLatestMessageTime);
        setThreads(result);
        hideSpinner();
      },
      (err) => {
        console.log("error getting threads", err);
        setThreads([]);
        hideSpinner();
      }
    );
    return () => {
      hideSpinner();
      unsubscribe();
    };
  }, [firebase.firestore, omitResolved, userId, showSpinner, adminLocation]);

  if (threads === null) {
    return { threads: [], loading: true };
  } else {
    return { threads, loading: false };
  }
};
