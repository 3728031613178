import React, { FC, useState } from "react";
import { ModalProps } from "../../Modal/modalManager";
import { useTranslation } from "react-i18next";
import ModalVeil from "../../Modal/modalVeil";
import { UnitOptionSelection } from "../../../database/cart";
import useTheme from "../../../customization/useTheme";
import Icon from "../../Icon/icon";
import { optionType, isUnitOption } from "../../../database/option";
import useStore from "../../../customization/useStore";
import useLockDimensions from "../../Main/useLockDimensions";
import useToast from "../../Main/useToast";
import { TOASTDURATION_ERROR } from "../../../utilities/constants/appConstants";
import { hexToRgbString } from "../../../utilities/colorUtils";
export interface STUnitSelectModalProps extends ModalProps {
  optionId: string;
  initialSelection?: UnitOptionSelection;
  onSubmit: (selection: UnitOptionSelection) => void;
  source?: "dashboard" | "store";
}

const STUnitSelectModal: FC<STUnitSelectModalProps> = ({
  optionId,
  initialSelection,
  onSubmit,
  closeModal,
  source,
}) => {
  const { t } = useTranslation();
  const lockDimensions = useLockDimensions();
  const toast = useToast();
  const { mainColor, color, iconColor, mainColorDark } = useTheme();
  const { options } = useStore();
  const option = options[optionId];
  const [selection, setSelection] = useState<UnitOptionSelection>(
    initialSelection || {
      optionId,
      type: optionType.UNIT,
      value: isUnitOption(option) ? option.defaultValue : 0,
    }
  );

  if (!isUnitOption(option)) {
    return null;
  }

  const isRound =
    Math.round(selection.value / option.increment) * option.increment ===
    selection.value;

  const handleSubmit = () => {
    if (selection.value < option.minValue) {
      toast({
        message: `${t("store.orders.chooseNumber")} ${option.minValue} ${t(
          "store.orders.orGreater"
        )}`,
        className: "sTthemeAlert1b",
        duration: TOASTDURATION_ERROR,
      });
      return;
    } else if (selection.value > option.maxValue) {
      toast({
        message: `${t("store.orders.chooseNumber")} ${option.maxValue} ${t(
          "store.orders.orLess"
        )}`,
        className: "sTthemeAlert1b",
        duration: TOASTDURATION_ERROR,
      });
      return;
    }

    let sel = selection;
    if (!isRound) {
      toast({
        message: `${t("store.orders.weHaveRounded")} ${option.increment} ${
          option.name
        }`,
        color: source !== "dashboard" ? color : undefined,
        backgroundColor: mainColor,
        duration: TOASTDURATION_ERROR,
      });
      sel = {
        ...selection,
        value:
          Math.round(selection.value / option.increment) * option.increment,
      };
    }

    onSubmit(sel);
    closeModal();
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "block",
          color: source !== "dashboard" ? color : undefined,
        }}
        className={
          source !== "dashboard" ? "sTmodal boxShadow5" : "dBmodal boxShadow5"
        }
      >
        <div
          className={
            source !== "dashboard"
              ? "sTmodalHeader"
              : "dBmodalHeader dBthemeBGcolor2"
          }
          style={{
            backgroundColor: source !== "dashboard" ? mainColorDark : undefined,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              name="input"
              fill={source !== "dashboard" ? color : "white"}
            />
            <div style={{ color: source !== "dashboard" ? color : undefined }}>
              &nbsp;{t("store.orders.chooseAmount")}
            </div>
          </div>
        </div>
        <div
          className="sTmodalContent"
          style={{
            padding: "1em 0.25em",
            color: iconColor,
          }}
        >
          Number of {option.name}
          <input
            type="number"
            placeholder={t("dashboard.customFields.pricePlaceholder")}
            onFocus={() => lockDimensions(true)}
            defaultValue={selection.value}
            onChange={(event) => {
              setSelection({
                ...selection,
                value: Number(event.target.value),
              });
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                handleSubmit();
              }
            }}
          />
        </div>
        <div
          className={
            source !== "dashboard"
              ? "sTmodalFooter AppFontSizeSmall"
              : "dBmodalFooter AppFontSizeSmall"
          }
          style={{
            backgroundColor:
              source !== "dashboard"
                ? `rgba(${hexToRgbString(mainColorDark)},0.7)`
                : undefined,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              color: source !== "dashboard" ? color : undefined,
            }}
            onClick={closeModal}
            className="sTmodalFooterItem"
          >
            {t("common.cancel")}
          </div>
          <div
            style={{
              fontWeight: "bold",
              color: source !== "dashboard" ? color : undefined,
            }}
            onClick={handleSubmit}
            className="sTmodalFooterItem"
          >
            {t("common.ok")}
          </div>
        </div>
      </div>
      <ModalVeil onClick={closeModal} />
    </React.Fragment>
  );
};

export default STUnitSelectModal;
