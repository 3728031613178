import React, { FC, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useCart from "../CartManager/useCart";
import useStore from "../../customization/useStore";
import { memoizedGetTotals } from "../../utilities/orderProcessing";
import * as ROUTES from "../../utilities/constants/routes";
import Icon from "../Icon/icon";

export interface CartIndicatorProps {
  color: string;
}

const CartIndicator: FC<CartIndicatorProps> = ({ color }) => {
  const history = useHistory();
  const location = useLocation();
  const { suborders, activeSuborderId } = useCart();
  const store = useStore();
  const { theme, allowMeals } = store;
  const { mainColor } = theme;
  if (!color) {
    color = mainColor;
  }
  const suborder = suborders[activeSuborderId];
  const subordersWithItems = Object.values(suborders).filter(
    s => Object.keys(s.items).length > 0
  );
  const cartTotals = memoizedGetTotals(suborders, store);
  const suborderTotals = memoizedGetTotals(suborder, store);
  const [cartChange, setCartChange] = useState<number>(0);
  useEffect(() => {
    setCartChange(cartChange => cartChange + 1);
  }, [suborderTotals.itemCount]);
  return (
    <div
      onClick={
        location.pathname === ROUTES.CART
          ? () => {
              if (cartTotals.itemCount !== 0) history.push(ROUTES.CHECKOUT);
            }
          : () => history.push(ROUTES.CART)
      }
      className="sTheaderTitleIcon"
      style={{
        opacity: cartTotals.itemCount === 0 ? 0 : 1,
        color,
        transition: "all 0.5s linear 0s"
      }}
    >
      {location.pathname === ROUTES.CART ? (
        <Icon name="checkout" fill={color} />
      ) : (
        <Icon
          name="shopping_cart"
          fill={color}
          style={{
            transform: cartChange % 2 ? "rotateZ(360deg)" : "rotateZ(720deg)",
            transition: "all 0.3s ease-out 0s"
          }}
        />
      )}

      <div
        className="sTheaderTitleIconText"
        style={{
          color: color,
          transition: "all 0.3s linear 0s"
        }}
      >
        {location.pathname === ROUTES.CART ? (
          <span>&nbsp;</span>
        ) : allowMeals ? (
          subordersWithItems.length
        ) : (
          cartTotals.itemCount
        )}
        &nbsp;
      </div>
    </div>
  );
};

export default CartIndicator;
