import { FirebaseContext } from "../Firebase";
import { useState, useContext, useEffect } from "react";
import { Option } from "../database/option";
import { CatalogItem_Database } from "../database/catalogItem";
import { Catalog_Database } from "../database/catalog";
import useAuthUser from "../components/AuthUserProvider/useAuthUser";

export const useGetCollection = <T>(collectionName: string) => {
  const [collection, setCollection] = useState<null | Record<string, T>>(null);
  const firebase = useContext(FirebaseContext);
  const { isCustomer } = useAuthUser();

  useEffect(() => {
    if (isCustomer) {
      return;
    }
    const unsubscribe = firebase.firestore
      .collection("stores")
      .doc("store")
      .collection(collectionName)
      .onSnapshot(
        (docs) => {
          const collection: Record<string, T> = {};
          docs.forEach((doc) => {
            const data = doc.data() as T | undefined;
            if (data) {
              collection[doc.id] = data;
            }
          });
          setCollection(collection);
        },
        (err) => {
          setCollection(null);
        }
      );
    return unsubscribe;
  }, [collectionName, firebase.firestore, isCustomer]);
  return collection;
};

export const useGetOptions = () => {
  return useGetCollection<Option>("options");
};

export const useGetItems = () => {
  return useGetCollection<CatalogItem_Database>("items");
};

export const useGetCatalogs = () => {
  return useGetCollection<Catalog_Database>("catalogs");
};
