import React, { FC } from "react";
import useStore from "../../customization/useStore";
import {
  memoizedGetTotals,
  groupEquivalent,
} from "../../utilities/orderProcessing";
import Icon from "../Icon/icon";
import ItemWithButtons from "./Templates/itemOrderBtns";
import { hexToRgbString } from "../../utilities/colorUtils";
import { AccountInfo } from "../Main/main";
import { Suborder, CartItem } from "../../database/cart";
import Dinero from "dinero.js";

export interface SuborderSummaryProps {
  suborder: Suborder;
  onRenameClicked: () => void;
  onEditClicked: () => void;
  onDeleteClicked: () => void;
  accountInfo: AccountInfo;
}

const SuborderSummary: FC<SuborderSummaryProps> = ({
  suborder,
  onRenameClicked,
  onEditClicked,
  onDeleteClicked,
  accountInfo,
}) => {
  const store = useStore();
  const suborderTotals = memoizedGetTotals(suborder, store);
  const { allowMeals, theme } = store;
  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: allowMeals
            ? `rgba(${hexToRgbString(theme.mainColor)},0.7)`
            : "",
        }}
        className="sTcartCard sT_anim_moveLeftSmall"
      >
        <div
          className=""
          style={{
            height: allowMeals ? "auto" : "0",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0.25em",
            }}
          >
            {allowMeals && (
              <React.Fragment>
                <div
                  style={{
                    fontWeight: "bold",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {suborder.name}
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    onClick={onRenameClicked}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      marginRight: "1.5em",
                    }}
                  >
                    <Icon name="group" fill="#FFF" />
                    <div className="AppFontSizeSmall">RENAME</div>
                  </div>
                  <div
                    onClick={onDeleteClicked}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      marginRight: "1.5em",
                    }}
                  >
                    <Icon name="delete" fill="#FFF" />
                    <div className="AppFontSizeSmall">DELETE</div>
                  </div>
                  <div
                    onClick={onEditClicked}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      marginRight: "0.25em",
                    }}
                  >
                    <Icon name="edit" fill="#FFF" />
                    <div className="AppFontSizeSmall">EDIT</div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div style={{ margin: "0 0.25em" }}>
          {groupEquivalent(suborder.items).map((items: CartItem[]) => (
            <ItemWithButtons
              key={items[0].cartItemId}
              itemId={items[0].itemId}
              cartItem={items[0]}
              cartItemCount={items.length}
              accountInfo={accountInfo}
              source="cart"
            />
          ))}
        </div>
        {allowMeals && (
          <div
            style={{
              textAlign: "right",
              paddingRight: "0.5em",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <table style={{}}>
                <tbody>
                  {suborderTotals.totalTax.greaterThan(
                    Dinero({ amount: 0 })
                  ) && (
                    <React.Fragment>
                      <tr>
                        <td style={{}}>Subtotal&nbsp;</td>
                        <td>
                          {/* TODO: this may be a place where we need the roundUpThousands formatting */}
                          {suborderTotals.preTax.toFormat()}
                        </td>
                      </tr>
                      {Object.entries(suborderTotals.taxTable).map(
                        ([taxRate, tax]) => (
                          <tr key={taxRate}>
                            <td style={{}}>Tax (at {taxRate}%)</td>
                            <td>
                              {/* TODO: this may be a place where we need the roundUpThousands formatting */}
                              {tax.toFormat()}
                            </td>
                          </tr>
                        )
                      )}
                    </React.Fragment>
                  )}

                  <tr>
                    <td
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Total&nbsp;
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {/* TODO: this may be a place where we need the roundUpThousands formatting */}
                      {suborderTotals.withTax.toFormat()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SuborderSummary;
