import { Catalog_ClientSide } from "../../../database/catalog";
import { createContext, useContext } from "react";
import { CatalogItem_ClientSide } from "../../../database/catalogItem";
import { OptionCollection } from "../../../database/option";

/**
 * Contains methods for editing a catalog
 */
export interface CatalogApi {
  /**
   * The copy of the catalogs which are being edited
   */
  catalogs: Record<string, Catalog_ClientSide>;
  setCatalog: (
    catalog: Catalog_ClientSide,
    filesToDelete?: { oldFiles: string[]; newFiles: string[] },
    initiatedByUser?: boolean
  ) => void;
  deleteCatalog: (catalogId: string, initiatedByUser?: boolean) => void;
  activeCatalogId: string;
  setActiveCatalogId: (newId: string | null) => void;
  /**
   * The copy of the items which are being edited
   */
  items: Record<string, CatalogItem_ClientSide>;
  setItem: (
    item: CatalogItem_ClientSide,
    filesToDelete?: { oldFiles: string[]; newFiles: string[] },
    initiatedByUser?: boolean
  ) => void;
  deleteItem: (itemId: string, initiatedByUser?: boolean) => void;
  options: OptionCollection;
  dirty: boolean;
  loading: boolean;
  noCatalogs: boolean;
  publishNeeded: boolean;
  revert: () => void;
  save: () => Promise<void>;
  publish: () => Promise<void>;
}

export const CatalogApiContext = createContext<CatalogApi>({} as CatalogApi);

export const useCatalogApi = () => useContext(CatalogApiContext);
