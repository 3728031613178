import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/firebase-storage";
import "firebase/functions";
import "firebase/firebase-messaging";

const config = {
  apiKey: process.env.REACT_APP_FRBS_API_KEY,
  authDomain: process.env.REACT_APP_FRBS_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FRBS_DATABASE_URL,
  projectId: process.env.REACT_APP_FRBS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FRBS_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FRBS_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FRBS_APP_ID,
};
class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.FieldValue = firebase.firestore.FieldValue;
    this.Timestamp = firebase.firestore.Timestamp;
    this.auth = firebase.app().auth();
    this.firestore = firebase.app().firestore();
    this.functions = firebase
      .app()
      .functions(process.env.REACT_APP_FRBS_REGION);
    this.pushSupported = false;
    try {
      this.messaging = firebase.app().messaging();
      this.messaging.usePublicVapidKey(process.env.REACT_APP_FRBS_VAPID_KEY);
      this.pushSupported = true;
    } catch (error) {
      console.log("PUSH NOTIFICATIONS NOT SUPPORTED", error);
    }
    //THE FOLLOWING LINE MUST BE COMMENTED OUT WHEN DEPLOYING
    //TO PRODUCTION //NPM RUN SERVE
    //this.functions.useFunctionsEmulator("http://localhost:5000");
  }
  signIn = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  signOut = () => {
    this.auth.signOut();
    sessionStorage.clear();
  };
  createAccount = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  resetPassword = (email) => this.auth.sendPasswordResetEmail(email);
  updatePassword = (password) => this.auth.currentUser.updatePassword(password);
  sendVerificationEmail = () => this.auth.currentUser.sendEmailVerification();
  updateEmail = (email) => this.auth.currentUser.updateEmail(email);
  updatePassword = (password) => this.auth.currentUser.updatePassword(password);
  isReady = () => {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  };
  getStorage = (link) => {
    return firebase.app().storage();
  };
}
export default Firebase;
