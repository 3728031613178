import React, { FC } from "react";
import useCart from "../../CartManager/useCart";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import ModalVeil from "../../Modal/modalVeil";
import { ModalProps } from "../../Modal/modalManager";
import { useTranslation } from "react-i18next";
import { hexToRgbString } from "../../../utilities/colorUtils";
export interface STRemoveMealModalProps extends ModalProps {
  suborderId: string;
}

const STRemoveMealModal: FC<STRemoveMealModalProps> = React.memo(
  ({ suborderId, closeModal }) => {
    const { suborders, removeSuborder } = useCart();
    const suborder = suborders[suborderId];
    let name = (suborder && suborder.name) || "Meal";
    const store = useStore();
    const { mainColorDark, color } = store.theme;
    const onOk = () => {
      removeSuborder(suborder);
      closeModal();
    };
    const { t } = useTranslation();
    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            color: color,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader"
            style={{
              backgroundColor: mainColorDark,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                color: color,
              }}
            >
              <Icon name="warning" fill={color} />
              <div>&nbsp;{t("store.meal.remove")}</div>
            </div>
            <br />
            <div className="AppFontSizeSmall" style={{ color: color }}>
              {name}
            </div>
            <div className="AppFontSizeSmall" style={{ color: color }}>
              <span style={{ fontWeight: "bold" }}></span>
            </div>
          </div>
          <div
            className="sTmodalContent"
            style={{ color: "black", padding: "0.5em" }}
          >
            {t("store.meal.sureToRemove")}
          </div>
          <div
            className="sTmodalFooter AppFontSizeSmall"
            style={{
              backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
            }}
          >
            <div
              onClick={closeModal}
              className="sTmodalFooterItem AppFontSizeSmall"
              style={{ color: color }}
            >
              {t("common.cancel")}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: color,
              }}
              onClick={onOk}
              className="sTmodalFooterItem AppFontSizeSmall"
            >
              {t("common.remove")}
            </div>
          </div>
        </div>
        <ModalVeil onClick={closeModal} />
      </React.Fragment>
    );
  }
);

export default STRemoveMealModal;
