import React, { FC } from "react";
import useStore from "../../../customization/useStore";
import * as ROUTES from "../../../utilities/constants/routes";
import useModal from "../../Modal/useModal";
import STguestInfoModal from "../Modals/sTguestInfo";
import { GuestDetails, secondCheckoutStep } from "../Pages/checkout";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { useTranslation } from "react-i18next";
import SectionSignedOut from "../Components/sectionSignedOut";
import { useHistory } from "react-router-dom";
import StoreButton from "../Components/stButton";

export interface SectionUserSOProps {
  guestDetails: GuestDetails;
  setGuestDetails: React.Dispatch<React.SetStateAction<GuestDetails>>;
}

const SectionUserSO: FC<SectionUserSOProps> = ({
  guestDetails,
  setGuestDetails,
}) => {
  const { user } = useAuthUser();
  const showModal = useModal();
  const store = useStore();
  const { t } = useTranslation();
  const history = useHistory();

  if (user) {
    return null;
  }
  return (
    <div
      style={{
        margin: "0 auto",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        color: "black",
      }}
    >
      <SectionSignedOut source="checkout" />

      <StoreButton
        text={t("guestCheckout.checkOutGuestButton")}
        theme="white"
        iconColor="black"
        icon="person_so"
        onClick={() => {
          showModal(
            STguestInfoModal,
            {
              initialGuestDetails: guestDetails,
              onSubmit: (guestDetails: GuestDetails) => {
                setGuestDetails(guestDetails);
                history.replace(secondCheckoutStep(store));
              },
              shouldGoBack: false,
              showAbort: false,
            },
            `${ROUTES.CHECKOUT}${ROUTES.ML_GUESTINFO}`
          );
        }}
      />
    </div>
  );
};

export default SectionUserSO;
