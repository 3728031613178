import { CatalogItem_Database } from "../../../database/catalogItem";
import { OptionSelection } from "../../../database/cart";
import {
  itemPricePerUnit,
  PriceAndUnit,
  finalItemPrice,
} from "../../../utilities/orderProcessing";
import useStore from "../../../customization/useStore";
import { useTranslation } from "react-i18next";

interface Options {
  catalogItem?: CatalogItem_Database;
  selectedOptions: OptionSelection[];
  count: number;
  isCart: boolean;
}

/**
 * Custom hook for standardizing the way that item prices are displayed.
 * This way all the item components can have similar formatting
 */
const useItemPriceLabels = ({
  catalogItem,
  selectedOptions,
  count,
  isCart,
}: Options) => {
  const store = useStore();
  const { t } = useTranslation();

  let pricePerUnit: PriceAndUnit = itemPricePerUnit(
    catalogItem?.itemId,
    store,
    selectedOptions
  );

  let priceLabel: string;
  let priceExtras: string[] = [];
  if (isCart && catalogItem) {
    // On the cart screen we display the final price
    const finalPrice = finalItemPrice(
      catalogItem.itemId,
      store,
      selectedOptions
    ).multiply(count);
    priceLabel = finalPrice.toFormat();

    // If the final price is different from the price per unit, display
    //   individual price in parentheses.
    if (!finalPrice.equalsTo(pricePerUnit.price)) {
      if (pricePerUnit.unit) {
        priceExtras.push(
          t("store.orders.pricePerUnit", {
            price: pricePerUnit.price.toFormat(),
            amount: pricePerUnit.unit.priceDenominator,
            unit: pricePerUnit.unit.name,
          })
        );
      } else {
        priceExtras.push(
          t("store.orders.priceEach", {
            price: pricePerUnit.price.toFormat(),
          })
        );
      }
    }
  } else {
    // Display only the price per unit.
    if (pricePerUnit.unit) {
      priceLabel = t("store.orders.pricePerUnit", {
        price: pricePerUnit.price.toFormat(),
        amount: pricePerUnit.unit.priceDenominator,
        unit: pricePerUnit.unit.name,
      });
    } else {
      priceLabel = pricePerUnit.price.toFormat();
    }
  }

  priceExtras.push(
    t("store.orders.itemTax", { amount: pricePerUnit.tax.toFormat() })
  );

  return {
    priceLabel,
    priceExtraLabel: priceExtras.join(", "),
  };
};

export default useItemPriceLabels;
