import React, { useState, useCallback, FC, useEffect } from "react";
import Icon from "../../Icon/icon";
import useTheme from "../../../customization/useTheme";
import useStore from "../../../customization/useStore";
import ModalVeil from "../../Modal/modalVeil";
import { ModalProps } from "../../Modal/modalManager";
import {
  ListOptionItem,
  listSelectionType,
  isListOption,
  optionType,
} from "../../../database/option";
import { ListOptionSelection } from "../../../database/cart";
import * as R from "ramda";
import { useTranslation } from "react-i18next";
import useToast from "../../Main/useToast";
import * as APPCONSTANTS from "../../../utilities/constants/appConstants";
import Dinero from "dinero.js";
import { hexToRgbString } from "../../../utilities/colorUtils";
interface ListSelectModalProps extends ModalProps {
  optionId: string;
  initialSelection?: ListOptionSelection;
  onSubmit: (newSelection: ListOptionSelection) => void;
  onCancel?: () => void;
  source?: "dashboard" | "store";
}

const ListSelectModal: FC<ListSelectModalProps> = ({
  optionId,
  initialSelection,
  onSubmit = () => {},
  onCancel = () => {},
  closeModal,
  source,
}) => {
  const toast = useToast();
  const { color, iconColor, mainColorDark } = useTheme();
  const [selection, setSelection] = useState<ListOptionSelection>(
    initialSelection || {
      optionId,
      type: optionType.LIST,
      selectedItems: {},
    }
  );
  const { options } = useStore();
  const option = options[selection.optionId];
  const { t } = useTranslation();

  const onClick = useCallback(
    (item: ListOptionItem) => {
      if (!isListOption(option)) {
        return;
      }
      setSelection((prev) => {
        let currentValue = prev.selectedItems[item.id];
        let selectedItems;
        if (currentValue === true) {
          selectedItems = R.omit([item.id], prev.selectedItems);
        } else {
          if (option.selectionType === listSelectionType.SINGLE) {
            // Unselect all others
            selectedItems = { [item.id]: true };
          } else {
            selectedItems = {
              ...prev.selectedItems,
              [item.id]: true,
            };
          }
        }

        return {
          ...prev,
          selectedItems,
        };
      });
    },
    [option]
  );

  let isValid =
    (option && !option.required) ||
    Object.keys(selection.selectedItems).length > 0;

  const handleSubmit = async () => {
    try {
      if (isValid) {
        onSubmit(selection);
      } else {
        onCancel();
      }
    } finally {
      closeModal();
    }
  };

  const priceString = (item: ListOptionItem) => {
    if (item.price && item.price !== 0) {
      const sign = item.price > 0 ? "+" : "";
      const digits = Dinero({ amount: item.price }).toFormat();
      return `(${sign}${digits})`;
    }
    return "";
  };

  useEffect(() => {
    if (!option) {
      // This would happen if an admin published and removed this option
      toast({
        message: t("store.toast.noLonger"),
        className: "sTthemeAlert1b",
        duration: APPCONSTANTS.TOASTDURATION_ERROR,
      });
      closeModal();
    }
  });

  if (!isListOption(option)) {
    return null;
  }
  return (
    <React.Fragment>
      <div
        style={{
          display: "block",
          color: source !== "dashboard" ? color : undefined,
        }}
        className={
          source !== "dashboard" ? "sTmodal boxShadow5" : "dBmodal boxShadow5"
        }
      >
        <div
          className={
            source !== "dashboard"
              ? "sTmodalHeader"
              : "dBmodalHeader dBthemeBGcolor2"
          }
          style={{
            backgroundColor: source !== "dashboard" ? mainColorDark : undefined,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon name="input" fill={color} />
            <div style={{ color: source !== "dashboard" ? color : undefined }}>
              &nbsp;Choose Your {option.name}
            </div>
          </div>
        </div>
        <div className="sTmodalContent" style={{ padding: "1em 0.25em" }}>
          {option.items.map((item) => (
            <div
              key={item.id}
              style={{
                display: "flex",
                width: "calc(100% - 0.5em)",
                alignItems: "center",
                color: source !== "dashboard" ? iconColor : "black",
                margin: "0.5em 0",
              }}
              onClick={() => onClick(item)}
            >
              <div>
                <Icon
                  name={
                    selection.selectedItems[item.id]
                      ? "checkbox_done"
                      : "checkbox_empty"
                  }
                  fill={source !== "dashboard" ? iconColor : "black"}
                />
              </div>
              <div>
                &nbsp;{item.description} {priceString(item)}
              </div>
            </div>
          ))}
        </div>
        <div
          className={
            source !== "dashboard"
              ? "sTmodalFooter AppFontSizeSmall"
              : "dBmodalFooter AppFontSizeSmall"
          }
          style={{
            backgroundColor:
              source !== "dashboard"
                ? `rgba(${hexToRgbString(mainColorDark)},0.7)`
                : undefined,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              color: source !== "dashboard" ? color : undefined,
            }}
            onClick={handleSubmit}
            className="sTmodalFooterItem"
          >
            {t(isValid ? "common.ok" : "common.cancel")}
          </div>
        </div>
      </div>
      <ModalVeil
        onClick={() => {
          onCancel();
          closeModal();
        }}
      />
    </React.Fragment>
  );
};

export default ListSelectModal;
