import React, { useState, useEffect, useContext, FC } from "react";
import { FirebaseContext } from "../../../Firebase";
import useStore from "../../../customization/useStore";
import Icon from "../../Icon/icon";
import toggleFavorite from "../../../utilities/toggleFavorite";
import * as ROUTES from "../../../utilities/constants/routes";
import "../css/order.css";
import useToast from "../../Main/useToast";
import OptionButton from "../optionButton";
import useCart from "../../CartManager/useCart";
import useModal from "../../Modal/useModal";
import STinstructionsModal from "../Modals/sTorderInstruct";
import useSpinner from "../../Main/useSpinner";
import { useTranslation } from "react-i18next";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { Suborder, CartItem, OptionSelection } from "../../../database/cart";
import Dompurify from "dompurify";
import { useSelectedOptions } from "../../../utilities/defaultSelections";
import useItemPriceLabels from "./useItemPriceLabels";
import useAccountInfo from "../../Main/useAccountInfo";

export interface ProductProps {
  itemId: string;
  cartItem?: CartItem;
  suborder?: Suborder;
  categoryName?: string;
  source?: "cart" | "favorites" | "order";
}

//ITEM IS USED WHEN ORDER BUTTONS ARE DESIRED IN ITEM BAR
const ProductStandard: FC<ProductProps> = React.memo(
  ({ cartItem, itemId, source }) => {
    const accountInfo = useAccountInfo();
    const store = useStore();
    const { theme, items } = store;
    const catalogItem = items[itemId];
    const { user } = useAuthUser();
    const showSpinner = useSpinner();
    const firebase = useContext(FirebaseContext);
    const cartApi = useCart();
    const { mainColor, color, mainColorDark } = theme;
    const toast = useToast();
    const showModal = useModal();
    const { t } = useTranslation();

    const [selectedOptions, setSelectedOptions] = useSelectedOptions(
      itemId,
      cartItem
    );

    const [instructions, setInstructions] = useState(
      cartItem && cartItem.instructions ? cartItem.instructions : ""
    );
    const [added, setAdded] = useState(false);
    const [count, setCount] = useState(1);

    const onInstructionsClicked = () => {
      if (!catalogItem || catalogItem.unavailable) return;

      showModal(
        STinstructionsModal,
        {
          item: catalogItem,
          initialInstructions: instructions,
          onSubmit: (newInstructions: string) => {
            if (source === "cart" && cartItem) {
              cartApi.setItemInstructions(cartItem.cartItemId, newInstructions);
            }
            setInstructions(newInstructions);
          },
        },
        ROUTES.ML_ITEMINSTRUCT
      );
    };
    const addToCart = () => {
      if (!catalogItem || catalogItem.unavailable) return;

      cartApi.addItem(catalogItem, count, instructions, selectedOptions);
    };

    function databaseAction(itemId: string) {
      // console.log("product1.tsx", itemId, user, catalogItem);
      if (!catalogItem) return;
      if (user) {
        toggleFavorite({
          firebase,
          showSpinner,
          accountInfo,
          itemId,
          user,
        });
      } else {
        toast({
          message: t("store.orders.startFavoring"),
          color: color,
          backgroundColor: mainColor,
        });
      }
    }

    const { priceLabel, priceExtraLabel } = useItemPriceLabels({
      catalogItem,
      selectedOptions,
      count,
      isCart: source === "cart",
    });

    useEffect(() => {
      if (added) {
        let timer = setTimeout(() => setAdded(false), 1000);
        return () => clearTimeout(timer);
      }
    }, [added]);

    if (!catalogItem) {
      return null;
    }

    const [countInput, setCountInput] = useState(count > 10 ? true : false);

    return (
      <div className="sTproductDetails">
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ lineHeight: "1em" }}>
              <div
                className=""
                style={{
                  fontWeight: "bold",
                }}
              >
                {priceLabel}
              </div>
              <div className="AppFontSizeSmall">{priceExtraLabel}</div>
            </div>
          </div>
          <div
            style={{ marginBottom: "0.5em" }}
            className="sTorderCustomInputBtnWrap"
          >
            {catalogItem.options &&
              catalogItem.options.map((optionRef, i) => {
                const selection = selectedOptions.find(
                  (selection) => selection.optionId === optionRef.optionId
                );
                return (
                  <OptionButton
                    key={optionRef.optionId}
                    optionId={optionRef.optionId}
                    selection={selection}
                    onSelectionChanged={(newSelection: OptionSelection) => {
                      setSelectedOptions((prev: OptionSelection[]) => {
                        const newSelections = [...prev];
                        newSelections[i] = newSelection;
                        if (source === "cart" && cartItem) {
                          cartApi.setItemOptionSelections(
                            cartItem.cartItemId,
                            newSelections
                          );
                        }
                        return newSelections;
                      });
                    }}
                    source="store"
                    allowClick={true}
                  />
                );
              })}
          </div>

          {catalogItem.description.replace(/<[^>]*>/g, "").trim().length >
            0 && (
            <div
              style={{
                marginBottom: "2.5em",
                padding: "0.25em 0.25em 0 0.25em",
                textAlign: "justify",
                wordBreak: "break-word",
              }}
              dangerouslySetInnerHTML={{
                __html: Dompurify.sanitize(catalogItem.description.trim()),
              }}
            ></div>
          )}
        </div>
        <span
          className="sTorderActionFloat"
          style={{ backgroundColor: mainColorDark }}
        >
          <div
            className="sTorderItemActionBtn"
            onClick={() => databaseAction(catalogItem.itemId)}
            style={{
              flexDirection: "column",
            }}
          >
            <Icon
              name={
                accountInfo.favorites &&
                accountInfo.favorites[catalogItem.itemId] &&
                accountInfo.favorites[catalogItem.itemId]["fav"]
                  ? "favorite"
                  : "unfavorite"
              }
              fill={color}
            />
            <div
              className="AppFontSizeSmall"
              style={{ fontWeight: "bold", color: color }}
            >
              &nbsp;
            </div>
          </div>
          <div
            className="sTorderItemActionBtn"
            onClick={onInstructionsClicked}
            style={{
              flexDirection: "column",
              opacity: catalogItem.unavailable ? 0.5 : 1,
              alignItems: "center",
            }}
          >
            <Icon
              name={instructions ? "add_note3" : "add_note4"}
              fill={color}
            />
            <div
              className="AppFontSizeSmall"
              style={{ fontWeight: "bold", color: color }}
            >
              {t("store.orders.addNote")}
            </div>
          </div>
          <div
            style={{
              flexDirection: "column",
              opacity: catalogItem.unavailable ? 0.3 : 1,
              padding: 0,
            }}
            className="sTorderItemActionBtn"
          >
            {!countInput ? (
              <React.Fragment>
                <div
                  style={{
                    position: "relative",
                    top: "0.7em",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {count}
                </div>
                <select
                  value={count}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value === -9) {
                      setCountInput(true);
                      setCount(1);
                    } else {
                      setCount(value);
                    }
                  }}
                >
                  {(() => {
                    const options = [];
                    for (let a = 1; a <= 10; a++) {
                      options.push(
                        <option key={a} value={a}>
                          {a}
                        </option>
                      );
                    }
                    options.push(
                      <option key={0} value={-9}>
                        10+
                      </option>
                    );
                    return options;
                  })()}
                </select>
              </React.Fragment>
            ) : (
              <input
                type="number"
                style={{
                  padding: "0.5em 0.25em",
                  textAlign: "center",
                  color: "white",
                }}
                defaultValue={count === 0 ? "" : count}
                placeholder={t("common.count")}
                onChange={(e) => {
                  const value =
                    Number(e.target.value) <= 0 ? 1 : Number(e.target.value);
                  setCount(value);
                }}
              />
            )}
          </div>
          <div
            style={{
              padding: "0 0.5em",
              flexDirection: "column",
              opacity: catalogItem.unavailable ? 0.3 : 1,
            }}
            className="sTorderItemActionBtn"
            onClick={() => {
              if (!catalogItem.unavailable) {
                setAdded(true);
                addToCart();
              }
            }}
          >
            {added ? (
              <Icon name="done" fill={color} />
            ) : (
              <Icon name="shopping_cart_add" fill={color} />
            )}
            <div
              className="AppFontSizeSmall"
              style={{ fontWeight: "bold", color: color }}
            >
              {added ? `+${count}` : t("store.orders.addToOrder")}
            </div>
          </div>
        </span>
      </div>
    );
  }
);

export default ProductStandard;
