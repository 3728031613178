import React, { useEffect, useContext, useState } from "react";
import * as ROUTES from "../../../utilities/constants/routes";
import "../css/paymentAuth.css";
import { getUrlParams } from "../../../utilities/urlUtils";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon/icon";
import { paymentAuthUtils } from "../../../utilities/httpsCallables/httpsCallables";
import { FirebaseContext } from "../../../Firebase";
import useSpinner from "../../Main/useSpinner";
import PaymentAuthMain from "../Components/paymentAuthMain";
import { useLocation, useHistory } from "react-router-dom";
import { Order } from "../../../database/order";

const PaymentAuth = React.memo(() => {
  const { t } = useTranslation();
  const location = useLocation<{ order: Order }>();
  const history = useHistory();
  const firebase = useContext(FirebaseContext);
  const showSpinner = useSpinner();
  const [content, setContent] = useState("spinner");
  const [order, setOrder] = useState<Order | null>(
    location.state ? location.state.order : null
  );
  let orderId: string = "";
  let paymentIntentId: string = "";
  if (!order && location.search.length !== 0) {
    //GET ORDER FROM URL
    orderId = getUrlParams(location.search).paramValues[0];
    paymentIntentId = getUrlParams(location.search).paramValues[1];
  }
  useEffect(
    () => {
      (async () => {
        try {
          if (!order) {
            if (location && location.state && location.state.order) {
              console.log(
                "Routed from track page, but order not loaded yet. Getting order out of location.state"
              );
              setOrder(location.state.order);
              setContent("order");
            } else {
              console.log(
                "Routed via link. Getting order out via server.",
                orderId,
                paymentIntentId
              );
              const hideSpinner = showSpinner({ lag: "none" });
              try {
                const response = await paymentAuthUtils(firebase, {
                  action: "retrieve",
                  orderId,
                  paymentIntentId,
                });
                setOrder(response.order);
                setContent("order");
              } catch (error) {
                console.log("paymentAuth.tsx", error.message);
                setContent("error");
              } finally {
                hideSpinner();
              }
            }
          } else {
            console.log(
              "Routed from track page. Order loaded on page load.",
              orderId,
              paymentIntentId
            );
            setContent("order");
          }
        } catch (error) {
          console.log("Error getting paymentIntent object:", error);
        }
      })();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <React.Fragment>
      <div className="sTpaymentAuthOuterWrap sTthemeBGcolorSoft">
        <div className="sTpaymentAuthHeader"></div>
        <div className="sTpaymentAuthContentsWrap">
          <div
            className="sTpaymentAuthContents2 sTpaymentAuthFadedOut sTpaymentAuth_anim_fadeIn_0305"
            style={{ color: "black" }}
          >
            {content === "order" && order ? (
              <PaymentAuthMain
                order={order}
                paymentIntentId={paymentIntentId}
              />
            ) : content === "error" ? (
              <React.Fragment>
                <div className="AppFontSizeBig2">
                  <strong>400</strong>
                </div>
                <div>{t("store.paymentAuth.badRequest")}</div>
                <div
                  onClick={() => {
                    history.push(ROUTES.STORE);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "2em",
                    flexDirection: "column",
                  }}
                >
                  <Icon name="hottub" />
                  <span style={{ textDecoration: "underline" }}>
                    {t("store.paymentAuth.getBackLink")}
                  </span>
                </div>
              </React.Fragment>
            ) : (
              <div>{t("common.pleaseWait")}</div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default PaymentAuth;
