import { useRef, useState, useEffect, RefObject } from "react";
//CUSTOM HOOK FOR DETECTING HOVER OVER SIDEPANEL

const useHover = (): [RefObject<HTMLDivElement>, boolean] => {
  const [value, setValue] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  // const isTouchDevice = "ontouchstart" in document.documentElement;
  const node = ref.current;
  useEffect(() => {
    // if (!isTouchDevice) {
    if (node) {
      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);
      //CLOSE DRAWER AFTER PICKING AN ITEM FOR BETTER EXPERIENCE ON TOUCH DEVICES
      node.addEventListener("click", handleMouseOut);
      node.addEventListener("touchstart", handleMouseOut);
      return () => {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
        //CLOSE DRAWER AFTER PICKING AN ITEM FOR BETTER EXPERIENCE ON TOUCH DEVICES
        node.removeEventListener("click", handleMouseOut);
        node.removeEventListener("touchstart", handleMouseOut);
      };
    }
  });
  return [ref, value];
};

export default useHover;
