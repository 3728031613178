import React, { FC } from "react";
import useStore from "../../customization/useStore";
import { memoizedGetTotals } from "../../utilities/orderProcessing";
import useCart from "../CartManager/useCart";
import Dinero from "dinero.js";

export interface CartSummaryProps {}

const CartSummary: FC<CartSummaryProps> = () => {
  const { suborders } = useCart();
  const store = useStore();
  const cartTotals = memoizedGetTotals(suborders, store);

  return (
    <div
      className="sT_anim_moveRightSmall"
      style={{
        width: "100%",
        padding: "0.5em",
      }}
    >
      <table
        style={{
          width: "100%",
          margin: "0 auto",
          top: 0,
        }}
      >
        <tbody>
          {cartTotals.totalTax.greaterThan(Dinero({ amount: 0 })) && (
            <React.Fragment>
              <tr>
                <td style={{ textAlign: "left" }}>Subtotal</td>
                <td style={{ textAlign: "right" }}>
                  {/* TODO: this may be a place where we need the roundUpThousands formatting */}
                  {cartTotals.preTax.toFormat()}
                </td>
              </tr>
              {Object.entries(cartTotals.taxTable).map(([taxRate, tax]) => (
                <tr key={taxRate}>
                  <td style={{ textAlign: "left" }}>Tax (at {taxRate}%)</td>
                  <td style={{ textAlign: "right" }}>
                    {/* TODO: this may be a place where we need the roundUpThousands formatting */}
                    {tax.toFormat()}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          )}
          <tr>
            <td
              className="AppFontSizeBig2"
              style={{
                textAlign: "left",
                fontWeight: "bold",
                paddingRight: "1em",
              }}
            >
              Total
            </td>
            <td
              className="AppFontSizeBig2"
              style={{
                textAlign: "right",
                fontWeight: "bold",
                paddingLeft: "1em",
              }}
            >
              {/* TODO: this may be a place where we need the roundUpThousands formatting */}
              {cartTotals.withTax.toFormat()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CartSummary;
