import { useRef, useState, useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

interface Bounds {
  left: number;
  top: number;
  width: number;
  height: number;
}

/**
 * Measures the size of a dom element, and listens to changes in that size
 *
 * usage:
 *
 * const [ref, bounds] = useMeasure();
 *
 * return (
 *   <p>
 *     The targetted div is at {bounds.left}, {bounds.top}; with size {bounds.width}, {bounds.height}
 *     <div ref={ref} />
 *   </p>
 * )
 */
export function useMeasure<T extends HTMLElement = HTMLDivElement>(): [
  React.RefObject<T>,
  Bounds
] {
  const ref = useRef<T>(null);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });
  const [observer] = useState(
    () => new ResizeObserver(([entry]) => setBounds(entry.contentRect))
  );
  useEffect(() => {
    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer]);
  return [ref, bounds];
}
