import React, { FC } from "react";
import "../css/favorites.css";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { CatalogItem_Database } from "../../../database/catalogItem";
import { useTranslation } from "react-i18next";
import useAccountInfo from "../../Main/useAccountInfo";
import Item1 from "../Templates/itemOrderBtns";
import Item2 from "../Templates/itemNoOrderBtns";
import { ItemProps } from "../Templates/itemOrderBtns";
import SectionSignedOut from "../Components/sectionSignedOut";
import { useLocation, matchPath } from "react-router-dom";
import Product from "./product";
import { FAVORITES_PRODUCT } from "../../../utilities/constants/routes";

export interface FavoritesProps {}

function isDefined<T>(val: T | undefined): val is T {
  return val !== undefined;
}

const Favorites: FC<FavoritesProps> = React.memo(() => {
  const accountInfo = useAccountInfo();
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const store = useStore();
  const { theme, items } = store;
  const location = useLocation();
  const favorites: CatalogItem_Database[] =
    user && accountInfo.favorites
      ? Object.keys(accountInfo.favorites)
          .filter((id) => accountInfo.favorites[id].fav)
          .map((id) => items[id])
          .filter(isDefined)
      : [];
  let ItemComponent: FC<ItemProps>;
  if (
    theme.template.item === "noImgNoOrder" ||
    theme.template.item === "imgNoOrder"
  ) {
    ItemComponent = Item2;
  } else {
    ItemComponent = Item1;
  }
  const productMatch = matchPath<{ itemId: string }>(location.pathname, {
    path: `${FAVORITES_PRODUCT}/:itemId?`,
  });

  return (
    <React.Fragment>
      <Product itemId={productMatch?.params.itemId ?? null} />
      <div
        className="sTfavsOuterWrap sTthemeBGcolorSoft"
        style={{
          transition: "transform 0.5s ease",
          transform: `translateX(${productMatch ? -100 : 0}%)`,
        }}
      >
        <div className="sTfavsHeader"></div>
        <div className="sTfavsContentsWrap">
          <div
            className={
              user && favorites.length > 0
                ? "sTfavsContents2"
                : "sTfavsContents2_so"
            }
          >
            {favorites.length > 0 ? (
              <div className="sTfavsFavsWrap">
                {favorites.map((item) => (
                  <ItemComponent
                    key={item.itemId}
                    itemId={item.itemId}
                    accountInfo={accountInfo}
                    source="favorites"
                  />
                ))}
              </div>
            ) : (
              <div style={{ margin: "1.75em auto", width: "95%" }}>
                {!user ? (
                  <SectionSignedOut source="favorites" />
                ) : (
                  <React.Fragment>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        width: "95%",
                      }}
                    >
                      {t("store.favorites.emptyMessage")}
                      <Icon name="favorite" />
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="sTfavsFooterWrap"></div>
      </div>
    </React.Fragment>
  );
});

export default Favorites;
