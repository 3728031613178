import React, { FC } from "react";
import * as ROUTES from "../../../utilities/constants/routes";
import { useTranslation } from "react-i18next";
import STguestInfoModal from "../Modals/sTguestInfo";
import { GuestDetails, DeliveryOption } from "../Pages/checkout";
import Icon from "../../Icon/icon";
import STdeliveryModal from "../Modals/sTdeliveryInfo";
import STorderDateModal from "../Modals/sTorderDate";
import moment from "moment";
import useModal from "../../Modal/useModal";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import useStore from "../../../customization/useStore";
import { LocationAddress } from "../../../database/storeLocation";
import { useHistory } from "react-router-dom";

export interface CheckoutOptionsProps {
  deliveryOption: DeliveryOption;
  setDeliveryOption: React.Dispatch<React.SetStateAction<DeliveryOption>>;
  guestDetails: GuestDetails;
  setGuestDetails: React.Dispatch<React.SetStateAction<GuestDetails>>;
  addressInfo: LocationAddress;
  setAddressInfo: React.Dispatch<React.SetStateAction<LocationAddress>>;
  requestedTime: moment.Moment;
  setRequestedTime: React.Dispatch<React.SetStateAction<moment.Moment>>;
}

const CheckoutOptions: FC<CheckoutOptionsProps> = ({
  deliveryOption,
  setDeliveryOption,
  guestDetails,
  setGuestDetails,
  addressInfo,
  setAddressInfo,
  requestedTime,
  setRequestedTime,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const showModal = useModal();
  const { user } = useAuthUser();
  const { currency, theme } = useStore();
  const { color, mainColor } = theme;

  return (
    <div
      className="sTcartCard sT_anim_moveRightSmall"
      style={{ width: "100%" }}
    >
      {!user && (
        <div
          onClick={() => {
            showModal(
              STguestInfoModal,
              {
                initialGuestDetails: guestDetails,
                onSubmit: (guestDetails: GuestDetails) => {
                  setGuestDetails(guestDetails);
                },
                showAbort: true,
                onAbort: () => {
                  history.goBack();
                },
              },
              `${ROUTES.CHECKOUT}${ROUTES.ML_GUESTINFO}`
            );
          }}
          className="boxShadow1"
          style={{
            margin: "0.5em auto",
            padding: "0.5em 0.25em",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "95%",
            maxWidth: "20em",
            backgroundColor: mainColor,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Icon name="person_so" fill={color} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                paddingLeft: "0.25em",
              }}
            >
              <div
                style={{
                  wordBreak: "break-word",
                  color: color,
                }}
              >
                {t("store.track.trackYourOrder")}
              </div>
              <div
                style={{ fontWeight: "bold", color: color }}
                className="AppFontSizeSmall"
              >
                &nbsp;{t("store.track.signInForBenefits")}
              </div>
            </div>
          </div>
          <div className="sTiconWrap AppFontSizeSmall" style={{ color: color }}>
            <Icon name="edit" fill={color} />
            {t("common.edit")}
          </div>
        </div>
      )}
      <div
        onClick={() => {
          showModal(
            STdeliveryModal,
            {
              initialAddressInfo: addressInfo,
              initialDeliveryOption: deliveryOption,
              onSubmit: (
                newAddress: LocationAddress,
                newDeliveryOption: DeliveryOption
              ) => {
                setAddressInfo(newAddress);
                setDeliveryOption(newDeliveryOption);
              },
            },
            `${ROUTES.CHECKOUT}${ROUTES.ML_DELIVERY}`
          );
        }}
        className="boxShadow1"
        style={{
          margin: "0.5em auto",
          padding: "0.5em 0.25em",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "95%",
          maxWidth: "20em",
          backgroundColor: mainColor,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Icon
              name={deliveryOption === "DELIVERY" ? "delivery" : "walking"}
              fill={color}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingLeft: "0.25em",
            }}
          >
            <div style={{ wordBreak: "break-word", color: color }}>
              {deliveryOption === "DELIVERY"
                ? t("common.delivery")
                : t("common.pickUp")}
            </div>
            <div
              style={{ fontWeight: "bold", color: color }}
              className="AppFontSizeSmall"
            >
              &nbsp;{t("store.checkout.pickupOrDelivery")}
            </div>
          </div>
        </div>
        <div className="sTiconWrap AppFontSizeSmall" style={{ color: color }}>
          <Icon name="edit" fill={color} />
          {t("common.edit")}
        </div>
      </div>
      <div
        onClick={() => {
          showModal(
            STorderDateModal,
            {
              initialRequestedTime: requestedTime,
              onSubmit: setRequestedTime,
            },
            `${ROUTES.CHECKOUT}${ROUTES.ML_ORDERDATE}`
          );
        }}
        className="boxShadow1"
        style={{
          margin: "0.5em auto",
          padding: "0.5em 0.25em",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "95%",
          maxWidth: "20em",
          backgroundColor: mainColor,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Icon name="clock" fill={color} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingLeft: "0.25em",
            }}
          >
            <div
              style={{
                wordBreak: "break-word",
                color: color,
                textAlign: "left",
              }}
            >
              {requestedTime.toDate().toLocaleDateString(currency.locale, {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
            <div
              style={{ fontWeight: "bold", color: color }}
              className="AppFontSizeSmall"
            >
              &nbsp;{t("store.checkout.orderingOptions")}
            </div>
          </div>
        </div>
        <div className="sTiconWrap AppFontSizeSmall" style={{ color: color }}>
          <Icon name="edit" fill={color} />
          {t("common.edit")}
        </div>
      </div>
    </div>
  );
};

export default CheckoutOptions;
