import React, { FC } from "react";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import ModalVeil from "../../Modal/modalVeil";
import { useTranslation } from "react-i18next";
import { ModalProps } from "../../Modal/modalManager";

interface STxenditIframeModalProps extends ModalProps {
  url?: string;
}

const STxenditIframeModal: FC<STxenditIframeModalProps> = React.memo(
  ({ url, closeModal }) => {
    const store = useStore();
    const { t } = useTranslation();
    const { color, mainColorDark } = store.theme;
    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            backgroundColor: mainColorDark,
            overflow: "auto",
          }}
          className="sTmodal sTmodalIframe boxShadow5"
        >
          <div className="sTmodalHeader">
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                color: color,
              }}
            >
              <Icon name="information" fill={color} />
              <div>&nbsp;{t("store.checkout.payment.ccAuth")}</div>
            </div>
            <br />
            <div
              className="AppFontSizeSmall"
              style={{ fontWeight: "bold", color: color }}
            >
              {t("store.checkout.payment.extraAuth")}
            </div>
          </div>
          <div className="sTmodalContent" style={{ height: "100%" }}>
            <iframe
              sandbox="allow-scripts allow-forms"
              src={url}
              style={{ width: "100%", height: "100%" }}
              title="finish checkout"
            />
          </div>
        </div>
        <ModalVeil onClick={closeModal} />
      </React.Fragment>
    );
  }
);

export default STxenditIframeModal;
