import React, { FC } from "react";
import "../css/paymentAuth.css";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon/icon";
import { Store } from "../../../customization/storeContext";

export interface MaintenanceProps {
  store: Store;
}

const Maintenance: FC<MaintenanceProps> = React.memo(({ store }) => {
  const { t } = useTranslation();

  return (
    <div
      className="App AppFontSizeNormal"
      style={{
        backgroundImage: `url(${store.theme.backgroundImage})`,
        fontFamily:
          store.theme.appFont || "Arial, Verdana, Geneva, Tahoma, sans-serif",
      }}
    >
      <div
        style={{
          position: "fixed",
          height: "100%",
          width: "100%",
        }}
        className={"appVeil appVeilTheme-7"}
      ></div>
      <div className="sTpaymentAuthOuterWrap">
        <div className="sTpaymentAuthHeader"></div>
        <div className="sTpaymentAuthContentsWrap">
          <div
            className="sTpaymentAuthContents2 sTpaymentAuthFadedOut sTpaymentAuth_anim_fadeIn_0305"
            style={{ color: store.theme.color }}
          >
            <div className="AppFontSizeBig2">
              <strong>{t("store.maintenance.inMaintenance")}</strong>
            </div>
            <div>{t("store.maintenance.shortly")}</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2em",
                flexDirection: "column",
              }}
            >
              <Icon name="hottub" fill={store.theme.color} />
              <span style={{}}>{t("store.maintenance.relax")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Maintenance;
