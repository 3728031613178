import {
  optionType,
  ListOption,
  listSelectionType,
  listOptionPriceMode
} from "../../../database/option";
import uuid from "uuid/v4";
import { TFunction } from "i18next";

export const createListOption = (name = "", optionId?: string): ListOption => ({
  name,
  type: optionType.LIST,
  required: false,
  selectionType: listSelectionType.SINGLE,
  priceMode: listOptionPriceMode.ADD,
  items: [],
  optionId: optionId || uuid()
});

export const validateListOption = (option: ListOption, t: TFunction) => {
  if (option.items.length === 0) {
    return t("dashboard.customFields.errorNoOptions", {
      name: option.name
    });
  }
  for (let i = 0; i < option.items.length; i++) {
    const item = option.items[i];
    if (!item.description) {
      return t("dashboard.customFields.errorOptionNeedsName", {
        number: i + 1,
        name: option.name
      });
    }
  }
  if (option.required && option.items.every(item => !item.selectedByDefault)) {
    return t("dashboard.customFields.errorNoDefaultOption", {
      name: option.name
    });
  }
};
