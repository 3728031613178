import { optionType } from "./option";

/**
 * An order is split up into one or more suborders. Stores
 * can toggle a flag to say whether there's just one or if
 * more are allowed.
 *
 * This type is used when building the card. For storing in
 * the database use DatabaseSuborder
 */
export interface Suborder {
  name: string;
  suborderId: string;
  items: CartItemCollection;
}

/**
 * An order is split up into one or more suborders. Stores
 * can toggle a flag to say whether there's just one or if
 * more are allowed.
 *
 * Used when saving to the database. Adds in the prices at time of
 * purchase, so that they are available forever, even if prices change.
 */
export interface DatabaseSuborder extends Suborder {
  items: DatabaseCartItemCollection;
}

/**
 * Group of suborders.
 *
 * Used client side while assembling the order.
 */
export interface SuborderCollection {
  [suborderId: string]: Suborder;
}

/**
 * Group of suborders
 *
 * Used when saving to the database. Adds in the prices at time of
 * purchase, so that they are available forever, even if prices change.
 */
export interface DatabaseSuborderCollection {
  [suborderId: string]: DatabaseSuborder;
}

/**
 * Group of cart items.
 *
 * Used client side while assembling the order.
 */
export interface CartItemCollection {
  [cartItemId: string]: CartItem;
}

/**
 * Group of cart items
 *
 * Used when saving to the database. Adds in the prices at time of
 * purchase, so that they are available forever, even if prices change.
 */
export interface DatabaseCartItemCollection {
  [cartItemId: string]: DatabaseCartItem;
}

/**
 * An item in the cart to be purchased
 *
 * This type is used when building the cart. For storing in the
 * database, use DatabaseCartItem
 */
export interface CartItem {
  /** id to find entry in the cart */
  cartItemId: string;
  /** id to find the corresponding item in store.items */
  itemId: string;
  /** Added by user to be more specific about their order */
  instructions?: string;
  /** Added by user to be more specific about their order */
  optionSelections?: OptionSelection[];
  /** Added by admin to indicate this is complete */
  checked?: boolean;
  /** Added by admin to indicate this cannot be fulfilled */
  unable?: boolean;
  /** Indicates that this was created by an admin, not a user */
  byAdmin?: boolean;
  /**
   * Indicates that we're waiting on the customer to agree to an
   * upcharge payment. If they agree, this flag gets cleared.
   * If they decline, this item gets removed from the order.
   * */
  awaitingUpcharge?: boolean;
}

/**
 * Any item that was purchased
 *
 * This type is stored in the database. When building the
 * cart, use CartItem instead.
 */
export interface DatabaseCartItem extends CartItem {
  /** Price of the item at the time of purchase */
  basePrice: number;
  /** Price of the item at the time of purchase, taking into account all selected options */
  priceWithOptions: number;
  /** Tax rate that was applied at the time of purchase */
  taxPercentage: number;
  /** Added by user to be more specific about their order */
  optionSelections?: DatabaseOptionSelection[];
}

/**
 * Data about what items are selected.
 *
 * This type is used when building the cart. Prices should be determined by
 * looking in the catalog and computing. When saving to the database, use
 * DatabaseOptionSelection instead.
 * */
export type OptionSelection =
  | ListOptionSelection
  | NumberOptionSelection
  | UnitOptionSelection;

interface BaseOptionSelection {
  optionId: string;
}

export interface ListOptionSelection extends BaseOptionSelection {
  /**
   * This type property used to not exist (as everything was a list), so old
   * database entries have undefined
   */
  type?: optionType.LIST;
  selectedItems: {
    [id: string]: boolean | undefined;
  };
}

export const isListOptionSelection = (
  selection?: OptionSelection
): selection is ListOptionSelection => {
  return !!(
    selection &&
    (selection.type === undefined || selection.type === optionType.LIST)
  );
};

export interface NumberOptionSelection extends BaseOptionSelection {
  type: optionType.NUMBER;
  value: number;
}

export const isNumberOptionSelection = (
  selection?: OptionSelection
): selection is NumberOptionSelection => {
  return !!(selection?.type === optionType.NUMBER);
};

export interface UnitOptionSelection extends BaseOptionSelection {
  type: optionType.UNIT;
  value: number;
}

export const isUnitOptionSelection = (
  selection?: OptionSelection
): selection is UnitOptionSelection => {
  return !!(selection?.type === optionType.UNIT);
};

/**
 * Data about what items are selected.
 *
 * Used when saving to the database. Adds in the prices at time of
 * purchase, so that they are available forever, even if prices change.
 */
export type DatabaseOptionSelection =
  | DatabaseListOptionSelection
  | DatabaseNumberOptionSelection
  | DatabaseUnitOptionSelection;

export interface DatabaseListOptionSelection extends ListOptionSelection {
  /** Sum of all the itemPrices */
  price: number;
  /** Price of the individual items at time of purchase */
  itemPrices: {
    [id: string]: number;
  };
}

export interface DatabaseNumberOptionSelection extends NumberOptionSelection {
  /**
   * The amount this changed the total cost
   */
  price: number;
}

export interface DatabaseUnitOptionSelection extends UnitOptionSelection {
  /**
   * Base price multiplies every time this many units is selected.
   * total price = (base price / price denominator) * amount
   *
   * For example, if base price is 10 euros, 500 grams are sold, and
   * priceDenominator is 100, then the total cost is 50 euros
   */
  priceDenominator: number;
}
