import React, { FC } from "react";
import useCartApi, { Cart } from "./useCartApi";
import { CartContext } from "./useCart";

let restoredCart: null | Cart = null;
try {
  const data = sessionStorage.getItem("cart");
  if (data) {
    restoredCart = JSON.parse(data);
  }
} catch (err) {}

const CartManager: FC = props => {
  const value = useCartApi(restoredCart);

  return (
    <CartContext.Provider value={value}>{props.children}</CartContext.Provider>
  );
};

export default CartManager;
