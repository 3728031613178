import React, { FC, useState } from "react";
import { useSettingsApi } from "./settingsApiContext";
import { useTranslation } from "react-i18next";
// import useLockDimensions from "../../Main/useLockDimensions";
import DashboardButton from "../Components/dbButton";

interface OrderTimingInputSettingsProps {
  handleSubmit: () => void;
}

const OrderTimingInputSettings: FC<OrderTimingInputSettingsProps> = ({
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const settingsApi = useSettingsApi();
  // const lockDimensions = useLockDimensions();

  const [daysValue, setDaysValue] = useState(
    Math.floor(Number(settingsApi.location.orderIncrement) / 1440)
  );
  const [hoursValue, setHoursValue] = useState(
    Math.floor(Number(settingsApi.location.orderIncrement) / 60) % 24
  );
  const [minutesValue, setMinutesValue] = useState(
    Number(settingsApi.location.orderIncrement) % 60
  );

  return (
    <React.Fragment>
      <div
        className="AppFontSizeSmall"
        style={{
          padding: "0.5em 0.25em 0.25em 0.25em",
          textAlign: "left",
          fontWeight: "bold",
        }}
      >
        {t("dashboard.settings.orderTimingDays")}
      </div>
      <input
        type="number"
        min="0"
        defaultValue={daysValue}
        style={{ margin: 0 }}
        onChange={(event) => {
          if (Number(event.target.value) >= 0)
            setDaysValue(Number(event.target.value));
          else setDaysValue(0);
        }}
        // onFocus={() => lockDimensions(true, "DB orderTiming Days")}
        placeholder={t("dashboard.settings.orderTimingDaysPlaceholder")}
      />
      <div
        className="AppFontSizeSmall"
        style={{
          padding: "0.5em 0.25em 0.25em 0.25em",
          textAlign: "left",
          fontWeight: "bold",
        }}
      >
        {t("dashboard.settings.orderTimingHours")}
      </div>
      <input
        type="number"
        min="0"
        defaultValue={hoursValue}
        style={{ margin: 0 }}
        onChange={(event) => {
          if (Number(event.target.value) >= 0)
            setHoursValue(Number(event.target.value));
          else setHoursValue(0);
        }}
        // onFocus={() => lockDimensions(true, "DB orderTiming Hours")}
        placeholder={t("dashboard.settings.orderTimingHoursPlaceholder")}
      />
      <div
        className="AppFontSizeSmall"
        style={{
          padding: "0.5em 0.25em 0.25em 0.25em",
          textAlign: "left",
          fontWeight: "bold",
        }}
      >
        {t("dashboard.settings.orderTimingMinutes")}
      </div>
      <input
        type="number"
        min="0"
        defaultValue={minutesValue}
        style={{ margin: 0 }}
        onChange={(event) => {
          if (Number(event.target.value) >= 0)
            setMinutesValue(Number(event.currentTarget.value));
          else setMinutesValue(0);
        }}
        // onFocus={() => lockDimensions(true, "DB orderTiming Minutes")}
        placeholder={t("dashboard.settings.orderTimingMinutesPlaceholder")}
      />
      <DashboardButton
        style={{ marginTop: "1em" }}
        subtitle={t("common.confirm")}
        text={t("dashboard.settings.orderTimingConfirmButton")}
        icon="done"
        onClick={() => {
          settingsApi.setLocation((prev) => ({
            ...prev,
            orderIncrement:
              minutesValue + hoursValue * 60 + daysValue * 24 * 60,
          }));
          handleSubmit();
        }}
      />
    </React.Fragment>
  );
};

export default OrderTimingInputSettings;
