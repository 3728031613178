import {
  Catalog_Database,
  Catalog_ClientSide,
  ItemReference,
} from "../database/catalog";
import {
  CatalogItem_Database,
  CatalogItem_ClientSide,
} from "../database/catalogItem";
import { OptionCollection } from "../database/option";
import { notUndefined } from "./notNull";
import { StoreLocation } from "../database/storeLocation";

/**
 * Takes a catalog as it exists in the database and merges together
 * various datasources to replace item and option references with
 * actual items and actual options
 */
export const convertCatalogFromDatabase = (
  catalog: Catalog_Database,
  items: Record<string, CatalogItem_ClientSide>
): Catalog_ClientSide => {
  const newCatalog: Catalog_ClientSide = {
    ...catalog,
    categories: catalog.categories.map((category) => ({
      ...category,
      items: category.items
        .map((itemRef) => items[itemRef.itemId])
        .filter(notUndefined),
    })),
  };
  return newCatalog;
};

export const convertItemFromDatabase = (
  item: CatalogItem_Database,
  options: OptionCollection
) => ({
  ...item,
  options: item.options
    ? item.options
        .map((optionRef) => options[optionRef.optionId])
        .filter(notUndefined)
    : [],
});

export const convertCatalogToDatabase = (
  catalog: Catalog_ClientSide
): Catalog_Database => {
  const newCatalog: Catalog_Database = {
    ...catalog,
    categories: catalog.categories.map((category) => ({
      ...category,
      items: category.items.map((item) => {
        const ref: ItemReference = {
          itemId: item.itemId,
        };
        return ref;
      }),
    })),
  };

  return newCatalog;
};

type CatalogPieces = Pick<StoreLocation, "catalog" | "items" | "options">;

export const convertCatalogToPartialLocation = (
  catalog: Catalog_ClientSide
): CatalogPieces => {
  const location: CatalogPieces = {
    catalog: convertCatalogToDatabase(catalog),
    items: {},
    options: {},
  };

  catalog.categories.forEach((category) => {
    category.items.forEach((item) => {
      location.items[item.itemId] = convertItemToDatabase(item);
      item.options.forEach((option) => {
        location.options[option.optionId] = option;
      });
    });
  });

  return location;
};

export const convertItemToDatabase = (
  item: CatalogItem_ClientSide
): CatalogItem_Database => {
  const newItem: CatalogItem_Database = {
    ...item,
    options: item.options.map((option) => ({
      optionId: option.optionId,
    })),
  };
  return newItem;
};
