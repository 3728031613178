import React, { useState, FC } from "react";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import ModalVeil from "../../Modal/modalVeil";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ModalProps } from "../../Modal/modalManager";
import useToast from "../../Main/useToast";
import {
  nextTimeOpen,
  isOpenU,
  isInFutureU,
  checkOrderDate,
  isInFutureFromNowU,
} from "../../../utilities/checkStoreOpen";
import useRefreshInterval from "../../../utilities/useRefreshInterval";
import { hexToRgbString } from "../../../utilities/colorUtils";
import getDateObject from "../../../utilities/getDateObjectFromString";
export interface STorderDateModalProps extends ModalProps {
  initialRequestedTime: moment.Moment;
  onSubmit: (value: moment.Moment) => void;
}

const dateFormat = "YYYY-MM-DD";
const timeFormat = "kk:mm";

const STorderDateModal: FC<STorderDateModalProps> = React.memo(
  ({ initialRequestedTime, onSubmit, closeModal }) => {
    const toast = useToast();
    const { t } = useTranslation();
    const store = useStore();
    const { theme, orderIncrement } = store;
    const { mainColorDark, color } = theme;
    const earliest = moment().add(orderIncrement + 1, "minutes");

    const [date, setDate] = useState(initialRequestedTime.format(dateFormat));
    const [time, setTime] = useState(initialRequestedTime.format(timeFormat));

    const selectedMoment =
      date && time
        ? moment(`${date} ${time}`, `${dateFormat} ${timeFormat}`)
        : undefined;
    let isOpen = isOpenU(selectedMoment, store);
    let isInFuture = isInFutureU(selectedMoment, store);
    let isInFutureFromNow = isInFutureFromNowU(selectedMoment);
    let valid = checkOrderDate(date, time, selectedMoment, store);
    let validDateTime = selectedMoment?.isValid;
    const setToNextOpen = () => {
      let newSelectedMoment = earliest.clone();
      if (!isOpenU(newSelectedMoment, store)) {
        newSelectedMoment = nextTimeOpen(newSelectedMoment, store);
      }
      setDate(newSelectedMoment.format(dateFormat));
      setTime(newSelectedMoment.format(timeFormat));
    };

    const handleSubmit = (e: React.MouseEvent) => {
      e.preventDefault();
      if (valid) {
        onSubmit(moment(`${date} ${time}`, `${dateFormat} ${timeFormat}`));
        closeModal();
      } else {
        toast({
          message: t("store.toast.validDateTime"),
          className: "sTthemeAlert1b",
        });
      }
    };

    useRefreshInterval(10000);
    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            color: color,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader"
            style={{
              backgroundColor: mainColorDark,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  color: color,
                }}
              >
                <Icon name="help" fill={color} />
                <div>&nbsp;{t("store.checkout.desiredDateAndTime")}&nbsp;</div>
              </div>
            </div>
            <div
              className="AppFontSizeSmall"
              style={{ fontWeight: "bold", color: color }}
            >
              {t("store.checkout.orderDateSubheader")}
            </div>
            <div className="AppFontSizeSmall" style={{ color: color }}>
              {t("store.checkout.orderDateDescription")}
            </div>
          </div>
          <div className="sTmodalContent">
            <br />
            <div
              style={{
                margin: "0 0 0.5em 0",
              }}
            >
              {
                <React.Fragment>
                  <div
                    className="AppFontSizeExtraSmall"
                    style={{
                      color: "black",
                      marginLeft: "0.5em",
                      fontWeight: "bold",
                    }}
                  >
                    <span>
                      {getDateObject(date, time).dateObject.toLocaleDateString(
                        []
                      )}
                    </span>
                  </div>
                  <input
                    name="orderDate"
                    style={{ marginTop: 0 }}
                    placeholder="yyyy-mm-dd"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <div
                    className="AppFontSizeExtraSmall"
                    style={{
                      color: "black",
                      marginLeft: "0.5em",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <span>
                      {getDateObject(date, time).dateObject.toLocaleTimeString(
                        [],
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </span>
                    <span
                      onClick={() => {
                        const h = parseInt(time.substr(0, 2));
                        const hours =
                          h <= 11
                            ? h + 12
                            : h - 12 < 10
                            ? `0${h - 12}`
                            : h - 12;

                        setTime(`${hours}:${time.substring(3, 5)}`);
                      }}
                      style={{
                        opacity: isNaN(
                          getDateObject(date, time).dateObject.getTime()
                        )
                          ? 0
                          : 1,
                        transition: "all 0.3s linear 0s",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "0.5em",
                      }}
                    >
                      <Icon name="arrow_forward" />
                      &nbsp;
                      {parseInt(time.substr(0, 2)) <= 11
                        ? t("store.checkout.setToAfternoon")
                        : t("store.checkout.setToMorning")}
                    </span>
                  </div>
                  <input
                    style={{ marginTop: 0 }}
                    name="orderTime"
                    type="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                  {!validDateTime ? (
                    <p style={{ color: "red", padding: "0 0.25em" }}>
                      {t("store.toast.validDateTime")}
                    </p>
                  ) : isInFutureFromNow ? (
                    !isOpen ? (
                      isInFuture ? (
                        <p style={{ color: "red", padding: "0 0.25em" }}>
                          {(() => {
                            const next = nextTimeOpen(
                              selectedMoment || moment(),
                              store
                            );
                            if (!next) {
                              return t("store.checkout.notOpenThisWeek");
                            } else {
                              return t("store.checkout.notOpenUntil", {
                                time: next.format("dddd, MMMM Do, LT"),
                              });
                            }
                          })()}
                        </p>
                      ) : (
                        <p style={{ color: "red", padding: "0 0.25em" }}>
                          {(() => {
                            const next = nextTimeOpen(
                              moment().add(orderIncrement + 1, "minutes"),
                              store
                            );
                            if (!next) {
                              return t("store.checkout.notOpenThisWeek");
                            } else {
                              return t("store.checkout.notOpenUntil", {
                                time: next.format("dddd, MMMM Do, LT"),
                              });
                            }
                          })()}
                        </p>
                      )
                    ) : !isInFuture ? (
                      <p style={{ color: "red", padding: "0 0.25em" }}>
                        {(() => {
                          const next = nextTimeOpen(
                            moment().add(orderIncrement + 1, "minutes"),
                            store
                          );
                          if (!next) {
                            return t("store.checkout.notOpenThisWeek");
                          } else {
                            return t("store.checkout.notOpenUntil", {
                              time: next.format("dddd, MMMM Do, LT"),
                            });
                          }
                        })()}
                      </p>
                    ) : (
                      true
                    )
                  ) : (
                    <p style={{ color: "red", padding: "0 0.25em" }}>
                      {t("store.checkout.dateInThePast")}
                    </p>
                  )}
                </React.Fragment>
              }
            </div>
          </div>
          <div
            className="sTmodalFooter AppFontSizeSmall"
            style={{
              backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                transition: "all 0.5s ease 0s",
                color: color,
              }}
              onClick={() => setToNextOpen()}
              className="sTmodalFooterItem AppFontSizeSmall"
            >
              {t("store.checkout.setTimeToNext")}
            </div>
            <div
              style={{
                fontWeight: "bold",
                transition: "all 0.5s ease 0s",
                color: color,
              }}
              onClick={(e) => handleSubmit(e)}
              className="sTmodalFooterItem AppFontSizeSmall"
            >
              {t("common.submit")}
            </div>
          </div>
        </div>
        <ModalVeil onClick={closeModal} />
      </React.Fragment>
    );
  }
);

export default STorderDateModal;
