import React, { FC, useContext, useRef, useEffect } from "react";
import * as ROUTES from "../../../utilities/constants/routes";
import * as R from "ramda";
import Icon from "../../Icon/icon";
import { useTranslation } from "react-i18next";
import useStore from "../../../customization/useStore";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { Order } from "../../../database/order";
import useChargeAdjustments from "../../../utilities/chargeAdjustments";
import STauthCancelModal from "../Modals/sTauthCancel";
import useModal from "../../Modal/useModal";
import { useHistory } from "react-router-dom";
import { FirebaseContext } from "../../../Firebase";
import STreceiptModal from "../Modals/sTreceipt";
import { PaymentContext } from "../../PaymentProvider/usePayment";
import useToast from "../../Main/useToast";
import * as APPCONSTANTS from "../../../utilities/constants/appConstants";
import { StripePaymentIntent } from "../Checkout/paymentForm";
import useSpinner from "../../Main/useSpinner";
import {
  getStripePaymentIntent,
  orderRechargeUpdate,
} from "../../../utilities/httpsCallables/httpsCallables";
import { paymentRechargeStatus as paymentRechargeStatusType } from "../../../database/order";

interface PaymentAuthMainProps {
  order: Order;
  paymentIntentId: string;
}
const PaymentAuthMain: FC<PaymentAuthMainProps> = React.memo(
  ({ order, paymentIntentId }) => {
    const { t } = useTranslation();
    const store = useStore();
    const { theme } = store;
    const showSpinner = useSpinner();
    const { user } = useAuthUser();
    const { mainColorDark, mainColorLight, color, mainColor } = theme;
    const {
      totalDiff,
      taxTable,
      newTotal,
      totalTax,
      type,
      adjustment,
    } = useChargeAdjustments(order);
    const history = useHistory();
    const showModal = useModal();
    const { loading, stripe } = useContext(PaymentContext);
    const toast = useToast();
    const paymentInProgress = useRef(false);
    const firebase = useContext(FirebaseContext);
    const paymentIntentPromiseRef = useRef<Promise<StripePaymentIntent>>();
    useEffect(
      () => {
        paymentIntentPromiseRef.current = getStripePaymentIntent(firebase, {
          action: "retrieve",
          paymentIntentId: order.paymentToken,
          amount: totalDiff.getAmount(),
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const redirect = () => {
      console.log("paymentAuthMain.tsx GOING TO REDIRECT");
      if (user) {
        console.log("Redirecting user to track page.");
        history.replace(ROUTES.TRACK);
      } else {
        console.log("Redirecting user to home page.");
        history.replace(ROUTES.STORE);
      }
    };
    const handleSubmit = async () => {
      if (paymentInProgress.current) {
        return; // already in progress, don't do it again
      }
      paymentInProgress.current = true;
      if (loading) {
        toast({
          message: t("store.toast.paymentLoading"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
        paymentInProgress.current = false;
        return;
      } else if (!stripe) {
        // I think this would only happen if we failed to load the stripe script
        toast({
          message: t("store.toast.paymentSystemDown"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
        paymentInProgress.current = false;
        return;
      } else {
        const hideSpinner = showSpinner({ lag: "none" });
        try {
          const paymentIntentObject = await paymentIntentPromiseRef.current;
          // console.log("paymentAuthMain.tsx", paymentIntentObject);
          try {
            //WANT TO CATCH PAYMENT ERROR SEPARATELY
            const payment = await stripe.confirmCardPayment(
              paymentIntentObject?.clientSecret || "",
              {
                payment_method: paymentIntentObject?.id,
              }
            );
            if (payment.paymentIntent?.status === "succeeded") {
              const orderNew = R.clone(order);
              Object.values(orderNew.suborders).forEach(
                (suborder: any, index) => {
                  Object.values(suborder.items).forEach((item: any, index2) => {
                    if (item.awaitingUpcharge) {
                      delete item.awaitingUpcharge;
                    }
                  });
                }
              );
              await orderRechargeUpdate(firebase, {
                orderId: order.orderId,
                locationId: store.locationId,
                tax: totalTax.getAmount(),
                taxTable: R.map((tax) => tax.getAmount(), taxTable),
                total: newTotal.getAmount(),
                amount: Math.abs(totalDiff.getAmount()),
                chargeChange: type,
                emailFromSystem: store.emailFromSystem,
                emailTo: store.emailTo,
                customerEmail: order.customerEmail,
                allowMeals: store.allowMeals,
                suborders: orderNew.suborders,
                invoiceCode: order.invoiceCode,
                currencyObjectFromOrder: order.currency,
                adjustmentReason: order.totalAdjustmentReason || "",
                adjustmentAmount: adjustment.getAmount() || 0,
                paymentAuthSent: "delete",
                paymentRechargeStatus: paymentRechargeStatusType.complete,
                adjustmentLast: adjustment.getAmount() || 0,
                userId: order.userId,
                url: `${window.location.protocol}//${window.location.host}`,
              });
              toast({
                message: t("store.checkout.payment.success"),
                color: color,
                backgroundColor: mainColor,
              });
              redirect();
            } else {
              console.log("PAYMENT ERROR:", payment.error);
              toast({
                message: payment.error?.message || t("toast.systemError"),
                className: "sTthemeAlert1b",
                duration: APPCONSTANTS.TOASTDURATION_ERROR,
              });
            }
            paymentInProgress.current = false;
          } catch (error) {
            console.log("PAYMENT ERROR:", error);
            toast({
              message: error,
              className: "sTthemeAlert1b",
              duration: APPCONSTANTS.TOASTDURATION_ERROR,
            });
            paymentInProgress.current = false;
          }
        } catch (error) {
          toast({
            message: t("store.toast.paymentSystemDown"),
            className: "sTthemeAlert1b",
            duration: APPCONSTANTS.TOASTDURATION_ERROR,
          });
          paymentInProgress.current = false;
        } finally {
          hideSpinner();
          paymentInProgress.current = false;
        }
      }
    };
    return (
      <React.Fragment>
        <div style={{ margin: "1em 0" }}>
          {t("store.paymentAuth.newCharge")}
        </div>
        <div
          onClick={() => {
            showModal(
              STreceiptModal,
              {
                order,
              },
              `${ROUTES.PAYMENTAUTH}${ROUTES.ML_RECEIPT}`
            );
          }}
          style={{ margin: "1em 0" }}
          className="sTtoggleOrLinkButton AppFontSizeSmall"
        >
          <Icon name="receipt" />
          {t("store.paymentAuth.reviewOrder")}
        </div>
        <div
          onClick={() => {
            handleSubmit();
          }}
          style={{
            backgroundColor: mainColorDark,
            color: color,
            margin: "1em 0 0 0",
          }}
          className="sTbutton"
        >
          <div
            style={{
              backgroundColor: mainColorLight,
            }}
            className="sTbuttonIcon"
          >
            <Icon name="lock" fill={color} />
          </div>
          <span className="sTbuttonText AppFontSizeNormal">
            {t("store.checkout.payButton")}
            {totalDiff.toFormat()}
          </span>
          <div
            style={{
              opacity: 0,
            }}
            className="sTbuttonIcon"
          >
            <Icon name="lock" />
          </div>
        </div>
        <div
          style={{ margin: "1em 0" }}
          onClick={() => {
            showModal(
              STauthCancelModal,
              {
                redirect,
                order,
                paymentIntentId,
              },
              `${ROUTES.PAYMENTAUTH}${ROUTES.ML_CANCELPAYAUTH}`
            );
          }}
          className="sTtoggleOrLinkButton AppFontSizeSmall"
        >
          <Icon name="delete_forever" />
          {t("store.paymentAuth.cancelCharge")}
        </div>
      </React.Fragment>
    );
  }
);

export default PaymentAuthMain;
