import Firebase from "../Firebase";
import { AccountInfo } from "../components/Main/main";
import { ShowSpinner } from "../components/Main/mainContext";

export interface Options {
  firebase: Firebase;
  showSpinner: ShowSpinner;
  accountInfo: AccountInfo;
  itemId: string;
  user: firebase.User | null;
}

const toggleFavorite = async ({
  firebase,
  showSpinner,
  accountInfo,
  itemId,
  user,
}: Options) => {
  if (!user) {
    // Can't set favorites if not logged in
    return;
  }
  console.log("Updating meal favorite", itemId);
  const hideSpinner = showSpinner();
  const update: firebase.firestore.UpdateData = {
    ["favorites." + itemId]: {
      fav: !(
        accountInfo.favorites[itemId] && accountInfo.favorites[itemId].fav
      ),
    },
  };
  try {
    await firebase.firestore
      .collection("stores")
      .doc("store")
      .collection("users")
      .doc(user.uid)
      .update(update);
    console.log("FAVORITES UPDATE WORKED");
  } catch (error) {
    console.log("FAVORITES UPDATE DIDNT WORK", error);
  } finally {
    hideSpinner();
  }
};

export default toggleFavorite;
