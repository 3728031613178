import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { countryCodes } from "./countryCodes";
import { LocationAddress } from "../../../database/storeLocation";
import { stateCodes } from "./stateCodes";
import StoreSelect from "../Components/sTselect";

export interface AddressFormProps {
  address: LocationAddress;
  onAddressChanged: (address: LocationAddress) => void;
  style?: React.CSSProperties;
}

const AddressForm: FC<AddressFormProps> = ({
  style,
  address,
  onAddressChanged,
}) => {
  const { t } = useTranslation();
  // const lockDimensions = useLockDimensions();

  return (
    <div style={style}>
      <input
        value={address.streetLine1}
        onChange={(e) =>
          onAddressChanged({
            ...address,
            streetLine1: e.target.value,
          })
        }
        type="text"
        placeholder={t("store.account.streetPlaceholder")}
        // onFocus={() => lockDimensions(true)}
      />
      <input
        value={address.streetLine2 || ""}
        onChange={(e) =>
          onAddressChanged({
            ...address,
            streetLine2: e.target.value,
          })
        }
        type="text"
        placeholder={t("store.account.streetPlaceholder2")}
        // onFocus={() => lockDimensions(true)}
      />
      <input
        value={address.streetLine3 || ""}
        onChange={(e) =>
          onAddressChanged({
            ...address,
            streetLine3: e.target.value,
          })
        }
        type="text"
        placeholder={t("store.account.streetPlaceholder3")}
        // onFocus={() => lockDimensions(true)}
      />
      <input
        value={address.city}
        onChange={(e) =>
          onAddressChanged({
            ...address,
            city: e.target.value,
          })
        }
        type="text"
        placeholder={t("store.account.cityPlaceholder")}
        // onFocus={() => lockDimensions(true)}
      />
      <input
        value={address.zip}
        onChange={(e) =>
          onAddressChanged({
            ...address,
            zip: e.target.value,
          })
        }
        type="text"
        placeholder={t("store.account.zipPlaceholder")}
        // onFocus={() => lockDimensions(true)}
      />
      <StoreSelect
        style={{
          position: "relative",
          transition: "all 0.3s linear 0s",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
          margin: "0.5em 0",
        }}
        text={
          address.countryCode
            ? t(`common.country.${address.countryCode}`)
            : t("store.account.countryPlaceholder")
        }
        textStyle={{ color: "black", paddingLeft: "0.25em" }}
        icon={undefined}
        iconColor="black"
        expandIcon={true}
        value={address.countryCode}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          onAddressChanged({
            ...address,
            countryCode: e.target.value,
          })
        }
        selectStyle={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "1.7em",
          opacity: 0,
        }}
      >
        <option value={""} disabled>
          {t("store.account.countryPlaceholder")}
        </option>
        {countryCodes.map((countryCode) => (
          <option key={countryCode} value={countryCode}>
            {t(`common.country.${countryCode}`)}
          </option>
        ))}
      </StoreSelect>
      {address.countryCode === "US" && (
        <StoreSelect
          style={{
            position: "relative",
            transition: "all 0.3s linear 0s",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            margin: "0.5em 0",
          }}
          text={
            address.stateCode
              ? address.stateCode
              : t("store.account.statePlaceholder")
          }
          textStyle={{ color: "black", paddingLeft: "0.25em" }}
          icon={undefined}
          iconColor="black"
          expandIcon={true}
          value={address.stateCode}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            onAddressChanged({
              ...address,
              stateCode: e.target.value,
            })
          }
          selectStyle={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "1.7em",
            opacity: 0,
          }}
        >
          <option value={""} disabled>
            {t("store.account.statePlaceholder")}
          </option>
          {stateCodes.map((stateCode) => (
            <option key={stateCode} value={stateCode}>
              {t(`common.state.${stateCode}`)}
            </option>
          ))}
        </StoreSelect>
      )}
    </div>
  );
};

export default AddressForm;
