import { Redirect } from "react-router-dom";
import React from "react";
import * as ROUTES from "../../utilities/constants/routes";

const Superuser = ({ isSuperuser }) => {
  if (isSuperuser === false) {
    return <Redirect to={ROUTES.HOME} />;
  }

  return (
    <div style={{ color: "black" }}>
      Hello World!
      <p>
        This page will eventually contain controls for updating user priveleges
      </p>
    </div>
  );
};

export default Superuser;
