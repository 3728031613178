import memoize from "memoize-one";

const getDateObjectFromString = memoize(
  (dateString: string, timeString: string) => {
    const dateTimeDetails = {
      dateObject: new Date(
        parseInt(dateString.substr(0, 4)),
        parseInt(dateString.substr(5, 2)) - 1,
        parseInt(dateString.substr(8, 2)),
        parseInt(timeString.substr(0, 2)),
        parseInt(timeString.substr(3, 2)),
        0,
        0
      ),
      year: parseInt(dateString.substr(0, 4)),
      month: parseInt(dateString.substr(5, 2)) - 1,
      day: parseInt(dateString.substr(8, 2)),
      hour: parseInt(timeString.substr(0, 2)),
      minute: parseInt(timeString.substr(3, 2)),
    };

    // console.log(
    //   "getDateObjectFromString.js",
    //   dateTimeDetails.dateObject,
    //   dateTimeDetails.dateObject.getTime(),
    //   new Date(),
    //   new Date().getTime() + 15 * 60000
    // );

    return dateTimeDetails;
  }
);

export default getDateObjectFromString;
