import React, { FC } from "react";
import Draggable from "react-draggable";
import useStore from "../../../customization/useStore";
import DashboardButton from "../../Dashboard/Components/dbButton";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon/icon";

export interface PreviewOverlayProps {}

const PreviewOverlay: FC<PreviewOverlayProps> = (props) => {
  const { isPreview, previewReturnUrl } = useStore();
  const history = useHistory();
  const { t } = useTranslation();

  if (!isPreview) {
    return null;
  }

  return (
    <Draggable bounds="body" handle="#previewDragHandle">
      <div className="editingOverlay">
        <div id="previewDragHandle">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "grab",
              textAlign: "left",
            }}
          >
            <div className="AppFontSizeSmall" style={{ fontWeight: "bold" }}>
              {t("dashboard.catalog.previewMode").toUpperCase()}
            </div>
            <Icon name="drag" fill="white" />
          </div>
          <div style={{ cursor: "grab" }}>
            {t("dashboard.catalog.dragOverlay")}
          </div>
        </div>
        <DashboardButton
          text={t("dashboard.catalog.returnToEditing")}
          theme="white left"
          iconColor="white"
          icon="arrow_back"
          onClick={(e) => {
            e.stopPropagation();
            history.push(previewReturnUrl);
          }}
        />
      </div>
    </Draggable>
  );
};

export default PreviewOverlay;
