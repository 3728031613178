import { useState } from "react";
import Dinero from "dinero.js";
import useStore from "../customization/useStore";
import { memoizedGetTotals } from "./orderProcessing";
import { Order } from "../database/order";

const useAdjustmentState = ({
  total: originalTotalNumber,
  suborders,
  totalAdjustmentAmount = 0,
  totalAdjustmentReason = ""
}: Order) => {
  const originalTotal = Dinero({ amount: originalTotalNumber });
  const store = useStore();
  const { totalTax, preTax, taxTable } = memoizedGetTotals(suborders, store);
  const [adjustment, setAdjustment] = useState(
    Dinero({ amount: totalAdjustmentAmount })
  );
  const [reason, setReason] = useState(totalAdjustmentReason);
  const hasAdjustment = adjustment.getAmount() !== 0;
  const hasChanged = adjustment.getAmount() !== (totalAdjustmentAmount || 0);
  const newSubtotal = preTax.add(adjustment);
  const newTotal = newSubtotal.add(totalTax);
  const totalDiff = newTotal.subtract(originalTotal);
  const type: "refund" | "upcharge" =
    totalDiff.getAmount() < 0 ? "refund" : "upcharge";

  return {
    reason,
    setReason,
    adjustment,
    setAdjustment,
    previousAdjustment: totalAdjustmentAmount,
    hasAdjustment,
    hasChanged,
    totalTax,
    taxTable,
    newSubtotal,
    newTotal,
    totalDiff,
    type,
    originalTotal: originalTotalNumber
  };
};

export default useAdjustmentState;
