import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  useMemo,
  Suspense,
} from "react";
import Spinner from "../Spinner/spinner";
import Store from "../Store/store";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import * as ROUTES from "../../utilities/constants/routes";
import { TOASTDURATION_ERROR } from "../../utilities/constants/appConstants";
import useStore from "../../customization/useStore";
import useAuthUser from "../AuthUserProvider/useAuthUser";
import { FirebaseContext } from "../../Firebase";
import Superuser from "../Superuser/Superuser";
import Toast from "../Toast/toast";
import changeNotifyPrefs from "../../utilities/changeNotifyPrefs";
import ModalManager, { ModalBackgroundState } from "../Modal/modalManager";
import { MainContext, ShowSpinner, ShowSpinnerProps } from "./mainContext";
import { ToastProps } from "../Toast/toast";
import { UserData, Favorite } from "../../database/userData";
import { useTranslation } from "react-i18next";
import EditingProvider from "../Dashboard/Settings/editingProvider";
import * as R from "ramda";

const Dashboard = React.lazy(() => import("../Dashboard/dashboard"));
/**
 * Helper utility to create a function which will only be run once.
 */
const once = (fn: (...args: any[]) => any) => {
  let called = false;
  return (...args: any[]): any => {
    if (!called) {
      called = true;
      return fn(...args);
    }
  };
};

export type AccountInfo = typeof defaultAccountInfo;

export const defaultAccountInfo = {
  name: "",
  email: "",
  phone: "",
  street: "",
  city: "",
  zip: "",
  suite: "",
  state: "",
  country: "",
  notifyEmail: true,
  notifySms: false,
  notifyPush: false,
  pushTokens: [] as string[],
  favorites: {} as { [menuItemId: string]: Favorite },
};
export const srcSetString = (urls: { [key: string]: string } | undefined) => {
  if (!urls) return "";
  let imageString = "";
  Object.entries(urls).forEach((url) => {
    imageString += `${url[1]} ${url[0]}w, `;
  });
  return imageString;
};

const Main: FC = React.memo(() => {
  const { t } = useTranslation();
  const location = useLocation<ModalBackgroundState>();
  const history = useHistory();
  const { user, isSuperuser, isOwner, isAdmin } = useAuthUser();
  const appContainer = useRef<HTMLDivElement>(null);
  const animContainer = useRef<HTMLDivElement>(null);
  const firebase = useContext(FirebaseContext);
  const store = useStore();
  const { theme } = store;
  const [toastActive, setToastActive] = useState<ToastProps | null>(null);
  const hideToast = useCallback(() => setToastActive(null), []);
  const [dBnavMenu, setdBNavMenu] = useState(false);
  const [dBnavMenuFull, setdBNavMenuFull] = useState(false);
  const [accountInfo, setAccountInfo] = useState(defaultAccountInfo);
  const [spinners, setSpinners] = useState<
    Record<string, ShowSpinnerProps | undefined>
  >({});
  const spinnerArray = useMemo(() => Object.values(spinners), [spinners]);

  //WE SET A FIXED PIXEL HEIGHT DURING INPUT FIELD FOCUS
  //1) TO AVOID THE VIRTUAL KEYBOARD FROM SQUISHING THE PAGE
  //2) FROM THE BACKGROUND IMAGE LOOKING ALL MESSED UP (THIS IS BROWSER GLITCH)
  //WE REMOVE THE FIXED PIXEL HEIGHT BELOW IN USEEFFECT
  //SCREEN.HEIGHT = SCREEN SIZE (STAYS THE SAME) / SERVES AS OUR CONSTANT
  //INNERHEIGHT = HEIGHT OF PAGE CONTENTS (DIFFERS WHEN SOFT KEYS COME OUT)
  //THE REASON WE COMPARE THE DIFFERENCE IS FOR THE FOLLOWING USE CASE:
  //ON A MOBILE DEVICE, WHEN THE USER GOES INSTANTLY FROM ONE INPUT FIELD
  //TO ANOTHER INPUT FIELD, THE KEYBOARD WILL BRIEFLY CHANGE HEIGHT (E.G.
  //TO ADD A ROW OF NUMBERS AT THE TOP...),
  //THIS WILL RESET THE HEIGHT IF WE DONT USE THIS LITTLE TRICK OF
  //MEASURING HEIGHT DIFFERENCE (BY WHICH WE ONLY TURN OFF THE PIXEL HEIGTH
  //WHEN WE ARE SURE THE KEYBOARD IS DOWN ALL THE WAY)
  const [inputFocus, setInputFocus] = useState({
    focus: false,
    heightDiff: window.screen.height - window.innerHeight,
  });
  const lockDimensions = useCallback((lock, source) => {
    // console.log("In lockDimensions", lock, source);
    if (lock) {
      // console.log("SETTING FIXED HEIGHT");
      setInputFocus((prev) => ({ focus: true, heightDiff: prev.heightDiff }));
      if (!appContainer.current) {
        return;
      }
      if (!appContainer.current.style.minHeight) {
        // console.log("SETTING MIN HEIGHT", window.innerHeight + "px");
        appContainer.current.style.minHeight = window.innerHeight + "px";
        if (animContainer.current) {
          animContainer.current.style.minHeight = window.innerHeight + "px";
        }
      }
    } else {
      setInputFocus((prev) => ({ focus: false, heightDiff: prev.heightDiff }));
    }
  }, []);

  let spinnerId = useRef(0);

  const showSpinner: ShowSpinner = useCallback(
    (props?: ShowSpinnerProps): (() => void) => {
      const id = spinnerId.current;
      spinnerId.current++;
      setSpinners((prev) => ({
        ...prev,
        [id]: props,
      }));

      return once(() => setSpinners(R.omit(["" + id])));
    },
    []
  );

  useEffect(() => {
    //HANDLE INCOMING PUSH MESSAGES WHEN THE APP IS OPEN AND IN FOCUS

    //IF THE BROWSER IS CLOSED OR THE APP IS NOT IN FOCUS,
    //THE FIREBASE SERVICE WORKER WILL HANDLE THE MESSAGE:
    //firebase-messaging-sw.js
    //THERE ARE TWO TYPES OF PUSH ALERTS WE CAN USE
    //1. DATA - LEGACY BROWSER PUSH ALERTS
    //2. NOTIFICATION - FIREBASE CLOUD MESSAGING SDK
    //WE ARE USING NOTIFICATION MESSAGES FROM FIREBASE
    //!!KEEP IN MIND THAT THIS IN-APP LISTENER ONLY WORKS WHEN A DATA OBJECT
    //IS PASSED IN THE NOTIFATION PAYLOAD (IN FUNCTIONS)
    //SEE THE SERVICE WORKER FILE FOR MORE DOCUMENTATION
    if (firebase.pushSupported) {
      const unsubscribe = firebase.messaging.onMessage((payload) => {
        console.log("PUSH notification received! ", payload);
        setToastActive({
          message: payload.data.body,
          className: "dBthemeToast",
          duration: 10000,
          clickAction: payload.data.clickAction,
        });
      });
      return unsubscribe;
    }
  });

  useEffect(() => {
    if (firebase.pushSupported) {
      //console.log("main.js UseEffect(). Listening for token updates");
      const unsubscribe = firebase.messaging.onTokenRefresh(() => {
        console.log("Token listener indicates update. Getting new token...");
        changeNotifyPrefs({
          element: "push",
          newValue: true,
          pushTokens: accountInfo.pushTokens,
          smsPref: accountInfo.notifySms,
          pushPref: accountInfo.notifyPush,
          toast: setToastActive,
          t,
          showSpinner,
          firebase,
          authUser: user,
        });
        return unsubscribe;
      });
    }
  });
  const getHeightDiff = () => {
    const height = window.innerHeight;
    return window.screen.height - height;
  };
  // const getOrientation = () => {
  //   const height = window.innerHeight;
  //   const width = window.innerWidth;
  //   return height > width
  //     ? "portrait"
  //     : height === width
  //     ? "square"
  //     : "landscape";
  // };
  // const [orientation, setOrientation] = useState(getOrientation());
  useEffect(() => {
    const handleResize = () => {
      console.log("MAIN UseEffect(). RESIZE DETECTED.");
      // setOrientation(getOrientation());
      setdBNavMenu(false); //CLOSE DRAWER IN STORE / DASHBOARD
      setdBNavMenuFull(false); //CLOSE DRAWER FOR WIDE SCREEN IN STORE / DASHBOARD
      if (inputFocus.heightDiff === getHeightDiff()) {
        //IF THE HEIGHT DIFFERENCE BETWEEN THE CONTENTS AND SCREEN SIZE IS NOT
        //DIFFERENT THAT WHEN WE INALLY MEASURED IT (APP LOAD), THEN WE
        //KNOW THE SOFT KEYBOARD IS DOWN AND WE CAN TURN OFF PIXEL HEIGHT
        // console.log("main.js UseEffect(). Resetting height to non-fixed.");
        if (appContainer.current) {
          appContainer.current.style.minHeight = "";
        }
        if (animContainer.current) {
          animContainer.current.style.minHeight = "";
        }
        //DO WE NEED TO RESET OUR CONSTANT? I BELIEVE NOT, THE SCREEN SIZE
        //SHOULD NOT BE CHANGING (USE CASE IS VERY MINIMAL)
        //setInputFocus({ focus: false, heightDiff: heightDiff });
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    const routingListener = history.listen((location, action) => {
      // console.log("main.js UseEffect(). ROUTINGLISTENER", location, action);
      if (action === "POP") {
        location.state = "";
        if (dBnavMenu) {
          //console.log("main.js UseEffect(). ROUTINGLISTENER dBnavMenu.");
          setdBNavMenu(false);
        }
      }
    });
    return routingListener;
  });

  /**
   * User Data
   */
  useEffect(
    () => {
      if (!user) {
        setAccountInfo(defaultAccountInfo);
        return;
      }
      const hideSpinner = showSpinner();
      const unsubscribe = firebase.firestore
        .collection("stores")
        .doc("store")
        .collection("users")
        .doc(user.uid)
        .onSnapshot(
          async (userDataDoc) => {
            console.log("Received user info update.");
            hideSpinner();
            if (userDataDoc.exists) {
              // console.log("User data exists in db.");
              //WE NEED TO STORE THESE IN SEPERATE VARIABLES FROM
              //ACCOUNTINFO AS WE NEED THEM TO BE AVAILABLE IMMEDIATELY
              //FOR CORRECTING PUSH FURTHER DOWN
              //AND SETTING ACCOUNTINFO BELOW HAS A DELAY
              //notificationsPush = doc.data().notifications.push;
              //notificationsPushTokens = doc.data().notifications.pushTokens;
              const {
                contactInfo,
                notifications,
                favorites,
              } = userDataDoc.data() as UserData;
              //console.log("Setting state account info.");
              setAccountInfo({
                name: contactInfo.name,
                email: user.email || "",
                phone: contactInfo.phone,
                street: contactInfo.street,
                city: contactInfo.city,
                zip: contactInfo.zip,
                suite: contactInfo.suite,
                state: contactInfo.state,
                country: contactInfo.country,
                notifyEmail:
                  notifications.email !== null ? notifications.email : true,
                notifySms:
                  notifications.sms !== null ? notifications.sms : false,
                notifyPush:
                  notifications.push !== null ? notifications.push : false,
                pushTokens: Array.isArray(notifications.pushTokens)
                  ? notifications.pushTokens
                  : [],
                favorites,
              });

              // - PUSH TOKENS ARE CREATED WHEN THE USER SETS PREFERENCE IN ACCOUNT
              //   SETTINGS TO TRUE
              // - KEEP IN MIND THAT IF A USER HAS TWO BROWSER SESSIONS OPEN AND ONE SESSION
              //   IS IN INCOGNITO, THE SETTING WILL ALWAYS REVERT BACK TO TURNED OFF,
              //   EVEN IN THE NON-INCOGNITO SESSION
              // - WHEN THE USER TURNS OFF PUSH SETTINGS, THE TOKENS ARE DELETED. THIS MEANS
              //   THAT WITH THE WAY THINGS ARE SETUP, WE TIE THE ACCOUNT PUSH SETTINGS TO
              //   ALL DEVICES, AND NOT JUST THE CURRENT DEVICE. MEANING, IF THEY TURN OFF
              //   PUSH ALERTS IN THEIR ACCOUNT, NO DEVICE WILL RECEIVE IT. SOME SITES DO
              //   THIS DIFFERENTLY AND SET ONE TOKEN ONLY. BUT THAT WOULD MEAN THAT IF A
              //   USER WAS VISITING THE APP LAST USING DESKTOP, THEY WILL ONLY GET NOTIFIED
              //   THERE AND NOT IN MOBILE. THE DOWNSIDE IS THAT IF USERS SHARE BROWSERS
              //   THEY WILL BE RECEIVING ALERTS FOR ALL SHARED USERS.
              // - IN MAIN.TSX, WE ALWAYS CHECK FOR PUSHTOKENS AGAIN:
              //   --> IF USER PUSH PREFERENCE EQUALS TRUE, THEN CHECK IF BROWSER ALLOWS
              //       PUSH. IF NOT, ASK USER AGAIN, AND ADD THE NEW TOKEN TO THE
              //       EXISTING TOKENS ARRAY FOR THIS USER
              //   (BELOW IS WHEN WE START DOING THAT)

              //CORRECT PUSH SETTINGS
              //IF THE USER ONCE SET THE PUSH NOTIFICATIONS TO TRUE
              //BUT THE TOKEN GOT LOST DUE TO E.G. BROWSER HISTORY WIPE
              //GET A NEW TOKEN FROM THEM
              if (!isAdmin && !isOwner && !isSuperuser) {
                if (firebase.pushSupported && userDataDoc.exists) {
                  //const { notifications } = userDataDoc.data() as UserData;
                  //DOES THE USER ALLOW PUSH ALERTS?
                  if (notifications.push) {
                    //DO WE HAVE BROWSER PERMISSION?
                    //IF IN THE FOLLOWING LINE PERMISSION EQUALS DENIED,
                    //A POPUP FROM THE BROWSER WILL REQUEST PERMISSION AGAIN
                    const permission = await Notification.requestPermission();
                    changeNotifyPrefs({
                      element: "push",
                      newValue: permission === "granted",
                      smsPref: notifications.sms,
                      pushPref: notifications.push,
                      pushTokens: notifications.pushTokens,
                      toast: setToastActive,
                      t,
                      showSpinner,
                      firebase,
                      authUser: user,
                    });
                  }
                }
              }
            }
            //END CORRECT PUSH SETTINGS
          },
          (error) => {
            console.log("main.js UseEffect(). GETTING USER DATA ERROR", error);
            hideSpinner();
          }
        );

      return unsubscribe;
    },
    [user] //eslint-disable-line react-hooks/exhaustive-deps
  );
  useEffect(
    () => {
      //ADMIN PUSH TOKENS CHECK
      //SEE MORE DOCS ABOUT HOW WE IMPLEMENT PUSH TOKENS ABOVE WHERE
      //WE CHECK TOKENS FOR CUSTOMERS
      //IF ONE OF THE TWO PUSH ALERT SETTINGS IS SET TO TRUE
      //  CHECK IF BROWSER ALLOWS PUSH
      //  NO
      //  --> ASK FOR PERMISSION
      //  --> ADD PUSH TOKEN TO DATABASE
      //  YES
      //  CHECK IF PUSH TOKENS AVAILABLE FOR STORE (IN DATABASE)
      //  NO
      //  --> REQUEST NEW TOKEN
      //  --> ADD TO DATABASE
      if (isAdmin || isOwner || isSuperuser) {
        if (
          store.communication.usePush &&
          (store.communication.pushAlertCstmrMsg ||
            store.communication.pushAlertOrder)
        ) {
          console.log("CHECKING PUSHTOKENS FOR ADMIN");
          if (firebase.pushSupported) {
            Notification.requestPermission().then(async (permission) => {
              if (permission === "granted") {
                try {
                  const pushToken = await firebase.messaging.getToken();
                  //ONLY STORE A NEW ONE IF ITS NOT THERE ALREADY
                  if (!store.communication.pushTokens.includes(pushToken)) {
                    console.log("ADDING PUSHTOKEN TO DATABASE");
                    const tokens = [...store.communication.pushTokens];
                    tokens.push(pushToken);
                    const update: firebase.firestore.UpdateData = {};
                    update[`communication.pushTokens`] = tokens;
                    firebase.firestore
                      .collection("stores")
                      .doc("store")
                      .collection("locations")
                      .doc(store.locationId)
                      .update(update);
                  } else {
                    console.log("NO NEED TO ADD TOKEN TO DATABASE.");
                  }
                } catch (error) {
                  console.log("changeNotifyPrefs.js ERROR", error);
                  setToastActive({
                    message: t("dashboard.toast.unableTokens"),
                    className: "sTthemeAlert1b",
                    duration: TOASTDURATION_ERROR,
                  });
                }
              } else {
                setToastActive({
                  message: t("toast.pushNotificationDenied"),
                  className: "sTthemeAlert1b",
                  duration: TOASTDURATION_ERROR,
                });
              }
            });
          }
        }
      }
    },
    [isAdmin, isOwner, isSuperuser] //eslint-disable-line react-hooks/exhaustive-deps
  );

  const shared = useMemo(
    () => ({
      lockDimensions,
      setToastActive,
      showSpinner,
      accountInfo,
    }),
    [accountInfo, lockDimensions, showSpinner]
  );
  const background = location.state && location.state.background;
  let pathname = (background || location).pathname;

  const isRoute = (route: string) => {
    return pathname === route || pathname.startsWith(route + "/");
  };
  const [imageInfo, setImageInfo] = useState<{
    loaded: boolean;
    error: boolean;
  }>({ loaded: false, error: false });

  return (
    <MainContext.Provider value={shared}>
      <EditingProvider>
        <ModalManager>
          <div
            ref={appContainer}
            className="App AppFontSizeNormal"
            style={{
              fontFamily:
                theme.appFont || "Arial, Verdana, Geneva, Tahoma, sans-serif",
            }}
          >
            <div id="modal-root" /> {/* REACT PORTAL */}
            <div
              style={{
                position: "fixed",
                zIndex: -100,
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <img
                src={`${theme.backgroundImage}`}
                srcSet={srcSetString(theme.backgroundImageUrls)}
                sizes="100vw"
                alt={store._name}
                onLoad={() => {
                  setImageInfo({ loaded: true, error: false });
                }}
                onError={() => {
                  setImageInfo({ loaded: true, error: true });
                }}
                style={{
                  objectFit: "cover",
                  objectPosition: theme.backgroundImageZoom
                    ? `${theme.backgroundImageZoom.x}% center`
                    : "center",
                  verticalAlign: "middle",
                  minHeight: "100%",
                  minWidth: "100%",
                  width: "100%",
                  opacity: imageInfo.loaded ? 1 : 0,
                  transition: "all 0.3s linear 0s",
                }}
              />
            </div>
            <div
              style={{
                position: "fixed",
                height: "100%",
                width: "100%",
              }}
              className={
                isRoute(ROUTES.DASHBOARD) ||
                isRoute(ROUTES.ORDER) ||
                isRoute(ROUTES.FAVORITES)
                  ? "appVeil appVeilTheme-11"
                  : undefined
              }
            ></div>
            <Switch location={background || location}>
              <Route
                path={`${ROUTES.DASHBOARD}/:subpage?`}
                render={() => (
                  <Suspense
                    fallback={<Spinner isOpen dBnavMenuFull={dBnavMenuFull} />}
                  >
                    <Dashboard
                      dBnavMenu={dBnavMenu}
                      setdBNavMenu={setdBNavMenu}
                      dBnavMenuFull={dBnavMenuFull}
                      setdBNavMenuFull={setdBNavMenuFull}
                      animContainer={animContainer}
                    />
                  </Suspense>
                )}
              />
              <Route
                path={ROUTES.SUPER_USER}
                isSuperuser={isSuperuser}
                component={Superuser}
              />
              <Route
                path={`${ROUTES.STORE}:subpage?/:index?`}
                render={(props) => (
                  <Store
                    dBnavMenu={dBnavMenu}
                    setdBNavMenu={setdBNavMenu}
                    dBnavMenuFull={dBnavMenuFull}
                    setdBNavMenuFull={setdBNavMenuFull}
                    animContainer={animContainer}
                    {...props}
                  />
                )}
              />
            </Switch>
            <Spinner
              isOpen={spinnerArray.length > 0}
              dBnavMenuFull={dBnavMenuFull}
              // If there are multiple spinners, use the props from the most recent one
              {...spinnerArray[spinnerArray.length - 1]}
              // Except for the lag; for the lag, use the shortest delay.
              lag={
                spinnerArray.find((props) => props?.lag === "none")
                  ? "none"
                  : spinnerArray.find((props) => props?.lag === "short")
                  ? "short"
                  : "long"
              }
            />
            {toastActive && <Toast {...toastActive} onHide={hideToast} />}
          </div>
        </ModalManager>
      </EditingProvider>
    </MainContext.Provider>
  );
});

export default Main;
