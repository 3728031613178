import { useContext } from "react";
import { MainContext } from "./mainContext";

/**
 * Shows a global loading spinner for the app.
 *
 * @param props Props to pass to the <Spinner> component. If there are
 * multiple spinners, the props from the most recent one are used
 *
 * ```typescript
 * const showSpinner = useSpinner();
 *
 * const hideSpinner = showSpinner({ lag: "none" });
 * setTimeout(() => hideSpinner(), 5000);
 * ```
 */
const useSpinner = () => useContext(MainContext).showSpinner;

export default useSpinner;
