import React, { FC, useState } from "react";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import useToast from "../../Main/useToast";
import ModalVeil from "../../Modal/modalVeil";
import { useTranslation } from "react-i18next";
import { ModalProps } from "../../Modal/modalManager";
import { LocationAddress } from "../../../database/storeLocation";
import AddressForm from "../Checkout/addressForm";
import { notUndefined } from "../../../utilities/notNull";
import { DeliveryOption } from "../Pages/checkout";
import StoreSelect from "../Components/sTselect";
import { hexToRgbString } from "../../../utilities/colorUtils";
interface STdeliveryInfoModalProps extends ModalProps {
  initialAddressInfo: LocationAddress;
  initialDeliveryOption: DeliveryOption;
  onSubmit: (address: LocationAddress, deliveryOption: DeliveryOption) => void;
}

const STdeliveryModal: FC<STdeliveryInfoModalProps> = React.memo(
  ({ initialAddressInfo, initialDeliveryOption, onSubmit, closeModal }) => {
    const toast = useToast();
    const store = useStore();
    const { t } = useTranslation();
    const { theme, address: storeAddress, delivery } = store;
    const { mainColor, color, mainColorDark } = theme;

    const [deliveryOption, setDeliveryOption] = useState(initialDeliveryOption);
    const [customerAddress, setCustomerAddress] = useState<LocationAddress>(
      initialAddressInfo
    );

    const valid =
      customerAddress.streetLine1.trim().length >= 3 &&
      customerAddress.city.trim().length >= 2;

    const handleSubmit = (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (valid) {
        onSubmit(customerAddress, deliveryOption);
      }
      closeModal();
    };
    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            color: color,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader"
            style={{
              backgroundColor: mainColorDark,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  color: color,
                }}
              >
                <Icon name="help" fill={color} />
                <div>
                  &nbsp;{t("store.checkout.pickupOrDeliveryTitle")} &nbsp;
                </div>
              </div>
            </div>

            <div></div>
          </div>
          <div className="sTmodalContent">
            <StoreSelect
              className="sTthemeBGcolorSoft"
              style={{
                margin: "1em 0",
                position: "relative",
                transition: "all 0.3s linear 0s",
              }}
              text={
                deliveryOption === "DELIVERY"
                  ? t("common.delivery_lower")
                  : t("common.pickUp_lower")
              }
              icon={deliveryOption === "PICKUP" ? "store" : "in_transit"}
              iconColor="black"
              expandIcon={true}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const deliveryEnabled =
                  typeof delivery.delivery === "boolean"
                    ? delivery.delivery
                    : delivery.delivery.enabled;
                if (!deliveryEnabled && e.target.value === "DELIVERY") {
                  toast({
                    message: t("store.toast.pickUp"),
                    color: color,
                    backgroundColor: mainColor,
                  });
                } else {
                  setDeliveryOption(e.target.value as DeliveryOption);
                }
              }}
              selectStyle={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "1.8em",
                opacity: 0,
              }}
            >
              <option value="PICKUP">{t("common.pickUp_lower")}</option>
              <option value="DELIVERY">{t("common.delivery_lower")}</option>
            </StoreSelect>
            <div
              style={{
                margin: "0 0 0.5em 0",
              }}
            >
              {deliveryOption === "DELIVERY" ? (
                <AddressForm
                  address={customerAddress}
                  onAddressChanged={setCustomerAddress}
                />
              ) : (
                <div style={{ color: "black", padding: "0.25em" }}>
                  <div>{t("store.checkout.ourAddress")}</div>
                  <br />
                  <div>{storeAddress.streetLine1}</div>
                  {storeAddress.streetLine2 && (
                    <div>{storeAddress.streetLine2}</div>
                  )}
                  {storeAddress.streetLine3 && (
                    <div>{storeAddress.streetLine3}</div>
                  )}
                  <div>{storeAddress.city}</div>
                  {storeAddress.stateCode && (
                    <div>{t(`common.state.${storeAddress.stateCode}`)}</div>
                  )}
                  <div>{t(`common.country.${storeAddress.countryCode}`)}</div>
                </div>
              )}
            </div>
          </div>
          <div
            className="sTmodalFooter sTthemeBGcolor"
            style={{
              backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
            }}
          >
            <div
              onClick={() =>
                window.open(
                  deliveryOption === "DELIVERY"
                    ? "https://www.google.com/maps/search/?api=1&query=" +
                        encodeURI(
                          [
                            customerAddress.streetLine1,
                            customerAddress.streetLine2,
                            customerAddress.streetLine3,
                            customerAddress.city,
                            customerAddress.stateCode,
                            customerAddress.zip,
                            customerAddress.countryCode,
                          ]
                            .filter(notUndefined)
                            .join(",")
                        )
                    : `https://www.google.com/maps/search/?api=1&query=${store.address.googleMaps}`
                )
              }
              className="AppFontSizeSmall sTmodalFooterItem"
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              {t("store.checkout.avoidDelays")}
              <Icon name="directions" fill="white" />
              &nbsp;{t("store.checkout.verifyAddress")}
            </div>
            <div
              style={{
                fontWeight: "bold",
                transition: "all 0.5s ease 0s",
                color: color,
              }}
              onClick={(e) => handleSubmit(e)}
              className="sTmodalFooterItem AppFontSizeSmall"
            >
              {deliveryOption === "DELIVERY"
                ? valid
                  ? t("common.ok")
                  : t("common.cancel")
                : t("common.ok")}
            </div>
          </div>
        </div>
        <ModalVeil onClick={closeModal} />
      </React.Fragment>
    );
  }
);

export default STdeliveryModal;
