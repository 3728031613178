import React, { useState, useMemo, FC, useEffect, useRef } from "react";
import "../css/order.css";
import useStore from "../../../customization/useStore";
import CategoryWithImage from "../Templates/catImgPortrait";
import CategoryWithoutImage from "../Templates/catNoImgPortrait";
import ItemWithButtons from "../Templates/itemOrderBtns";
import ItemWithoutButtons from "../Templates/itemNoOrderBtns";
import useCart from "../../CartManager/useCart";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import * as ROUTES from "../../../utilities/constants/routes";
import useAccountInfo from "../../Main/useAccountInfo";
import { CategoryProps } from "../Templates/catImgPortrait";
import Product from "../Pages/product";
import { useQuery } from "../../../utilities/useQuery";
import usePrevious from "../../../utilities/usePrevious";

interface OrderProps {}

const Order: FC<OrderProps> = React.memo(() => {
  const accountInfo = useAccountInfo();
  const { isAdmin, isOwner, isSuperuser } = useAuthUser();
  const history = useHistory();
  const store = useStore();
  const { t } = useTranslation();
  const { catalog, theme } = store;
  const { categories } = catalog;
  const { suborders, activeSuborderId } = useCart();
  const suborder = suborders[activeSuborderId];

  const query = useQuery();
  const [catIndex, setCatIndex] = useState(() => {
    const idFromUrl = query.get("categoryId");
    let indexFromUrl: number | undefined;
    if (idFromUrl) {
      indexFromUrl = categories.findIndex(
        (category) => category.categoryId === idFromUrl
      );
    }
    if (indexFromUrl !== undefined) {
      return indexFromUrl;
    }
    return 0;
  });
  const itemIdFromUrl = query.get("itemId");

  // If deep linking to a specific item, scroll to it
  const itemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const id = setTimeout(() => {
      itemRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }, 750);
    return () => clearTimeout(id);
  }, []);

  let CategoryComponent: FC<CategoryProps>;
  if (
    theme.template.category === "noImgM_imgL" ||
    theme.template.category === "noImgM_noImgL"
  ) {
    CategoryComponent = CategoryWithoutImage;
  } else {
    CategoryComponent = CategoryWithImage;
  }
  let ItemComponent: typeof ItemWithButtons;
  if (
    theme.template.item === "noImgNoOrder" ||
    theme.template.item === "imgNoOrder"
  ) {
    ItemComponent = ItemWithoutButtons;
  } else {
    ItemComponent = ItemWithButtons;
  }
  const filteredCategories = useMemo(() => {
    return (catalog.categories || []).filter((category) => !category.hidden);
  }, [catalog.categories]);

  const location = useLocation();
  const productMatch = matchPath<{ itemId: string }>(location.pathname, {
    path: `${ROUTES.ORDER_PRODUCT}/:itemId?`,
  });

  const itemScrollRef = useRef<HTMLDivElement>(null);
  const prevCatIndex = usePrevious(catIndex);
  useEffect(() => {
    if (prevCatIndex !== undefined && catIndex !== prevCatIndex) {
      itemScrollRef.current?.scrollTo(0, 0);
    }
  });

  return (
    <React.Fragment>
      <Product itemId={productMatch?.params.itemId ?? null} />
      <div
        className="sTorderOuterWrap sTthemeBGcolorSoft"
        style={{
          transition: "transform 0.5s ease",
          transform: `translateX(${productMatch ? -100 : 0}%)`,
        }}
      >
        <div className="sTorderHeader"></div>
        {categories.length === 0 && (isAdmin || isOwner || isSuperuser) ? (
          <div onClick={() => history.push(ROUTES.DASHBOARD_CATALOG)}>
            {t("store.orders.visitDashboard")}
          </div>
        ) : categories.length === 0 ? (
          <div>{t("store.orders.comingSoon")}</div>
        ) : (
          <div className="sTorderContentsWrap">
            <div className="sTorderContents1">
              <div className="sTorderContents1b">
                {filteredCategories.map((category, index) => {
                  return (
                    <CategoryComponent
                      key={index}
                      index={index}
                      catIndex={catIndex}
                      setCatIndex={setCatIndex}
                      category={category}
                    />
                  );
                })}
              </div>
            </div>

            <div className="sTorderContents2" ref={itemScrollRef}>
              {categories[catIndex].items.map((item, index) => (
                <ItemComponent
                  key={categories[catIndex].categoryId + "-" + item.itemId}
                  ref={item.itemId === itemIdFromUrl ? itemRef : undefined}
                  categoryName={categories[catIndex].name}
                  itemId={item.itemId}
                  suborder={suborder}
                  accountInfo={accountInfo}
                  index={index}
                  source="order"
                />
              ))}
            </div>
          </div>
        )}
        <div className="sTorderFooterWrap"></div>
      </div>
    </React.Fragment>
  );
});

export default Order;
