import { injectStripe, ReactStripeElements } from "react-stripe-elements";
import React, { FC } from "react";

interface StripeInjectorProps<T extends object> {
  children: (
    combinedProps: T & ReactStripeElements.InjectedStripeProps
  ) => React.ReactElement;
}

const StripeInjector: FC<StripeInjectorProps<any>> = (props: any) => {
  const { children, ...rest } = props;
  return children(rest);
};

export default injectStripe(StripeInjector);
