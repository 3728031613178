type Attributes = {
  [key in keyof HTMLScriptElement]?: HTMLScriptElement[key];
};

// Appends a new script tag to the document, and waits for it to load (or error).
export default (src: string, attributes: Attributes = {}) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    Object.entries(attributes).forEach(([key, value]) => {
      script.setAttribute(key, value);
    });
    // default async to true if not set with custom attributes
    if (!script.hasAttribute("async")) {
      script.async = true;
    }
    script.src = src;
    script.addEventListener("load", resolve);
    script.addEventListener("error", reject);
    document.body.appendChild(script);
  });
};
