import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { PageConfig, StoreLocation } from "../../../database/storeLocation";
import Icon from "../../Icon/icon";
import { useSettingsApi } from "./settingsApiContext";
import { DASHBOARD_SETTINGS } from "../../../utilities/constants/routes";
import { useHistory, Switch, Route, useRouteMatch } from "react-router-dom";
import { TFunction } from "i18next";
import ExtraPage from "../Settings/extraPage";
import DashboardButton from "../Components/dbButton";

interface ExtraPagesProps {}

/**
 * For backwards compatability, if extraPages does not exist we fill it
 * out with an about us page.
 */
export const createDefaultExtraPages = (
  location: StoreLocation,
  t: TFunction
) => {
  const defaultState: StoreLocation["extraPageOptions"] = {
    pages: [
      {
        label: t("dashboard.settings.extraPages.aboutUsTitle"),
        path: "/about",
        icon: "store",
        template: {
          type: "richText",
          content:
            location.aboutUs ??
            t("dashboard.settings.extraPages.aboutUsContent"),
        },
      },
    ],
  };
  return defaultState;
};

const ExtraPages: FC<ExtraPagesProps> = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const settingsApi = useSettingsApi();

  /**
   * Swaps two elements in an array. This will mutate the array.
   */
  function swap<T extends any[]>(array: T, a: number, b: number): T {
    [array[a], array[b]] = [array[b], array[a]];
    return array;
  }
  const extraPageOptions =
    settingsApi.location.extraPageOptions ??
    createDefaultExtraPages(settingsApi.location, t);

  return (
    <React.Fragment>
      {match.isExact ? (
        <div style={{ gridRowGap: "0.5em" }}>
          <DashboardButton
            text={t("dashboard.settings.extraPages.newPageButton")}
            theme="white"
            iconColor="black"
            icon="add_sq"
            onClick={() => {
              const config: PageConfig = {
                label: "unnamed",
                path: "unnamed",
                icon: "information",
                template: {
                  type: "richText",
                  content: t(
                    "dashboard.settings.extraPages.contentPlaceholder"
                  ),
                },
              };
              settingsApi.setLocation({
                ...settingsApi.location,
                extraPageOptions: {
                  ...extraPageOptions,
                  pages: [...extraPageOptions.pages, config],
                },
              });
              history.push(
                `${DASHBOARD_SETTINGS}/theme/extraPages/${extraPageOptions.pages.length}`
              );
            }}
          />
          <div style={{ height: "1em" }}>&nbsp;</div>
          {extraPageOptions.pages.map((pageConfig, i) => (
            <React.Fragment key={i}>
              <div className="dBsettingsCard boxShadow2">
                <div
                  className="dBthemeBGcolor2"
                  style={{
                    padding: "0.5em",
                    textAlign: "left",
                    marginBottom: "0.25em",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Icon name={pageConfig.icon} fill="white" />
                  <div>&nbsp;{pageConfig.label}</div>
                </div>
                <div
                  style={{ marginBottom: "0.5em", border: "none" }}
                  className="dBsettingsInputFieldButton dBthemeBGcolor3"
                >
                  <DashboardButton
                    text={t("common.edit")}
                    theme="white"
                    iconColor="black"
                    icon="edit"
                    onClick={() => {
                      history.push(
                        `${DASHBOARD_SETTINGS}/theme/extraPages/${i}`
                      );
                    }}
                  />
                  {extraPageOptions.pages.length > 1 && (
                    <React.Fragment>
                      <div
                        className="dBsettingsItemActionWrap2 AppFontSizeSmall"
                        onClick={(event) => {
                          event.stopPropagation();
                          if (i === extraPageOptions.pages.length - 1) {
                            return;
                          }
                          const newPages = [...extraPageOptions.pages];
                          swap(newPages, i, i + 1);
                          settingsApi.setLocation({
                            ...settingsApi.location,
                            extraPageOptions: {
                              ...extraPageOptions,
                              pages: newPages,
                            },
                          });
                          return;
                        }}
                      >
                        <Icon name="arrow_down" fill="black" />
                        {t("dashboard.catalog.moveDownButton")}
                      </div>
                      <div
                        className="dBsettingsItemActionWrap2 AppFontSizeSmall"
                        onClick={(event) => {
                          event.stopPropagation();
                          if (i === 0) {
                            return;
                          }
                          const newPages = [...extraPageOptions.pages];
                          swap(newPages, i, i - 1);
                          settingsApi.setLocation({
                            ...settingsApi.location,
                            extraPageOptions: {
                              ...extraPageOptions,
                              pages: newPages,
                            },
                          });
                        }}
                      >
                        <Icon name="arrow_up" fill="black" />
                        {t("dashboard.catalog.moveUpButton")}
                      </div>
                    </React.Fragment>
                  )}
                  <DashboardButton
                    text={t("common.remove")}
                    theme="white"
                    iconColor="black"
                    icon="delete_forever"
                    onClick={(event) => {
                      event.stopPropagation();
                      const newPages = [...extraPageOptions.pages];
                      newPages.splice(i, 1);
                      settingsApi.setLocation({
                        ...settingsApi.location,
                        extraPageOptions: {
                          ...extraPageOptions,
                          pages: newPages,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div className="dBsettings_anim_moveLeft_0300">
          <Switch>
            <Route
              path={`${DASHBOARD_SETTINGS}/theme/extraPages/:index`}
              render={() => <ExtraPage />}
            />
          </Switch>
        </div>
      )}
    </React.Fragment>
  );
};

export default ExtraPages;
