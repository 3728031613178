/// <reference lib="dom" />
// The firebase functions pull in these type definitions. And since they
//   run in node, they are not aware of the dom references that firebase
//   makes. So the above reference allows functions to compile
import { CurrencyOptions, paymentGateway } from "./storeLocation";
import { DatabaseSuborderCollection } from "./cart";

export interface OrderCollection {
  [id: string]: Order;
}

/**
 * Data about an order.
 */
export interface Order {
  orderId: string;
  currency: CurrencyOptions;
  customerAddress: string;
  customerEmail: string;
  customerPhone: string;
  delivery: OrderDeliveryOptions;
  invoiceCode: string;
  locationId: string;
  suborders: DatabaseSuborderCollection;
  paymentChargeToken: string;
  paymentGateway: paymentGateway;
  paymentToken: string;
  paymentMethod: "bancontact" | "credit_stripe" | "credit_xendit";
  paymentCustomer: string;
  paymentRechargeStatus?: paymentRechargeStatus;
  status: orderStatus;
  paymentAuthSent?: paymentAuthSent;
  statusCompleted: boolean;
  tax: number;
  taxTable: Record<string, number>;
  total: number;
  userId: string;
  messageThreadId?: string | null;
  pushTokens?: string[];
  totalAdjustmentReason?: string;
  totalAdjustmentAmount?: number;
  //HOLDS THE LAST ADJUSTMENT WHEN THE ORDER WAS FINALIZED
  //WE NEED IT FOR WHEN AN ORDER UPCHARGE GETS REVERSED BY A CUSTOMER
  totalAdjustmentLast?: number;
}

// Alias, in case we eventually need a distinction between
// client side orders and database side orders.
export type DatabaseOrder = Order;

export type paymentAuthSent = firebase.firestore.Timestamp;

export enum paymentRechargeStatus {
  //THE REFUND/UPCHARGE HAS BEEN FINALIZED
  complete = "complete",
  //THE REFUND/UPCHARGE HAS NOT BEEN FINALIZED
  incomplete = "incomplete",
  //THE ADMIN TRIED CHARGING THE CREDIT CARD BUT REAUTH IS NEEDED
  authrequired = "authrequired",
  //THE ADMIN SENT THE REAUTH LINK TO THE CUSTOMER
  pendingauth = "pendingauth",
  //THE CUSTOMER DECLINED THE EXTRA CHARGE
  //HANDLED THE SAME AS COMPLETE BUT INDICATED TO THE ADMIN WHAT HAPPENED
  complete_declined = "complete_declined"
}
export enum orderStatus {
  inprogress = "inprogress",
  inqueue = "inqueue",
  intransit = "intransit",
  ready = "ready",
  delivered = "delivered",
  pending = "pending",
  failed = "failed"
}

export interface OrderDeliveryOptions {
  delivery: boolean;
  timeCompleted: firebase.firestore.Timestamp | null;
  timeCreated: firebase.firestore.Timestamp;
  timeCreated_locale: string;
  timeHandedoff: firebase.firestore.Timestamp | null;
  timePlanned: firebase.firestore.Timestamp;
  timePlannedOrig: firebase.firestore.Timestamp;
  timeRequested: "ASAP" | firebase.firestore.Timestamp;
  // (string & {}) is a little trick to let 'ASAP' be shown in
  //   intellisense, even though any string is allowed.
  timeRequested_locale: "ASAP" | (string & {});
}
