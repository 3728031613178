import * as APPCONSTANTS from "./constants/appConstants";
import Firebase from "../Firebase";
import { TFunction } from "i18next";
import { ShowSpinner } from "../components/Main/mainContext";
import { ToastProps } from "../components/Toast/toast";

interface Options {
  firebase: Firebase;
  showSpinner: ShowSpinner;
  smsPref: boolean;
  pushPref: boolean;
  pushTokens: string[];
  element: string;
  newValue: boolean;
  toast: (props: ToastProps) => void;
  t: TFunction;
  authUser: firebase.User | null;
}
const changeNotifyPrefs = async ({
  smsPref,
  pushPref,
  pushTokens,
  element,
  newValue,
  showSpinner,
  firebase,
  toast,
  t,
  authUser,
}: Options) => {
  const firebaseUpdate = (newPushToken: string, action: string) => {
    let tokens = [...pushTokens];
    if (action === "add") {
      tokens.push(newPushToken);
    } else if (action === "delete") {
      tokens = [];
    }
    if (authUser !== null)
      return firebase.firestore
        .collection("stores")
        .doc("store")
        .collection("users")
        .doc(authUser.uid)
        .update({
          notifications: {
            email: true,
            sms: element === "sms" ? newValue : smsPref,
            push: element === "push" ? newValue : pushPref,
            pushTokens: tokens,
          },
        });
  };
  if (element === "sms") {
    const hideSpinner = showSpinner();
    try {
      await firebaseUpdate("", "sms");
    } catch (error) {
      console.log("changeNotifyPrefs.js ERROR", error);
      toast({
        message: t("toast.systemError"),
        className: "sTthemeAlert1b",
        duration: APPCONSTANTS.TOASTDURATION_ERROR,
      });
    } finally {
      hideSpinner();
    }
  } else {
    //PUSH
    if (newValue === true) {
      if (firebase.pushSupported) {
        // showSpinner(true, "push stuff start");
        Notification.requestPermission().then(async (permission) => {
          if (permission === "granted") {
            try {
              const pushToken = await firebase.messaging.getToken();
              // console.log("changeNotifications.js", pushToken);
              //ONLY STORE A NEW ONE IF ITS NOT THERE ALREADY
              if (!pushTokens.includes(pushToken)) {
                console.log(
                  "changeNotifyPrefs.js. Adding push token to database."
                );
                await firebaseUpdate(pushToken, "add");
              } else {
                console.log(
                  "changeNotifyPrefs.js. No need to add push token to database."
                );
              }
              // showSpinner(false, "change account notification push pref stop");
            } catch (error) {
              console.log("changeNotifyPrefs.js ERROR", error);
              // showSpinner(false, "change account notification push pref error");
              toast({
                message: t("toast.systemError"),
                className: "sTthemeAlert1b",
                duration: APPCONSTANTS.TOASTDURATION_ERROR,
              });
            }
          } else {
            // showSpinner(false, "change account notification push denied.");
            toast({
              message: t("toast.pushNotificationDenied"),
              className: "sTthemeAlert1b",
              duration: APPCONSTANTS.TOASTDURATION_ERROR,
            });
          }
        });
      } else {
        toast({
          message: t("toast.notSupportIncognito"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
      }
    } else {
      //DELETE PUSHTOKENS
      const hideSpinner = showSpinner();
      try {
        await firebaseUpdate("", "delete");
      } catch (error) {
        console.log("account.js", error);
        toast({
          message: t("toast.systemError"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
      } finally {
        hideSpinner();
      }
    }
  }
};

export default changeNotifyPrefs;
