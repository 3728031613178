// These option types should all go in the customOption file, not here.
export enum optionType {
  NONE_YET = "none",
  LIST = "list",
  UNIT = "unit",
  NUMBER = "number"
}

export interface OptionCollection {
  [optionId: string]: Option | undefined;
}

// Deliberately not allowing IncompleteOption. IncompleteOption should only
//   exist in memory while creating the option, never in the database.
export type Option = ListOption | NumberOption | UnitOption;

interface BaseOption {
  name: string;
  type: optionType;
  optionId: string;
  required: boolean;
}

export enum listSelectionType {
  SINGLE = "single",
  MULTIPLE = "multiple"
}
export enum listOptionPriceMode {
  NONE = "none",
  ADD = "add"
}
export interface IncompleteOption extends BaseOption {
  type: optionType.NONE_YET;
}

export enum numberOptionPriceMode {
  ADD = "add"
}

export interface NumberOption extends BaseOption {
  type: optionType.NUMBER;
  price: number;
  priceString: string;
  priceDenominator: number;
  unit?: string;
  defaultValue: number;
  minValue: number;
  maxValue: number;
  increment: number;
}

export const isNumberOption = (
  option?: Option | IncompleteOption
): option is NumberOption => {
  return !!(option?.type === optionType.NUMBER);
};

export interface UnitOption extends BaseOption {
  type: optionType.UNIT;
  priceDenominator: number;
  defaultValue: number;
  minValue: number;
  maxValue: number;
  increment: number;
}

export const isUnitOption = (
  option?: Option | IncompleteOption
): option is UnitOption => {
  return !!(option?.type === optionType.UNIT);
};

export interface ListOption extends BaseOption {
  type: optionType.LIST;
  priceMode: listOptionPriceMode;
  selectionType: listSelectionType;
  items: ListOptionItem[];
}

export const isListOption = (
  option?: Option | IncompleteOption
): option is ListOption => {
  return !!(option?.type === optionType.LIST);
};

export interface ListOptionItem {
  description: string;
  price: number;
  priceString: string;
  selectedByDefault?: boolean;
  id: string;
}
