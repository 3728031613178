import STxenditIframeModal from "../Modals/sTxenditIframe";
import { ShowSpinner } from "../../Main/mainContext";
import { ShowModal, CloseModal } from "../../Modal/modalManager";
import { MutableRefObject } from "react";
import * as ROUTES from "../../../utilities/constants/routes";

export const createXenditAuthentication = (
  card: Xendit.Card,
  tokenId: string,
  amount: number,
  showSpinner: ShowSpinner,
  showModal: ShowModal,
  closeModalRef?: MutableRefObject<CloseModal | undefined>
) => {
  return new Promise<Xendit.AuthenticationResponse>((resolve, reject) => {
    let inReview = false;
    let hideSpinner: (() => void) | undefined;
    card.createAuthentication(
      {
        amount: "" + amount,
        token_id: tokenId,
      },
      (error, auth) => {
        if (inReview) {
          hideSpinner = showSpinner();
          inReview = false;
        }
        if (error) {
          reject(error);
        } else if (auth.status === "FAILED") {
          reject(new Error("Authentication failed."));
        } else if (auth.status === "IN_REVIEW") {
          inReview = true;
          if (hideSpinner) {
            hideSpinner();
          }
          // Can't resolve or reject yet. The callback will be
          //   called again once the modal is done.
          console.log("paymentForm.js GOING TO OPEN IFRAME 2");
          let closeModal = showModal(
            STxenditIframeModal,
            { url: auth.payer_authentication_url },
            `${ROUTES.ML_XENDITIFRAME}`
          );
          if (closeModalRef) {
            closeModalRef.current = closeModal;
          }
        } else if (auth.status === "VERIFIED") {
          resolve(auth);
        }
      }
    );
  });
};
