import React, { FC, useState, useContext, useRef, useEffect } from "react";
import Icon from "../../Icon/icon";
import ModalVeil from "../../Modal/modalVeil";
import { ModalProps } from "../../Modal/modalManager";
import useStore from "../../../customization/useStore";
import { useTranslation } from "react-i18next";
import { MessageThread, Message } from "../../../database/message";
import getOrderIdStrings from "../../../utilities/getOrderIdString";
import {
  useMessageThread,
  useMarkReadMessages,
} from "../../../utilities/useMessages";
import moment from "moment";
import uuid from "uuid/v4";
import { FirestoreUpdate } from "../../../utilities/firestoreUpdateType";
import { TOASTDURATION_ERROR } from "../../../utilities/constants/appConstants";
import { FirebaseContext } from "../../../Firebase";
import useToast from "../../Main/useToast";
import useRefreshInterval from "../../../utilities/useRefreshInterval";
import { useMeasure } from "../../../utilities/useMeasure";
import StoreButton from "../Components/stButton";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import useAccountInfo from "../../Main/useAccountInfo";
import CommSettings from "../Components/commSettings";

interface STMessageThreadModalProps extends ModalProps {
  threadId: string;
  initialThread?: MessageThread;
}

const STMessageThreadModal: FC<STMessageThreadModalProps> = React.memo(
  ({ threadId, initialThread, closeModal }) => {
    const { user } = useAuthUser();
    const { t } = useTranslation();
    const accountInfo = useAccountInfo();
    const store = useStore();
    const firebase = useContext(FirebaseContext);
    const toast = useToast();
    const { theme } = store;
    const { mainColorDark, color } = theme;
    const [showCommSettings, setShowCommSettings] = useState(false);
    // Periodically update so the time labels stay up to date
    useRefreshInterval(10000);
    const { thread, messages } = useMessageThread(threadId, initialThread);
    //UPDATE THREAD AS READ SINCE THEY OPENED IT
    useMarkReadMessages(threadId, messages, "customer");
    const [message, setMessage] = useState("");
    const sendMessage = async () => {
      if (!message) {
        return;
      }
      setMessage("");
      const messageId = uuid();
      const newMessage: FirestoreUpdate<Message> = {
        messageId,
        from: "customer",
        message,
        timeSent: new Date(),
        resolved: false,
        read: false,
      };
      try {
        await firebase.firestore
          .collection("stores")
          .doc("store")
          .collection("messageThreads")
          .doc(threadId)
          .set(
            {
              read: false,
              resolved: false,
              messages: {
                [messageId]: newMessage,
              },
            },
            { merge: true }
          );
      } catch (error) {
        if (error.code === "permission-denied") {
          toast({
            message: t("common.permissionDenied"),
            className: "dBthemeAlert1",
            duration: TOASTDURATION_ERROR,
          });
        } else {
          toast({
            message: t("toast.systemErrorCall"),
            className: "dBthemeAlert1",
            duration: TOASTDURATION_ERROR,
          });
        }
      }
    };
    const orderId = thread?.orderId ?? null;
    const [ref, bounds] = useMeasure<HTMLTextAreaElement>();
    const heightInput = bounds.height;
    const lastDiv = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      setTimeout(() => {
        if (lastDiv && lastDiv.current) lastDiv.current.scrollIntoView();
      }, 0);
    }, [messages.length, heightInput, lastDiv]);
    return (
      <React.Fragment>
        <div
          style={{
            color: color,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader sTmsgModalHeader"
            style={{ backgroundColor: mainColorDark }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0.25em",
              }}
            >
              {!orderId ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0.25em",
                  }}
                >
                  <Icon name="message" fill="#FFF" />
                  &nbsp;
                  {t("store.contactUs.message")}
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <Icon name="assignment" fill="#FFF" />
                  &nbsp; */}
                  <div className="AppFontSizeSmall">
                    <div>
                      <span>{`${t("common.id")}:`}</span>&nbsp;
                      <strong>{`${getOrderIdStrings(orderId).mainId}`}</strong>
                    </div>
                    <div>
                      <span>{`${t("common.subId")}:`}</span>&nbsp;
                      <strong>{`${getOrderIdStrings(orderId).subId}`}</strong>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="AppFontSizeSmall"
                style={{ width: "2em" }}
                onClick={() => {
                  closeModal();
                }}
              >
                <Icon name="close" fill="white" />
              </div>
            </div>
            {user && (
              <StoreButton
                text={t("store.contactUs.commSettings")}
                style={{ color: "white", margin: 0 }}
                theme="white left"
                iconColor="white"
                icon="flag"
                onClick={() => {
                  setShowCommSettings(!showCommSettings);
                }}
              />
            )}
            {showCommSettings && (
              <CommSettings
                storeCommunication={store.communication}
                notifyPush={accountInfo.notifyPush}
                notifySms={accountInfo.notifySms}
                // email={email}
                // phone={phone}
                t={t}
              />
            )}
            {store.address.phone && (
              <div className="AppFontSizeSmall" style={{ padding: "0.25em" }}>
                {t("store.contactUs.ratherCall")}&nbsp;
                <a
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    color: color,
                  }}
                  href={"tel:" + store.address.phone}
                >
                  {store.address.phone}
                </a>
              </div>
            )}
          </div>
          <div className="sTmodalContent" style={{ padding: "0.5em" }}>
            {thread ? (
              <div className="sTmsgMessageContainer">
                {messages.map((message, index) => (
                  <div
                    ref={(element) => {
                      if (index === messages.length - 1) {
                        lastDiv.current = element;
                      }
                    }}
                    key={message.messageId}
                    style={{
                      width: "100%",
                      color: message.from === "customer" ? "black" : "navy",
                      textAlign: "left",
                    }}
                    className="AppFontSizeSmall"
                  >
                    <div style={{ fontWeight: "bold" }}>
                      {message.from === "customer"
                        ? `You: ${moment(
                            message.timeSent.toMillis()
                          ).fromNow()}`
                        : `Admin: ${moment(
                            message.timeSent.toMillis()
                          ).fromNow()}`}
                    </div>
                    <div style={{ padding: "0.5em" }}>{message.message}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                className="AppFontSizeNormal anim_fadeInOut"
                style={{
                  opacity: "0",
                  width: "100%",
                  height: "8em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Arial, Verdana, Geneva, Tahoma, sans-serif",
                }}
              >
                <strong>LOADING...</strong>
              </div>
            )}
          </div>
          <div className="sTmsgMessageInput">
            <div className="sTmsgMessageInputInner">
              <textarea
                ref={ref}
                style={{
                  margin: 0,
                  fontFamily: "Arial, Verdana, Geneva, Tahoma, sans-serif",
                  minHeight: "4em",
                  maxHeight: "8em",
                  height: message.length === 0 ? "4em" : undefined,
                }}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  const height = e.currentTarget.scrollHeight;
                  if (height < 200)
                    e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
                }}
                onFocus={() =>
                  setTimeout(() => {
                    if (lastDiv && lastDiv.current)
                      lastDiv.current.scrollIntoView();
                  }, 500)
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && !e.shiftKey) {
                    e.preventDefault();
                  }
                }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13 && !e.shiftKey) {
                    sendMessage();
                  }
                }}
                placeholder={t("common.enterMessage")}
              />
              <span
                onClick={(e) => {
                  e.preventDefault();
                  ref.current?.focus();
                  sendMessage();
                }}
                className="sTmsgHintIcon AppFontSizeSmall"
              >
                <Icon name="send" />
              </span>
            </div>
            <div className="sTmsgHintText AppFontSizeExtraSmall">
              <span style={{ fontWeight: "bolder" }}>
                {t("dashboard.messages.return")}
              </span>
              &nbsp;
              {t("dashboard.messages.toSend")}&nbsp;&nbsp;&nbsp;
              <span className="sTmsgHintTextInner">
                <span style={{ fontWeight: "bolder" }}>
                  {t("dashboard.messages.shiftReturn")}
                </span>
                &nbsp;
                {t("dashboard.messages.toAddNewLine")}
              </span>
            </div>
          </div>
        </div>
        <ModalVeil onClick={() => closeModal()} />
      </React.Fragment>
    );
  }
);

export default STMessageThreadModal;
