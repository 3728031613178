import React, { FC } from "react";

export interface ModalVeilProps {
  onClick: (event: React.MouseEvent) => void;
}

const ModalVeil: FC<ModalVeilProps> = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="sTnavBarVeil sTnavBarVeil2 sTthemeVeil sTveilOpen"
    />
  );
};

export default ModalVeil;
