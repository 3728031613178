import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  FC,
  useCallback,
} from "react";
import * as R from "ramda";
import { FirebaseContext } from "../../../Firebase";
import Icon from "../../Icon/icon";
import useTheme from "../../../customization/useTheme";
import {
  Elements,
  CardElement,
  ReactStripeElements,
} from "react-stripe-elements";
import useCart from "../../CartManager/useCart";
import useStore from "../../../customization/useStore";
import { memoizedGetTotals } from "../../../utilities/orderProcessing";
import { constructUrl } from "../../../utilities/urlUtils";
import { STRIPE, XENDIT } from "../../PaymentProvider/constants";
import StripeInjector from "./stripeInjector";
import * as ROUTES from "../../../utilities/constants/routes";
import useSpinner from "../../Main/useSpinner";
import useToast from "../../Main/useToast";
// import useLockDimensions from "../../Main/useLockDimensions";
import useModal from "../../Modal/useModal";
import STxenditIframeModal from "../Modals/sTxenditIframe";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { AccountInfo } from "../../Main/main";
import { GuestDetails, DeliveryOption } from "../Pages/checkout";
import { CloseModal } from "../../Modal/modalManager";
import { PaymentContext } from "../../PaymentProvider/usePayment";
import { orderStatus } from "../../../database/order";
import {
  sendXenditCharge,
  storePendingOrder,
} from "../../../utilities/httpsCallables/httpsCallables";
import {
  OrderDetails,
  PaymentMethod,
} from "../../../utilities/httpsCallables/types";
import { createXenditAuthentication } from "./xenditHelpers";
import * as APPCONSTANTS from "../../../utilities/constants/appConstants";
import { useTranslation } from "react-i18next";
import bancontactLogo from "../../../img/bancontact.svg";
import { useLocation, useHistory } from "react-router-dom";
import STpayThirdPartyModal from "../Modals/sTpayThirdParty";
import moment from "moment";
import { LocationAddress } from "../../../database/storeLocation";
import { notUndefined } from "../../../utilities/notNull";

interface CardDetails {
  name: string;
  nameError: boolean;
  number: string;
  numberError: boolean;
  month: string;
  monthError: boolean;
  year: string;
  yearError: boolean;
  cvn: string;
  cvnError: boolean;
}

export interface StripePaymentIntent {
  clientSecret: string;
  id: string;
  amount: number;
}

export interface PaymentFormProps {
  accountInfo: AccountInfo;
  deliveryOption: DeliveryOption;
  addressInfo: LocationAddress;
  requestedTime: moment.Moment;
  guestDetails: GuestDetails;
}

// Depending on the payment gateway, we may need to wrap the payment
//   form in some extra components.
const PaymentForm: FC<PaymentFormProps> = (props) => {
  const { paymentGateway } = useStore();
  const { loading, xendit } = useContext(PaymentContext);
  if (loading) {
    return null;
  }
  if (paymentGateway === STRIPE) {
    return (
      <Elements>
        <StripeInjector {...props}>
          {(propsWithStripe) => <InnerComponent {...propsWithStripe} />}
        </StripeInjector>
      </Elements>
    );
  } else {
    return <InnerComponent xendit={xendit} {...props} />;
  }
};

type InnerPaymentFormProps = PaymentFormProps &
  Partial<ReactStripeElements.InjectedStripeProps> & {
    xendit?: Xendit.default | null;
  };

const InnerComponent: FC<InnerPaymentFormProps> = React.memo((props) => {
  const {
    deliveryOption,
    accountInfo,
    addressInfo,
    requestedTime,
    guestDetails,
    stripe,
    xendit,
  } = props;
  const { user } = useAuthUser();
  const toast = useToast();
  const showSpinner = useSpinner();
  // const lockDimensions = useLockDimensions();
  const showModal = useModal();
  const paymentInProgress = useRef(false);

  const cardNumber = React.useRef<HTMLInputElement>(null);
  const cardMonth = React.useRef<HTMLInputElement>(null);
  const cardYear = React.useRef<HTMLInputElement>(null);
  const cardCvn = React.useRef<HTMLInputElement>(null);
  const firebase = useContext(FirebaseContext);
  const location = useLocation();
  const history = useHistory();
  // FOR TESTING
  // const [cardDetails, setCardDetails] = useState<CardDetails>({
  //   name: "",
  //   nameError: false,
  //   number: "5200000000000056", //4000000000000002",//5200000000000056
  //   numberError: false,
  //   month: "02",
  //   monthError: false,
  //   year: "2022",
  //   yearError: false,
  //   cvn: "222",
  //   cvnError: false
  // });
  const [cardDetails, setCardDetails] = useState({
    name: "",
    nameError: false,
    number: "",
    numberError: true,
    month: "",
    monthError: true,
    year: "",
    yearError: true,
    cvn: "",
    cvnError: true,
  });
  const [cardFocus, setCardFocus] = useState(false);
  const { clearCart, suborders, createOrUpdatePaymentIntent } = useCart();
  const store = useStore();
  const { t } = useTranslation();
  const {
    currency,
    paymentGateway,
    locationId,
    emailFromSystem,
    emailTo,
    allowMeals,
    invoiceCode,
  } = store;
  const totals = memoizedGetTotals(suborders, store);
  const withTaxAmount = totals.withTax.getAmount();
  const { mainColor, mainColorDark, mainColorLight, color } = useTheme();
  const successAction = useCallback(() => {
    // console.log("successAction called.");
    //--------INFO--------
    //NEXT, clearCart(true), WILL ROUTE USER TO TRACK PAGE
    //BECAUSE OF THE REDIRECT CODE IN CHECKOUT.JS:
    // if (cartTotals.itemCount === 0) {
    //   return (
    //     //WE LEAD ALL USERS TO TRACK BECAUSE:
    //     //1. FOR THEM TO SEE THEY COULD TRACK AN ORDER
    //     //2. PAY SUCCESS MODAL NEEDS TRACK AS A ROUTE!!
    //     <Redirect to={ROUTES.TRACK} />
    //   );
    // }
    //console.log("CLEARING CART NOW.");
    // This true flag is used by track.js to show a success modal.
    clearCart(true);
    history.replace(ROUTES.TRACK);
  }, [clearCart, history]);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("credit");
  const assembleOrder = () => {
    const order: OrderDetails = {
      emailFromSystem,
      emailTo,
      invoiceCode,
      allowMeals,
      currency,
      locationId,
      paymentGateway,
      suborders,
      customerAddress:
        deliveryOption === "DELIVERY"
          ? [
              addressInfo.streetLine1,
              addressInfo.streetLine2,
              addressInfo.streetLine3,
              addressInfo.city,
              addressInfo.stateCode,
              addressInfo.zip,
              addressInfo.countryCode,
            ]
              .filter(notUndefined)
              .join(" ")
          : "",
      delivery: {
        timeCompleted: null,
        timePlanned: requestedTime.toDate().toString(),
        //-----INFO----
        //WE DONT CREATE A TIMECREATED OBJECT HERE AS WE WANT
        //TO STORE IT AS A TIMESTAMP IN THE DATABASE AND NEED
        //TO DO IT IN FUNCTIONS
        timeCreated_locale: new Date().toLocaleDateString(currency.locale, {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }),
        timeRequested: requestedTime.toDate().toString(),
        timeRequested_locale: requestedTime
          .toDate()
          .toLocaleDateString(currency.locale, {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
        timeHandedoff: null,
        delivery: deliveryOption === "DELIVERY",
      },
      customerPhone: user ? accountInfo.phone : guestDetails.phone,
      customerEmail: user ? accountInfo.email : guestDetails.email,
      status: orderStatus.inqueue,
      //THE FOLLOWING 'STATUSCOMPLETED' FIELD IS NECESSARY
      //BECAUSE FIRESTORE CANNOT QUERY WITH AN "OR"
      //E.G. THE FOLLOWING DOESNT WORK:
      //.where("status", "==", "inqueue")
      //.where("status", "==", "inprogress")
      statusCompleted: false,
      total: withTaxAmount,
      tax: totals.totalTax.getAmount(),
      taxTable: R.map((tax) => tax.getAmount(), totals.taxTable),
      userId: user ? user.uid : "guest",
      url: `${window.location.protocol}//${window.location.host}`,
    };
    return order;
  };

  const handleSubmit = (e: React.FormEvent) => {
    if (e) e.preventDefault();
    if (store.isPreview) {
      toast({
        message: t("dashboard.catalog.disabledDueToPreview"),
        className: "sTthemeAlert1b",
        duration: APPCONSTANTS.TOASTDURATION_ERROR,
      });
      return;
    }
    if (paymentInProgress.current) {
      return; // already in progress, don't do it again
    }
    paymentInProgress.current = true;
    if (paymentMethod === "credit") {
      if (cardDetails.name.length < 3) {
        toast({
          message: t("store.toast.enterName"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
        paymentInProgress.current = false;
        return;
      }
      if (paymentGateway === XENDIT) {
        return handleXendit();
      } else {
        return handleStripe();
      }
    } else if (paymentMethod.startsWith("bancontact")) {
      return handleStripe();
    }
  };

  const closeModalRef = useRef<CloseModal>();
  const handleXendit = async () => {
    if (!xendit) {
      // I think this would only happen if we failed to load the xendit script
      toast({
        message: t("store.toast.paymentSystemDown"),
        className: "sTthemeAlert1b",
        duration: APPCONSTANTS.TOASTDURATION_ERROR,
      });
      paymentInProgress.current = false;
      return;
    }
    const hideSpinner = showSpinner({ lag: "none" });
    try {
      if (cardDetails.numberError) {
        console.log("Generating card number error locally.");
        paymentInProgress.current = false;
        throw new Error("Card number is invalid.");
      } else if (cardDetails.monthError || cardDetails.yearError) {
        paymentInProgress.current = false;
        throw new Error(
          "Expiration date " +
            cardDetails.month +
            " / " +
            cardDetails.year +
            " is invalid."
        );
      } else if (cardDetails.cvnError) {
        paymentInProgress.current = false;
        throw new Error("CVN number is invalid.");
      } else if (!xendit.card.validateCardNumber(cardDetails.number.trim())) {
        paymentInProgress.current = false;
        throw new Error("Card number invalid.");
      } else if (
        !xendit.card.validateExpiry(cardDetails.month, cardDetails.year)
      ) {
        paymentInProgress.current = false;
        throw new Error(
          `Expiration date ${cardDetails.month} / ${cardDetails.year} is invalid.`
        );
      } else if (!xendit.card.validateCvn(cardDetails.cvn)) {
        paymentInProgress.current = false;
        throw new Error("Card CVN invalid.");
      }

      //****FOR TESTING COMMENT BELOW THE LOG STATEMENT */
      //console.log("paymentForm.js ORDER DETAILS:", assembleOrder());

      //4000000000000002 5200000000000056
      const token = await createXenditToken(xendit.card, cardDetails);
      const authentication = await createXenditAuthentication(
        xendit.card,
        token.id,
        withTaxAmount,
        showSpinner,
        showModal,
        closeModalRef
      );
      await sendXenditCharge(firebase, {
        name: cardDetails.name.toUpperCase(),
        tokenId: token.id,
        authenticationId: authentication.id,
        amount: withTaxAmount,
        orderDetails: assembleOrder(),
      });
      successAction();
    } catch (error) {
      if (closeModalRef.current) {
        closeModalRef.current();
        closeModalRef.current = undefined;
      }
      console.log("XENDIT ERROR", error.code, error.message);
      toast({
        message: error.message,
        className: "sTthemeAlert1b",
        duration: APPCONSTANTS.TOASTDURATION_ERROR,
      });
    } finally {
      paymentInProgress.current = false;
      hideSpinner();
    }
  };
  const createXenditToken = (card: Xendit.Card, cardDetails: CardDetails) => {
    return new Promise<Xendit.TokenizationResponse>((resolve, reject) => {
      let inReview = false;
      let hideSpinner: (() => void) | undefined;
      card.createToken(
        {
          card_number: cardDetails.number.trim(),
          card_exp_month: cardDetails.month,
          card_exp_year: cardDetails.year,
          card_cvn: cardDetails.cvn.trim(),
          is_multiple_use: true,
        },
        (error, token) => {
          if (inReview) {
            hideSpinner = showSpinner({ lag: "none" });
            inReview = false;
          }
          if (error) {
            reject(error);
          } else if (token.status === "FAILED") {
            const message = token.failure_reason
              ? token.failure_reason
              : token.error_code === "AUTHENTICATION_FAILED"
              ? "Authentication failed."
              : token.error_code;
            paymentInProgress.current = false;
            reject(new Error(message));
          } else if (token.status === "IN_REVIEW") {
            inReview = true;
            paymentInProgress.current = false;
            if (hideSpinner) {
              hideSpinner();
            }
            // Can't resolve or reject yet. The callback will be
            //   called again once the modal is done.
            closeModalRef.current = showModal(
              STxenditIframeModal,
              { url: token.payer_authentication_url },
              `${ROUTES.CHECKOUT}${ROUTES.ML_XENDITIFRAME}`
            );
          } else if (token.status === "VERIFIED") {
            resolve(token);
          }
        }
      );
    });
  };

  const handleStripe = async () => {
    if (!stripe) {
      // I think this would only happen if we failed to load the stripe script
      toast({
        message: t("store.toast.paymentSystemDown"),
        className: "sTthemeAlert1b",
        duration: APPCONSTANTS.TOASTDURATION_ERROR,
      });
      paymentInProgress.current = false;
      return;
    }
    if (paymentMethod === "bancontact") {
      const hideSpinner = showSpinner({ lag: "none" });
      const result = await stripe.createSource({
        type: "bancontact",
        amount: withTaxAmount,
        currency: currency.currency,
        owner: {
          name: cardDetails.name,
        },
        redirect: {
          return_url: constructUrl(["checkout", "payment"], [], []),
        },
      });
      if (result.error || !result.source?.redirect?.url) {
        hideSpinner();
        paymentInProgress.current = false;
        toast({
          message:
            result?.error?.code === "parameter_invalid_empty" ||
            result?.error?.code === "invalid_owner_name"
              ? t("store.toast.badName")
              : t("store.toast.somethingWrong"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
        console.log("BANCONTACT ERROR", result.error);
      } else {
        //BANCONTACT TOKEN CREATED SUCCESSFULLY
        try {
          console.log("CREATING PENDING ORDER");
          await storePendingOrder(firebase, {
            orderDetails: assembleOrder(),
            source: "bancontact",
            id: result.source.id,
          });
          sessionStorage.setItem(
            "bancontactClientSecret",
            result.source.client_secret
          );
          //REDIRECT TO BANCONTACT PAGE
          window.location.assign(result.source?.redirect?.url);
        } catch (error) {
          toast({
            message: t("store.toast.somethingWrong"),
            className: "sTthemeAlert1b",
            duration: APPCONSTANTS.TOASTDURATION_ERROR,
          });
          console.log("CREATING PENDING ORDER ERROR", error);
          paymentInProgress.current = false;
        } finally {
          hideSpinner();
        }
      }
    } //PAYMENT METHOD IS CREDIT CARD
    else if (paymentMethod === "credit") {
      const card = props.elements?.getElement("card");
      if (!card) {
        toast({
          message: t("store.toast.paymentSystemDown"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
        paymentInProgress.current = false;
        return;
      }
      const hideSpinner = showSpinner({ lag: "none" });
      try {
        const paymentIntent = await createOrUpdatePaymentIntent(withTaxAmount);
        if (!paymentIntent) return;
        //CREATING PENDING ORDER FIRST,
        //ENSURING ITS THERE WHEN PAYMENT SUCCEEDS
        //ELSE IF PAYMENT SUCCEEDS, AND THEN WE LOSE CONNECTION,
        //THERE'S NO ORDER TO FINALIZE, YET THE CUSTOMER PAID
        //ORDER WILL BE FINALIZED IN WEBHOOK
        await storePendingOrder(firebase, {
          orderDetails: assembleOrder(),
          source: "credit_stripe",
          id: paymentIntent.id,
        });
        const result = await stripe.confirmCardPayment(
          paymentIntent.clientSecret,
          {
            payment_method: {
              card: card,
              billing_details: {
                name: cardDetails.name.toUpperCase(),
              },
            },
          }
        );
        if (result.error && result.error.message) {
          toast({
            message: result.error.message,
            className: "sTthemeAlert1b",
            duration: APPCONSTANTS.TOASTDURATION_ERROR,
          });
        } else {
          console.log("STRIPE PAYMENTINTENT PAYMENT SUCCESSFUL!", result);
          successAction();
        }
      } catch (error) {
        console.log("STRIPE CHARGE ERROR", error.code, error.message);
        toast({
          message: error.message,
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
      } finally {
        paymentInProgress.current = false;
        hideSpinner();
      }
    }
  };

  let instantKeyCode = 0;
  const handleKeyInputs = (e: React.KeyboardEvent<HTMLInputElement>) => {
    instantKeyCode = e.keyCode;
  };
  let date = new Date();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("In handleInputChange", e.currentTarget.value);
    if (e.currentTarget.name === "ccname") {
      setCardDetails({
        name: e.currentTarget.value,
        nameError: cardDetails.nameError,
        number: cardDetails.number,
        numberError: cardDetails.numberError,
        month: cardDetails.month,
        monthError: cardDetails.monthError,
        year: cardDetails.year,
        yearError: cardDetails.yearError,
        cvn: cardDetails.cvn,
        cvnError: cardDetails.cvnError,
      });
    } else if (e.currentTarget.name === "cardnumber") {
      if (instantKeyCode === 69) {
        return;
      }
      let error = false;
      if (e.currentTarget.value.length < 20) {
        setCardDetails({
          name: cardDetails.name,
          nameError: cardDetails.nameError,
          number: e.currentTarget.value,
          numberError: error ? true : false,
          month: cardDetails.month,
          monthError: cardDetails.monthError,
          year: cardDetails.year,
          yearError: cardDetails.yearError,
          cvn: cardDetails.cvn,
          cvnError: cardDetails.cvnError,
        });
      }
    } else if (e.currentTarget.name === "ccmonth") {
      let error = false;
      if (e.currentTarget.value.length < 3) {
        if (+e.currentTarget.value > 12) {
          error = true;
        }
        if (
          e.currentTarget.value.length === 2 &&
          cardYear.current!.value.length === 4
        ) {
          if (
            new Date(+cardYear.current!.value, +e.currentTarget.value) < date
          ) {
            error = true;
          }
        }
        setCardDetails({
          name: cardDetails.name,
          nameError: cardDetails.nameError,
          number: cardDetails.number,
          numberError: cardDetails.numberError,
          month: e.currentTarget.value,
          monthError: error,
          year: cardDetails.year,
          yearError: error,
          cvn: cardDetails.cvn,
          cvnError: cardDetails.cvnError,
        });
      }
    } else if (e.currentTarget.name === "ccyear") {
      let error = false;
      if (e.currentTarget.value.length < 5) {
        if (e.currentTarget.value.length === 4) {
          if (+e.currentTarget.value < date.getFullYear()) {
            error = true;
          } else if (cardMonth.current!.value.length === 2) {
            if (
              new Date(+e.currentTarget.value, +cardMonth.current!.value) < date
            ) {
              error = true;
            }
          }
        }
        setCardDetails({
          name: cardDetails.name,
          nameError: cardDetails.nameError,
          number: cardDetails.number,
          numberError: cardDetails.numberError,
          month: cardDetails.month,
          monthError: error,
          year: e.currentTarget.value,
          yearError: error,
          cvn: cardDetails.cvn,
          cvnError: cardDetails.cvnError,
        });
        // if (error === false && e.currentTarget.value.length === 4) {
        //   cardCvn.current.focus();
        // }
      }
    } else if (e.currentTarget.name === "cvc") {
      let error = true;
      if (e.currentTarget.value.length < 5) {
        if (e.currentTarget.value.length > 2) {
          error = false;
        }
        setCardDetails({
          name: cardDetails.name,
          nameError: cardDetails.nameError,
          number: cardDetails.number,
          numberError: cardDetails.numberError,
          month: cardDetails.month,
          monthError: cardDetails.monthError,
          year: cardDetails.year,
          yearError: cardDetails.yearError,
          cvn: e.currentTarget.value,
          cvnError: error ? true : false,
        });
      }
    }
  };

  const checkDate = (e: React.FocusEvent<HTMLInputElement>) => {
    let error = false;
    if (e.currentTarget.name === "ccmonth") {
      if (e.currentTarget.value.length === 1) {
        error = true;
        if (e.currentTarget.value !== "0") {
          e.currentTarget.value = "0" + e.currentTarget.value;
          error = false;
        }
      }
      setCardDetails({
        name: cardDetails.name,
        nameError: cardDetails.nameError,
        number: cardDetails.number,
        numberError: cardDetails.numberError,
        month: e.currentTarget.value,
        monthError: error,
        year: cardDetails.year,
        yearError: cardDetails.yearError,
        cvn: cardDetails.cvn,
        cvnError: cardDetails.cvnError,
      });
    } else if (e.currentTarget.name === "ccyear") {
      if (
        e.currentTarget.value.length > 1 &&
        e.currentTarget.value.length < 4
      ) {
        error = true;
      }
      setCardDetails({
        name: cardDetails.name,
        nameError: cardDetails.nameError,
        number: cardDetails.number,
        numberError: cardDetails.numberError,
        month: cardDetails.month,
        monthError: cardDetails.monthError,
        year: e.currentTarget.value,
        yearError: error,
        cvn: cardDetails.cvn,
        cvnError: cardDetails.cvnError,
      });
    }
  };

  const vmin = () => {
    return Math.min(window.innerWidth, window.innerHeight) / 100;
  };
  const [stripeFontSize, setStripeFontSize] = useState(8 + 1.8 * vmin() + "px");
  useEffect(() => {
    const handleResize = () => {
      setStripeFontSize(8 + 1.8 * vmin() + "px");
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    //FOR BANCONTACT USERS RETURNING FROM BANCONTACT
    if (location.search.length > 0) {
      // console.log("paymentForm.tsx", location.search);
      const ids: {
        source: undefined | string;
        clientSecret: undefined | string;
      } = { source: undefined, clientSecret: undefined };
      location.search
        .substr(1) //GET RID OF "?"-character
        .split("&")
        .forEach((part) => {
          let item = part.split("=");
          if (item[0] === "source") {
            ids.source = decodeURIComponent(item[1]);
          } else if (item[0] === "client_secret") {
            ids.clientSecret = decodeURIComponent(item[1]);
          }
        });
      if (ids.source) {
        setPaymentMethod("bancontact");
        if (
          sessionStorage.getItem("bancontactClientSecret") === ids.clientSecret
        ) {
          sessionStorage.removeItem("bancontactClientSecret");
          history.replace("/checkout");
          // console.log("BANCONTACT RETURN SUCCESS", bancontactSource);
          setTimeout(() => {
            showModal(
              STpayThirdPartyModal,
              {
                paymentMethodString: "Bancontact",
                successAction,
                tempId: ids.source!,
              },
              `${ROUTES.CHECKOUT}${ROUTES.ML_PAYINPROGRESS}`
            );
          });
        }
      } else {
        //PAYMENT AUTH URL
      }
    }
  }, [location.search, history, showModal, successAction]);

  useEffect(() => {
    if (paymentGateway === STRIPE) {
      createOrUpdatePaymentIntent(withTaxAmount);
    }
  }, [createOrUpdatePaymentIntent, paymentGateway, withTaxAmount]);

  return (
    <React.Fragment>
      <div className="sTcheckoutPaymentWrap sTcartCard sT_anim_moveLeftSmall">
        <form noValidate onSubmit={(e) => handleSubmit(e)}>
          {paymentGateway === STRIPE && (
            <div
              className="sTcheckoutSelectWrap sTthemeBGcolor"
              style={{
                position: "relative",
                transition: "all 0.3s linear 0s",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      height: "1.5em",
                      width: "3em",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {paymentMethod === "bancontact" ? (
                      <img
                        src={bancontactLogo}
                        style={{
                          height: "100%",
                        }}
                        alt="bancontact logo"
                      />
                    ) : (
                      <Icon name="checkout" style={{}} />
                    )}
                  </div>
                  <div
                    style={{
                      fontFamily: "Arial, Verdana, Geneva, Tahoma, sans-serif",
                    }}
                  >
                    {paymentMethod === "credit"
                      ? t("store.checkout.payment.creditCard")
                      : t("store.checkout.payment.banContact")}
                  </div>
                </div>
                <Icon name="expand_more" />
              </div>
              <select
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "2em",
                  opacity: 0,
                }}
                onChange={(e) => {
                  e.target.value === "bancontact"
                    ? setPaymentMethod("bancontact")
                    : setPaymentMethod("credit");
                }}
              >
                <option value="credit">
                  {t("store.checkout.payment.creditCard")}
                </option>
                <option value="bancontact">
                  {t("store.checkout.payment.banContact")}
                </option>
              </select>
            </div>
          )}
          <input
            style={{
              padding: "0.5em",
              fontFamily: "Arial, Verdana, Geneva, Tahoma, sans-serif",
            }}
            tabIndex={1}
            autoComplete="cc-name"
            id="ccname"
            name="ccname"
            type="text"
            placeholder="Name on card"
            autoCorrect="off"
            spellCheck={false}
            aria-placeholder="Name as it appears on the card"
            aria-label="Credit or Debit card name"
            value={cardDetails.name.toUpperCase()}
            onChange={(e) => handleInputChange(e)}
            // onFocus={() => lockDimensions(true, "name")}
          />
          {paymentGateway === STRIPE ? (
            // paymentMethod === "credit" && (
            <div
              className={
                paymentMethod === "credit"
                  ? "sTcheckout_anim_fadeInVisible_0300"
                  : "sTcheckout_anim_fadeOutHidden_0300"
              }
              style={{ width: "100%" }}
            >
              <CardElement
                className="sTStripeElement"
                // onFocus={() => lockDimensions(true, "card")}
                style={{
                  base: {
                    iconColor: mainColor,
                    fontSize: stripeFontSize,
                    fontFamily: "Arial, Verdana, Geneva, Tahoma, sans-serif",
                    "::placeholder": {
                      color: "gray",
                    },
                  },
                }}
              />
            </div>
          ) : (
            // )
            <div className="sTcheckoutXenditWrap">
              <span
                className="sTcheckoutXenditCC"
                style={{
                  position: "relative",
                  transition: "all 0.5s ease 0s",
                }}
              >
                <span
                  style={{ position: "relative" }}
                  className="sTcheckoutXenditIcon sTcheckoutDivPosLeft sTcheckout_anim_goInViewRight"
                >
                  <Icon name="checkout" />
                  &nbsp;
                </span>
                <input
                  ref={cardNumber}
                  tabIndex={2}
                  style={{
                    transition: "all 0.5s ease 0s",
                    width: cardFocus ? "12em" : "8em",
                    color: cardDetails.numberError ? "red" : "",
                  }}
                  className="sTcheckoutFadedOut sTcheckout_anim_fadeIn_0507"
                  id="cardnumber"
                  name="cardnumber"
                  type="number"
                  pattern="\d*"
                  placeholder="Card Number"
                  autoCorrect="off"
                  spellCheck={false}
                  aria-placeholder="Card Number"
                  aria-label="Credit or debit card number"
                  value={cardDetails.number}
                  onChange={(e) => handleInputChange(e)}
                  onKeyDown={(e) => handleKeyInputs(e)}
                  onFocus={() => {
                    // lockDimensions(true, "card");
                    setCardFocus(true);
                  }}
                  onBlur={() => {
                    setCardFocus(false);
                  }}
                  autoComplete="cc-number"
                />
              </span>
              <span
                style={{
                  display: "flex",
                  position: "relative",
                  right: cardFocus ? "-1em" : "0",
                  transition: "all 0.3s ease 0s",
                  color: cardDetails.cvnError ? "red" : "",
                }}
              >
                <span
                  style={{
                    display: "flex",
                  }}
                >
                  <input
                    ref={cardMonth}
                    tabIndex={3}
                    id="ccmonth"
                    name="ccmonth"
                    type="number"
                    pattern="\d*"
                    value={cardDetails.month}
                    // onFocus={() => lockDimensions(true, "month")}
                    onBlur={(e) => {
                      checkDate(e);
                    }}
                    onChange={(e) => handleInputChange(e)}
                    onKeyDown={(e) => handleKeyInputs(e)}
                    autoComplete="cc-exp-month"
                    min="1"
                    max="12"
                    placeholder="MM"
                    autoCorrect="off"
                    spellCheck={false}
                    aria-placeholder="MM"
                    aria-label="Credit or debit card expiration month"
                    style={{
                      width: "3em",
                      color: cardDetails.monthError ? "red" : "",
                    }}
                  />
                  <input
                    ref={cardYear}
                    tabIndex={4}
                    id="ccyear"
                    name="ccyear"
                    type="number"
                    pattern="\d*"
                    value={cardDetails.year}
                    // onFocus={() => lockDimensions(true, "year")}
                    onBlur={(e) => {
                      checkDate(e);
                    }}
                    onChange={(e) => handleInputChange(e)}
                    onKeyDown={(e) => handleKeyInputs(e)}
                    autoComplete="cc-exp-year"
                    min={new Date().getFullYear()}
                    max={parseInt(new Date().getFullYear() + "") + 20}
                    placeholder="YYYY"
                    autoCorrect="off"
                    spellCheck={false}
                    aria-placeholder="YYYY"
                    aria-label="Credit or debit card expiration year"
                    style={{
                      width: "4em",
                      color: cardDetails.yearError ? "red" : "",
                    }}
                  />
                </span>

                <input
                  ref={cardCvn}
                  tabIndex={5}
                  style={{
                    width: "3.5em",
                    position: "relative",
                  }}
                  id="cvc"
                  name="cvc"
                  type="number"
                  pattern="\d*"
                  placeholder="CVN"
                  // onFocus={() => {
                  //   lockDimensions(true, "cvn");
                  // }}
                  value={cardDetails.cvn}
                  onChange={(e) => handleInputChange(e)}
                  onKeyDown={(e) => handleKeyInputs(e)}
                  autoComplete="cc-csc"
                />
              </span>
            </div>
          )}
          <br />
          <div
            onClick={handleSubmit}
            style={{
              backgroundColor: mainColorDark,
              color: color,
            }}
            className="sTbutton"
          >
            <div
              style={{
                backgroundColor: mainColorLight,
              }}
              className="sTbuttonIcon"
            >
              <Icon name="lock" fill={color} />
            </div>
            <span className="sTbuttonText AppFontSizeNormal">
              {t("store.checkout.payButton")}
              {totals.withTax.toFormat()}
            </span>
            <div />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
});

export default PaymentForm;
