import React from "react";
import { Order, orderStatus } from "../../../database/order";
import Icon from "../../Icon/icon";
import { IconName } from "../../Icon/IconName";
import { useTranslation } from "react-i18next";
import useStore from "../../../customization/useStore";

export interface TrackingSummaryProps {
  order: Order;
  selected: boolean;
  onClick: () => void;
}

export const trackingStatusIcons: Record<orderStatus, IconName> = {
  [orderStatus.inprogress]: "in_progress",
  [orderStatus.inqueue]: "in_queue",
  [orderStatus.intransit]: "in_transit",
  [orderStatus.delivered]: "done",
  [orderStatus.ready]: "done",
  [orderStatus.pending]: "pause",
  [orderStatus.failed]: "warning",
};

export const trackingStatusStrings: Record<orderStatus, string> = {
  [orderStatus.inprogress]: "store.track.inKitchenStatus",
  [orderStatus.inqueue]: "store.track.inQueueStatus",
  [orderStatus.intransit]: "store.track.inTransitStatus",
  [orderStatus.delivered]: "store.track.deliveredStatus",
  [orderStatus.ready]: "store.track.readyStatus",
  [orderStatus.pending]: "store.track.pendingStatus",
  [orderStatus.failed]: "store.track.failedStatus",
};

const TrackingSummary: React.FC<TrackingSummaryProps> = ({
  order,
  selected,
  onClick,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const { theme } = store;
  const { mainColor, mainColorDark, color } = theme;
  let itemCount = 0;
  Object.values(order.suborders).forEach(
    (suborder) => (itemCount += Object.keys(suborder.items).length)
  );
  return (
    <div
      onClick={onClick}
      className="sTtrackItem1 AppFontSizeSmall sT_anim_fadeIn_0303"
      style={{
        opacity: 0,
        transition: "all 0.3s linear 0s",
        backgroundColor: selected ? mainColorDark : mainColor,
      }}
    >
      <div className="sTtrackSmallCardContents">
        <div className="sTtrackIconWrap">
          <Icon
            name={
              order.paymentRechargeStatus === "pendingauth"
                ? "warning"
                : trackingStatusIcons[order.status] || "report"
            }
            fill={color}
          />
          <div
            style={{
              color: color,
            }}
          >
            {order.paymentRechargeStatus === "pendingauth"
              ? t("common.status.paused")
              : t(
                  trackingStatusStrings[order.status] ||
                    "store.track.unknownStatus"
                ).toUpperCase()}
          </div>
        </div>
        <div>
          <div
            className="sTtrackIconWrap"
            style={{
              color: color,
            }}
          >
            {
              <Icon
                name={order.delivery.delivery ? "delivery" : "store"}
                fill={color}
              />
            }
            {order.delivery.delivery ? "DELIVERY" : "PICK UP"}
          </div>
        </div>
        <div className="sTtrackIconWrap">
          <Icon name="food" fill={color} />
          <div style={{ color: color }}>
            {itemCount === 1
              ? t("store.track.itemCount", { count: itemCount }).toUpperCase()
              : t("store.track.itemCount_plural", {
                  count: itemCount,
                }).toUpperCase()}
          </div>
        </div>

        <div
          style={{
            color: color,
          }}
          className="sTtrackCategoryText"
        >
          {order.delivery.timeCreated.toDate().toLocaleString([], {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      </div>
    </div>
  );
};

export default TrackingSummary;
