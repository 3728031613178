import React from "react";
import Firebase from ".";
const FirebaseContext = React.createContext<Firebase>({} as Firebase);

export default FirebaseContext;

// The Firebase Context from the Firebase module (folder)
// is used to provide a Firebase instance to your entire
// application in the src/index.js file. You only need to
// create the Firebase instance with the Firebase class
// and pass it as value prop to the React’s Context.

// The createContext() function essentially creates two components.
// 1. The FirebaseContext.Provider component is used to provide a
// Firebase instance once at the top-level of your React component
// tree.
// 2. The FirebaseContext.Consumer component is used to retrieve the
// Firebase instance if it is needed in the React component.

// For a well-encapsulated Firebase module, we’ll define a index.js
// file in our Firebase folder that exports all necessary
// functionalities (Firebase class, Firebase context for Consumer
// and Provider components).
