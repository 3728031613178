import React, { FC } from "react";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import ModalVeil from "../../Modal/modalVeil";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { ModalProps } from "../../Modal/modalManager";
import { useTranslation } from "react-i18next";
import { hexToRgbString } from "../../../utilities/colorUtils";
interface STpaySuccessModalProps extends ModalProps {}

const STpaySuccessModal: FC<STpaySuccessModalProps> = React.memo(
  ({ closeModal }) => {
    const { user } = useAuthUser();
    const store = useStore();
    const { t } = useTranslation();
    const { color, mainColorDark } = store.theme;
    const doClose = () => {
      closeModal();
    };
    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            color: color,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader"
            style={{
              backgroundColor: mainColorDark,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                color: color,
              }}
            >
              <Icon name="information" fill={color} />
              <div>&nbsp;{t("store.orders.orderSuccessfulTitle")}</div>
            </div>
            <br />
            <div
              className="AppFontSizeSmall"
              style={{ fontWeight: "bold", color: color }}
            >
              {t("store.orders.thankYou")}
            </div>
            <div className="AppFontSizeSmall"></div>
          </div>
          <div className="sTmodalContent">
            <div style={{ padding: "2em 1em", color: "black" }}>
              {user
                ? t("store.track.trackOrderLive")
                : t("store.track.estimatedTime")}
            </div>
          </div>
          <div
            className="sTmodalFooter AppFontSizeSmall"
            style={{
              backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
            }}
          >
            <div
              //USER WILL BE ROUTED TO EITHER TRACK OR MENU
              //BECAUSE OF THE REDIRECT IN CHECKOUT.JS
              onClick={doClose}
              className="sTmodalFooterItem AppFontSizeSmall"
              style={{ color: color }}
            >
              {t("common.ok")}
            </div>
          </div>
        </div>
        <ModalVeil onClick={doClose} />
      </React.Fragment>
    );
  }
);

export default STpaySuccessModal;
