import React, { FC } from "react";
import { useSettingsApi } from "./settingsApiContext";
import { useTranslation } from "react-i18next";
// // import useLockDimensions from "../../Main/useLockDimensions";
import { useRouteMatch, Redirect } from "react-router-dom";
import { DASHBOARD_SETTINGS } from "../../../utilities/constants/routes";
import Icon from "../../Icon/icon";
import { IconName } from "../../Icon/IconName";
import { toTitleCase } from "../../../utilities/stringFunctions";
import {
  defaultToolbarConfig,
  useLazyRTE,
} from "../../../utilities/useLazyRTE";
import useStore from "../../../customization/useStore";
import useModal from "../../Modal/useModal";
import DBIconModal from "../Modals/dBicon";
import * as R from "ramda";
import { PageConfig } from "../../../database/storeLocation";

interface ExtraPageSettingsProps {}

const ExtraPageSettings: FC<ExtraPageSettingsProps> = () => {
  const { t } = useTranslation();
  const { theme } = useStore();
  const showModal = useModal();
  const settingsApi = useSettingsApi();
  // // const lockDimensions = useLockDimensions();
  const match = useRouteMatch<{ index: string }>();
  const index = Number(match.params.index);
  const config = settingsApi.location.extraPageOptions?.pages[index];
  const { value: content, setValue: setContent, RichTextEditor } = useLazyRTE(
    config?.template.content
  );

  if (!config) {
    return <Redirect to={`${DASHBOARD_SETTINGS}/theme/extraPages`} />;
  }

  return (
    <React.Fragment>
      <div className="dBsettingsCard boxShadow2">
        <div
          className="dBthemeBGcolor2"
          style={{
            padding: "0.5em",
            textAlign: "left",
            marginBottom: "0.25em",
          }}
        >
          {t("dashboard.settings.extraPages.subtitle")}
        </div>
        <div style={{ padding: "0.5em" }}>
          <div className="AppFontSizeSmall" style={{ textAlign: "left" }}>
            <strong>{t("dashboard.settings.extraPages.pageName")}</strong>
          </div>
          <input
            type="text"
            value={config.label}
            onChange={(event) => {
              const label = toTitleCase(event.target.value);
              const path = "/" + label.toLowerCase();
              settingsApi.setLocation(
                R.evolve(
                  {
                    extraPageOptions: {
                      pages: R.update(index, {
                        ...config,
                        label,
                        path,
                      }),
                    },
                  },
                  settingsApi.location
                )
              );
            }}
            placeholder={t("dashboard.settings.extraPages.pageNamePlaceholder")}
            // // onFocus={() => lockDimensions(true)}
            style={{ margin: 0 }}
          />

          <div
            className="AppFontSizeSmall"
            style={{ textAlign: "left", margin: "1em 0 0.25em 0" }}
          >
            <strong>{t("dashboard.settings.extraPages.icon")}</strong>
          </div>
          <div style={{ textAlign: "left" }}>
            <Icon
              name={config.icon}
              fill={theme.iconColor}
              style={{ border: "1px dashed" }}
              onClick={() => {
                showModal(DBIconModal, {
                  initialValue: config.icon,
                  onSubmit: (icon: IconName) => {
                    settingsApi.setLocation(
                      R.evolve(
                        {
                          extraPageOptions: {
                            pages: R.update(index, { ...config, icon }),
                          },
                        },
                        settingsApi.location
                      )
                    );
                  },
                });
              }}
            />
          </div>

          <RichTextEditor
            toolbarConfig={defaultToolbarConfig}
            value={content}
            onChange={(value) => {
              setContent(value);
              const valueStr = value.toString("html");
              settingsApi.setLocation(
                R.evolve(
                  {
                    extraPageOptions: {
                      pages: R.update<PageConfig>(index, {
                        ...config,
                        template: {
                          type: "richText",
                          content: valueStr,
                        },
                      }),
                    },
                  },
                  settingsApi.location
                )
              );
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExtraPageSettings;
