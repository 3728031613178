import React, { FC } from "react";
import Icon from "../Icon/icon";
import { IconName } from "../Icon/IconName";
import { useTranslation } from "react-i18next";

export type SpinnerLag = "none" | "short" | "long";

export interface SpinnerProps {
  isOpen: boolean;
  dBnavMenuFull: boolean;
  lag?: SpinnerLag;
  text?: string;
  icon?: IconName;
  animationClass?: string;
}

const Spinner: FC<SpinnerProps> = ({
  isOpen,
  dBnavMenuFull,
  lag = "long",
  text,
  icon = "swap_vert",
  animationClass = "anim_spinnerMove",
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div
        className={
          lag === "none"
            ? "spinnerDiv sT_anim_fadeIn_0300"
            : lag === "short"
            ? "spinnerDiv sT_anim_fadeIn_0303"
            : "spinnerDiv sT_anim_fadeIn_0307"
        }
        style={{
          opacity: 0,
          paddingLeft: dBnavMenuFull ? "8em" : undefined,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          display: isOpen ? "flex" : "none",
        }}
      >
        <div style={{ color: "white" }}>
          <div className={animationClass}>
            <Icon name={icon} fill="white" />
          </div>
          <div className="AppFontSizeExtraSmall">
            {text ?? t("common.loading")}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Spinner;
