import React, { useRef, FC } from "react";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../../utilities/constants/routes";
import useStore from "../../../customization/useStore";
import Icon from "../../Icon/icon";
import "../css/cart.css";
import useCart from "../../CartManager/useCart";
import { memoizedGetTotals } from "../../../utilities/orderProcessing";
import { Redirect } from "react-router-dom";
import CartSummary from "../cartSummary";
import SuborderSummary from "../suborderSummary";
import useModal from "../../Modal/useModal";
import STrenameMealModal from "../Modals/sTrenameMeal";
import STRemoveMealModal from "../Modals/sTremoveMeal";
import { useTranslation } from "react-i18next";
import useAccountInfo from "../../Main/useAccountInfo";

export interface CartProps {}

const Cart: FC<CartProps> = React.memo(() => {
  const accountInfo = useAccountInfo();
  const { t } = useTranslation();
  const history = useHistory();
  const showModal = useModal();
  const { suborders, addSuborder, setActiveSuborder } = useCart();
  const initialSuborders = useRef(suborders);
  const store = useStore();
  const { theme, allowMeals } = store;
  const { color, mainColorDark } = theme;
  const cartTotals = memoizedGetTotals(suborders, store);

  if (memoizedGetTotals(initialSuborders.current, store).itemCount === 0) {
    return <Redirect to={ROUTES.ORDER} />;
  }
  return (
    <React.Fragment>
      <div className="sTcartOuterWrap sTthemeBGcolorSoft">
        <div className="sTcartHeader"></div>
        <div className="sTcartContentsWrap">
          <div className="sTcartContents2">
            <CartSummary />
            <div className="sTcartContents2b">
              {Object.values(suborders)
                .filter((suborder) => Object.keys(suborder.items).length > 0)
                .map((suborder) => (
                  <SuborderSummary
                    key={suborder.suborderId}
                    accountInfo={accountInfo}
                    suborder={suborder}
                    onRenameClicked={() => {
                      showModal(
                        STrenameMealModal,
                        {
                          suborderId: suborder.suborderId,
                        },
                        `${ROUTES.CART}${ROUTES.ML_MEALRENAME}`
                      );
                    }}
                    onEditClicked={() => {
                      setActiveSuborder(suborder);
                      history.push(ROUTES.ORDER, "edit");
                    }}
                    onDeleteClicked={() => {
                      showModal(
                        STRemoveMealModal,
                        {
                          suborderId: suborder.suborderId,
                        },
                        `${ROUTES.CART}${ROUTES.ML_MEALDELETE}`
                      );
                    }}
                  />
                ))}

              {cartTotals.itemCount === 0 && (
                <div
                  className=""
                  style={{
                    margin: "auto",
                    color: "black",
                  }}
                >
                  {t("store.cart.emptyCart")}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="sTcartFooterWrap"
          style={{ backgroundColor: mainColorDark, color: color }}
        >
          <div className="sTcartFooter AppFontSizeSmall">
            <div
              onClick={
                allowMeals
                  ? () => {
                      addSuborder();
                      history.push(ROUTES.ORDER);
                    }
                  : () => {
                      history.push(ROUTES.ORDER, "edit");
                    }
              }
              className="sTcartFooterItem"
            >
              <Icon name="add" fill="#FFF" />
              {allowMeals ? (
                <span>&nbsp;{t("store.cart.addMeal")}</span>
              ) : (
                <span>&nbsp;{t("store.cart.addItem")}</span>
              )}
            </div>
            <div
              onClick={() => {
                history.push(ROUTES.CHECKOUT);
              }}
              className="sTcartFooterItem"
            >
              <Icon name="checkout" fill="#FFF" />
              &nbsp;{t("store.cart.checkout")}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default Cart;
