import React, { useState, useContext, FC } from "react";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import { FirebaseContext } from "../../../Firebase";
import {
  validateEmail,
  validatePhone,
} from "../../../utilities/validateEmailPassPhone";
import useToast from "../../Main/useToast";
import useSpinner from "../../Main/useSpinner";
import useLockDimensions from "../../Main/useLockDimensions";
import getOrderIdStrings from "../../../utilities/getOrderIdString";
import ModalVeil from "../../Modal/modalVeil";
import { useTranslation } from "react-i18next";
import * as APPCONSTANTS from "../../../utilities/constants/appConstants";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import useAccountInfo from "../../Main/useAccountInfo";
import { ModalProps } from "../../Modal/modalManager";
import uuid from "uuid/v4";
import { FirestoreUpdate } from "../../../utilities/firestoreUpdateType";
import { Message, MessageThread } from "../../../database/message";
import useModal from "../../Modal/useModal";
import STMessageThreadModal from "./sTmessageThread";
import { addMessageThreadToOrder } from "../../../utilities/httpsCallables/httpsCallables";
import StoreButton from "../Components/stButton";
import { useHistory } from "react-router-dom";
import { TRACK } from "../../../utilities/constants/routes";
import CommSettings from "../Components/commSettings";
import { hexToRgbString } from "../../../utilities/colorUtils";
export interface STSendMessageModalProps extends ModalProps {
  orderId: string | null;
}

const minLength = 1;
const maxLength = 500;

const STSendMessageModal: FC<STSendMessageModalProps> = React.memo(
  ({ orderId, closeModal }) => {
    const { user } = useAuthUser();
    const accountInfo = useAccountInfo();
    const toast = useToast();
    const showSpinner = useSpinner();
    const { t } = useTranslation();
    const lockDimensions = useLockDimensions();
    const firebase = useContext(FirebaseContext);
    const showModal = useModal();
    const history = useHistory();
    const store = useStore();
    const { theme, address } = store;
    const { mainColorDark, color, iconColor } = theme;

    const [phone, setPhone] = useState(accountInfo.phone);
    const [email, setEmail] = useState(user?.email ?? "");
    const emailIsValid = validateEmail(email);
    const phoneIsValid = phone.length === 0 || validatePhone(phone);
    const [message, setMessage] = useState("");
    const messageIsValid =
      message.trim().length > minLength && message.trim().length < maxLength;
    const [showCommSettings, setShowCommSettings] = useState(false);

    const onSubmit = () => {
      if (!emailIsValid) {
        toast({
          message: t("toast.badEmail"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
      } else if (!phoneIsValid) {
        toast({
          message: t("store.checkout.phoneInvalid"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
      } else if (!messageIsValid) {
        toast({
          message: t("toast.moreDetails"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
      } else {
        databaseAction();
      }
    };
    async function databaseAction() {
      const hideSpinner = showSpinner({ lag: "none" });
      const messageId = uuid();
      const newMessage: FirestoreUpdate<Message> = {
        messageId,
        from: "customer",
        message: message.trim(),
        timeSent: new Date(),
        resolved: false,
        read: false,
      };
      try {
        const threadId = uuid();
        const thread: FirestoreUpdate<MessageThread> = {
          threadId: threadId,
          locationId: store.locationId,
          userId: user?.uid ?? null,
          email: email.trim(),
          phone: phone.trim() ?? null,
          orderId,
          read: false,
          resolved: false,
          messages: {
            [messageId]: newMessage,
          },
          url: `${window.location.protocol}//${window.location.host}`,
        };
        const promises: Promise<any>[] = [];
        promises.push(
          firebase.firestore
            .collection("stores")
            .doc("store")
            .collection("messageThreads")
            .doc(threadId)
            .set(thread)
        );
        if (orderId) {
          promises.push(
            addMessageThreadToOrder(firebase, {
              orderId,
              threadId,
            })
          );
        }

        await Promise.all(promises);
        closeModal();
        if (user)
          showModal(STMessageThreadModal, {
            threadId: threadId,
          });
      } catch (error) {
        if (error.code === "permission-denied") {
          toast({
            message: t("common.permissionDenied"),
            className: "sTthemeAlert1b",
            duration: APPCONSTANTS.TOASTDURATION_ERROR,
          });
        } else {
          console.log("error sending messages", error);
          toast({
            message: t("toast.systemErrorCall"),
            className: "sTthemeAlert1b",
            duration: APPCONSTANTS.TOASTDURATION_ERROR,
          });
        }
      } finally {
        hideSpinner();
      }
    }
    const subtitle = orderId
      ? t("store.contactUs.orderSubTitle")
      : user
      ? t("store.contactUs.contactUsSubTitle2")
      : store.communication.useSMS
      ? t("store.contactUs.phoneNumberSms")
      : "";

    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            color: color,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader"
            style={{
              backgroundColor: mainColorDark,
            }}
          >
            {!orderId ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0.25em",
                }}
              >
                <Icon name="message" fill="#FFF" />
                &nbsp;
                {t("store.contactUs.message")}
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="AppFontSizeSmall">
                  <div>
                    {`${t("common.id")}: `}
                    <strong>{`${getOrderIdStrings(orderId).mainId}`}</strong>
                  </div>
                  <div>
                    {`${t("common.subId")}: `}
                    <strong>{`${getOrderIdStrings(orderId).subId}`}</strong>
                  </div>
                </div>
              </div>
            )}
            <div className="AppFontSizeSmall" style={{ color: color }}>
              <div style={{ fontWeight: "bold" }}>{subtitle}</div>
              {user && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                  }}
                >
                  {!orderId && (
                    <StoreButton
                      text={t("store.contactUs.viewDashboard")}
                      style={{ color: "white" }}
                      theme="white left"
                      iconColor="white"
                      icon="past"
                      onClick={() => {
                        history.replace(`${TRACK}`);
                      }}
                    />
                  )}
                  <StoreButton
                    text={t("store.contactUs.commSettings")}
                    style={{ color: "white", margin: 0 }}
                    theme="white left"
                    iconColor="white"
                    icon="flag"
                    onClick={() => {
                      setShowCommSettings(!showCommSettings);
                    }}
                  />
                </div>
              )}
              {showCommSettings && (
                <CommSettings
                  storeCommunication={store.communication}
                  notifyPush={accountInfo.notifyPush}
                  notifySms={accountInfo.notifySms}
                  email={email}
                  phone={phone}
                  t={t}
                />
              )}
              {address.phone && (
                <div style={{ color: color }}>
                  {t("store.contactUs.ratherCall")}&nbsp;
                  <a
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      color: color,
                    }}
                    href={"tel:" + address.phone}
                  >
                    {address.phone}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="sTmodalContent">
            <React.Fragment>
              {!user && (
                <React.Fragment>
                  <input
                    name="email"
                    type="email"
                    placeholder={t("signIn.signInPlaceholder")}
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    onFocus={() => lockDimensions(true, "sendMessage")}
                  />
                  <input
                    name="phone"
                    type="text"
                    placeholder={t("store.contactUs.enterPhonePlaceholder")}
                    value={phone}
                    onChange={(e) => setPhone(e.currentTarget.value)}
                    onFocus={() => lockDimensions(true, "sendMessage")}
                  />
                </React.Fragment>
              )}
              <textarea
                style={{
                  marginBottom: "0",
                  fontFamily: "Arial, Verdana, Geneva, Tahoma, sans-serif",
                }}
                name="message"
                rows={3}
                placeholder={t("store.orders.requestPlaceholder")}
                value={message}
                onChange={(e) => setMessage(e.currentTarget.value)}
                onFocus={() => lockDimensions(true, "sendMessage")}
                maxLength={500}
              />
              <div
                className="AppFontSizeSmall"
                style={{
                  marginLeft: "0.5em",
                  textAlign: "left",
                  marginBottom: "1em",
                  color: iconColor,
                }}
              >
                {t("common.charactersLeft", {
                  count: maxLength - message.length,
                })}
              </div>
              {!user && (
                <React.Fragment>
                  <div
                    className="AppFontSizeSmall"
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      color: emailIsValid ? iconColor : "orangered",
                    }}
                  >
                    <Icon
                      name={emailIsValid ? "done" : "close"}
                      fill={emailIsValid ? iconColor : "orangered"}
                    />
                    <span>{t("store.contactUs.provideValidEmail")}</span>
                  </div>
                  <div
                    className="AppFontSizeSmall"
                    style={{
                      opacity: phoneIsValid ? 0 : 1,
                      fontWeight: "bold",
                      transition: "all 0.3s linear 0s",
                      display: "flex",
                      alignItems: "center",
                      color: phoneIsValid ? iconColor : "orangered",
                    }}
                  >
                    <Icon
                      name={phoneIsValid ? "done" : "close"}
                      fill={phoneIsValid ? iconColor : "orangered"}
                    />
                    <span>{t("store.checkout.phoneInvalid")}</span>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
          <div
            className="sTmodalFooter AppFontSizeSmall"
            style={{
              backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
            }}
          >
            <div
              onClick={closeModal}
              className="sTmodalFooterItem AppFontSizeSmall"
              style={{ color: color }}
            >
              {t("common.cancel")}
            </div>
            <div
              style={{
                fontWeight: "bold",
                // opacity: messageIsValid && emailIsValid ? "1" : "0.3",
                transition: "all 0.5s ease 0s",
                color: color,
              }}
              onClick={onSubmit}
              className="sTmodalFooterItem AppFontSizeSmall"
            >
              {t("common.send")}
            </div>
          </div>
        </div>
        <ModalVeil onClick={closeModal} />
      </React.Fragment>
    );
  }
);

export default STSendMessageModal;
