import React, { FC, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import AddressForm from "./addressForm";
import { LocationAddress } from "../../../database/storeLocation";
import useStore from "../../../customization/useStore";
import useCart from "../../CartManager/useCart";
import { DhlRateRequest } from "../../../dhl/rateRequest";
import moment from "moment";
import notNull from "../../../utilities/notNull";
import { callDhl } from "../../../utilities/httpsCallables/httpsCallables";
import { TOASTDURATION_ERROR } from "../../../utilities/constants/appConstants";
import Icon from "../../Icon/icon";
import useTheme from "../../../customization/useTheme";
import useToast from "../../Main/useToast";
import { FirebaseContext } from "../../../Firebase";
import useSpinner from "../../Main/useSpinner";

export interface ShippingOptionsProps {}

const ShippingOptions: FC<ShippingOptionsProps> = (props) => {
  const { t } = useTranslation();
  const { items } = useStore();
  const { suborders } = useCart();
  const showSpinner = useSpinner();

  const [address, setAddress] = useState<LocationAddress>({
    city: "",
    countryCode: "",
    stateCode: "",
    streetLine1: "",
    zip: "",
  });

  const { locationId } = useStore();
  const { color, mainColorDark, mainColorLight } = useTheme();
  const toast = useToast();
  const firebase = useContext(FirebaseContext);

  const fetchShippingOptions = async () => {
    // TODO: this is just a quick way to get an example value for the uom.
    //   need a better solution
    const temp =
      items[Object.values(Object.values(suborders)[0].items)[0].itemId];

    const payload: DhlRateRequest = {
      RateRequest: {
        ClientDetails: null,
        RequestedShipment: {
          DropOffType: "REGULAR_PICKUP",
          // TODO: when should this be for? Might need to be configurable
          ShipTimestamp: moment().add(1, "day").toISOString(),
          //TODO: what if they used different units for each item? gotta conver to a common unit
          UnitOfMeasurement: temp?.shippingInfo?.unit || "SI",
          Content: "NON_DOCUMENTS",
          // client side does not know the account number. This will be
          //   replaced by the server side with the real account.
          Account: 0,
          Ship: {
            Shipper: {
              City: address.city,
              PostalCode: address.zip,
              CountryCode: address.countryCode,
              StreetLines: address.streetLine1,
              StreetLines2: address.streetLine2,
              StreetLines3: address.streetLine3,
              StateOrProvinceCode: address.stateCode,
            },
            Recipient: {
              City: address.city,
              PostalCode: address.zip,
              CountryCode: address.countryCode,
              StreetLines: address.streetLine1,
              StreetLines2: address.streetLine2,
              StreetLines3: address.streetLine3,
              StateOrProvinceCode: address.stateCode,
            },
          },
          Packages: Object.values(Object.values(suborders)[0].items)
            .map((cartItem, i) => {
              const catalogItem = items[cartItem.itemId];
              if (!catalogItem) {
                return null;
              }
              // TODO: account for the possibility that items
              //   don't have shipping info
              return {
                RequestedPackages: {
                  "@number": i,
                  Weight: {
                    Value: catalogItem.shippingInfo?.weight ?? 0,
                  },
                  Dimensions: {
                    Length: catalogItem.shippingInfo?.length ?? 0,
                    Width: catalogItem.shippingInfo?.width ?? 0,
                    Height: catalogItem.shippingInfo?.height ?? 0,
                  },
                },
              };
            })
            .filter(notNull),
        },
      },
    };

    const hideSpinner = showSpinner();
    try {
      const result = await callDhl(firebase, {
        type: "rateRequest",
        locationId,
        data: payload,
      });
      console.log(result);
      const provider = result.data.RateResponse.Provider[0];
      // zero indicates success, >0 indicates error
      let error = provider.Notification.find(
        (notif) => Number(notif["@code"]) > 0
      );
      if (error) {
        toast({
          message: error.Message,
          className: "dBthemeAlert1",
          duration: TOASTDURATION_ERROR,
        });
        return;
      } else {
        //TODO: do stuff with the results
      }
    } catch (error) {
      if (error.code === "permission-denied") {
        toast({
          message: t("common.permissionDenied"),
          className: "dBthemeAlert1",
          duration: TOASTDURATION_ERROR,
        });
      } else {
        toast({
          message: t("toast.systemErrorCall"),
          className: "dBthemeAlert1",
          duration: TOASTDURATION_ERROR,
        });
      }
    } finally {
      hideSpinner();
    }
  };

  return (
    <div className="sTcheckoutContentsWrap">
      <div className="sTcheckoutContents2">
        <AddressForm
          style={{ width: "95%", maxWidth: "20em" }}
          address={address}
          onAddressChanged={setAddress}
        />
        <div
          onClick={fetchShippingOptions}
          style={{
            backgroundColor: mainColorDark,
            color: color,
            marginTop: "0.5em",
            width: "100%",
          }}
          className="sTbutton"
        >
          <div
            style={{
              backgroundColor: mainColorLight,
            }}
            className="sTbuttonIcon"
          >
            <Icon name="in_transit" fill={color} />
          </div>
          <span className="sTbuttonText AppFontSizeNormal">
            Get shipping options
          </span>
          <div />
        </div>
      </div>
    </div>
  );
};

export default ShippingOptions;
