import { NumberOption, optionType } from "../../../database/option";
import uuid from "uuid/v4";
import { TFunction } from "i18next";

export const createNumberOption = (
  name = "",
  optionId?: string
): NumberOption => ({
  name,
  type: optionType.NUMBER,
  required: false,
  optionId: optionId || uuid(),
  minValue: 0,
  maxValue: 1000000,
  defaultValue: 0,
  increment: 1,
  unit: "",
  price: 0,
  priceString: "",
  priceDenominator: 1,
});

export const validateNumberOption = (option: NumberOption, t: TFunction) => {
  if (option.price < 0) {
    return t("dashboard.customFields.errorInvalidPrice");
  } else if (option.priceDenominator <= 0) {
    return t("dashboard.customFields.errorInvalidDenominator");
  } else if (option.minValue >= option.maxValue) {
    return t("dashboard.customFields.errorMinVsMax");
  } else if (option.defaultValue < option.minValue) {
    return t("dashboard.customFields.errorDefaultToSmall");
  } else if (option.defaultValue > option.maxValue) {
    return t("dashboard.customFields.errorDefaultToLarge");
  } else if (option.increment <= 0) {
    return t("dashboard.customFields.errorInvalidIncrement");
  } else if (
    Math.round(option.defaultValue / option.increment) * option.increment !==
    option.defaultValue
  ) {
    return t("dashboard.customFields.errorDefaultMismatchesIncrement", {
      defaultValue: option.defaultValue,
      increment: option.increment,
    });
  }
};
