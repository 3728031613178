import { createContext, useContext } from "react";
import { StoreLocation } from "../../../database/storeLocation";
import { PrivateSettings } from "../../../database/privateSettings";

export interface SettingsApi {
  /**
   * The copy of the store location which is being edited
   */
  location: StoreLocation;
  /**
   * The store location currently in the database
   */
  realLocation: StoreLocation;
  setLocation: React.Dispatch<React.SetStateAction<StoreLocation>>;
  imagesToDelete: {
    oldImages: string[];
    newImages: string[];
  };
  setImagesToDelete: React.Dispatch<
    React.SetStateAction<{
      oldImages: string[];
      newImages: string[];
    }>
  >;
  locationDirty: boolean;
  /**
   * The copy of private settings which is being edited. Null indicates
   * it has not yet been downloaded
   */
  privateSettings: PrivateSettings | null;
  /**
   * The private settings currently in the database. Null indicates
   * it has not yet been downloaded
   */
  setPrivateSettings: React.Dispatch<
    React.SetStateAction<PrivateSettings | null>
  >;
  privateSettingsDirty: boolean;
  realPrivateSettings: PrivateSettings | null;
  valid: boolean;
  /**
   * A collection of errors with the current data. The key of this object
   * should be a url path to a page that can address the error.
   *
   * Currently the value is just a string, but if we add more complex
   * validation it may need to be something with more details that the
   * page can use to display the correct error information.
   */
  validationErrors: Record<string, string>;
  setValidationErrors: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
}

export const SettingsApiContext = createContext<SettingsApi>({} as SettingsApi);

export const useSettingsApi = () => useContext(SettingsApiContext);
