import React, { useState, FC } from "react";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import {
  validateEmail,
  validatePhone,
} from "../../../utilities/validateEmailPassPhone";
import useLockDimensions from "../../Main/useLockDimensions";
import ModalVeil from "../../Modal/modalVeil";
import { ModalProps } from "../../Modal/modalManager";
import { useTranslation } from "react-i18next";
import { GuestDetails } from "../Pages/checkout";
import { hexToRgbString } from "../../../utilities/colorUtils";
interface STguestInfoModalProps extends ModalProps {
  initialGuestDetails: GuestDetails;
  onSubmit: (guestDetails: GuestDetails) => void;
  onCancel?: () => void;
  /** shows an extra button to back out of guest checkout */
  showAbort: boolean;
  onAbort?: () => void;
  shouldGoBack?: boolean;
}

const STguestInfoModal: FC<STguestInfoModalProps> = React.memo(
  ({
    initialGuestDetails: guestDetails,
    onSubmit,
    onCancel = () => {},
    shouldGoBack,
    showAbort,
    onAbort = () => {},
    closeModal,
  }) => {
    const lockDimensions = useLockDimensions();
    const store = useStore();
    const { t } = useTranslation();
    const { theme } = store;
    const { color, mainColorDark } = theme;
    const [guestInfo, setGuestInfo] = useState<GuestDetails>(guestDetails);
    const emailIsValid = validateEmail(guestInfo.email.trim());
    const phoneIsValid = //IF NOT FILLED IN ITS VALID, ELSE ONLY VALID IF IT PASSES THE TEST
      guestInfo.phone.trim().length === 0 ||
      validatePhone(guestInfo.phone.trim());

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      if (emailIsValid && phoneIsValid) {
        onSubmit({
          email: guestInfo.email.trim(),
          phone: guestInfo.phone.trim(),
        });
      } else {
        onCancel();
      }
      closeModal(shouldGoBack);
    };

    return (
      <React.Fragment>
        <form>
          <div
            style={{
              display: "block",
              color: color,
            }}
            className="sTmodal boxShadow5"
          >
            <div
              className="sTmodalHeader"
              style={{
                backgroundColor: mainColorDark,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    color: color,
                  }}
                >
                  <Icon name="help" fill={color} />
                  <div>&nbsp;{t("store.checkout.reachCustomer")} &nbsp;</div>
                </div>
              </div>
              <div className="AppFontSizeSmall" style={{ color: color }}>
                {t("guestCheckout.getintouchMessage")}
              </div>
              {store.communication.useSMS && store.communication.SMSAuto && (
                <div className="AppFontSizeSmall" style={{ color: color }}>
                  {t("guestCheckout.phoneNumberSms")}
                </div>
              )}
            </div>
            <div className="sTmodalContent">
              <div
                style={{
                  margin: "0",
                }}
              >
                <input
                  name="email"
                  value={guestInfo.email}
                  onChange={(e) => {
                    const newEmail = e.currentTarget.value;
                    setGuestInfo((prev) => ({
                      email: newEmail,
                      phone: prev.phone,
                    }));
                  }}
                  type="email"
                  placeholder={t("guestCheckout.emailRequiredPlaceholder")}
                  onFocus={() => lockDimensions(true, "guest checkout")}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      handleSubmit(e);
                    }
                  }}
                />
                <br />
                <input
                  name="phone"
                  value={guestInfo.phone}
                  onChange={(e) => {
                    const newPhone = e.currentTarget.value;
                    setGuestInfo((prev) => ({
                      email: prev.email,
                      phone: newPhone,
                    }));
                  }}
                  type="text"
                  placeholder={t("guestCheckout.phoneOptionalPlaceholder")}
                  onFocus={() => lockDimensions(true, "guest checkout")}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      handleSubmit(e);
                    }
                  }}
                />
              </div>
              <div
                className="AppFontSizeSmall"
                style={{
                  margin: "0.25em",
                  display: "flex",
                  flexDirection: "column",
                  opacity: !emailIsValid ? 1 : 0,
                  transition: "all 0.5s ease 0.3s",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    transition: "all 0.5s ease 0s",
                    color: emailIsValid ? "black" : "red",
                  }}
                >
                  <Icon
                    name={emailIsValid ? "done" : "close"}
                    fill={emailIsValid ? "black" : "red"}
                  />

                  {guestInfo.email.length > 0
                    ? emailIsValid
                      ? t("store.checkout.emailValid")
                      : t("store.checkout.emailInvalid")
                    : t("store.checkout.emailRequired")}
                </span>
              </div>
              <div
                className="AppFontSizeSmall"
                style={{
                  margin: "0.25em",
                  display: "flex",
                  flexDirection: "column",
                  opacity: !phoneIsValid ? 1 : 0,
                  transition: "all 0.5s ease 0.3s",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    transition: "all 0.5s ease 0s",
                    color: phoneIsValid ? "black" : "red",
                  }}
                >
                  <Icon
                    name={phoneIsValid ? "done" : "close"}
                    fill={phoneIsValid ? "black" : "red"}
                  />

                  {phoneIsValid
                    ? t("store.checkout.phoneValid")
                    : t("store.checkout.phoneInvalid")}
                </span>
              </div>
            </div>
            <div
              className="sTmodalFooter AppFontSizeSmall"
              style={{
                backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
              }}
            >
              {showAbort ? (
                <div
                  style={{
                    fontWeight: "bold",
                    transition: "all 0.5s ease 0s",
                    textAlign: "center",
                    color: color,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    onAbort();
                    setTimeout(() => {
                      closeModal(shouldGoBack);
                    }, 300);
                  }}
                  className="sTmodalFooterItem AppFontSizeSmall"
                >
                  {t("common.signIn").toUpperCase()}
                </div>
              ) : (
                <div
                  style={{
                    transition: "all 0.5s ease 0s",
                    textAlign: "right",
                    color: color,
                    opacity:
                      guestInfo.email.length > 0 && emailIsValid && phoneIsValid
                        ? 1
                        : 0,
                  }}
                  onClick={closeModal}
                  className="sTmodalFooterItem AppFontSizeSmall"
                >
                  {t("common.cancel")}
                </div>
              )}
              <div
                style={{
                  fontWeight: "bold",
                  // opacity: guestInfo.emailValid ? 1 : 0.3,
                  transition: "all 0.5s ease 0s",
                  textAlign: "center",
                  color: color,
                }}
                onClick={(e) => handleSubmit(e)}
                className="sTmodalFooterItem AppFontSizeSmall"
              >
                {emailIsValid && phoneIsValid
                  ? t("guestCheckout.checkOutGuestButton")
                  : t("common.cancel")}
              </div>
            </div>
          </div>
        </form>
        <ModalVeil onClick={closeModal} />
      </React.Fragment>
    );
  }
);

export default STguestInfoModal;
