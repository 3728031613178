import Firebase from "../../Firebase";
import {
  SendXenditChargePayload,
  OrderRechargeUpdatePayload,
  NotifyUserPayload,
  RechargeStripeCustomerPayload,
  RechargeXenditCustomerPayload,
  StorePendingOrderPayload,
  AddMessageThreadToOrderPayload,
  GetStripePaymentIntentPayload,
  PaymentAuthUtilsPayload,
  CreateFirestoreBackupPayload,
  RateRequestPayload,
  DhlRequestPayload,
  DhlResponse,
  ShippingRequestPayload
} from "./types";
import { StripePaymentIntent } from "../../components/Store/Checkout/paymentForm";
import { DhlRateResponse } from "../../dhl/rateRequest";
import { DhlShipmentResponse } from "../../dhl/shipmentRequest";

/**
 * This file adds some typesafety around using firebase's httpsCallable.
 * These helper methods define strict types about what can be sent, and
 * this in turn allows the function code to assume that that is what it
 * received.
 */

export const sendXenditCharge = (
  firebase: Firebase,
  data: SendXenditChargePayload
) => {
  return firebase.functions.httpsCallable("sendXenditCharge")(data);
};

export const getStripePaymentIntent = async (
  firebase: Firebase,
  data: GetStripePaymentIntentPayload
): Promise<StripePaymentIntent> => {
  const response = await firebase.functions.httpsCallable(
    "getStripePaymentIntent"
  )(data);
  return {
    amount: data.amount,
    clientSecret: response.data.clientSecret,
    id: response.data.id
  };
};

export const paymentAuthUtils = async (
  firebase: Firebase,
  data: PaymentAuthUtilsPayload
) => {
  const response = await firebase.functions.httpsCallable("paymentAuthUtils")(
    data
  );
  return response.data;
};

export const storePendingOrder = (
  firebase: Firebase,
  data: StorePendingOrderPayload
) => {
  return firebase.functions.httpsCallable("storePendingOrder")(data);
};

export const orderRechargeUpdate = (
  firebase: Firebase,
  data: OrderRechargeUpdatePayload
) => {
  return firebase.functions.httpsCallable("orderRechargeUpdate")(data);
};

export const emailOrderReceipt = (
  firebase: Firebase,
  data: OrderRechargeUpdatePayload
) => {
  return firebase.functions.httpsCallable("emailOrderReceipt")(data);
};

export const notifyUser = (firebase: Firebase, data: NotifyUserPayload) => {
  return firebase.functions.httpsCallable("notifyUser")(data);
};

export const rechargeStripeCustomer = (
  firebase: Firebase,
  data: RechargeStripeCustomerPayload
) => {
  return firebase.functions.httpsCallable("rechargeStripeCustomer")(data);
};

export const rechargeXenditCustomer = (
  firebase: Firebase,
  data: RechargeXenditCustomerPayload
) => {
  return firebase.functions.httpsCallable("rechargeXenditCustomer")(data);
};

/**
 * For the most part, sending messages does not require contacting the server.
 * But updating an order is privileged, so the act of connecting a message
 * thread to an order needs to be done server side to prevent other tampering
 * with the order.
 */
export const addMessageThreadToOrder = (
  firebase: Firebase,
  data: AddMessageThreadToOrderPayload
) => {
  return firebase.functions.httpsCallable("addMessageThreadToOrder")(data);
};

export const createFirestoreBackup = (
  firebase: Firebase,
  data: CreateFirestoreBackupPayload
) => {
  return firebase.functions.httpsCallable("createFirestoreBackup")(data);
};

export function callDhl(
  firebase: Firebase,
  payload: ShippingRequestPayload
): Promise<{ data: DhlShipmentResponse }>;
export function callDhl(
  firebase: Firebase,
  payload: RateRequestPayload
): Promise<{ data: DhlRateResponse }>;
export function callDhl(
  firebase: Firebase,
  payload: DhlRequestPayload
): Promise<{ data: DhlResponse }> {
  return firebase.functions.httpsCallable("callDhl")(payload);
}
