import React, { FC, useContext, useMemo, useState, useEffect } from "react";
import { StoreLocationContext } from "../../../customization/storeContext";
import { useStateWithConflictResolution } from "../Inventory/useStateWithConflictResolution";
import * as R from "ramda";
import { SettingsApiContext } from "./settingsApiContext";
import { PrivateSettings } from "../../../database/privateSettings";
import { FirebaseContext } from "../../../Firebase";
import useStore from "../../../customization/useStore";
import useAuthUser from "../../AuthUserProvider/useAuthUser";

const SettingsApiProvider: FC<{}> = (props) => {
  const { locationId } = useStore();
  const firebase = useContext(FirebaseContext);
  const realLocation = useContext(StoreLocationContext);
  const [imagesToDelete, setImagesToDelete] = useState<{
    oldImages: string[];
    newImages: string[];
  }>({ oldImages: [], newImages: [] });
  const [location, setLocation] = useStateWithConflictResolution(realLocation);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const [
    realPrivateSettings,
    setRealPrivateSettings,
  ] = useState<null | PrivateSettings>(null);
  const [privateSettings, setPrivateSettings] = useStateWithConflictResolution(
    realPrivateSettings
  );

  const { isCustomer } = useAuthUser();
  useEffect(() => {
    if (isCustomer) {
      return;
    }
    const doc = firebase.firestore
      .collection("stores")
      .doc("store")
      .collection("locations")
      .doc(locationId)
      .collection("private")
      .doc("privateSettings");

    const unsubscribe = doc.onSnapshot(
      (doc) => {
        if (!doc.exists) {
          // create blank private settings
          const privateSettings: PrivateSettings = {
            dhl: {
              accountNumber: "",
              username: "",
              password: "",
            },
          };
          doc.ref.set(privateSettings);
        } else {
          setRealPrivateSettings(doc.data() as PrivateSettings);
        }
      },
      (err) => {
        console.log("error subscribing to private settings", err);
      }
    );

    return unsubscribe;
  }, [firebase, isCustomer, locationId, setPrivateSettings]);

  const value = useMemo(
    () => ({
      location,
      setLocation,
      imagesToDelete,
      setImagesToDelete,
      realLocation,
      locationDirty: !R.equals(location, realLocation),
      privateSettings,
      setPrivateSettings,
      realPrivateSettings,
      privateSettingsDirty: !R.equals(privateSettings, realPrivateSettings),
      validationErrors,
      setValidationErrors,
      valid: Object.keys(validationErrors).length === 0,
    }),
    [
      location,
      privateSettings,
      realLocation,
      realPrivateSettings,
      setLocation,
      imagesToDelete,
      setImagesToDelete,
      setPrivateSettings,
      validationErrors,
    ]
  );

  return (
    <SettingsApiContext.Provider value={value}>
      {props.children}
    </SettingsApiContext.Provider>
  );
};

export default SettingsApiProvider;
