import React, { useState, useEffect, FC, useContext } from "react";
import usePrevious from "../../../utilities/usePrevious";
import "../css/sTtrack.css";
import Icon from "../../Icon/icon";
import useCart from "../../CartManager/useCart";
import useModal from "../../Modal/useModal";
import STpaySuccessModal from "../Modals/sTpaySuccess";
import * as ROUTES from "../../../utilities/constants/routes";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { Order, orderStatus } from "../../../database/order";
import TrackingSummary from "../Track/trackingSummary";
import TrackingDetail from "../Track/trackingDetail";
import { useTranslation } from "react-i18next";
import useAccountInfo from "../../Main/useAccountInfo";
import SectionSignedOut from "../Components/sectionSignedOut";
import { FirebaseContext } from "../../../Firebase";
// import useSpinner from "../../Main/useSpinner";

interface TrackProps {}

const Track: FC<TrackProps> = React.memo(() => {
  const accountInfo = useAccountInfo();
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const showModal = useModal();
  // const showSpinner = useSpinner();
  const firebase = useContext(FirebaseContext);

  const { clearCart, justPaid } = useCart();

  useEffect(() => {
    if (justPaid) {
      // They just came from paying. Show a success modal, then clear flag
      showModal(
        STpaySuccessModal,
        {},
        `${ROUTES.TRACK}${ROUTES.ML_PAYSUCCESS}`
      );
      clearCart();
    }
  }, [clearCart, justPaid, showModal]);

  // const fakeOrder: Order = {
  //   orderId: "",
  //   currency: {
  //     currency: "EUR",
  //     locale: "en-US",
  //     precision: 2,
  //     roundingMode: "HALF_AWAY_FROM_ZERO",
  //   },
  //   customerAddress: "",
  //   customerEmail: "",
  //   customerPhone: "",
  //   delivery: {
  //     delivery: false,
  //     timeCompleted: null,
  //     timeCreated: firebase.Timestamp.fromDate(new Date()),
  //     timeCreated_locale: "",
  //     timeHandedoff: null,
  //     timePlanned: firebase.Timestamp.fromDate(new Date()),
  //     timePlannedOrig: firebase.Timestamp.fromDate(new Date()),
  //     timeRequested: "ASAP",
  //     timeRequested_locale: "ASAP",
  //   },
  //   invoiceCode: "",
  //   locationId: "",
  //   suborders: {
  //     1: {
  //       items: {
  //         2: {
  //           basePrice: 750,
  //           cartItemId: "2",
  //           checked: true,
  //           instructions: "",
  //           itemId: "38acb3c1-1150-4ac9-9475-107a244e6495",
  //           optionSelections: [],
  //           priceWithOptions: 750,
  //           taxPercentage: 11.5,
  //         },
  //       },
  //       name: "Meal 1",
  //       suborderId: "1",
  //     },
  //   },
  //   paymentChargeToken: "",
  //   paymentGateway: paymentGateway.stripe,
  //   paymentToken: "",
  //   paymentMethod: "bancontact",
  //   paymentCustomer: "",
  //   status: orderStatus.inqueue,
  //   statusCompleted: false,
  //   tax: 0,
  //   taxTable: { 11.5: 86 },
  //   total: 0,
  //   userId: "",
  // };
  // const fakeOrders: Order[] = [];
  // for (let a = 0; a < 24; a++) {
  //   const order = { ...fakeOrder };
  //   order.orderId = `${a}`;
  //   fakeOrders.push(order);
  // }
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");

  const selectedOrder = orders.find((order) => order.orderId === selectedId);
  useEffect(() => {
    if (!user) {
      setOrders([]);
      setSelectedId("");
      return;
    }
    // const hideSpinner = showSpinner({ lag: "none" });
    let unsubscribe = firebase.firestore
      .collection("stores")
      .doc("store")
      .collection("orders")
      .where("userId", "==", user.uid)
      .where("status", "in", [
        orderStatus.inqueue,
        orderStatus.delivered,
        orderStatus.inprogress,
        orderStatus.intransit,
        orderStatus.ready,
      ])
      .orderBy("delivery.timeCreated", "desc")
      .onSnapshot(
        (snapshot) => {
          // hideSpinner();
          const orders: Order[] = [];
          snapshot.forEach((doc) => {
            orders.push(doc.data() as Order);
          });
          setOrders(orders);
        },
        (error) => {
          // hideSpinner();
          setOrders([]);
        }
      );
    return () => {
      unsubscribe();
      // hideSpinner();
    };
  }, [user, firebase.firestore]);

  const prevLength = usePrevious(orders.length);
  useEffect(() => {
    if ((orders && !selectedId) || (orders && orders.length !== prevLength)) {
      setSelectedId(orders[0]?.orderId ?? "");
    }
  }, [orders, selectedId, prevLength]);
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (selectedOrder) {
      const id = setTimeout(() => {
        setShow(false);
      }, 1000);
      return () => clearTimeout(id);
    } else {
      const id = setTimeout(() => {
        setShow(true);
      }, 0);
      return () => clearTimeout(id);
    }
  }, [selectedOrder]);

  return (
    <React.Fragment>
      <div className="sTtrackOuterWrap sTthemeBGcolorSoft">
        <div className="sTtrackHeader"></div>
        <div className="sTtrackContentsWrap ">
          {user ? (
            <React.Fragment>
              <div className="sTtrackContents1">
                <div className="sTtrackContents1b">
                  {orders.length > 0 &&
                    orders.map((order) => (
                      <TrackingSummary
                        key={order.orderId}
                        order={order}
                        selected={!!(selectedId === order.orderId)}
                        onClick={() => setSelectedId(order.orderId)}
                      />
                    ))}
                </div>
              </div>
              <div className="sTtrackContents2">
                {selectedOrder && (
                  <TrackingDetail
                    order={selectedOrder}
                    accountInfo={accountInfo}
                  />
                )}
                {show && (
                  <div
                    className={
                      orders.length > 0 ? "sT_anim_fadeOut_0300" : undefined
                    }
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                      display: "flex",
                      flexDirection: "column",
                      width: "95%",
                      alignItems: "center",
                      zIndex: 1,
                    }}
                  >
                    {t("store.track.emptyMessage")}
                    <Icon name="past" />
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : (
            <div className="sTtrackContents2_so">
              <div style={{ margin: "1.75em auto", width: "100%" }}>
                <SectionSignedOut source="track" />
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
});

export default Track;
