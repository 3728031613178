import React, { FC } from "react";
import SettingsApiProvider from "./settingsApiProvider";
import CatalogApiProvider from "../Catalog/catalogApiProvider";

/**
 * If an admin is logged in, renders the providers for managing
 * editing state.
 */
const EditingProvider: FC<{}> = ({ children }) => {
  // Create the global editing apis. These are done at the top of the component
  //   tree so they can be retained when navigating to other pages
  return (
    <SettingsApiProvider>
      <CatalogApiProvider>{children}</CatalogApiProvider>
    </SettingsApiProvider>
  );
};

export default EditingProvider;
