import React, { useState, useEffect, FC } from "react";
import { StripeProvider } from "react-stripe-elements";
import useStore from "../../customization/useStore";
import { STRIPE } from "./constants";
import loadScript from "../../utilities/loadScript";
import { PaymentContext, PaymentState } from "./usePayment";

const PaymentProvider: FC = ({ children }) => {
  const { paymentGateway } = useStore();
  const [value, setValue] = useState<PaymentState>({
    loading: true,
    type: paymentGateway,
    stripe: null,
    xendit: null
  });

  useEffect(() => {
    (async () => {
      if (paymentGateway === STRIPE) {
        // console.log("paymentProvider.tsx", process.env.REACT_APP_FRBS_STRIPE);
        await loadScript("https://js.stripe.com/v3/");
        setValue({
          loading: false,
          type: paymentGateway,
          stripe: (window as any).Stripe(process.env.REACT_APP_FRBS_STRIPE),
          xendit: null
        });
      } else {
        await loadScript("https://js.xendit.co/v1/xendit.min.js");
        (window as any).Xendit.setPublishableKey(
          process.env.REACT_APP_FRBS_XENDIT
        );
        setValue({
          loading: false,
          type: paymentGateway,
          stripe: null,
          xendit: (window as any).Xendit
        });
      }
    })();
  }, [paymentGateway]);

  if (paymentGateway === STRIPE) {
    return (
      <PaymentContext.Provider value={value}>
        <StripeProvider stripe={value.stripe}>{children}</StripeProvider>
      </PaymentContext.Provider>
    );
  } else {
    return (
      <PaymentContext.Provider value={value}>
        {children}
      </PaymentContext.Provider>
    );
  }
};

export default PaymentProvider;
