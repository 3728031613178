import React from "react";
import { IconName } from "../../Icon/IconName";

export interface DBinputFieldButtonProps {
  style: React.CSSProperties;
  text: string | React.ReactElement;
  icon?: IconName;
  iconContainerColor?: string;
}

const DBinputFieldButton = React.forwardRef<
  HTMLDivElement,
  DBinputFieldButtonProps
>(({ style, text, icon = "no_icon", iconContainerColor }, ref) => {
  return (
    <div ref={ref} style={style} className="dBthemeBGcolor7">
      {text}
    </div>
  );
});

export default DBinputFieldButton;
