import React, { FC } from "react";
import "../App/App.css";
import { IconName } from "./IconName";

const getData = (name: IconName) => {
  switch (name) {
    case "triangle_left":
      return {
        path1: "M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z",
        title: "Arrow Back",
      };
    case "triangle_right":
      return {
        path1: "M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z",
        title: "Arrow Forward",
      };
    case "arrow_forward":
      return {
        path1: "M 16.01 11 H 4 v 2 h 12.01 v 3 L 20 12 l -3.99 -4 Z",
        title: "Arrow Forward",
      };
    case "arrow_forward2":
      return {
        path1:
          "M 12 4 l -1.41 1.41 L 16.17 11 H 4 v 2 h 12.17 l -5.58 5.59 L 12 20 l 8 -8 Z",
        title: "Arrow Forward",
      };
    case "arrow_back":
      return {
        path1: "M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z",
        title: "Go Back",
      };
    case "arrow_back2":
      return {
        path1:
          "M 20 11 H 7.83 l 5.59 -5.59 L 12 4 l -8 8 l 8 8 l 1.41 -1.41 L 7.83 13 H 20 v -2 Z",
        title: "Go Back",
      };
    case "arrow_up":
      return {
        path1:
          "M 4 12 l 1.41 1.41 L 11 7.83 V 20 h 2 V 7.83 l 5.58 5.59 L 20 12 l -8 -8 l -8 8 Z",
        title: "Arrow Upward",
      };
    case "arrow_down":
      return {
        path1:
          "M 20 12 l -1.41 -1.41 L 13 16.17 V 4 h -2 v 12.17 l -5.58 -5.59 L 4 12 l 8 8 l 8 -8 Z",
        title: "Arrow Downward",
      };
    case "next_step":
      return {
        path1:
          "M 11.59 7.41 L 15.17 11 H 1 v 2 h 14.17 l -3.59 3.59 L 13 18 l 6 -6 l -6 -6 l -1.41 1.41 Z M 20 6 v 12 h 2 V 6 h -2 Z",
        title: "Next Step",
      };
    case "background_image":
      return {
        path1:
          "M 2 6 H 0 v 5 h 0.01 L 0 20 c 0 1.1 0.9 2 2 2 h 18 v -2 H 2 V 6 Z m 20 -2 h -8 l -2 -2 H 6 c -1.1 0 -1.99 0.9 -1.99 2 L 4 16 c 0 1.1 0.9 2 2 2 h 16 c 1.1 0 2 -0.9 2 -2 V 6 c 0 -1.1 -0.9 -2 -2 -2 Z M 7 15 l 4.5 -6 l 3.5 4.51 l 2.5 -3.01 L 21 15 H 7 Z",
        title: "Background Image",
      };
    case "build":
      return {
        path1:
          "M 22.7 19 l -9.1 -9.1 c 0.9 -2.3 0.4 -5 -1.5 -6.9 c -2 -2 -5 -2.4 -7.4 -1.3 L 9 6 L 6 9 L 1.6 4.7 C 0.4 7.1 0.9 10.1 2.9 12.1 c 1.9 1.9 4.6 2.4 6.9 1.5 l 9.1 9.1 c 0.4 0.4 1 0.4 1.4 0 l 2.3 -2.3 c 0.5 -0.4 0.5 -1.1 0.1 -1.4 Z",
        title: "Build",
      };
    case "refresh":
      return {
        path1:
          "M 17.65 6.35 C 16.2 4.9 14.21 4 12 4 c -4.42 0 -7.99 3.58 -7.99 8 s 3.57 8 7.99 8 c 3.73 0 6.84 -2.55 7.73 -6 h -2.08 c -0.82 2.33 -3.04 4 -5.65 4 c -3.31 0 -6 -2.69 -6 -6 s 2.69 -6 6 -6 c 1.66 0 3.14 0.69 4.22 1.78 L 13 11 h 7 V 4 l -2.35 2.35 Z",
        title: "Refresh",
      };
    case "copy":
      return {
        path1:
          "M 16 1 H 4 c -1.1 0 -2 0.9 -2 2 v 14 h 2 V 3 h 12 V 1 Z m -1 4 l 6 6 v 10 c 0 1.1 -0.9 2 -2 2 H 7.99 C 6.89 23 6 22.1 6 21 l 0.01 -14 c 0 -1.1 0.89 -2 1.99 -2 h 7 Z m -1 7 h 5.5 L 14 6.5 V 12 Z",
        title: "Copy",
      };
    case "hottub":
      return {
        path1:
          "M 11.15 12 c -0.31 -0.22 -0.59 -0.46 -0.82 -0.72 l -1.4 -1.55 c -0.19 -0.21 -0.43 -0.38 -0.69 -0.5 c -0.29 -0.14 -0.62 -0.23 -0.96 -0.23 h -0.03 C 6.01 9 5 10.01 5 11.25 V 12 H 2 v 8 c 0 1.1 0.9 2 2 2 h 16 c 1.1 0 2 -0.9 2 -2 v -8 H 11.15 Z M 7 20 H 5 v -6 h 2 v 6 Z m 4 0 H 9 v -6 h 2 v 6 Z m 4 0 h -2 v -6 h 2 v 6 Z m 4 0 h -2 v -6 h 2 v 6 Z m -0.35 -14.14 l -0.07 -0.07 c -0.57 -0.62 -0.82 -1.41 -0.67 -2.2 L 18 3 h -1.89 l -0.06 0.43 c -0.2 1.36 0.27 2.71 1.3 3.72 l 0.07 0.06 c 0.57 0.62 0.82 1.41 0.67 2.2 l -0.11 0.59 h 1.91 l 0.06 -0.43 c 0.21 -1.36 -0.27 -2.71 -1.3 -3.71 Z m -4 0 l -0.07 -0.07 c -0.57 -0.62 -0.82 -1.41 -0.67 -2.2 L 14 3 h -1.89 l -0.06 0.43 c -0.2 1.36 0.27 2.71 1.3 3.72 l 0.07 0.06 c 0.57 0.62 0.82 1.41 0.67 2.2 l -0.11 0.59 h 1.91 l 0.06 -0.43 c 0.21 -1.36 -0.27 -2.71 -1.3 -3.71 Z",
        title: "Hot Tub",
      };
    case "drag":
      return {
        path1:
          "M 11 18 c 0 1.1 -0.9 2 -2 2 s -2 -0.9 -2 -2 s 0.9 -2 2 -2 s 2 0.9 2 2 Z m -2 -8 c -1.1 0 -2 0.9 -2 2 s 0.9 2 2 2 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z m 0 -6 c -1.1 0 -2 0.9 -2 2 s 0.9 2 2 2 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z m 6 4 c 1.1 0 2 -0.9 2 -2 s -0.9 -2 -2 -2 s -2 0.9 -2 2 s 0.9 2 2 2 Z m 0 2 c -1.1 0 -2 0.9 -2 2 s 0.9 2 2 2 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z m 0 6 c -1.1 0 -2 0.9 -2 2 s 0.9 2 2 2 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z",
        title: "Drag",
      };
    case "image":
      return {
        path1:
          "M 21 19 V 5 c 0 -1.1 -0.9 -2 -2 -2 H 5 c -1.1 0 -2 0.9 -2 2 v 14 c 0 1.1 0.9 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 Z M 8.5 13.5 l 2.5 3.01 L 14.5 12 l 4.5 6 H 5 l 3.5 -4.5 Z",
        title: "Image",
      };
    case "launch":
      return {
        path1:
          "M 19 19 H 5 V 5 h 7 V 3 H 5 c -1.11 0 -2 0.9 -2 2 v 14 c 0 1.1 0.89 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 v -7 h -2 v 7 Z M 14 3 v 2 h 3.59 l -9.83 9.83 l 1.41 1.41 L 19 6.41 V 10 h 2 V 3 h -7 Z",
        title: "Launch",
      };
    case "timeline":
      return {
        path1:
          "M 23 8 c 0 1.1 -0.9 2 -2 2 c -0.18 0 -0.35 -0.02 -0.51 -0.07 l -3.56 3.55 c 0.05 0.16 0.07 0.34 0.07 0.52 c 0 1.1 -0.9 2 -2 2 s -2 -0.9 -2 -2 c 0 -0.18 0.02 -0.36 0.07 -0.52 l -2.55 -2.55 c -0.16 0.05 -0.34 0.07 -0.52 0.07 s -0.36 -0.02 -0.52 -0.07 l -4.55 4.56 c 0.05 0.16 0.07 0.33 0.07 0.51 c 0 1.1 -0.9 2 -2 2 s -2 -0.9 -2 -2 s 0.9 -2 2 -2 c 0.18 0 0.35 0.02 0.51 0.07 l 4.56 -4.55 C 8.02 9.36 8 9.18 8 9 c 0 -1.1 0.9 -2 2 -2 s 2 0.9 2 2 c 0 0.18 -0.02 0.36 -0.07 0.52 l 2.55 2.55 c 0.16 -0.05 0.34 -0.07 0.52 -0.07 s 0.36 0.02 0.52 0.07 l 3.55 -3.56 C 19.02 8.35 19 8.18 19 8 c 0 -1.1 0.9 -2 2 -2 s 2 0.9 2 2 Z",
        title: "Timeline",
      };
    case "home":
      return {
        path1: "M 10 20 v -6 h 4 v 6 h 5 v -8 h 3 L 12 3 L 2 12 h 3 v 8 Z",
        title: "Home",
      };
    case "redo":
      return {
        path1:
          "M 18.4 10.6 C 16.55 8.99 14.15 8 11.5 8 c -4.65 0 -8.58 3.03 -9.96 7.22 L 3.9 16 c 1.05 -3.19 4.05 -5.5 7.6 -5.5 c 1.95 0 3.73 0.72 5.12 1.88 L 13 16 h 9 V 7 l -3.6 3.6 Z",
        title: "Redo",
      };
    case "shopping_cart":
      return {
        path1:
          "M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z",
        title: "Shopping Cart",
      };
    case "shopping_cart_add":
      return {
        path1:
          "M 11 9 h 2 V 6 h 3 V 4 h -3 V 1 h -2 v 3 H 8 v 2 h 3 v 3 Z m -4 9 c -1.1 0 -1.99 0.9 -1.99 2 S 5.9 22 7 22 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z m 10 0 c -1.1 0 -1.99 0.9 -1.99 2 s 0.89 2 1.99 2 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z m -9.83 -3.25 l 0.03 -0.12 l 0.9 -1.63 h 7.45 c 0.75 0 1.41 -0.41 1.75 -1.03 l 3.86 -7.01 L 19.42 4 h -0.01 l -1.1 2 l -2.76 5 H 8.53 l -0.13 -0.27 L 6.16 6 l -0.95 -2 l -0.94 -2 H 1 v 2 h 2 l 3.6 7.59 l -1.35 2.45 c -0.16 0.28 -0.25 0.61 -0.25 0.96 c 0 1.1 0.9 2 2 2 h 12 v -2 H 7.42 c -0.13 0 -0.25 -0.11 -0.25 -0.25 Z",
        title: "Shopping Cart Add",
      };
    case "menu_open":
      return {
        path1:
          "M 3 18 h 13 v -2 H 3 v 2 Z m 0 -5 h 10 v -2 H 3 v 2 Z m 0 -7 v 2 h 13 V 6 H 3 Z m 18 9.59 L 17.42 12 L 21 8.41 L 19.59 7 l -5 5 l 5 5 L 21 15.59 Z",
        title: "Menu Open",
      };
    case "menu_toggle":
      return {
        path1: "M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z",
        title: "Toggle Menu",
      };
    case "font":
      return {
        path1:
          "M 9.93 13.5 h 4.14 L 12 7.98 Z M 20 2 H 4 c -1.1 0 -2 0.9 -2 2 v 16 c 0 1.1 0.9 2 2 2 h 16 c 1.1 0 2 -0.9 2 -2 V 4 c 0 -1.1 -0.9 -2 -2 -2 Z m -4.05 16.5 l -1.14 -3 H 9.17 l -1.12 3 H 5.96 l 5.11 -13 h 1.86 l 5.11 13 h -2.09 Z",
        title: "Font",
      };
    case "catalog":
      return {
        path1:
          "M 21 5 c -1.11 -0.35 -2.33 -0.5 -3.5 -0.5 c -1.95 0 -4.05 0.4 -5.5 1.5 c -1.45 -1.1 -3.55 -1.5 -5.5 -1.5 S 2.45 4.9 1 6 v 14.65 c 0 0.25 0.25 0.5 0.5 0.5 c 0.1 0 0.15 -0.05 0.25 -0.05 C 3.1 20.45 5.05 20 6.5 20 c 1.95 0 4.05 0.4 5.5 1.5 c 1.35 -0.85 3.8 -1.5 5.5 -1.5 c 1.65 0 3.35 0.3 4.75 1.05 c 0.1 0.05 0.15 0.05 0.25 0.05 c 0.25 0 0.5 -0.25 0.5 -0.5 V 6 C 22.4 5.55 21.75 5.25 21 5 Z M 21 18.5 c -1.1 -0.35 -2.3 -0.5 -3.5 -0.5 c -1.7 0 -4.15 0.65 -5.5 1.5 V 8 c 1.35 -0.85 3.8 -1.5 5.5 -1.5 c 1.2 0 2.4 0.15 3.5 0.5 V 18.5 Z",
        g: {
          path1:
            "M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z",
          path2:
            "M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z",
          path3:
            "M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z",
        },
        title: "Catalog",
      };
    case "cooking_fire":
      return {
        path1:
          "M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z",
        title: "Cooking Fire",
      };
    case "account_balance":
      return {
        path1:
          "M 4 10 v 7 h 3 v -7 H 4 Z m 6 0 v 7 h 3 v -7 h -3 Z M 2 22 h 19 v -3 H 2 v 3 Z m 14 -12 v 7 h 3 v -7 h -3 Z m -4.5 -9 L 2 6 v 2 h 19 V 6 l -9.5 -5 Z",
        title: "Account Balance",
      };
    case "line_spacing":
      return {
        path1:
          "M 6 7 h 2.5 L 5 3.5 L 1.5 7 H 4 v 10 H 1.5 L 5 20.5 L 8.5 17 H 6 V 7 Z m 4 -2 v 2 h 12 V 5 H 10 Z m 0 14 h 12 v -2 H 10 v 2 Z m 0 -6 h 12 v -2 H 10 v 2 Z",
        title: "Line Spacing",
      };
    case "notification_on":
      return {
        path1:
          "M 12 22 c 1.1 0 2 -0.9 2 -2 h -4 c 0 1.1 0.89 2 2 2 Z m 6 -6 v -5 c 0 -3.07 -1.64 -5.64 -4.5 -6.32 V 4 c 0 -0.83 -0.67 -1.5 -1.5 -1.5 s -1.5 0.67 -1.5 1.5 v 0.68 C 7.63 5.36 6 7.92 6 11 v 5 l -2 2 v 1 h 16 v -1 l -2 -2 Z",
        title: "notification_on",
      };
    case "notification_off":
      return {
        path1:
          "M 20 18.69 L 7.84 6.14 L 5.27 3.49 L 4 4.76 l 2.8 2.8 v 0.01 c -0.52 0.99 -0.8 2.16 -0.8 3.42 v 5 l -2 2 v 1 h 13.73 l 2 2 L 21 19.72 l -1 -1.03 Z M 12 22 c 1.11 0 2 -0.89 2 -2 h -4 c 0 1.11 0.89 2 2 2 Z m 6 -7.32 V 11 c 0 -3.08 -1.64 -5.64 -4.5 -6.32 V 4 c 0 -0.83 -0.67 -1.5 -1.5 -1.5 s -1.5 0.67 -1.5 1.5 v 0.68 c -0.15 0.03 -0.29 0.08 -0.42 0.12 c -0.1 0.03 -0.2 0.07 -0.3 0.11 h -0.01 c -0.01 0 -0.01 0 -0.02 0.01 c -0.23 0.09 -0.46 0.2 -0.68 0.31 c 0 0 -0.01 0 -0.01 0.01 L 18 14.68 Z",
        title: "notification_off",
      };
    case "tune":
      return {
        path1:
          "M 3 17 v 2 h 6 v -2 H 3 Z M 3 5 v 2 h 10 V 5 H 3 Z m 10 16 v -2 h 8 v -2 h -8 v -2 h -2 v 6 h 2 Z M 7 9 v 2 H 3 v 2 h 4 v 2 h 2 V 9 H 7 Z m 14 4 v -2 H 11 v 2 h 10 Z m -6 -4 h 2 V 7 h 4 V 5 h -4 V 3 h -2 v 6 Z",
        title: "Tune",
      };
    case "title":
      return {
        path1: "M 5 4 v 3 h 5.5 v 12 h 3 V 7 H 19 V 4 Z",
        title: "Title",
      };
    case "swap_vert":
      return {
        path1:
          "M 16 17.01 V 10 h -2 v 7.01 h -3 L 15 21 l 4 -3.99 h -3 Z M 9 3 L 5 6.99 h 3 V 14 h 2 V 6.99 h 3 L 9 3 Z",
        title: "Vertical Swap Arrows",
      };
    case "swap_hor":
      return {
        path1:
          "M 6.99 11 L 3 15 l 3.99 4 v -3 H 14 v -2 H 6.99 v -3 Z M 21 9 l -3.99 -4 v 3 H 10 v 2 h 7.01 v 3 L 21 9 Z",
        title: "Horizontal Swap Arrows",
      };
    case "save":
      return {
        path1:
          "M 19 12 v 7 H 5 v -7 H 3 v 7 c 0 1.1 0.9 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 v -7 h -2 Z m -6 0.67 l 2.59 -2.58 L 17 11.5 l -5 5 l -5 -5 l 1.41 -1.41 L 11 12.67 V 3 h 2 Z",
        title: "Save",
      };
    case "color_palette":
      return {
        path1:
          "M 12 3 c -4.97 0 -9 4.03 -9 9 s 4.03 9 9 9 c 0.83 0 1.5 -0.67 1.5 -1.5 c 0 -0.39 -0.15 -0.74 -0.39 -1.01 c -0.23 -0.26 -0.38 -0.61 -0.38 -0.99 c 0 -0.83 0.67 -1.5 1.5 -1.5 H 16 c 2.76 0 5 -2.24 5 -5 c 0 -4.42 -4.03 -8 -9 -8 Z m -5.5 9 c -0.83 0 -1.5 -0.67 -1.5 -1.5 S 5.67 9 6.5 9 S 8 9.67 8 10.5 S 7.33 12 6.5 12 Z m 3 -4 C 8.67 8 8 7.33 8 6.5 S 8.67 5 9.5 5 s 1.5 0.67 1.5 1.5 S 10.33 8 9.5 8 Z m 5 0 c -0.83 0 -1.5 -0.67 -1.5 -1.5 S 13.67 5 14.5 5 s 1.5 0.67 1.5 1.5 S 15.33 8 14.5 8 Z m 3 4 c -0.83 0 -1.5 -0.67 -1.5 -1.5 S 16.67 9 17.5 9 s 1.5 0.67 1.5 1.5 s -0.67 1.5 -1.5 1.5 Z",
        title: "Color Palette",
      };
    case "rewards":
      return {
        path1: "M10 8H8v4H4v2h4v4h2v-4h4v-2h-4zm4.5-1.92V7.9l2.5-.5V18h2V5z",
        title: "Rewards",
      };
    case "information":
      return {
        path1:
          "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z",
        title: "Information",
      };
    case "thumb_up":
      return {
        path1:
          "M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z",
        title: "Thumb Up",
      };
    case "thumb_down":
      return {
        path1:
          "M 15 3 H 6 c -0.83 0 -1.54 0.5 -1.84 1.22 l -3.02 7.05 c -0.09 0.23 -0.14 0.47 -0.14 0.73 v 2 c 0 1.1 0.9 2 2 2 h 6.31 l -0.95 4.57 l -0.03 0.32 c 0 0.41 0.17 0.79 0.44 1.06 L 9.83 23 l 6.59 -6.59 c 0.36 -0.36 0.58 -0.86 0.58 -1.41 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z m 4 0 v 12 h 4 V 3 h -4 Z",
        title: "Thumb Down",
      };
    case "error":
      return {
        path1:
          "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z",
        title: "Error",
      };
    case "settings":
      return {
        path1:
          "M 12 10 c -1.1 0 -2 0.9 -2 2 s 0.9 2 2 2 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z m 7 -7 H 5 c -1.11 0 -2 0.9 -2 2 v 14 c 0 1.1 0.89 2 2 2 h 14 c 1.11 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.89 -2 -2 -2 Z m -1.75 9 c 0 0.23 -0.02 0.46 -0.05 0.68 l 1.48 1.16 c 0.13 0.11 0.17 0.3 0.08 0.45 l -1.4 2.42 c -0.09 0.15 -0.27 0.21 -0.43 0.15 l -1.74 -0.7 c -0.36 0.28 -0.76 0.51 -1.18 0.69 l -0.26 1.85 c -0.03 0.17 -0.18 0.3 -0.35 0.3 h -2.8 c -0.17 0 -0.32 -0.13 -0.35 -0.29 l -0.26 -1.85 c -0.43 -0.18 -0.82 -0.41 -1.18 -0.69 l -1.74 0.7 c -0.16 0.06 -0.34 0 -0.43 -0.15 l -1.4 -2.42 c -0.09 -0.15 -0.05 -0.34 0.08 -0.45 l 1.48 -1.16 c -0.03 -0.23 -0.05 -0.46 -0.05 -0.69 c 0 -0.23 0.02 -0.46 0.05 -0.68 l -1.48 -1.16 c -0.13 -0.11 -0.17 -0.3 -0.08 -0.45 l 1.4 -2.42 c 0.09 -0.15 0.27 -0.21 0.43 -0.15 l 1.74 0.7 c 0.36 -0.28 0.76 -0.51 1.18 -0.69 l 0.26 -1.85 c 0.03 -0.17 0.18 -0.3 0.35 -0.3 h 2.8 c 0.17 0 0.32 0.13 0.35 0.29 l 0.26 1.85 c 0.43 0.18 0.82 0.41 1.18 0.69 l 1.74 -0.7 c 0.16 -0.06 0.34 0 0.43 0.15 l 1.4 2.42 c 0.09 0.15 0.05 0.34 -0.08 0.45 l -1.48 1.16 c 0.03 0.23 0.05 0.46 0.05 0.69 Z",
        title: "Settings",
      };
    case "settings2":
      return {
        path1:
          "M 19.14 12.94 c 0.04 -0.3 0.06 -0.61 0.06 -0.94 c 0 -0.32 -0.02 -0.64 -0.07 -0.94 l 2.03 -1.58 c 0.18 -0.14 0.23 -0.41 0.12 -0.61 l -1.92 -3.32 c -0.12 -0.22 -0.37 -0.29 -0.59 -0.22 l -2.39 0.96 c -0.5 -0.38 -1.03 -0.7 -1.62 -0.94 L 14.4 2.81 c -0.04 -0.24 -0.24 -0.41 -0.48 -0.41 h -3.84 c -0.24 0 -0.43 0.17 -0.47 0.41 L 9.25 5.35 C 8.66 5.59 8.12 5.92 7.63 6.29 L 5.24 5.33 c -0.22 -0.08 -0.47 0 -0.59 0.22 L 2.74 8.87 C 2.62 9.08 2.66 9.34 2.86 9.48 l 2.03 1.58 C 4.84 11.36 4.8 11.69 4.8 12 s 0.02 0.64 0.07 0.94 l -2.03 1.58 c -0.18 0.14 -0.23 0.41 -0.12 0.61 l 1.92 3.32 c 0.12 0.22 0.37 0.29 0.59 0.22 l 2.39 -0.96 c 0.5 0.38 1.03 0.7 1.62 0.94 l 0.36 2.54 c 0.05 0.24 0.24 0.41 0.48 0.41 h 3.84 c 0.24 0 0.44 -0.17 0.47 -0.41 l 0.36 -2.54 c 0.59 -0.24 1.13 -0.56 1.62 -0.94 l 2.39 0.96 c 0.22 0.08 0.47 0 0.59 -0.22 l 1.92 -3.32 c 0.12 -0.22 0.07 -0.47 -0.12 -0.61 L 19.14 12.94 Z M 12 15.6 c -1.98 0 -3.6 -1.62 -3.6 -3.6 s 1.62 -3.6 3.6 -3.6 s 3.6 1.62 3.6 3.6 S 13.98 15.6 12 15.6 Z",
        title: "Settings",
      };
    case "dashboard":
      return {
        path1:
          "M 3 13 h 8 V 3 H 3 v 10 Z m 0 8 h 8 v -6 H 3 v 6 Z m 10 0 h 8 V 11 h -8 v 10 Z m 0 -18 v 6 h 8 V 3 h -8 Z",
        title: "Dashboard",
      };
    case "warning":
      return {
        path1: "M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z",
        title: "Warning",
      };
    case "attention":
      return {
        path1:
          "M 23 5.5 V 20 c 0 2.2 -1.8 4 -4 4 h -7.3 c -1.08 0 -2.1 -0.43 -2.85 -1.19 L 1 14.83 c 0 0 1.26 -1.23 1.3 -1.25 c 0.22 -0.19 0.49 -0.29 0.79 -0.29 c 0.22 0 0.42 0.06 0.6 0.16 C 3.73 13.46 8 15.91 8 15.91 V 4 c 0 -0.83 0.67 -1.5 1.5 -1.5 S 11 3.17 11 4 v 7 h 1 V 1.5 C 12 0.67 12.67 0 13.5 0 S 15 0.67 15 1.5 V 11 h 1 V 2.5 C 16 1.67 16.67 1 17.5 1 S 19 1.67 19 2.5 V 11 h 1 V 5.5 C 20 4.67 20.67 4 21.5 4 S 23 4.67 23 5.5 Z",
        title: "Attention",
      };
    case "report":
      return {
        path1:
          "M 15.73 3 H 8.27 L 3 8.27 v 7.46 L 8.27 21 h 7.46 L 21 15.73 V 8.27 L 15.73 3 Z M 12 17.3 c -0.72 0 -1.3 -0.58 -1.3 -1.3 c 0 -0.72 0.58 -1.3 1.3 -1.3 c 0.72 0 1.3 0.58 1.3 1.3 c 0 0.72 -0.58 1.3 -1.3 1.3 Z m 1 -4.3 h -2 V 7 h 2 v 6 Z",
        title: "Report",
      };
    case "view_day":
      return {
        path1:
          "M 2 21 h 19 v -3 H 2 v 3 Z M 20 8 H 3 c -0.55 0 -1 0.45 -1 1 v 6 c 0 0.55 0.45 1 1 1 h 17 c 0.55 0 1 -0.45 1 -1 V 9 c 0 -0.55 -0.45 -1 -1 -1 Z M 2 3 v 3 h 19 V 3 H 2 Z",
        title: "View Day",
      };
    case "checkout":
      return {
        path1:
          "M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z",
        title: "Checkout",
      };
    case "food":
      return {
        path1:
          "M 11 9 H 9 V 2 H 7 v 7 H 5 V 2 H 3 v 7 c 0 2.12 1.66 3.84 3.75 3.97 V 22 h 2.5 v -9.03 C 11.34 12.84 13 11.12 13 9 V 2 h -2 v 7 Z m 5 -3 v 8 h 2.5 v 8 H 21 V 2 c -2.76 0 -5 2.24 -5 4 Z",
        title: "Straight Fork Knife",
      };
    case "food_menu":
      return {
        path1:
          "M8.1 13.34l2.83-2.83L3.91 3.5c-1.56 1.56-1.56 4.09 0 5.66l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38 1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27L3.7 19.87l1.41 1.41L12 14.41l6.88 6.88 1.41-1.41L13.41 13l1.47-1.47z",
        title: "Food Menu",
      };
    case "close":
      return {
        path1:
          "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z",
        title: "Close",
      };
    case "publish":
      return {
        path1:
          "M 5 4 v 2 h 14 V 4 H 5 Z m 0 10 h 4 v 6 h 6 v -6 h 4 l -7 -7 l -7 7 Z",
        title: "Publish",
      };
    case "email":
      return {
        path1:
          "M 20 4 H 4 c -1.1 0 -1.99 0.9 -1.99 2 L 2 18 c 0 1.1 0.9 2 2 2 h 16 c 1.1 0 2 -0.9 2 -2 V 6 c 0 -1.1 -0.9 -2 -2 -2 Z m 0 4 l -8 5 l -8 -5 V 6 l 8 5 l 8 -5 v 2 Z",
        title: "Envelope",
      };
    case "email_unread":
      return {
        path1:
          "M 20 6 H 10 v 6 H 8 V 4 h 6 V 0 H 6 v 6 H 4 c -1.1 0 -2 0.9 -2 2 v 12 c 0 1.1 0.9 2 2 2 h 16 c 1.1 0 2 -0.9 2 -2 V 8 c 0 -1.1 -0.9 -2 -2 -2 Z",
        title: "Unread",
      };
    case "contact_email":
      return {
        path1:
          "M 21 8 V 7 l -3 2 l -3 -2 v 1 l 3 2 l 3 -2 Z m 1 -5 H 2 C 0.9 3 0 3.9 0 5 v 14 c 0 1.1 0.9 2 2 2 h 20 c 1.1 0 1.99 -0.9 1.99 -2 L 24 5 c 0 -1.1 -0.9 -2 -2 -2 Z M 8 6 c 1.66 0 3 1.34 3 3 s -1.34 3 -3 3 s -3 -1.34 -3 -3 s 1.34 -3 3 -3 Z m 6 12 H 2 v -1 c 0 -2 4 -3.1 6 -3.1 s 6 1.1 6 3.1 v 1 Z m 8 -6 h -8 V 6 h 8 v 6 Z",
        title: "Contact Via Email",
      };
    case "phone":
      return {
        path1:
          "M 6.62 10.79 c 1.44 2.83 3.76 5.14 6.59 6.59 l 2.2 -2.2 c 0.27 -0.27 0.67 -0.36 1.02 -0.24 c 1.12 0.37 2.33 0.57 3.57 0.57 c 0.55 0 1 0.45 1 1 V 20 c 0 0.55 -0.45 1 -1 1 c -9.39 0 -17 -7.61 -17 -17 c 0 -0.55 0.45 -1 1 -1 h 3.5 c 0.55 0 1 0.45 1 1 c 0 1.25 0.2 2.45 0.57 3.57 c 0.11 0.35 0.03 0.74 -0.25 1.02 l -2.2 2.2 Z",
        title: "Phone",
      };
    case "contact_phone":
      return {
        path1:
          "M 22 3 H 2 C 0.9 3 0 3.9 0 5 v 14 c 0 1.1 0.9 2 2 2 h 20 c 1.1 0 1.99 -0.9 1.99 -2 L 24 5 c 0 -1.1 -0.9 -2 -2 -2 Z M 8 6 c 1.66 0 3 1.34 3 3 s -1.34 3 -3 3 s -3 -1.34 -3 -3 s 1.34 -3 3 -3 Z m 6 12 H 2 v -1 c 0 -2 4 -3.1 6 -3.1 s 6 1.1 6 3.1 v 1 Z m 3.85 -4 h 1.64 L 21 16 l -1.99 1.99 c -1.31 -0.98 -2.28 -2.38 -2.73 -3.99 c -0.18 -0.64 -0.28 -1.31 -0.28 -2 s 0.1 -1.36 0.28 -2 c 0.45 -1.62 1.42 -3.01 2.73 -3.99 L 21 8 l -1.51 2 h -1.64 c -0.22 0.63 -0.35 1.3 -0.35 2 s 0.13 1.37 0.35 2 Z",
        title: "Contact Via Phone",
      };
    case "location":
      return {
        path1:
          "M 12 2 C 8.13 2 5 5.13 5 9 c 0 5.25 7 13 7 13 s 7 -7.75 7 -13 c 0 -3.87 -3.13 -7 -7 -7 Z m 0 9.5 c -1.38 0 -2.5 -1.12 -2.5 -2.5 s 1.12 -2.5 2.5 -2.5 s 2.5 1.12 2.5 2.5 s -1.12 2.5 -2.5 2.5 Z",
        title: "Location",
      };
    case "message":
      return {
        path1:
          "M 20 2 H 4 c -1.1 0 -1.99 0.9 -1.99 2 L 2 22 l 4 -4 h 14 c 1.1 0 2 -0.9 2 -2 V 4 c 0 -1.1 -0.9 -2 -2 -2 Z m -2 12 H 6 v -2 h 12 v 2 Z m 0 -3 H 6 V 9 h 12 v 2 Z m 0 -3 H 6 V 6 h 12 v 2 Z",
        title: "Message",
      };
    case "message_off":
      return {
        path1:
          "M 20 2 H 4 c -1.1 0 -2 0.9 -2 2 v 18 l 4 -4 h 14 c 1.1 0 2 -0.9 2 -2 V 4 c 0 -1.1 -0.9 -2 -2 -2 Z m 0 14 H 6 l -2 2 V 4 h 16 v 12 Z",
        title: "Message Off",
      };
    case "send":
      return {
        path1: "M 2.01 21 L 23 12 L 2.01 3 L 2 10 l 15 2 l -15 2 Z",
        title: "Send",
      };
    case "message_unread":
      return {
        path1:
          "M 20 2 H 4 c -1.1 0 -1.99 0.9 -1.99 2 L 2 22 l 4 -4 h 14 c 1.1 0 2 -0.9 2 -2 V 4 c 0 -1.1 -0.9 -2 -2 -2 Z m -7 9 h -2 V 5 h 2 v 6 Z m 0 4 h -2 v -2 h 2 v 2 Z",
        title: "Unread Message",
      };
    case "book":
      return {
        path1:
          "M 18 2 H 6 c -1.1 0 -2 0.9 -2 2 v 16 c 0 1.1 0.9 2 2 2 h 12 c 1.1 0 2 -0.9 2 -2 V 4 c 0 -1.1 -0.9 -2 -2 -2 Z M 6 4 h 5 v 8 l -2.5 -1.5 L 6 12 V 4 Z",
        title: "Book",
      };
    case "security":
      return {
        path1:
          "M 12 1 L 3 5 v 6 c 0 5.55 3.84 10.74 9 12 c 5.16 -1.26 9 -6.45 9 -12 V 5 l -9 -4 Z m 0 10.99 h 7 c -0.53 4.12 -3.28 7.79 -7 8.94 V 12 H 5 V 6.3 l 7 -3.11 v 8.8 Z",
        title: "Security",
      };
    case "code":
      return {
        path1:
          "M 9.4 16.6 L 4.8 12 l 4.6 -4.6 L 8 6 l -6 6 l 6 6 l 1.4 -1.4 Z m 5.2 0 l 4.6 -4.6 l -4.6 -4.6 L 16 6 l 6 6 l -6 6 l -1.4 -1.4 Z",
        title: "Code",
      };
    case "monetization":
      return {
        path1:
          "M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z m 1.41 16.09 V 20 h -2.67 v -1.93 c -1.71 -0.36 -3.16 -1.46 -3.27 -3.4 h 1.96 c 0.1 1.05 0.82 1.87 2.65 1.87 c 1.96 0 2.4 -0.98 2.4 -1.59 c 0 -0.83 -0.44 -1.61 -2.67 -2.14 c -2.48 -0.6 -4.18 -1.62 -4.18 -3.67 c 0 -1.72 1.39 -2.84 3.11 -3.21 V 4 h 2.67 v 1.95 c 1.86 0.45 2.79 1.86 2.85 3.39 H 14.3 c -0.05 -1.11 -0.64 -1.87 -2.22 -1.87 c -1.5 0 -2.4 0.68 -2.4 1.64 c 0 0.84 0.65 1.39 2.67 1.91 s 4.18 1.39 4.18 3.91 c -0.01 1.83 -1.38 2.83 -3.12 3.16 Z",
        title: "Currency",
      };
    case "edit":
      return {
        path1:
          "M 3 17.25 V 21 h 3.75 L 17.81 9.94 l -3.75 -3.75 L 3 17.25 Z M 20.71 7.04 c 0.39 -0.39 0.39 -1.02 0 -1.41 l -2.34 -2.34 c -0.39 -0.39 -1.02 -0.39 -1.41 0 l -1.83 1.83 l 3.75 3.75 l 1.83 -1.83 Z",
        title: "Edit",
      };
    case "delete":
      return {
        path1:
          "M 6 19 c 0 1.1 0.9 2 2 2 h 8 c 1.1 0 2 -0.9 2 -2 V 7 H 6 v 12 Z M 19 4 h -3.5 l -1 -1 h -5 l -1 1 H 5 v 2 h 14 V 4 Z",
        title: "Delete",
      };
    case "delete_forever":
      return {
        path1:
          "M 6 19 c 0 1.1 0.9 2 2 2 h 8 c 1.1 0 2 -0.9 2 -2 V 7 H 6 v 12 Z m 2.46 -7.12 l 1.41 -1.41 L 12 12.59 l 2.12 -2.12 l 1.41 1.41 L 13.41 14 l 2.12 2.12 l -1.41 1.41 L 12 15.41 l -2.12 2.12 l -1.41 -1.41 L 10.59 14 l -2.13 -2.12 Z M 15.5 4 l -1 -1 h -5 l -1 1 H 5 v 2 h 14 V 4 Z",
        title: "Delete",
      };
    case "bar_chart":
      return {
        path1:
          "M 19 3 H 5 c -1.1 0 -2 0.9 -2 2 v 14 c 0 1.1 0.9 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z M 9 17 H 7 v -7 h 2 v 7 Z m 4 0 h -2 V 7 h 2 v 10 Z m 4 0 h -2 v -4 h 2 v 4 Z",
        title: "Bar Chart",
      };
    case "exit_to_app":
      return {
        path1:
          "M 10.09 15.59 L 11.5 17 l 5 -5 l -5 -5 l -1.41 1.41 L 12.67 11 H 3 v 2 h 9.67 l -2.58 2.59 Z M 19 3 H 5 c -1.11 0 -2 0.9 -2 2 v 4 h 2 V 5 h 14 v 14 H 5 v -4 H 3 v 4 c 0 1.1 0.89 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z",
        title: "Exit To App",
      };
    case "lock":
      return {
        path1:
          "M 18 8 h -1 V 6 c 0 -2.76 -2.24 -5 -5 -5 S 7 3.24 7 6 v 2 H 6 c -1.1 0 -2 0.9 -2 2 v 10 c 0 1.1 0.9 2 2 2 h 12 c 1.1 0 2 -0.9 2 -2 V 10 c 0 -1.1 -0.9 -2 -2 -2 Z m -6 9 c -1.1 0 -2 -0.9 -2 -2 s 0.9 -2 2 -2 s 2 0.9 2 2 s -0.9 2 -2 2 Z m 3.1 -9 H 8.9 V 6 c 0 -1.71 1.39 -3.1 3.1 -3.1 c 1.71 0 3.1 1.39 3.1 3.1 v 2 Z",
        title: "Lock",
      };
    case "calendar":
      return {
        path1:
          "M 20 3 h -1 V 1 h -2 v 2 H 7 V 1 H 5 v 2 H 4 c -1.1 0 -2 0.9 -2 2 v 16 c 0 1.1 0.9 2 2 2 h 16 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z m 0 18 H 4 V 8 h 16 v 13 Z",
        title: "Calendar",
      };
    case "numbered_list":
      return {
        path1:
          "M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z",
        title: "Numbered List",
      };
    case "bullet_list":
      return {
        path1:
          "M 3 13 h 2 v -2 H 3 v 2 Z m 0 4 h 2 v -2 H 3 v 2 Z m 0 -8 h 2 V 7 H 3 v 2 Z m 4 4 h 14 v -2 H 7 v 2 Z m 0 4 h 14 v -2 H 7 v 2 Z M 7 7 v 2 h 14 V 7 H 7 Z",
        title: "Bulleted List",
      };
    case "view_list":
      return {
        path1:
          "M 4 14 h 4 v -4 H 4 v 4 Z m 0 5 h 4 v -4 H 4 v 4 Z M 4 9 h 4 V 5 H 4 v 4 Z m 5 5 h 12 v -4 H 9 v 4 Z m 0 5 h 12 v -4 H 9 v 4 Z M 9 5 v 4 h 12 V 5 H 9 Z",
        title: "Review List",
      };
    case "page_view":
      return {
        path1:
          "M 11.5 9 C 10.12 9 9 10.12 9 11.5 s 1.12 2.5 2.5 2.5 s 2.5 -1.12 2.5 -2.5 S 12.88 9 11.5 9 Z M 20 4 H 4 c -1.1 0 -2 0.9 -2 2 v 12 c 0 1.1 0.9 2 2 2 h 16 c 1.1 0 2 -0.9 2 -2 V 6 c 0 -1.1 -0.9 -2 -2 -2 Z m -3.21 14.21 l -2.91 -2.91 c -0.69 0.44 -1.51 0.7 -2.39 0.7 C 9.01 16 7 13.99 7 11.5 S 9.01 7 11.5 7 S 16 9.01 16 11.5 c 0 0.88 -0.26 1.69 -0.7 2.39 l 2.91 2.9 l -1.42 1.42 Z",
        title: "Preview",
      };
    case "communication":
      return {
        path1:
          "M 21 6 h -2 v 9 H 6 v 2 c 0 0.55 0.45 1 1 1 h 11 l 4 4 V 7 c 0 -0.55 -0.45 -1 -1 -1 Z m -4 6 V 3 c 0 -0.55 -0.45 -1 -1 -1 H 3 c -0.55 0 -1 0.45 -1 1 v 14 l 4 -4 h 10 c 0.55 0 1 -0.45 1 -1 Z",
        title: "Communication",
      };

    case "business":
      return {
        path1:
          "M 12 7 V 3 H 2 v 18 h 20 V 7 H 12 Z M 6 19 H 4 v -2 h 2 v 2 Z m 0 -4 H 4 v -2 h 2 v 2 Z m 0 -4 H 4 V 9 h 2 v 2 Z m 0 -4 H 4 V 5 h 2 v 2 Z m 4 12 H 8 v -2 h 2 v 2 Z m 0 -4 H 8 v -2 h 2 v 2 Z m 0 -4 H 8 V 9 h 2 v 2 Z m 0 -4 H 8 V 5 h 2 v 2 Z m 10 12 h -8 v -2 h 2 v -2 h -2 v -2 h 2 v -2 h -2 V 9 h 8 v 10 Z m -2 -8 h -2 v 2 h 2 v -2 Z m 0 4 h -2 v 2 h 2 v -2 Z",
        title: "Business",
      };
    case "event_note":
      return {
        path1:
          "M 17 10 H 7 v 2 h 10 v -2 Z m 2 -7 h -1 V 1 h -2 v 2 H 8 V 1 H 6 v 2 H 5 c -1.11 0 -1.99 0.9 -1.99 2 L 3 19 c 0 1.1 0.89 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z m 0 16 H 5 V 8 h 14 v 11 Z m -5 -5 H 7 v 2 h 7 v -2 Z",
        title: "Event Note",
      };
    case "add_note":
      return {
        path1:
          "M 21.99 4 c 0 -1.1 -0.89 -2 -1.99 -2 H 4 c -1.1 0 -2 0.9 -2 2 v 12 c 0 1.1 0.9 2 2 2 h 14 l 4 4 l -0.01 -18 Z M 18 14 H 6 v -2 h 12 v 2 Z m 0 -3 H 6 V 9 h 12 v 2 Z m 0 -3 H 6 V 6 h 12 v 2 Z",
        title: "Add Note",
      };
    case "add_note2":
      return {
        path1:
          "M 21.99 4 c 0 -1.1 -0.89 -2 -1.99 -2 H 4 c -1.1 0 -2 0.9 -2 2 v 12 c 0 1.1 0.9 2 2 2 h 14 l 4 4 l -0.01 -18 Z M 20 4 v 13.17 L 18.83 16 H 4 V 4 h 16 Z M 6 12 h 12 v 2 H 6 Z m 0 -3 h 12 v 2 H 6 Z m 0 -3 h 12 v 2 H 6 Z",
        title: "Add Note",
      };
    case "add_note3":
      return {
        path1:
          "M 14 2 H 6 c -1.1 0 -1.99 0.9 -1.99 2 L 4 20 c 0 1.1 0.89 2 1.99 2 H 18 c 1.1 0 2 -0.9 2 -2 V 8 l -6 -6 Z m 2 14 h -3 v 3 h -2 v -3 H 8 v -2 h 3 v -3 h 2 v 3 h 3 v 2 Z m -3 -7 V 3.5 L 18.5 9 H 13 Z",
        title: "Add Note",
      };
    case "add_note4":
      return {
        path1:
          "M 13 11 h -2 v 3 H 8 v 2 h 3 v 3 h 2 v -3 h 3 v -2 h -3 Z m 1 -9 H 6 c -1.1 0 -2 0.9 -2 2 v 16 c 0 1.1 0.89 2 1.99 2 H 18 c 1.1 0 2 -0.9 2 -2 V 8 l -6 -6 Z m 4 18 H 6 V 4 h 7 v 5 h 5 v 11 Z",
        title: "Add Note",
      };
    case "description":
      return {
        path1:
          "M 14 2 H 6 c -1.1 0 -1.99 0.9 -1.99 2 L 4 20 c 0 1.1 0.89 2 1.99 2 H 18 c 1.1 0 2 -0.9 2 -2 V 8 l -6 -6 Z m 2 16 H 8 v -2 h 8 v 2 Z m 0 -4 H 8 v -2 h 8 v 2 Z m -3 -5 V 3.5 L 18.5 9 H 13 Z",
        title: "View Description",
      };
    case "expand_more":
      return {
        path1: "M 16.59 8.59 L 12 13.17 L 7.41 8.59 L 6 10 l 6 6 l 6 -6 Z",
        title: "Expand",
      };
    case "expand_less":
      return {
        path1: "M 12 8 l -6 6 l 1.41 1.41 L 12 10.83 l 4.59 4.58 L 18 14 Z",
        title: "Expand Less",
      };
    case "add":
      return {
        path1:
          "M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z m 5 11 h -4 v 4 h -2 v -4 H 7 v -2 h 4 V 7 h 2 v 4 h 4 v 2 Z",
        title: "Add",
      };
    case "add_sq":
      return {
        path1:
          "M 19 3 H 5 c -1.11 0 -2 0.9 -2 2 v 14 c 0 1.1 0.89 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z m -2 10 h -4 v 4 h -2 v -4 H 7 v -2 h 4 V 7 h 2 v 4 h 4 v 2 Z",
        title: "Add",
      };
    case "add_to_list":
      return {
        path1:
          "M 14 10 H 2 v 2 h 12 v -2 Z m 0 -4 H 2 v 2 h 12 V 6 Z m 4 8 v -4 h -2 v 4 h -4 v 2 h 4 v 4 h 2 v -4 h 4 v -2 h -4 Z M 2 16 h 8 v -2 H 2 v 2 Z",
        title: "Add Additional Items",
      };
    case "template":
      return {
        path1:
          "M 10 18 h 5 v -6 h -5 v 6 Z m -6 0 h 5 V 5 H 4 v 13 Z m 12 0 h 5 v -6 h -5 v 6 Z M 10 5 v 6 h 11 V 5 H 10 Z",
        title: "Template",
      };
    case "assignment":
      return {
        path1:
          "M 19 3 h -4.18 C 14.4 1.84 13.3 1 12 1 c -1.3 0 -2.4 0.84 -2.82 2 H 5 c -1.1 0 -2 0.9 -2 2 v 14 c 0 1.1 0.9 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z m -7 0 c 0.55 0 1 0.45 1 1 s -0.45 1 -1 1 s -1 -0.45 -1 -1 s 0.45 -1 1 -1 Z m 2 14 H 7 v -2 h 7 v 2 Z m 3 -4 H 7 v -2 h 10 v 2 Z m 0 -4 H 7 V 7 h 10 v 2 Z",
        title: "Assignment",
      };
    case "subtract_sq":
      return {
        path1:
          "M 19 3 H 5 c -1.1 0 -2 0.9 -2 2 v 14 c 0 1.1 0.9 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z m -2 10 H 7 v -2 h 10 v 2 Z",
        title: "Subtract",
      };
    case "person_si":
      return {
        path1:
          "M 9 17 l 3 -2.94 c -0.39 -0.04 -0.68 -0.06 -1 -0.06 c -2.67 0 -8 1.34 -8 4 v 2 h 9 l -3 -3 Z m 2 -5 c 2.21 0 4 -1.79 4 -4 s -1.79 -4 -4 -4 s -4 1.79 -4 4 s 1.79 4 4 4",
        path2:
          "M 15.47 20.5 L 12 17 l 1.4 -1.41 l 2.07 2.08 l 5.13 -5.17 l 1.4 1.41 Z",
        title: "Signed In",
      };
    case "person_so":
      return {
        path1:
          "M 12 5.9 c 1.16 0 2.1 0.94 2.1 2.1 s -0.94 2.1 -2.1 2.1 S 9.9 9.16 9.9 8 s 0.94 -2.1 2.1 -2.1 m 0 9 c 2.97 0 6.1 1.46 6.1 2.1 v 1.1 H 5.9 V 17 c 0 -0.64 3.13 -2.1 6.1 -2.1 M 12 4 C 9.79 4 8 5.79 8 8 s 1.79 4 4 4 s 4 -1.79 4 -4 s -1.79 -4 -4 -4 Z m 0 9 c -2.67 0 -8 1.34 -8 4 v 3 h 16 v -3 c 0 -2.66 -5.33 -4 -8 -4 Z",
        title: "Signed Out",
      };
    case "group":
      return {
        path1:
          "M 16 11 c 1.66 0 2.99 -1.34 2.99 -3 S 17.66 5 16 5 c -1.66 0 -3 1.34 -3 3 s 1.34 3 3 3 Z m -8 0 c 1.66 0 2.99 -1.34 2.99 -3 S 9.66 5 8 5 C 6.34 5 5 6.34 5 8 s 1.34 3 3 3 Z m 0 2 c -2.33 0 -7 1.17 -7 3.5 V 19 h 14 v -2.5 c 0 -2.33 -4.67 -3.5 -7 -3.5 Z m 8 0 c -0.29 0 -0.62 0.02 -0.97 0.05 c 1.16 0.84 1.97 1.97 1.97 3.45 V 19 h 6 v -2.5 c 0 -2.33 -4.67 -3.5 -7 -3.5 Z",
        title: "Rename",
      };
    case "welcome":
      return {
        path1:
          "M 21.41 11.58 l -9 -9 C 12.05 2.22 11.55 2 11 2 H 4 c -1.1 0 -2 0.9 -2 2 v 7 c 0 0.55 0.22 1.05 0.59 1.42 l 9 9 c 0.36 0.36 0.86 0.58 1.41 0.58 c 0.55 0 1.05 -0.22 1.41 -0.59 l 7 -7 c 0.37 -0.36 0.59 -0.86 0.59 -1.41 c 0 -0.55 -0.23 -1.06 -0.59 -1.42 Z M 5.5 7 C 4.67 7 4 6.33 4 5.5 S 4.67 4 5.5 4 S 7 4.67 7 5.5 S 6.33 7 5.5 7 Z m 11.77 8.27 L 13 19.54 l -4.27 -4.27 C 8.28 14.81 8 14.19 8 13.5 c 0 -1.38 1.12 -2.5 2.5 -2.5 c 0.69 0 1.32 0.28 1.77 0.74 l 0.73 0.72 l 0.73 -0.73 c 0.45 -0.45 1.08 -0.73 1.77 -0.73 c 1.38 0 2.5 1.12 2.5 2.5 c 0 0.69 -0.28 1.32 -0.73 1.77 Z",
        title: "Welcome",
      };
    case "gift_card":
      return {
        path1:
          "M 20 6 h -2.18 c 0.11 -0.31 0.18 -0.65 0.18 -1 c 0 -1.66 -1.34 -3 -3 -3 c -1.05 0 -1.96 0.54 -2.5 1.35 l -0.5 0.67 l -0.5 -0.68 C 10.96 2.54 10.05 2 9 2 C 7.34 2 6 3.34 6 5 c 0 0.35 0.07 0.69 0.18 1 H 4 c -1.11 0 -1.99 0.89 -1.99 2 L 2 19 c 0 1.11 0.89 2 2 2 h 16 c 1.11 0 2 -0.89 2 -2 V 8 c 0 -1.11 -0.89 -2 -2 -2 Z m -5 -2 c 0.55 0 1 0.45 1 1 s -0.45 1 -1 1 s -1 -0.45 -1 -1 s 0.45 -1 1 -1 Z M 9 4 c 0.55 0 1 0.45 1 1 s -0.45 1 -1 1 s -1 -0.45 -1 -1 s 0.45 -1 1 -1 Z m 11 15 H 4 v -2 h 16 v 2 Z m 0 -5 H 4 V 8 h 5.08 L 7 10.83 L 8.62 12 L 11 8.76 l 1 -1.36 l 1 1.36 L 15.38 12 L 17 10.83 L 14.92 8 H 20 v 6 Z",
        title: "Gift Card",
      };
    case "flag":
      return {
        path1: "M 14.4 6 L 14 4 H 5 v 17 h 2 v -7 h 5.6 l 0.4 2 h 7 V 6 Z",
        title: "Flag",
      };
    case "help":
      return {
        path1:
          "M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z m 1 17 h -2 v -2 h 2 v 2 Z m 2.07 -7.75 l -0.9 0.92 C 13.45 12.9 13 13.5 13 15 h -2 v -0.5 c 0 -1.1 0.45 -2.1 1.17 -2.83 l 1.24 -1.26 c 0.37 -0.36 0.59 -0.86 0.59 -1.41 c 0 -1.1 -0.9 -2 -2 -2 s -2 0.9 -2 2 H 8 c 0 -2.21 1.79 -4 4 -4 s 4 1.79 4 4 c 0 0.88 -0.36 1.68 -0.93 2.25 Z",
        title: "Help",
      };
    case "delivery":
      return {
        path1:
          "M 18.92 6.01 C 18.72 5.42 18.16 5 17.5 5 H 15 V 3 H 9 v 2 H 6.5 c -0.66 0 -1.21 0.42 -1.42 1.01 L 3 12 v 8 c 0 0.55 0.45 1 1 1 h 1 c 0.55 0 1 -0.45 1 -1 v -1 h 12 v 1 c 0 0.55 0.45 1 1 1 h 1 c 0.55 0 1 -0.45 1 -1 v -8 l -2.08 -5.99 Z M 6.5 16 c -0.83 0 -1.5 -0.67 -1.5 -1.5 S 5.67 13 6.5 13 s 1.5 0.67 1.5 1.5 S 7.33 16 6.5 16 Z m 11 0 c -0.83 0 -1.5 -0.67 -1.5 -1.5 s 0.67 -1.5 1.5 -1.5 s 1.5 0.67 1.5 1.5 s -0.67 1.5 -1.5 1.5 Z M 5 11 l 1.5 -4.5 h 11 L 19 11 H 5 Z",
        title: "Delivery",
      };
    case "store":
      return {
        path1:
          "M 20 4 H 4 v 2 h 16 V 4 Z m 1 10 v -2 l -1 -5 H 4 l -1 5 v 2 h 1 v 6 h 10 v -6 h 4 v 6 h 2 v -6 h 1 Z m -9 4 H 6 v -4 h 6 v 4 Z",
        title: "Storefront",
      };
    case "asap":
      return {
        path1:
          "M 15 1 H 9 v 2 h 6 V 1 Z m -4 13 h 2 V 8 h -2 v 6 Z m 8.03 -6.61 l 1.42 -1.42 c -0.43 -0.51 -0.9 -0.99 -1.41 -1.41 l -1.42 1.42 C 16.07 4.74 14.12 4 12 4 c -4.97 0 -9 4.03 -9 9 s 4.02 9 9 9 s 9 -4.03 9 -9 c 0 -2.12 -0.74 -4.07 -1.97 -5.61 Z M 12 20 c -3.87 0 -7 -3.13 -7 -7 s 3.13 -7 7 -7 s 7 3.13 7 7 s -3.13 7 -7 7 Z",
        title: "Timer",
      };
    case "cloud_upload":
      return {
        path1:
          "M 19.35 10.04 C 18.67 6.59 15.64 4 12 4 C 9.11 4 6.6 5.64 5.35 8.04 C 2.34 8.36 0 10.91 0 14 c 0 3.31 2.69 6 6 6 h 13 c 2.76 0 5 -2.24 5 -5 c 0 -2.64 -2.05 -4.78 -4.65 -4.96 Z M 14 13 v 4 h -4 v -4 H 7 l 5 -5 l 5 5 h -3 Z",
        title: "Cloud Upload",
      };
    case "cloud_download":
      return {
        path1:
          "M 19.35 10.04 C 18.67 6.59 15.64 4 12 4 C 9.11 4 6.6 5.64 5.35 8.04 C 2.34 8.36 0 10.91 0 14 c 0 3.31 2.69 6 6 6 h 13 c 2.76 0 5 -2.24 5 -5 c 0 -2.64 -2.05 -4.78 -4.65 -4.96 Z M 17 13 l -5 5 l -5 -5 h 3 V 9 h 4 v 4 h 3 Z",
        title: "Cloud Download",
      };
    case "visibility_on":
      return {
        path1:
          "M 12 4.5 C 7 4.5 2.73 7.61 1 12 c 1.73 4.39 6 7.5 11 7.5 s 9.27 -3.11 11 -7.5 c -1.73 -4.39 -6 -7.5 -11 -7.5 Z M 12 17 c -2.76 0 -5 -2.24 -5 -5 s 2.24 -5 5 -5 s 5 2.24 5 5 s -2.24 5 -5 5 Z m 0 -8 c -1.66 0 -3 1.34 -3 3 s 1.34 3 3 3 s 3 -1.34 3 -3 s -1.34 -3 -3 -3 Z",
        title: "Visibilty On",
      };
    case "visibility_off":
      return {
        path1:
          "M 12 7 c 2.76 0 5 2.24 5 5 c 0 0.65 -0.13 1.26 -0.36 1.83 l 2.92 2.92 c 1.51 -1.26 2.7 -2.89 3.43 -4.75 c -1.73 -4.39 -6 -7.5 -11 -7.5 c -1.4 0 -2.74 0.25 -3.98 0.7 l 2.16 2.16 C 10.74 7.13 11.35 7 12 7 Z M 2 4.27 l 2.28 2.28 l 0.46 0.46 C 3.08 8.3 1.78 10.02 1 12 c 1.73 4.39 6 7.5 11 7.5 c 1.55 0 3.03 -0.3 4.38 -0.84 l 0.42 0.42 L 19.73 22 L 21 20.73 L 3.27 3 L 2 4.27 Z M 7.53 9.8 l 1.55 1.55 c -0.05 0.21 -0.08 0.43 -0.08 0.65 c 0 1.66 1.34 3 3 3 c 0.22 0 0.44 -0.03 0.65 -0.08 l 1.55 1.55 c -0.67 0.33 -1.41 0.53 -2.2 0.53 c -2.76 0 -5 -2.24 -5 -5 c 0 -0.79 0.2 -1.53 0.53 -2.2 Z m 4.31 -0.78 l 3.15 3.15 l 0.02 -0.16 c 0 -1.66 -1.34 -3 -3 -3 l -0.17 0.01 Z",
        title: "Visibility Off",
      };
    case "face":
      return {
        path1:
          "M 9 11.75 c -0.69 0 -1.25 0.56 -1.25 1.25 s 0.56 1.25 1.25 1.25 s 1.25 -0.56 1.25 -1.25 s -0.56 -1.25 -1.25 -1.25 Z m 6 0 c -0.69 0 -1.25 0.56 -1.25 1.25 s 0.56 1.25 1.25 1.25 s 1.25 -0.56 1.25 -1.25 s -0.56 -1.25 -1.25 -1.25 Z M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z m 0 18 c -4.41 0 -8 -3.59 -8 -8 c 0 -0.29 0.02 -0.58 0.05 -0.86 c 2.36 -1.05 4.23 -2.98 5.21 -5.37 C 11.07 8.33 14.05 10 17.42 10 c 0.78 0 1.53 -0.09 2.25 -0.26 c 0.21 0.71 0.33 1.47 0.33 2.26 c 0 4.41 -3.59 8 -8 8 Z",
        title: "Face",
      };
    case "walking":
      return {
        path1:
          "M 16.49 15.5 v -1.75 L 14 16.25 l 2.49 2.5 V 17 H 22 v -1.5 Z m 3.02 4.25 H 14 v 1.5 h 5.51 V 23 L 22 20.5 L 19.51 18 Z M 9.5 5.5 c 1.1 0 2 -0.9 2 -2 s -0.9 -2 -2 -2 s -2 0.9 -2 2 s 0.9 2 2 2 Z M 5.75 8.9 L 3 23 h 2.1 l 1.75 -8 L 9 17 v 6 h 2 v -7.55 L 8.95 13.4 l 0.6 -3 C 10.85 12 12.8 13 15 13 v -2 c -1.85 0 -3.45 -1 -4.35 -2.45 l -0.95 -1.6 C 9.35 6.35 8.7 6 8 6 c -0.25 0 -0.5 0.05 -0.75 0.15 L 2 8.3 V 13 h 2 V 9.65 l 1.75 -0.75",
        title: "Waiting For Customer",
      };
    case "navigation":
      return {
        path1: "M 12 2 L 4.5 20.29 l 0.71 0.71 L 12 18 l 6.79 3 l 0.71 -0.71 Z",
        title: "Navigation",
      };
    case "directions":
      return {
        path1:
          "M 21.71 11.29 l -9 -9 c -0.39 -0.39 -1.02 -0.39 -1.41 0 l -9 9 c -0.39 0.39 -0.39 1.02 0 1.41 l 9 9 c 0.39 0.39 1.02 0.39 1.41 0 l 9 -9 c 0.39 -0.38 0.39 -1.01 0 -1.41 Z M 14 14.5 V 12 h -4 v 3 H 8 v -4 c 0 -0.55 0.45 -1 1 -1 h 5 V 7.5 l 3.5 3.5 l -3.5 3.5 Z",
        title: "Directions",
      };
    case "in_progress":
      return {
        path1:
          "M 21 10.12 h -6.78 l 2.74 -2.82 c -2.73 -2.7 -7.15 -2.8 -9.88 -0.1 c -2.73 2.71 -2.73 7.08 0 9.79 c 2.73 2.71 7.15 2.71 9.88 0 C 18.32 15.65 19 14.08 19 12.1 h 2 c 0 1.98 -0.88 4.55 -2.64 6.29 c -3.51 3.48 -9.21 3.48 -12.72 0 c -3.5 -3.47 -3.53 -9.11 -0.02 -12.58 c 3.51 -3.47 9.14 -3.47 12.65 0 L 21 3 v 7.12 Z M 12.5 8 v 4.25 l 3.5 2.08 l -0.72 1.21 L 11 13 V 8 h 1.5 Z",
        title: "Progress",
      };
    case "in_queue":
      return {
        path1:
          "M 6 5 H 3 c -0.55 0 -1 0.45 -1 1 v 12 c 0 0.55 0.45 1 1 1 h 3 c 0.55 0 1 -0.45 1 -1 V 6 c 0 -0.55 -0.45 -1 -1 -1 Z m 14 0 h -3 c -0.55 0 -1 0.45 -1 1 v 12 c 0 0.55 0.45 1 1 1 h 3 c 0.55 0 1 -0.45 1 -1 V 6 c 0 -0.55 -0.45 -1 -1 -1 Z m -7 0 h -3 c -0.55 0 -1 0.45 -1 1 v 12 c 0 0.55 0.45 1 1 1 h 3 c 0.55 0 1 -0.45 1 -1 V 6 c 0 -0.55 -0.45 -1 -1 -1 Z",
        title: "In Queue",
      };
    case "in_transit":
      return {
        path1:
          "M 20 8 h -3 V 4 H 3 c -1.1 0 -2 0.9 -2 2 v 11 h 2 c 0 1.66 1.34 3 3 3 s 3 -1.34 3 -3 h 6 c 0 1.66 1.34 3 3 3 s 3 -1.34 3 -3 h 2 v -5 l -3 -4 Z M 6 18.5 c -0.83 0 -1.5 -0.67 -1.5 -1.5 s 0.67 -1.5 1.5 -1.5 s 1.5 0.67 1.5 1.5 s -0.67 1.5 -1.5 1.5 Z m 13.5 -9 l 1.96 2.5 H 17 V 9.5 h 2.5 Z m -1.5 9 c -0.83 0 -1.5 -0.67 -1.5 -1.5 s 0.67 -1.5 1.5 -1.5 s 1.5 0.67 1.5 1.5 s -0.67 1.5 -1.5 1.5 Z",
        title: "In Transit",
      };
    case "past":
      return {
        path1:
          "M 13 3 c -4.97 0 -9 4.03 -9 9 H 1 l 3.89 3.89 l 0.07 0.14 L 9 12 H 6 c 0 -3.87 3.13 -7 7 -7 s 7 3.13 7 7 s -3.13 7 -7 7 c -1.93 0 -3.68 -0.79 -4.94 -2.06 l -1.42 1.42 C 8.27 19.99 10.51 21 13 21 c 4.97 0 9 -4.03 9 -9 s -4.03 -9 -9 -9 Z m -1 5 v 5 l 4.28 2.54 l 0.72 -1.21 l -3.5 -2.08 V 8 H 12 Z",
        title: "Past",
      };
    case "unfavorite":
      return {
        path1:
          "M 16.5 3 c -1.74 0 -3.41 0.81 -4.5 2.09 C 10.91 3.81 9.24 3 7.5 3 C 4.42 3 2 5.42 2 8.5 c 0 3.78 3.4 6.86 8.55 11.54 L 12 21.35 l 1.45 -1.32 C 18.6 15.36 22 12.28 22 8.5 C 22 5.42 19.58 3 16.5 3 Z m -4.4 15.55 l -0.1 0.1 l -0.1 -0.1 C 7.14 14.24 4 11.39 4 8.5 C 4 6.5 5.5 5 7.5 5 c 1.54 0 3.04 0.99 3.57 2.36 h 1.87 C 13.46 5.99 14.96 5 16.5 5 c 2 0 3.5 1.5 3.5 3.5 c 0 2.89 -3.14 5.74 -7.9 10.05 Z",
        title: "Unfavorite",
      };
    case "favorite":
      return {
        path1:
          "M 12 21.35 l -1.45 -1.32 C 5.4 15.36 2 12.28 2 8.5 C 2 5.42 4.42 3 7.5 3 c 1.74 0 3.41 0.81 4.5 2.09 C 13.09 3.81 14.76 3 16.5 3 C 19.58 3 22 5.42 22 8.5 c 0 3.78 -3.4 6.86 -8.55 11.54 L 12 21.35 Z",
        title: "Favorite",
      };
    case "done":
      return {
        path1: "M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z",
        title: "Done",
      };
    case "done_outline":
      return {
        path1:
          "M 19.77 5.03 l 1.4 1.4 L 8.43 19.17 l -5.6 -5.6 l 1.4 -1.4 l 4.2 4.2 L 19.77 5.03 m 0 -2.83 L 8.43 13.54 l -4.2 -4.2 L 0 13.57 L 8.43 22 L 24 6.43 L 19.77 2.2 Z",
        title: "Done",
      };
    case "done_all":
      return {
        path1:
          "M 18 7 l -1.41 -1.41 l -6.34 6.34 l 1.41 1.41 L 18 7 Z m 4.24 -1.41 L 11.66 16.17 L 7.48 12 l -1.41 1.41 L 11.66 19 l 12 -12 l -1.42 -1.41 Z M 0.41 13.41 L 6 19 l 1.41 -1.41 L 1.83 12 L 0.41 13.41 Z",
        title: "Done All",
      };
    case "checkbox_empty":
      return {
        path1:
          "M 19 5 v 14 H 5 V 5 h 14 m 0 -2 H 5 c -1.1 0 -2 0.9 -2 2 v 14 c 0 1.1 0.9 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z",
        title: "Empty Checkbox",
      };
    case "checkbox_done":
      return {
        path1:
          "M 19 3 H 5 c -1.11 0 -2 0.9 -2 2 v 14 c 0 1.1 0.89 2 2 2 h 14 c 1.11 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.89 -2 -2 -2 Z m -9 14 l -5 -5 l 1.41 -1.41 L 10 14.17 l 7.59 -7.59 L 19 8 l -9 9 Z",
        title: "Completed Checkbox",
      };
    case "undo":
      return {
        path1:
          "M 12.5 8 c -2.65 0 -5.05 0.99 -6.9 2.6 L 2 7 v 9 h 9 l -3.62 -3.62 c 1.39 -1.16 3.16 -1.88 5.12 -1.88 c 3.54 0 6.55 2.31 7.6 5.5 l 2.37 -0.78 C 21.08 11.03 17.15 8 12.5 8 Z",
        title: "Undo",
      };
    case "money":
      return {
        path1:
          "M 5 8 h 2 v 8 H 5 Z m 7 0 H 9 c -0.55 0 -1 0.45 -1 1 v 6 c 0 0.55 0.45 1 1 1 h 3 c 0.55 0 1 -0.45 1 -1 V 9 c 0 -0.55 -0.45 -1 -1 -1 Z m -1 6 h -1 v -4 h 1 v 4 Z m 7 -6 h -3 c -0.55 0 -1 0.45 -1 1 v 6 c 0 0.55 0.45 1 1 1 h 3 c 0.55 0 1 -0.45 1 -1 V 9 c 0 -0.55 -0.45 -1 -1 -1 Z m -1 6 h -1 v -4 h 1 v 4 Z",
        path2: "M 2 4 v 16 h 20 V 4 H 2 Z m 2 14 V 6 h 16 v 12 H 4 Z",
        title: "Money",
      };
    case "input":
      return {
        path1:
          "M 11 5 v 5.59 H 7.5 l 4.5 4.5 l 4.5 -4.5 H 13 V 5 h -2 Z m -5 9 c 0 3.31 2.69 6 6 6 s 6 -2.69 6 -6 h -2 c 0 2.21 -1.79 4 -4 4 s -4 -1.79 -4 -4 H 6 Z",
        title: "Input",
      };
    case "remove_circle_filled":
      return {
        path1:
          "M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z m 5 11 H 7 v -2 h 10 v 2 Z",
        title: "Unable",
      };
    case "remove_circle_outline":
      return {
        path1:
          "M 7 11 v 2 h 10 v -2 H 7 Z m 5 -9 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z m 0 18 c -4.41 0 -8 -3.59 -8 -8 s 3.59 -8 8 -8 s 8 3.59 8 8 s -3.59 8 -8 8 Z",
        title: "Unable",
      };
    case "cake":
      return {
        path1:
          "M12 6c1.11 0 2-.9 2-2 0-.38-.1-.73-.29-1.03L12 0l-1.71 2.97c-.19.3-.29.65-.29 1.03 0 1.1.9 2 2 2zm4.6 9.99l-1.07-1.07-1.08 1.07c-1.3 1.3-3.58 1.31-4.89 0l-1.07-1.07-1.09 1.07C6.75 16.64 5.88 17 4.96 17c-.73 0-1.4-.23-1.96-.61V21c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-4.61c-.56.38-1.23.61-1.96.61-.92 0-1.79-.36-2.44-1.01zM18 9h-5V7h-2v2H6c-1.66 0-3 1.34-3 3v1.54c0 1.08.88 1.96 1.96 1.96.52 0 1.02-.2 1.38-.57l2.14-2.13 2.13 2.13c.74.74 2.03.74 2.77 0l2.14-2.13 2.13 2.13c.37.37.86.57 1.38.57 1.08 0 1.96-.88 1.96-1.96V12C21 10.34 19.66 9 18 9z",
        title: "Cake",
      };
    case "clock":
      return {
        path1:
          "M 11.99 2 C 6.47 2 2 6.48 2 12 s 4.47 10 9.99 10 C 17.52 22 22 17.52 22 12 S 17.52 2 11.99 2 Z M 12 20 c -4.42 0 -8 -3.58 -8 -8 s 3.58 -8 8 -8 s 8 3.58 8 8 s -3.58 8 -8 8 Z",
        path2: "M 12.5 7 H 11 v 6 l 5.25 3.15 l 0.75 -1.23 l -4.5 -2.67 Z",
        title: "Clock",
      };
    case "flip":
      return {
        path1:
          "M15 21h2v-2h-2v2zm4-12h2V7h-2v2zM3 5v14c0 1.1.9 2 2 2h4v-2H5V5h4V3H5c-1.1 0-2 .9-2 2zm16-2v2h2c0-1.1-.9-2-2-2zm-8 20h2V1h-2v22zm8-6h2v-2h-2v2zM15 5h2V3h-2v2zm4 8h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2z",
        title: "Flip",
      };
    case "receipt":
      return {
        path1:
          "M 18 17 H 6 v -2 h 12 v 2 Z m 0 -4 H 6 v -2 h 12 v 2 Z m 0 -4 H 6 V 7 h 12 v 2 Z M 3 22 l 1.5 -1.5 L 6 22 l 1.5 -1.5 L 9 22 l 1.5 -1.5 L 12 22 l 1.5 -1.5 L 15 22 l 1.5 -1.5 L 18 22 l 1.5 -1.5 L 21 22 V 2 l -1.5 1.5 L 18 2 l -1.5 1.5 L 15 2 l -1.5 1.5 L 12 2 l -1.5 1.5 L 9 2 L 7.5 3.5 L 6 2 L 4.5 3.5 L 3 2 v 20 Z",
        title: "Receipt",
      };
    case "receipt_long":
      return {
        path1:
          "M 19.5 3.5 L 18 2 l -1.5 1.5 L 15 2 l -1.5 1.5 L 12 2 l -1.5 1.5 L 9 2 L 7.5 3.5 L 6 2 v 14 H 3 v 3 c 0 1.66 1.34 3 3 3 h 12 c 1.66 0 3 -1.34 3 -3 V 2 L 19.5 3.5 Z M 19 19 c 0 0.55 -0.45 1 -1 1 s -1 -0.45 -1 -1 v -3 H 8 V 5 h 11 V 19 Z",
        title: "Receipt",
        rects: [
          { height: "2", width: "6", x: "9", y: "7" },
          { height: "2", width: "2", x: "16", y: "7" },
          { height: "2", width: "6", x: "9", y: "10" },
          { height: "2", width: "2", x: "16", y: "10" },
        ],
      };
    case "no_icon":
      return {
        path1: undefined,
        title: "",
      };
    case "reply":
      return {
        path1:
          "M 10 9 V 5 l -7 7 l 7 7 v -4.1 c 5 0 8.5 1.6 11 5.1 c -1 -5 -4 -10 -11 -11 Z",
        title: "Reply",
      };
    case "translate":
      return {
        path1:
          "M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z",
        title: "Translate",
      };
    case "pause":
      return {
        path1:
          "M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z m -1 14 H 9 V 8 h 2 v 8 Z m 4 0 h -2 V 8 h 2 v 8 Z",
        title: "Pause",
      };
    case "membership":
      return {
        path1:
          "M 20 2 H 4 c -1.11 0 -2 0.89 -2 2 v 11 c 0 1.11 0.89 2 2 2 h 4 v 5 l 4 -2 l 4 2 v -5 h 4 c 1.11 0 2 -0.89 2 -2 V 4 c 0 -1.11 -0.89 -2 -2 -2 Z m 0 13 H 4 v -2 h 16 v 2 Z m 0 -5 H 4 V 4 h 16 v 6 Z",
        title: "Membership",
      };
    case "category":
      return {
        path1: "M 12 2 l -5.5 9 h 11 Z",
        path2: "M 3 13.5 h 8 v 8 H 3 Z",
        title: "Category",
        cx: 17.5,
        cy: 17.5,
        r: 4.5,
      };
    case "notes":
      return {
        path1:
          "M 3 18 h 12 v -2 H 3 v 2 Z M 3 6 v 2 h 18 V 6 H 3 Z m 0 7 h 18 v -2 H 3 v 2 Z",
        title: "Notes",
      };
    case "more_horizontal":
      return {
        path1:
          "M 6 10 c -1.1 0 -2 0.9 -2 2 s 0.9 2 2 2 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z m 12 0 c -1.1 0 -2 0.9 -2 2 s 0.9 2 2 2 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z m -6 0 c -1.1 0 -2 0.9 -2 2 s 0.9 2 2 2 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z",
        title: "Overflow Menu",
      };
    case "more_vertical":
      return {
        path1:
          "M 12 8 c 1.1 0 2 -0.9 2 -2 s -0.9 -2 -2 -2 s -2 0.9 -2 2 s 0.9 2 2 2 Z m 0 2 c -1.1 0 -2 0.9 -2 2 s 0.9 2 2 2 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z m 0 6 c -1.1 0 -2 0.9 -2 2 s 0.9 2 2 2 s 2 -0.9 2 -2 s -0.9 -2 -2 -2 Z",
        title: "Overflow Menu",
      };
    case "menu_component":
      return {
        path1:
          "M 4 8 h 4 V 4 H 4 v 4 Z m 6 12 h 4 v -4 h -4 v 4 Z m -6 0 h 4 v -4 H 4 v 4 Z m 0 -6 h 4 v -4 H 4 v 4 Z m 6 0 h 4 v -4 h -4 v 4 Z m 6 -10 v 4 h 4 V 4 h -4 Z m -6 4 h 4 V 4 h -4 v 4 Z m 6 6 h 4 v -4 h -4 v 4 Z m 0 6 h 4 v -4 h -4 v 4 Z",
        title: "Menu",
      };
    case "sunny":
      return {
        path1:
          "M 6.76 4.84 l -1.8 -1.79 l -1.41 1.41 l 1.79 1.79 l 1.42 -1.41 Z M 4 10.5 H 1 v 2 h 3 v -2 Z m 9 -9.95 h -2 V 3.5 h 2 V 0.55 Z m 7.45 3.91 l -1.41 -1.41 l -1.79 1.79 l 1.41 1.41 l 1.79 -1.79 Z m -3.21 13.7 l 1.79 1.8 l 1.41 -1.41 l -1.8 -1.79 l -1.4 1.4 Z M 20 10.5 v 2 h 3 v -2 h -3 Z m -8 -5 c -3.31 0 -6 2.69 -6 6 s 2.69 6 6 6 s 6 -2.69 6 -6 s -2.69 -6 -6 -6 Z m -1 16.95 h 2 V 19.5 h -2 v 2.95 Z m -7.45 -3.91 l 1.41 1.41 l 1.79 -1.8 l -1.41 -1.41 l -1.79 1.8 Z",
        title: "Sunny",
      };
    case "filter":
      return {
        path1:
          "M 10 18 h 4 v -2 h -4 v 2 Z M 3 6 v 2 h 18 V 6 H 3 Z m 3 7 h 12 v -2 H 6 v 2 Z",
        title: "Filter",
      };
    case "search":
      return {
        path1:
          "M 15.5 14 h -0.79 l -0.28 -0.27 C 15.41 12.59 16 11.11 16 9.5 C 16 5.91 13.09 3 9.5 3 S 3 5.91 3 9.5 S 5.91 16 9.5 16 c 1.61 0 3.09 -0.59 4.23 -1.57 l 0.27 0.28 v 0.79 l 5 4.99 L 20.49 19 l -4.99 -5 Z m -6 0 C 7.01 14 5 11.99 5 9.5 S 7.01 5 9.5 5 S 14 7.01 14 9.5 S 11.99 14 9.5 14 Z",
        title: "Search",
      };
    case "portrait":
      return {
        path1:
          "M 17 1.01 L 7 1 c -1.1 0 -1.99 0.9 -1.99 2 v 18 c 0 1.1 0.89 2 1.99 2 h 10 c 1.1 0 2 -0.9 2 -2 V 3 c 0 -1.1 -0.9 -1.99 -2 -1.99 Z M 17 19 H 7 V 5 h 10 v 14 Z",
        title: "Portrait",
      };
    case "laptop":
      return {
        path1:
          "M 20 18 c 1.1 0 2 -0.9 2 -2 V 6 c 0 -1.1 -0.9 -2 -2 -2 H 4 C 2.9 4 2 4.9 2 6 v 10 c 0 1.1 0.9 2 2 2 H 0 v 2 h 24 v -2 H 20 Z M 4 6 h 16 v 10 H 4 V 6 Z",
        title: "Laptop",
      };
    case "responsive":
      return {
        path1:
          "M 4 6 h 18 V 4 H 4 c -1.1 0 -2 0.9 -2 2 v 11 H 0 v 3 h 14 v -3 H 4 V 6 Z m 19 2 h -6 c -0.55 0 -1 0.45 -1 1 v 10 c 0 0.55 0.45 1 1 1 h 6 c 0.55 0 1 -0.45 1 -1 V 9 c 0 -0.55 -0.45 -1 -1 -1 Z m -1 9 h -4 v -7 h 4 v 7 Z",
        title: "Responsive Design",
      };
    case "keyboard_tab":
      return {
        path1:
          "M 11.59 7.41 L 15.17 11 H 1 v 2 h 14.17 l -3.59 3.59 L 13 18 l 6 -6 l -6 -6 l -1.41 1.41 Z M 20 6 v 12 h 2 V 6 h -2 Z",
        title: "Tab",
      };
    case "folder":
      return {
        path1:
          "M 10 4 H 4 c -1.1 0 -1.99 0.9 -1.99 2 L 2 18 c 0 1.1 0.9 2 2 2 h 16 c 1.1 0 2 -0.9 2 -2 V 8 c 0 -1.1 -0.9 -2 -2 -2 h -8 l -2 -2 Z",
        title: "Folder",
      };
    case "archive":
      return {
        path1:
          "M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM12 17.5L6.5 12H10v-2h4v2h3.5L12 17.5zM5.12 5l.81-1h12l.94 1H5.12z",
        title: "Archive",
      };
    case "unarchive":
      return {
        path1:
          "M20.55 5.22l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.15.55L3.46 5.22C3.17 5.57 3 6.01 3 6.5V19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.49-.17-.93-.45-1.28zM12 9.5l5.5 5.5H14v2h-4v-2H6.5L12 9.5zM5.12 5l.82-1h12l.93 1H5.12z",
        title: "Archive",
      };
    default:
      console.log("In Icon. SWITCH DID NOT BREAK!", name);
      return {
        path1: undefined,
        title: "",
      };
  }
};

interface IconProps {
  name: IconName;
  fill?: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  classApply?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
}

const Icon: FC<IconProps> = React.memo(
  ({
    name,
    width = "1.8em", //"24",
    height = "1.8em", //"24",
    style,
    fill,
    viewBox = "0 0 24 24",
    classApply,
    onClick,
  }) => {
    classApply = classApply ? classApply : "";
    const rects = getData(name).rects;
    return (
      <svg
        id={name}
        width={width}
        height={height}
        style={style}
        className={"icon_svg " + classApply}
        viewBox={viewBox}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        onClick={onClick}
      >
        <title id={name + "Title"}>{getData(name).title}</title>
        <path d={getData(name).path1} fill={fill} />
        <path d={getData(name).path2} fill={fill} />
        <g>
          <path d={getData(name).g && getData(name).g?.path1} fill={fill} />
          <path d={getData(name).g && getData(name).g?.path2} fill={fill} />
          <path d={getData(name).g && getData(name).g?.path3} fill={fill} />
        </g>
        <circle
          cx={getData(name).cx}
          cy={getData(name).cy}
          r={getData(name).r}
          fill={fill}
        ></circle>
        {rects &&
          rects.map((rect, index) => (
            <rect
              key={index}
              fill={fill}
              height={rect.height}
              width={rect.width}
              x={rect.x}
              y={rect.y}
            ></rect>
          ))}
      </svg>
    );
  }
);

export default Icon;
