import { useState, useEffect } from "react";
import * as R from "ramda";
import usePrevious from "../../../utilities/usePrevious";
import useToast from "../../Main/useToast";
import { useTranslation } from "react-i18next";
import { TOASTDURATION_ERROR } from "../../../utilities/constants/appConstants";

/**
 * Helper function for useInventory. Stores a piece of inventory state, and
 * also keeps an eye out for conflicting publishes.
 */
export const useStateWithConflictResolution = <T>(
  realValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState(realValue);
  const dirty = !R.equals(value, realValue);
  const previousRealValue = usePrevious(realValue);
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (dirty) {
      const callback = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = "";
      };
      window.addEventListener("beforeunload", callback);
      return () => window.removeEventListener("beforeunload", callback);
    }
  }, [dirty]);

  useEffect(() => {
    if (!previousRealValue && realValue) {
      // Loading complete
      setValue(realValue);
    } else if (previousRealValue && !R.equals(realValue, previousRealValue)) {
      // New data was published.
      const wasDirty = !R.equals(value, previousRealValue);
      const matchesLocalEdits = R.equals(value, realValue);
      if (dirty && wasDirty && !matchesLocalEdits) {
        // sadly, we need to throw out the changes that were made so far
        toast({
          message: t("dashboard.catalog.conflict"),
          className: "dBthemeAlert1",
          duration: TOASTDURATION_ERROR,
        });
      }
      setValue(realValue);
    }
  }, [dirty, previousRealValue, realValue, t, toast, value]);

  return [value, setValue];
};
