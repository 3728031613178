import React, { useContext, useRef, Suspense, FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { FirebaseContext } from "../../Firebase";
import useStore from "../../customization/useStore";
import "./css/global.css";
import Icon from "../Icon/icon";
import { Switch, Route, RouteChildrenProps } from "react-router-dom";
import * as ROUTES from "../../utilities/constants/routes";
import HomePage from "./Pages/home";
import OrderPage from "./Pages/order";
import TrackPage from "./Pages/track";
import FavoritesPage from "./Pages/favorites";
import PaymentAuthPage from "./Pages/paymentAuth";
import CartPage from "./Pages/cart";
import CheckoutPage from "./Pages/checkout";
import CartIndicator from "../CartIndicator/cartIndicator";
import useModal from "../Modal/useModal";
import STauthModal from "./Modals/sTauth";
import { hexToRgbString } from "../../utilities/colorUtils";
import { useTranslation } from "react-i18next";
import { toTitleCase } from "../../utilities/stringFunctions";
import useAuthUser from "../AuthUserProvider/useAuthUser";
import { menuItemKeys, defaultConfigs } from "./menuItems";
import { IconName } from "../Icon/IconName";
import { Category_Database } from "../../database/catalog";
import STSendMessageModal from "./Modals/sTsendMessage";
import useHover from "../Main/useHover";
import { Message } from "../../database/message";
import STMessageThreadModal from "./Modals/sTmessageThread";
import { useMessageThreads } from "../../utilities/useMessageThreads";
import Spinner from "../Spinner/spinner";
import PageTemplateLoader from "./Templates/pageTemplateLoader";
import { createDefaultExtraPages } from "../Dashboard/Settings/extraPages";
import { ModalBackgroundState } from "../Modal/modalManager";
import { TOASTDURATION_ERROR } from "../../utilities/constants/appConstants";
import useToast from "../Main/useToast";
import PreviewOverlay from "./Components/previewOverlay";
import ModalPortal from "../Modal/modalPortal";
import MsgRouterSO from "./Components/msgRouterSO";
const AccountPage = React.lazy(() => import("./Pages/account"));
const subpages = {
  main: undefined,
  category: "category",
  settings: "settings",
  favorites: "favorites",
};

interface SubpageConfig {
  omitFromMenu?: boolean;
  text?: string;
  icon?: IconName;
  route?: string;
  exact?: boolean;
  component?: React.ComponentType<any>;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  iconFill?: string;
  props?: object;
}

export interface StoreProps
  extends RouteChildrenProps<{ subpage: string; index: string }> {
  dBnavMenu: boolean;
  setdBNavMenu: React.Dispatch<React.SetStateAction<boolean>>;
  dBnavMenuFull: boolean;
  setdBNavMenuFull: React.Dispatch<React.SetStateAction<boolean>>;
  animContainer: React.Ref<HTMLDivElement>;
}

const Store: FC<StoreProps> = React.memo(
  ({
    history,
    match,
    dBnavMenu,
    setdBNavMenu,
    dBnavMenuFull,
    setdBNavMenuFull,
    animContainer,
  }) => {
    const location = useLocation<ModalBackgroundState>();
    const { user, isAdmin, isSuperuser } = useAuthUser();
    const [hoverRef, isHovered] = useHover();
    const toast = useToast();
    const showModal = useModal();
    // When launching a modal, we may set a background property which defines what page to
    //   show behind the modal. This allows the previous page to stay visible, even though
    //   we've routed to the modal.
    const background = location.state && location.state.background;

    const firebase = useContext(FirebaseContext);
    const store = useStore();
    const {
      theme,
      _name,
      homePageOptions,
      isPreview,
      previewReturnUrl,
    } = store;

    const menuConfig = homePageOptions?.menuConfig ?? {};
    const { mainColor, color, mainColorDark } = theme;
    const mainColorRGB = hexToRgbString(mainColor);
    const { t } = useTranslation();

    const { threads: messageThreads } = useMessageThreads({
      userId: user?.uid ?? null,
    });
    // const msgsOrder = useMemo(() => {
    //   return messageThreads.filter((message) => {
    //     return message.orderId;
    //   });
    // }, [messageThreads]);
    const msgsNonOrder = useMemo(() => {
      return messageThreads.filter((message) => {
        return !message.orderId;
      });
    }, [messageThreads]);
    const getTextAndIcon = (key: menuItemKeys) => {
      return {
        text: toTitleCase(
          menuConfig[key]?.label ?? t(defaultConfigs[key].label)
        ),
        icon: menuConfig[key]?.icon ?? defaultConfigs[key].icon,
      };
    };
    // These configurations determine the contents of the menu
    // and the direction of the slide animation. To represent the
    // groups of menu items, a nested array is used
    const subpageConfigs: SubpageConfig[][] = [
      [
        {
          ...getTextAndIcon(menuItemKeys.HOME),
          route: ROUTES.STORE,
          exact: true,
          component: HomePage,
        },
      ],
      [
        {
          ...getTextAndIcon(menuItemKeys.ORDER),
          route: ROUTES.ORDER,
          component: OrderPage,
          iconFill: "greenyellow",
        },
        {
          ...getTextAndIcon(menuItemKeys.TRACK),
          route: ROUTES.TRACK,
          component: TrackPage,
        },
        {
          ...getTextAndIcon(menuItemKeys.FAVORITES),
          route: ROUTES.FAVORITES,
          component: FavoritesPage,
        },
        // These items aren't in the sidebar menu, but they still
        //   need to be here to determine animation direction.
        {
          omitFromMenu: true,
          route: ROUTES.CART,
          component: CartPage,
        },
        {
          omitFromMenu: true,
          route: ROUTES.CHECKOUT,
          component: CheckoutPage,
        },
        {
          omitFromMenu: true,
          route: `${ROUTES.PAYMENTAUTH}`,
          component: PaymentAuthPage,
        },
      ],
      [
        {
          ...getTextAndIcon(user ? menuItemKeys.LOGOUT : menuItemKeys.LOGIN),
          onClick: () => {
            dBnavMenu && setdBNavMenu(false);
            if (store.isPreview) {
              toast({
                message: t("dashboard.catalog.disabledDueToPreview"),
                className: "sTthemeAlert1b",
                duration: TOASTDURATION_ERROR,
              });
              return;
            } else if (user) {
              firebase.signOut();
            } else {
              showModal(
                STauthModal,
                {
                  action: "signin",
                },
                ROUTES.ML_SIGNIN
              );
            }
          },
          iconFill: user ? undefined : "greenyellow",
        },
        {
          ...getTextAndIcon(menuItemKeys.ACCOUNT),
          route: ROUTES.ACCOUNT,
          component: AccountPage,
        },
        {
          ...getTextAndIcon(menuItemKeys.CONTACT),
          onClick: () => {
            dBnavMenu && setdBNavMenu(false);
            if (msgsNonOrder.length === 0) {
              showModal(
                STSendMessageModal,
                {
                  orderId: null,
                },
                ROUTES.ML_CONTACT
              );
            } else {
              showModal(
                STMessageThreadModal,
                {
                  threadId: msgsNonOrder[0].threadId,
                  initialThread: msgsNonOrder[0],
                },
                ROUTES.ML_CONTACT
              );
            }
          },
          iconFill:
            messageThreads[0] &&
            !messageThreads[0].orderId &&
            Object.values(messageThreads[0].messages).some(
              (message: Message) => message.from === "admin" && !message.read
            )
              ? "greenyellow"
              : undefined,
        },
        // Custom extra pages will be inserted here, but they're done a little
        // lower down, since they need to check what urls are being used to
        // avoid collisions.
      ],
    ];

    const showDashboard = isAdmin || isSuperuser;
    if (showDashboard) {
      subpageConfigs[subpageConfigs.length - 1].push({
        text: "Admin",
        route: store.previewReturnUrl || ROUTES.DASHBOARD,
        icon: "dashboard",
        iconFill: "orangered",
      });
    }

    const flatMenu: SubpageConfig[] = [];
    subpageConfigs.forEach((section) => {
      flatMenu.push(...section);
    });

    let paths = new Set<string>();
    flatMenu.forEach((config) => {
      if (config.route) {
        paths.add(config.route);
      }
    });

    // Add custom extra pages, but avoid route path collisions
    const extraPageConfigs: SubpageConfig[] = [];
    (store.extraPageOptions || createDefaultExtraPages(store, t)).pages.forEach(
      (config) => {
        let path = config.path;
        let suffix = 0;
        while (paths.has(path)) {
          suffix++;
          path = config.path + suffix;
        }
        paths.add(path);
        extraPageConfigs.push({
          text: config.label,
          icon: config.icon,
          route: path,
          props: { template: config.template },
          component: PageTemplateLoader,
        });
      }
    );

    const lastSection = subpageConfigs[subpageConfigs.length - 1];
    const offset = showDashboard ? 1 : 0; // Account for any menu items that show after the extra page links
    lastSection.splice(lastSection.length - offset, 0, ...extraPageConfigs);
    flatMenu.splice(flatMenu.length - offset, 0, ...extraPageConfigs);

    let subpage = "";
    let index = "0";
    if (match?.params) {
      ({ subpage, index } = match.params);
    }
    // Remember the most recent category (allows them to animate out)
    const categoryRef = useRef<Category_Database>();
    // Determines whether animations go to the left or right when transitioning

    const isCategorySubpage = subpage === subpages.category;
    if (isCategorySubpage) {
      categoryRef.current = store.catalog.categories[Number(index)];
    }

    const isRoute = (pathName: string, route: string) => {
      return pathName === route || pathName.startsWith(route + "/");
    };
    return (
      <React.Fragment>
        {isPreview && <PreviewOverlay />}
        <Switch>
          <Route path="/l" render={(props) => <MsgRouterSO />} />
          <Route
            path={ROUTES.SIGNIN}
            render={(props) => (
              <ModalPortal>
                <STauthModal
                  action="signin"
                  closeModal={(shouldGoBack) => {
                    if (shouldGoBack !== false) {
                      history.replace("/");
                    }
                  }}
                />
              </ModalPortal>
            )}
          />
          <Route
            path="/messages/:threadId"
            render={(props) => (
              <ModalPortal>
                <STMessageThreadModal
                  threadId={props.match.params.threadId}
                  closeModal={() => history.push("/")}
                />
              </ModalPortal>
            )}
          />
        </Switch>
        <div
          ref={hoverRef}
          style={{
            width:
              dBnavMenu || dBnavMenuFull ? undefined : isHovered ? "8em" : "",
          }}
          className={
            dBnavMenu || dBnavMenuFull
              ? "sTpanelSide sTnavMenuLeft1"
              : "sTpanelSide"
          }
        >
          <div className="sTnavBar AppFontSizeSmall">
            {subpageConfigs.map((section, i) => (
              <React.Fragment key={i}>
                <div
                  className="sTnavBarGroup"
                  style={i === 0 ? { paddingTop: 0 } : undefined}
                >
                  {section.map(
                    (
                      { route, icon, iconFill, text, onClick, omitFromMenu },
                      sectionKey
                    ) =>
                      !omitFromMenu && (
                        <div
                          key={sectionKey}
                          style={{
                            backgroundColor:
                              route === ROUTES.STORE ? "transparent" : "",
                          }}
                          className="sTnavBarItem"
                          onClick={
                            onClick
                              ? onClick
                              : () => {
                                  // console.log("store.tsx", route);
                                  dBnavMenu && setdBNavMenu(false);
                                  if (route !== undefined) {
                                    history.push(route);
                                  } else {
                                    console.warn(
                                      "no route wasnt defined, nor was there a custom onclick"
                                    );
                                  }
                                }
                          }
                        >
                          <span className="sTnavBarItemIcon">
                            <Icon
                              name={icon ?? "no_icon"}
                              fill={iconFill || "#FFF"}
                            />
                          </span>
                          <div
                            style={{
                              transition: "all 0.1s linear 0s",
                              opacity:
                                isHovered || dBnavMenuFull || dBnavMenu ? 1 : 0,
                              whiteSpace: !dBnavMenuFull ? "nowrap" : undefined,
                            }}
                            className="sTnavBarText"
                          >
                            {text}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </React.Fragment>
            ))}
            <div
              onClick={
                !dBnavMenuFull
                  ? () => setdBNavMenuFull(true)
                  : () => setdBNavMenuFull(false)
              }
              className="sTnavBarFooterToggle"
            >
              <Icon
                style={{ opacity: !dBnavMenu ? 1 : 0 }}
                name={
                  dBnavMenu || dBnavMenuFull
                    ? "triangle_left"
                    : "triangle_right"
                }
                fill="#FFF"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "",
            backgroundColor: mainColor,
          }}
          className="sTheader"
        >
          <div
            onClick={() => {
              //MOBILE OPEN DRAWER ICON
              history.push(location.pathname + location.search, location.state);
              setdBNavMenu(true);
            }}
            className="sTnavBarHdrItem"
          >
            <Icon name="menu_toggle" fill={color} />
          </div>
        </div>
        <div
          className={
            dBnavMenuFull ? "sTStoreHeader sTStoreHeader2" : "sTStoreHeader"
          }
          style={{
            transition: "all 0.5s linear 0.3s",
            backgroundColor:
              isRoute(location.pathname, ROUTES.ORDER) ||
              isRoute(location.pathname, ROUTES.TRACK) ||
              isRoute(location.pathname, ROUTES.FAVORITES) ||
              isRoute(location.pathname, ROUTES.CART) ||
              isRoute(location.pathname, ROUTES.CHECKOUT) ||
              isRoute(location.pathname, ROUTES.PAYMENTAUTH)
                ? mainColorDark
                : `rgba(${mainColorRGB},0.5)`,
            color,
            fontWeight: "bold",
          }}
        >
          <span
            style={{
              padding: "0.25em 0.25em 0.25em 0",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            onClick={() => {
              if (previewReturnUrl) {
                history.push(previewReturnUrl);
              } else if (!dBnavMenuFull) {
                //MOBILE OPEN DRAWER ICON
                history.push(
                  location.pathname + location.search,
                  location.state
                );
                setdBNavMenu(true);
              }
            }}
          >
            &nbsp;{_name}
          </span>
          <CartIndicator color={color} />
        </div>
        <div
          ref={animContainer}
          className={
            dBnavMenuFull ? "sTcontent_anim sTcontent_anim2" : "sTcontent_anim"
          }
        >
          <Suspense fallback={<Spinner isOpen dBnavMenuFull={dBnavMenuFull} />}>
            <Switch>
              {flatMenu.map(
                ({ route, component: Component, exact, props }, menuKey) =>
                  route &&
                  Component && (
                    <Route
                      key={menuKey}
                      path={route}
                      exact={exact}
                      render={(routeProps) => (
                        <Route location={background || location}>
                          <Component {...routeProps} {...props} />
                        </Route>
                      )}
                    />
                  )
              )}
            </Switch>
          </Suspense>
        </div>

        {(isRoute((background || location).pathname, ROUTES.ORDER) ||
          isRoute((background || location).pathname, ROUTES.FAVORITES) ||
          isRoute((background || location).pathname, ROUTES.TRACK)) && (
          <div
            className={
              dBnavMenuFull ? "sTFooterWrap sTFooterWrap2" : "sTFooterWrap"
            }
            style={{ backgroundColor: mainColorDark, color: color }}
          >
            <div className="sTFooter AppFontSizeSmall">
              <div
                onClick={() => {
                  history.push(ROUTES.ORDER);
                }}
                className="sTFooterItem"
              >
                <Icon
                  name={getTextAndIcon(menuItemKeys.ORDER).icon}
                  fill={color}
                />
                &nbsp;{getTextAndIcon(menuItemKeys.ORDER).text}
              </div>
              <div
                onClick={() => {
                  history.push(ROUTES.TRACK);
                }}
                className="sTFooterItem"
              >
                <Icon
                  name={getTextAndIcon(menuItemKeys.TRACK).icon}
                  fill={color}
                />
                &nbsp;{getTextAndIcon(menuItemKeys.TRACK).text}
              </div>
              <div
                onClick={() => {
                  history.push(ROUTES.FAVORITES);
                }}
                className="sTFooterItem"
              >
                <Icon
                  name={getTextAndIcon(menuItemKeys.FAVORITES).icon}
                  fill={color}
                />
                &nbsp;{getTextAndIcon(menuItemKeys.FAVORITES).text}
              </div>
            </div>
            <div
              style={{
                transition: "all 0.3s ease-out 0s",
                height: "1px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: color,
                position: "absolute",
                bottom: "0",
                left: isRoute((background || location).pathname, ROUTES.ORDER)
                  ? "0"
                  : isRoute((background || location).pathname, ROUTES.TRACK)
                  ? "33%"
                  : "67%",
                width: "33%",
              }}
            >
              &nbsp;
            </div>
          </div>
        )}
        <div
          onClick={() => {
            history.goBack();
            setdBNavMenu(false);
          }}
          className={dBnavMenu ? "sTnavBarVeil sTveilOpen" : "sTnavBarVeil"}
        />
      </React.Fragment>
    );
  }
);

export default Store;
