import React, { useState, useContext, FC } from "react";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import { FirebaseContext } from "../../../Firebase";
import useSpinner from "../../Main/useSpinner";
import useToast from "../../Main/useToast";
import useLockDimensions from "../../Main/useLockDimensions";
import ModalVeil from "../../Modal/modalVeil";
import { useTranslation } from "react-i18next";
import * as APPCONSTANTS from "../../../utilities/constants/appConstants";
import { ModalProps } from "../../Modal/modalManager";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import useAccountInfo from "../../Main/useAccountInfo";
import { hexToRgbString } from "../../../utilities/colorUtils";
import { validatePhone } from "../../../utilities/validateEmailPassPhone";

interface STaccPhoneModalProps extends ModalProps {
  source?: "sms";
}

const STaccPhoneModal: FC<STaccPhoneModalProps> = React.memo(
  ({ source, closeModal }) => {
    const { user } = useAuthUser();
    const accountInfo = useAccountInfo();
    const showSpinner = useSpinner();
    const { t } = useTranslation();
    const toast = useToast();
    const lockDimensions = useLockDimensions();
    const firebase = useContext(FirebaseContext);
    const store = useStore();
    const { theme } = store;
    const { mainColor, color, mainColorDark } = theme;

    const [newAccount, setNewAccount] = useState({
      phone: accountInfo.phone,
    });

    const onSubmit = () => {
      changePhone();
    };

    const changePhone = async () => {
      if (!user) {
        return;
      }
      if (
        newAccount.phone &&
        newAccount.phone.length > 0 &&
        !validatePhone(newAccount.phone)
      ) {
        toast({
          message: t("store.account.providePhoneSMS"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
      } else {
        const hideSpinner = showSpinner();
        try {
          await firebase.firestore
            .collection("stores")
            .doc("store")
            .collection("users")
            .doc(user.uid)
            .update({
              "contactInfo.phone": newAccount.phone.trim(),
              "notifications.sms":
                source === "sms" && newAccount.phone.trim().length > 3
                  ? true
                  : newAccount.phone.trim().length < 4
                  ? false
                  : accountInfo.notifySms,
            });
          toast({
            message: t("store.toast.updatePhone"),
            color: color,
            backgroundColor: mainColor,
          });
          closeModal();
        } catch (error) {
          console.log("sTaccPhone.js", error);
          toast({
            message: t("toast.systemError"),
            className: "sTthemeAlert1b",
            duration: APPCONSTANTS.TOASTDURATION_ERROR,
          });
        } finally {
          hideSpinner();
        }
      }
    };

    return (
      <React.Fragment>
        <div
          style={{
            display: "block",
            color: color,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader"
            style={{
              backgroundColor: mainColorDark,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                color: color,
              }}
            >
              <Icon name="phone" fill={color} />
              <div>&nbsp;{t("store.account.phoneTitle")}</div>
            </div>
            <br />
            <div className="AppFontSizeSmall" style={{ color: color }}>
              {user?.email}
            </div>
            <div className="AppFontSizeSmall" style={{ color: color }}>
              <div style={{ fontWeight: "bold" }}>
                {t("store.account.phoneSubTitle")}
              </div>
              <div></div>
            </div>
          </div>
          <div className="sTmodalContent">
            <React.Fragment>
              <input
                name="phone"
                value={newAccount.phone}
                onChange={(e) => {
                  setNewAccount({
                    phone: e.currentTarget.value,
                  });
                }}
                id="phone"
                type="text"
                placeholder={t("store.account.phonePlaceholder")}
                onFocus={() => lockDimensions(true, "account")}
              />
            </React.Fragment>
          </div>
          <div
            className="sTmodalFooter AppFontSizeSmall"
            style={{
              backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
            }}
          >
            <div
              onClick={closeModal}
              className="sTmodalFooterItem AppFontSizeSmall"
              style={{ color: color }}
            >
              {t("common.cancel")}
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: color,
              }}
              onClick={onSubmit}
              className="sTmodalFooterItem AppFontSizeSmall"
            >
              {t("common.submit")}
            </div>
          </div>
        </div>
        <ModalVeil onClick={closeModal} />
      </React.Fragment>
    );
  }
);

export default STaccPhoneModal;
