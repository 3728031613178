import {
  SuborderCollection,
  Suborder,
  CartItem,
  OptionSelection,
  DatabaseOptionSelection,
  DatabaseCartItemCollection,
  DatabaseSuborderCollection,
  DatabaseSuborder,
  DatabaseCartItem,
  isNumberOptionSelection,
  isListOptionSelection,
  isUnitOptionSelection,
  UnitOptionSelection,
  DatabaseNumberOptionSelection,
  DatabaseListOptionSelection,
  DatabaseUnitOptionSelection
} from "../database/cart";
import * as R from "ramda";
import { StoreLocation } from "../database/storeLocation";
import {
  listOptionPriceMode,
  isNumberOption,
  isListOption,
  isUnitOption,
  UnitOption
} from "../database/option";
import Dinero from "dinero.js";
import memoize from "memoize-immutable";

/**
 * **********************************************
 * This file is used by both the client side code
 * and the serverside code
 * **********************************************
 */

export const initDinero = (location: StoreLocation): void => {
  const {
    currency,
    precision,
    locale,
    roundingMode,
    format
  } = location.currency;

  Dinero.defaultCurrency = currency || "USD";
  Dinero.defaultPrecision = typeof precision === "number" ? precision : 2;
  Dinero.globalLocale = locale || "en-US";
  Dinero.globalRoundingMode = roundingMode || "HALF_AWAY_FROM_ZERO";
  Dinero.globalFormat = format || "$0,0.00";
};

const isSuborder = (
  maybeSuborder: Suborder | SuborderCollection
): maybeSuborder is Suborder => !!maybeSuborder.items;

export interface PriceAndUnit {
  price: Dinero.Dinero;
  tax: Dinero.Dinero;
  taxPercentage: number;
  unit?: UnitOption;
  unitSelection?: UnitOptionSelection;
}

/**
 * Calculates the cost of the item taking into account all
 * option selections, except for the unit which is split out
 */
export const itemPricePerUnit = (
  itemId: string | undefined,
  location: StoreLocation,
  optionSelections?: OptionSelection[],
  quantity = 1
): PriceAndUnit => {
  const { items, options } = location;
  const catalogItem = itemId && items[itemId];
  if (!catalogItem) {
    return {
      price: Dinero({ amount: 0 }),
      tax: Dinero({ amount: 0 }),
      taxPercentage: location.taxPercentage
    };
  }

  let price = Dinero({ amount: catalogItem.price });
  if (quantity !== 1) {
    price = price.multiply(quantity);
  }
  let unit = undefined;
  let unitSelection = undefined;

  if (optionSelections) {
    optionSelections.forEach(selection => {
      const option = options[selection.optionId];
      if (isListOption(option) && isListOptionSelection(selection)) {
        option.items.forEach(optionItem => {
          if (selection.selectedItems[optionItem.id]) {
            if (option.priceMode === listOptionPriceMode.ADD) {
              price = price.add(Dinero({ amount: optionItem.price }));
            }
          }
        });
      } else if (isNumberOption(option) && isNumberOptionSelection(selection)) {
        price = price.add(
          Dinero({
            amount: (option.price / option.priceDenominator) * selection.value
          })
        );
      } else if (isUnitOption(option) && isUnitOptionSelection(selection)) {
        unit = option;
        unitSelection = selection;
      }
    });
  }

  const taxPercentage = catalogItem.taxPercentage ?? location.taxPercentage;
  let tax;
  if (location.priceIncludesTax) {
    tax = price.multiply(taxPercentage / 100).divide(1 + taxPercentage / 100);
  } else {
    tax = price.multiply(taxPercentage / 100);
  }

  return { price, tax, taxPercentage, unit, unitSelection };
};

/**
 * Calculates the cost of the item taking into account all
 * option selections, including unit and quantity.
 */
export const finalItemPrice = (
  itemId: string,
  location: StoreLocation,
  optionSelections?: OptionSelection[]
): Dinero.Dinero => {
  let { price, unit, unitSelection } = itemPricePerUnit(
    itemId,
    location,
    optionSelections
  );

  if (unit && unitSelection) {
    price = price.multiply(unitSelection.value / unit.priceDenominator);
  }

  return price;
};

/**
 * Converts a cart item into a string. If the strings are identical, then the
 * two items are treated as treated as equivalent.
 */
export const getSignature = (item: CartItem | DatabaseCartItem): string => {
  return (
    item.itemId + item.instructions + JSON.stringify(item.optionSelections)
  );
};

/**
 * Creates an array of all equivalent cart items (including the input item).
 * Two items are equivalent if they are for the same catalog item and have all
 * the same options.
 */
export const findEquivalent = <T extends CartItem | DatabaseCartItem>(
  item: T,
  items: Record<string, T>
): T[] => {
  const signature = getSignature(item);
  const equivalentItems = R.filter(
    (item: CartItem) => getSignature(item) === signature,
    Object.values(items)
  );
  return equivalentItems;
};

/**
 * Splits up a collection of cart items into groups of equivalent items.
 *
 * For example, if there are 4 vanilla ice creams with no toppings,
 * 5 vanilla ice creams with sprinkles, and 6 chocolate icecream then
 * the return value is an outer array with three subarrays, with 4, 5,
 * and 6 items respectively
 */
export const groupEquivalent = <T extends CartItem | DatabaseCartItem>(
  items: Record<string, T>
): T[][] => {
  const values = Object.values(items);
  return Object.values(R.groupBy(getSignature, values));
};

export interface Totals {
  itemCount: number;
  preTax: Dinero.Dinero;
  taxTable: Record<string, Dinero.Dinero>;
  totalTax: Dinero.Dinero;
  withTax: Dinero.Dinero;
}

export const getTotals = (
  suborders: Suborder | SuborderCollection,
  location: StoreLocation
): Totals => {
  const { priceIncludesTax } = location;
  if (!isSuborder(suborders)) {
    // Recurse to get the result for the individual suborders, and sum them
    let accumulatedResult: Totals = {
      itemCount: 0,
      preTax: Dinero({ amount: 0 }),
      taxTable: {},
      totalTax: Dinero({ amount: 0 }),
      withTax: Dinero({ amount: 0 })
    };

    Object.values(suborders).forEach(suborder => {
      const result = getTotals(suborder, location);
      accumulatedResult.itemCount += result.itemCount;
      accumulatedResult.preTax = accumulatedResult.preTax.add(result.preTax);
      Object.entries(result.taxTable).forEach(([taxRate, tax]) => {
        if (accumulatedResult.taxTable[taxRate]) {
          accumulatedResult.taxTable[taxRate] = accumulatedResult.taxTable[
            taxRate
          ].add(tax);
        } else {
          accumulatedResult.taxTable[taxRate] = tax;
        }
      });
      accumulatedResult.totalTax = accumulatedResult.totalTax.add(
        result.totalTax
      );
      accumulatedResult.withTax = accumulatedResult.withTax.add(result.withTax);
    });
    return accumulatedResult;
  }

  let itemCount = 0;
  let preTax = Dinero({ amount: 0 });
  const taxTable: Record<string, Dinero.Dinero> = {};
  let withTax = Dinero({ amount: 0 });

  // Doing lots of computations with Dinero objects can be time intensive,
  //   so it's quicker to group them, then let the magic of multiplication
  //   reduce the number of math operations.
  groupEquivalent(suborders.items).forEach((group: CartItem[]) => {
    const cartItem = group[0];
    itemCount += group.length;
    const ableCount = group.filter(cartItem => !cartItem.unable).length;
    if (ableCount === 0) {
      return; // Doesn't contribute to price
    }

    const price = finalItemPrice(
      cartItem.itemId,
      location,
      cartItem.optionSelections
    ).multiply(ableCount);
    if (priceIncludesTax) {
      withTax = withTax.add(price);
    } else {
      preTax = preTax.add(price);
    }

    const catalogItem = location.items[cartItem.itemId];
    let taxRate = location.taxPercentage;
    if (catalogItem) {
      taxRate = catalogItem.taxPercentage ?? location.taxPercentage;
    }

    if (!taxTable[taxRate]) {
      taxTable[taxRate] = Dinero({ amount: 0 });
    }

    if (priceIncludesTax) {
      taxTable[taxRate] = taxTable[taxRate].add(
        price.multiply(taxRate / 100).divide(1 + taxRate / 100)
      );
    } else {
      taxTable[taxRate] = taxTable[taxRate].add(price.multiply(taxRate / 100));
    }
  });

  let totalTax = Dinero({ amount: 0 });
  if (!priceIncludesTax) {
    withTax = preTax;
  }

  Object.values(taxTable).forEach(tax => {
    totalTax = totalTax.add(tax);
    if (priceIncludesTax) {
      preTax = withTax.subtract(tax);
    } else {
      withTax = withTax.add(tax);
    }
  });

  return {
    itemCount,
    preTax,
    taxTable,
    totalTax,
    withTax
  };
};

/**
 * Exported separately because the server side wants the function, but doesn't want it memoized
 *
 * We call this multiple times with different parameters, so memoizing just one value
 * is not sufficient.
 */
export const memoizedGetTotals = memoize(getTotals, { limit: 20 });

/**
 * Appends price information to optionSelections.
 * This gets stored in the database so we have a record
 * of what it cost at the time of purchase
 */
export const addOptionSelectionPrices = (
  selections: OptionSelection[] | undefined,
  location: StoreLocation
): DatabaseOptionSelection[] => {
  if (!selections) {
    return [];
  }
  const { options } = location;
  const newSelections: DatabaseOptionSelection[] = [];
  selections.forEach(selection => {
    let option = options[selection.optionId];
    if (isListOption(option) && isListOptionSelection(selection)) {
      let price = 0;
      let itemPrices: { [id: string]: number } = {};
      option.items.forEach(item => {
        if (selection.selectedItems[item.id]) {
          itemPrices[item.id] = item.price;
          price += item.price;
        }
      });
      const sel: DatabaseListOptionSelection = {
        ...selection,
        price,
        itemPrices
      };
      newSelections.push(sel);
    } else if (isNumberOption(option) && isNumberOptionSelection(selection)) {
      const sel: DatabaseNumberOptionSelection = {
        ...selection,
        price: (option.price / option.priceDenominator) * selection.value
      };
      newSelections.push(sel);
    } else if (isUnitOption(option) && isUnitOptionSelection(selection)) {
      const sel: DatabaseUnitOptionSelection = {
        ...selection,
        priceDenominator: option.priceDenominator
      };
      newSelections.push(sel);
    } else {
      // Option not found, or there's a mismatch of types.
      return;
    }
  });
  return newSelections;
};

/**
 * Appends price information to items.
 * This gets stored in the database so we have a record
 * of what it cost at the time of purchase
 */
export const addItemPrices = (
  cartItem: CartItem,
  location: StoreLocation
): DatabaseCartItem | undefined => {
  const catalogItem = location.items[cartItem.itemId];
  if (!catalogItem) {
    return;
  }
  let basePrice = catalogItem.price;
  let priceWithOptions = catalogItem.price;
  let newSelections = addOptionSelectionPrices(
    cartItem.optionSelections,
    location
  );
  if (newSelections) {
    newSelections.forEach(selection => {
      if (!isUnitOptionSelection(selection)) {
        priceWithOptions += selection.price;
      }
    });
    newSelections.forEach(selection => {
      if (isUnitOptionSelection(selection)) {
        priceWithOptions *= selection.value / selection.priceDenominator;
      }
    });
  }

  return {
    ...cartItem,
    basePrice,
    priceWithOptions,
    taxPercentage: catalogItem.taxPercentage ?? location.taxPercentage,
    optionSelections: newSelections
  };
};

/**
 * Appends price information to suborders.
 * This gets stored in the database so we have a record
 * of what it cost at the time of purchase
 */
export const addSuborderPrices = (
  suborders: SuborderCollection,
  location: StoreLocation
): DatabaseSuborderCollection => {
  let result: DatabaseSuborderCollection = {};
  Object.values(suborders).forEach(suborder => {
    const newItems: DatabaseCartItemCollection = {};
    Object.values(suborder.items).forEach(cartItem => {
      const newItem = addItemPrices(cartItem, location);
      if (newItem) {
        newItems[cartItem.cartItemId] = newItem;
      }
    });
    const newSuborder: DatabaseSuborder = {
      ...suborder,
      items: newItems
    };
    result[suborder.suborderId] = newSuborder;
  });
  return result;
};
