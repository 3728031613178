import React, { FC } from "react";
import useStore from "../../../customization/useStore";
import Icon from "../../Icon/icon";
import useModal from "../../Modal/useModal";
import STauth from "../Modals/sTauth";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { useTranslation } from "react-i18next";

export interface SectionSOProps {
  source: string;
}

const SectionSO: FC<SectionSOProps> = ({ source }) => {
  const { user } = useAuthUser();
  const showModal = useModal();
  const store = useStore();
  const { t } = useTranslation();
  const { theme } = store;
  const { mainColorDark, mainColorLight, color, iconColor } = theme;
  if (user) {
    return null;
  }
  return (
    <div
      style={{
        margin: "0 auto",
        height: source !== "checkout" ? "100%" : "",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        color: source !== "account" ? "black" : "",
      }}
    >
      <div
        className="sT_anim_moveRightSmall"
        style={{
          display: "table",
          padding: "0.5em",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon
            name="membership"
            fill={source !== "account" ? iconColor : "white"}
          />
          &nbsp;
          {t("store.checkout.signInPrompt")}
        </div>
        <ul
          className="AppFontSizeSmall"
          style={{ margin: "0.5em 0.5em 1em 1.5em", textAlign: "left" }}
        >
          <li>{t("store.checkout.signInBenefitTrack")}</li>
          <li>{t("store.checkout.signInBenefitPromotions")}</li>
          <li>{t("store.checkout.signInBenefitChat")}</li>
          <li>{t("store.checkout.signInBenefitFavorites")}</li>
          <li>{t("store.checkout.signInBenefitsTransactions")}</li>
          <li>{t("store.checkout.signInBenefitsSettings")}</li>
          <li>{t("store.checkout.signInBenefitsMore")}</li>
        </ul>
      </div>
      <br />

      {source !== "checkout_datetime" ? (
        <div
          onClick={() => {
            showModal(STauth, {
              action: "signin",
            });
          }}
          style={{
            backgroundColor: mainColorDark,
            color: color,
            margin: "1em auto",
          }}
          className="sTbutton sT_anim_moveLeftSmall"
        >
          <div
            style={{
              backgroundColor: mainColorLight,
            }}
            className="sTbuttonIcon"
          >
            <Icon name="person_si" fill={color} />
          </div>
          <span
            className="sTbuttonText"
            style={{
              color: color,
            }}
          >
            {t("common.signIn")}
          </span>
          <div />
        </div>
      ) : (
        <div
          className="sT_anim_moveLeftSmall"
          onClick={() => {
            showModal(STauth, {
              action: "signin",
            });
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "1em auto 0 auto",
          }}
        >
          <Icon name="person_si" />
          &nbsp;{t("common.signInLower")}
        </div>
      )}
    </div>
  );
};

export default SectionSO;
