/**
 * Converts a string to title case. 
 * 
 * For example: 'HELLO world' becomes 'Hello World'
 */
export const toTitleCase = (str: string) => {
  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};
