import React, { FC, useState, useEffect } from "react";
import * as R from "ramda";
import { useTranslation } from "react-i18next";
import {
  ListOption,
  listSelectionType,
  ListOptionItem,
} from "../../../database/option";
import Icon from "../../Icon/icon";
import useLockDimensions from "../../Main/useLockDimensions";
import uuid from "uuid/v4";
import useStore from "../../../customization/useStore";
import DashboardButton from "../Components/dbButton";

export interface ListOptionsProps {
  option: ListOption;
  setOption: (option: ListOption) => void;
}

const createItem = (description: string): ListOptionItem => ({
  description,
  price: 0,
  priceString: "",
  id: uuid(),
});

const ListOptionSettings: FC<ListOptionsProps> = ({ option, setOption }) => {
  const { t } = useTranslation();
  const lockDimensions = useLockDimensions();
  const store = useStore();
  const [newItemLabel, setNewItemLabel] = useState("");

  const addNewItem = () => {
    setNewItemLabel("");
    setOption({
      ...option,
      items: [...option.items, createItem(newItemLabel)],
    });
  };

  useEffect(
    () => {
      if (option.name === "") {
        //DONT CREATE NEW ONE WHEN EDITING EXISTING OPTION
        addNewItem();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <React.Fragment>
      <div
        onClick={() =>
          setOption({
            ...option,
            selectionType:
              option.selectionType === listSelectionType.SINGLE
                ? listSelectionType.MULTIPLE
                : listSelectionType.SINGLE,
          })
        }
        style={{ display: "flex", alignItems: "center" }}
      >
        <Icon
          name={
            option.selectionType === listSelectionType.MULTIPLE
              ? "checkbox_done"
              : "checkbox_empty"
          }
        />
        <div style={{ paddingLeft: "0.25em" }}>
          <div>{t("dashboard.catalog.allowMultipleSelections")}</div>
          <div className="AppFontSizeSmall">
            {t("dashboard.catalog.allowMultipleSubtitle")}
          </div>
        </div>
      </div>
      {option.items.map((item, itemIndex) => (
        <div
          key={itemIndex}
          className="dBthemeBGcolor7"
          style={{
            padding: "0.25em",
            margin: "0.5em 0",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              padding: "0 0 0 0.25em",
            }}
          >
            <div className="AppFontSizeSmall" style={{}}>
              <strong>{t("dashboard.catalog.optionNameSection")}</strong>
              <div>{t("dashboard.catalog.optionNameSubtitle")}</div>
            </div>
            <DashboardButton
              text={t("common.remove")}
              style={{ color: "black", margin: "0 0.25em" }}
              theme="white"
              icon="delete_forever"
              iconColor="black"
              onClick={() =>
                setOption(
                  R.evolve(
                    {
                      items: R.remove<ListOptionItem>(itemIndex, 1),
                    },
                    option
                  )
                )
              }
            />
          </div>
          <input
            type="text"
            style={{
              margin: 0,
            }}
            placeholder={t("dashboard.catalog.customInputListEnterName")}
            onFocus={() => {
              lockDimensions(true, "DB inventory");
            }}
            value={item.description}
            onChange={(event) =>
              setOption(
                R.evolve(
                  {
                    items: {
                      [itemIndex]: (item: ListOptionItem) => ({
                        ...item,
                        description: event.target.value,
                      }),
                    },
                  },
                  option
                )
              )
            }
          />
          <div
            className="AppFontSizeSmall"
            style={{ padding: "0.5em 0.25em 0 0.25em" }}
          >
            <strong>{t("dashboard.catalog.additionalCharge")}</strong>
            <div>
              {t("dashboard.customFields.priceIncreasedBy2", {
                amount: !isNaN(Number(item.priceString))
                  ? Number(item.priceString).toLocaleString(
                      store.currency.locale,
                      {
                        style: "currency",
                        currency: store.currency.currency,
                      }
                    )
                  : (0).toLocaleString(store.currency.locale, {
                      style: "currency",
                      currency: store.currency.currency,
                    }),
              })}
            </div>
          </div>
          <div>
            <input
              type="number"
              style={{ marginTop: "0" }}
              placeholder={t("dashboard.catalog.customInputListEnterPrice")}
              onFocus={() => lockDimensions(true, "DB inventory")}
              value={item.priceString}
              onChange={(event) => {
                let price: string;
                price = Number(
                  parseFloat(event.target.value).toFixed(2)
                ).toString();
                setOption(
                  R.evolve(
                    {
                      items: {
                        [itemIndex]: (item: ListOptionItem) => ({
                          ...item,
                          priceString: price,
                          price: Number(price) * 100,
                        }),
                      },
                    },
                    option
                  )
                );
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              const newValue = !item.selectedByDefault;
              const mapIndexed = R.addIndex<ListOptionItem>(R.map);
              setOption(
                R.evolve(
                  {
                    items: mapIndexed((item, i) => {
                      if (i === itemIndex) {
                        return {
                          ...item,
                          selectedByDefault: newValue,
                        };
                      } else if (
                        option.selectionType === listSelectionType.SINGLE &&
                        newValue
                      ) {
                        return {
                          ...item,
                          selectedByDefault: false,
                        };
                      }
                      return item;
                    }),
                  },
                  option
                )
              );
            }}
          >
            <Icon
              name={item.selectedByDefault ? "checkbox_done" : "checkbox_empty"}
            />
            {t("dashboard.catalog.customInputListSelectedDefault")}
          </div>
        </div>
      ))}
      <DashboardButton
        text={t("dashboard.catalog.addCustomInputOption")}
        style={{ color: "black" }}
        theme="white"
        icon="add_sq"
        iconColor="black"
        onClick={addNewItem}
      />
    </React.Fragment>
  );
};

export default ListOptionSettings;
