import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export interface OkAndCancelProps {
  onOk: () => void;
  onCancel: () => void;
}

const OkAndCancel: FC<OkAndCancelProps> = ({ onOk, onCancel }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        onClick={onCancel}
        className="dBmodalFooterItem"
        style={{ fontWeight: "normal" }}
      >
        {t("common.cancel")}
      </div>
      <div onClick={onOk} className="dBmodalFooterItem">
        {t("common.submit")}
      </div>
    </React.Fragment>
  );
};

export default OkAndCancel;
