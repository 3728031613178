import { createContext, useContext } from "react";
import { paymentGateway } from "../../database/storeLocation";

export interface PaymentState {
  loading: boolean;
  type: paymentGateway;
  stripe: stripe.Stripe | null;
  xendit: Xendit.default | null;
}

export const PaymentContext = createContext<PaymentState>({} as PaymentState);

export const usePayment = () => useContext(PaymentContext);
