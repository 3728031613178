import React from "react";
import { useSettingsApi } from "./settingsApiContext";
import { useTranslation } from "react-i18next";
import OrderTimingInput from "./orderTimingInput";
import {
  useLocation,
  useHistory,
  matchPath,
  Switch,
  Route,
} from "react-router-dom";
import { DASHBOARD_SETTINGS } from "../../../utilities/constants/routes";
import DashboardButton from "../Components/dbButton";

export const defaultOrderIncrement = 15;

const OrderTimingSettings = () => {
  const { t } = useTranslation();
  const settingsApi = useSettingsApi();
  const location = useLocation();
  const history = useHistory();
  const match = matchPath<{
    section: string;
    subsection: string;
    subsection2: string;
  }>(location.pathname, {
    path: `${DASHBOARD_SETTINGS}/:section?/:subsection?/:subsection2?`,
  });
  const subsection2 = match?.params.subsection2;
  const handleSubmit = () => {
    //console.log("HANDLESUBMIT", settingsApi.location.orderIncrement);
    history.goBack();
  };
  return (
    <div className="dBsettingsCard boxShadow2">
      <div
        className="dBthemeBGcolor2"
        style={{
          padding: "0.5em",
          textAlign: "left",
          marginBottom: "0.25em",
        }}
      >
        {t("dashboard.settings.minimumOrderTimingTitle")}
      </div>
      <div style={{ padding: "0.5em" }}>
        <div
          className="AppFontSizeSmall"
          style={{
            padding: "0.5em 0.25em",
            textAlign: "left",
          }}
        >
          {t("dashboard.settings.orderSubTitle")}
        </div>
        <div style={{ display: subsection2 ? "" : "none" }}>
          <Switch>
            <Route
              path={`${DASHBOARD_SETTINGS}/space/orderTiming/input`}
              render={() => <OrderTimingInput handleSubmit={handleSubmit} />}
            />
          </Switch>
        </div>
        <div style={{ display: subsection2 ? "none" : "" }}>
          <div>
            {`${t("dashboard.settings.orderTimingDays", {
              count: Math.floor(
                Number(settingsApi.location.orderIncrement) / 1440
              ),
            })}
            ${t("dashboard.settings.orderTimingHours", {
              count:
                Math.floor(Number(settingsApi.location.orderIncrement) / 60) %
                24,
            })}
            ${t("dashboard.settings.orderTimingMinutes", {
              count: Number(settingsApi.location.orderIncrement) % 60,
            })}
          `}
          </div>
          <DashboardButton
            text={t("common.edit")}
            style={{}}
            theme="white"
            iconColor="black"
            icon="edit"
            onClick={() =>
              history.push(`${DASHBOARD_SETTINGS}/space/orderTiming/input`)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OrderTimingSettings;
