/**
 * Formatting and subdivision of order id to make it a little
 * more human readable. This funciton is used both on the client
 * side and the functions side
 */
const getOrderIdStrings = (orderId: string) => {
  return {
    rawId: orderId,
    fullId: orderId.toUpperCase(),
    mainId: orderId.substring(0, 5).toUpperCase(),
    subId: orderId.substring(5).toUpperCase()
  };
};

export default getOrderIdStrings;
