// Turns a hex string (eg, '#BBAADD') into an rgb array (eg, [12, 34, 56])
export function hexToRgbArray(hex: string): number[] {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : [];
}

// Turns a hex string (eg, '#BBAADD') into an rgb string fragment (eg, '12, 34, 56')
export function hexToRgbString(hex: string) {
  return hexToRgbArray(hex).join(",");
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
