import { useContext } from "react";
import StoreContext from "./storeContext";

/**
 * Custom hook for getting the preferences which customize a restaurant
 * Returns an array with two elements:
 *   The current state
 *   A dispatch function for editing
 */
export default () => useContext(StoreContext);
