import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NumberOption } from "../../../database/option";
import useLockDimensions from "../../Main/useLockDimensions";
import useStore from "../../../customization/useStore";

export interface NumberOptionSettingsProps {
  option: NumberOption;
  setOption: (option: NumberOption) => void;
}

const NumberOptionSettings: FC<NumberOptionSettingsProps> = ({
  option,
  setOption,
}) => {
  const lockDimensions = useLockDimensions();
  const { t } = useTranslation();
  const store = useStore();
  const unitString = (value: number) =>
    option.unit || t("dashboard.customFields.unit", { count: value });

  return (
    <React.Fragment>
      <div style={{ padding: "0.5em 0.25em" }}>
        <div className="AppFontSizeSmall">
          <strong>{t("common.preview")}</strong>
        </div>
        <div>
          {t("dashboard.customFields.priceIncreasedBy", {
            //THERES A BUG IN JAVASCRIPT MULTIPLYING DECIMALS
            //HENCE THE /10 /10, INSTEAD OF *100 WHICH WILL RESULT IN A
            //AN OFF NUMBER
            amount: !isNaN(option.price / 10 / 10)
              ? (option.price / 10 / 10).toLocaleString(store.currency.locale, {
                  style: "currency",
                  currency: store.currency.currency,
                })
              : (0).toLocaleString(store.currency.locale, {
                  style: "currency",
                  currency: store.currency.currency,
                }),
            denominator: option.priceDenominator,
            unit: unitString(option.priceDenominator),
          })}
        </div>
      </div>
      <div
        className="AppFontSizeSmall"
        style={{ padding: "0.5em 0.25em 0 0.25em" }}
      >
        <div>
          <strong>{t("dashboard.customFields.unitSectionMandatory")}</strong>
        </div>
        {t("dashboard.customFields.unitNameSubtitle")}
      </div>
      <input
        type="text"
        style={{ marginTop: 0 }}
        placeholder={t("dashboard.customFields.unitPlaceholder")}
        defaultValue={option.unit}
        onFocus={() => lockDimensions(true)}
        onChange={(event) => {
          setOption({
            ...option,
            unit: event.target.value,
          });
        }}
      />
      <div
        className="AppFontSizeSmall"
        style={{ padding: "0.5em 0 0 0.25em", color: "black" }}
      >
        <strong>{t("dashboard.catalog.priceSection")}</strong>
      </div>
      <input
        type="number"
        style={{ marginTop: 0 }}
        placeholder={t("dashboard.customFields.pricePlaceholder")}
        onFocus={() => lockDimensions(true)}
        // defaultValue={option.price / Math.pow(10, Dinero.defaultPrecision)}
        value={option.priceString}
        onChange={(event) => {
          setOption({
            ...option,
            price:
              //THERES A BUG IN JAVASCRIPT MULTIPLYING DECIMALS
              //HENCE THE *10 *10, INSTEAD OF *100 WHICH WILL RESULT IN A
              //AN OFF NUMBER
              Number(
                Number(event.target.value)
                  .toFixed(store.currency.precision)
                  .replace(/\D/g, "")
              ),
            priceString: Number(
              parseFloat(event.target.value).toFixed(store.currency.precision)
            ).toString(),
          });
        }}
      />
      <div style={{ padding: "0.5em 0 0 0.25em" }} className="AppFontSizeSmall">
        <div>
          <strong>{t("dashboard.customFields.unitVolume")}</strong>
        </div>
        {t("dashboard.customFields.perNumberOfUnits", {
          unit: unitString(10000), // definitely want plural
        })}
      </div>
      <input
        type="number"
        onFocus={() => lockDimensions(true)}
        placeholder={t("dashboard.customFields.perNumberOfUnitsPlaceholder", {
          unit: option.name,
        })}
        defaultValue={option.priceDenominator}
        style={{ marginTop: 0 }}
        onChange={(event) => {
          setOption({
            ...option,
            priceDenominator: Number(event.target.value),
          });
        }}
      />
      <div style={{ padding: "0.5em 0 0 0.25em" }} className="AppFontSizeSmall">
        <div>
          <strong>{t("dashboard.customFields.defaultValue")}</strong>
        </div>
        {t("dashboard.customFields.defaultValueSubtitle", {
          unit: unitString(10000),
        })}
      </div>
      <input
        type="number"
        style={{ marginTop: 0 }}
        placeholder={t("dashboard.customFields.defaultValuePlaceholder")}
        defaultValue={option.defaultValue}
        onFocus={() => lockDimensions(true)}
        onChange={(event) => {
          setOption({
            ...option,
            defaultValue: Number(event.target.value),
          });
        }}
      />
      <div className="AppFontSizeSmall">
        <div>
          <strong>{t("dashboard.customFields.minimumValue")}</strong>
        </div>
        {t("dashboard.customFields.minimumValueSubtitle", {
          unit: unitString(10000),
        })}
      </div>
      <input
        type="number"
        placeholder={t("dashboard.customFields.minimumValuePlaceholder")}
        defaultValue={option.defaultValue}
        onFocus={() => lockDimensions(true)}
        onChange={(event) => {
          setOption({
            ...option,
            minValue: Number(event.target.value),
          });
        }}
      />
      <div className="AppFontSizeSmall">
        <div>
          <strong>{t("dashboard.customFields.maximumValue")}</strong>
        </div>
        {t("dashboard.customFields.maximumValueSubtitle", {
          unit: unitString(10000),
        })}
      </div>
      <input
        type="number"
        placeholder={t("dashboard.customFields.maximumValuePlaceholder")}
        defaultValue={option.maxValue}
        onFocus={() => lockDimensions(true)}
        onChange={(event) => {
          setOption({
            ...option,
            maxValue: Number(event.target.value),
          });
        }}
      />
      <div className="AppFontSizeSmall">
        <div>
          <strong>{t("dashboard.customFields.smallestIncrement")}</strong>
        </div>
        {t("dashboard.customFields.smallestIncrementSubtitle")}
      </div>
      <input
        type="number"
        placeholder={t("dashboard.customFields.smallestIncrementPlaceholder")}
        defaultValue={option.increment}
        onFocus={() => lockDimensions(true)}
        onChange={(event) => {
          setOption({
            ...option,
            increment: Number(event.target.value),
          });
        }}
      />
    </React.Fragment>
  );
};

export default NumberOptionSettings;
