import { useState, useEffect } from "react";

// Every time the specified number of milliseconds elapse, the component
//   will rerender. Setting duration to undefined will stop refreshing.
const useRefreshInterval = (durationMsec: number | undefined) => {
  const [, setVal] = useState(0);
  useEffect(() => {
    if (typeof durationMsec === "number") {
      const id = setInterval(() => setVal(prev => prev + 1), durationMsec);
      return () => clearInterval(id);
    }
  }, [durationMsec]);
};

export default useRefreshInterval;
