import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../../../Firebase";
import cat1_img from "../../../img/cat1_img.jpg";
import useStore from "../../../customization/useStore";
import Icon from "../../Icon/icon";
import toggleFavorite from "../../../utilities/toggleFavorite";
import * as ROUTES from "../../../utilities/constants/routes";
import "../css/order.css";
import useToast from "../../Main/useToast";
import OptionButton from "../optionButton";
import useCart from "../../CartManager/useCart";
import useModal from "../../Modal/useModal";
import STinstructionsModal from "../Modals/sTorderInstruct";
import useSpinner from "../../Main/useSpinner";
import { useTranslation } from "react-i18next";
import { AccountInfo } from "../../Main/main";
import useAuthUser from "../../AuthUserProvider/useAuthUser";
import { Suborder, CartItem, OptionSelection } from "../../../database/cart";
import Dompurify from "dompurify";
import { useSelectedOptions } from "../../../utilities/defaultSelections";
import useItemPriceLabels from "./useItemPriceLabels";
import { useHistory, useLocation } from "react-router-dom";
import ModalPortal from "../../Modal/modalPortal";
import { srcSetString } from "../../Main/main";

export interface ItemProps {
  itemId: string;
  cartItem?: CartItem;
  cartItemCount?: number;
  suborder?: Suborder;
  accountInfo: AccountInfo;
  categoryName?: string;
  source?: "cart" | "favorites" | "order";
  index?: number;
}

//ITEM IS USED WHEN ORDER BUTTONS ARE DESIRED IN ITEM BAR
const Item = React.memo(
  React.forwardRef<HTMLDivElement, ItemProps>(
    (
      { cartItem, cartItemCount, categoryName, itemId, accountInfo, source },
      ref
    ) => {
      const store = useStore();
      const { theme, items } = store;
      const history = useHistory();
      const location = useLocation();
      const catalogItem = items[itemId];
      const { user } = useAuthUser();
      const showSpinner = useSpinner();
      const firebase = useContext(FirebaseContext);
      const cartApi = useCart();
      const { mainColor, color, iconColor } = theme;
      const [descriptionActive, setDescriptionActive] = useState(false);
      const toast = useToast();
      const showModal = useModal();
      const { t } = useTranslation();
      const [selectedOptions, setSelectedOptions] = useSelectedOptions(
        itemId,
        cartItem
      );
      const [instructions, setInstructions] = useState(
        cartItem && cartItem.instructions ? cartItem.instructions : ""
      );
      const [added, setAdded] = useState(false);
      const [count, setCount] = useState(cartItemCount || 1);
      const [fullName, setFullName] = useState(false);

      const onInstructionsClicked = () => {
        if (!catalogItem || catalogItem.unavailable) return;
        showModal(
          STinstructionsModal,
          {
            item: catalogItem,
            initialInstructions: instructions,
            onSubmit: (newInstructions: string) => {
              if (source === "cart" && cartItem) {
                cartApi.setItemInstructions(
                  cartItem.cartItemId,
                  newInstructions
                );
              }
              setInstructions(newInstructions);
            },
          },
          ROUTES.ML_ITEMINSTRUCT
        );
      };
      const addToCart = () => {
        if (!catalogItem || catalogItem.unavailable) return;

        cartApi.addItem(catalogItem, count, instructions, selectedOptions);
      };
      const removeFromCart = () => {
        if (!catalogItem || catalogItem.unavailable) return;

        if (source === "cart" && cartItem) {
          cartApi.removeItem(cartItem.cartItemId);
        }
      };
      function databaseAction(itemId: string) {
        if (!catalogItem) return;

        if (user) {
          toggleFavorite({
            firebase,
            showSpinner,
            accountInfo,
            itemId,
            user,
          });
        } else {
          toast({
            message: t("store.orders.startFavoring"),
            color: color,
            backgroundColor: mainColor,
          });
        }
      }

      const { priceLabel, priceExtraLabel } = useItemPriceLabels({
        catalogItem,
        selectedOptions,
        count,
        isCart: source === "cart",
      });

      useEffect(() => {
        if (added) {
          let timer = setTimeout(() => setAdded(false), 1000);
          return () => clearTimeout(timer);
        }
      }, [added]);

      if (!catalogItem) {
        return null;
      }
      const [imageInfo, setImageInfo] = useState<{
        loaded: boolean;
        error: boolean;
      }>({
        loaded: false,
        error: false,
      });
      useEffect(() => {
        setImageInfo({ loaded: false, error: false });
      }, [catalogItem.imageUrl]);

      const [countInput, setCountInput] = useState(count > 10 ? true : false);

      if (!catalogItem) {
        return null;
      }

      /**
       * TESTED SUN AUG 2 2020
       *
       * MAKE SURE TO TEST FOUR SCENARIOS:
       * 1. IN ORDER PAGE
       * 2. IN CART PAGE
       * 3. THEME WITH ITEM IMAGES DISPLAYED
       * 4. THEME WITH ITEM IMAGES NOT DISPLAYED
       *    -> theme.template.item === "noImgOrder"
       */

      return (
        <React.Fragment>
          {location.pathname.includes(`img/${itemId}`) && imageInfo.loaded && (
            <ModalPortal>
              <div
                className="dBorders_anim_fadeIn_0100"
                style={{
                  opacity: 0,
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0,0,0,0.7)",
                  zIndex: 1000,
                }}
                onClick={() => history.goBack()}
              >
                <img
                  src={!imageInfo.error ? catalogItem.imageUrl : cat1_img}
                  srcSet={srcSetString(catalogItem.imageUrls)}
                  sizes="100vw"
                  alt={catalogItem.name}
                  onLoad={() => {
                    if (!imageInfo.error) {
                      setImageInfo({
                        loaded: true,
                        error: false,
                      });
                    }
                  }}
                  onError={(e) => {
                    setImageInfo({
                      loaded: true,
                      error: true,
                    });
                  }}
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    height: undefined,
                    maxHeight: "95%",
                    maxWidth: "95%",
                    borderRadius: "5px",
                    opacity: imageInfo.loaded ? 1 : 0,
                    transition: "opacity 0.3s linear 0s",
                  }}
                />
              </div>
            </ModalPortal>
          )}
          <div
            ref={ref}
            onClick={() => {}}
            className={
              theme.template.item === "noImgOrder"
                ? "sTorderItemBar2 boxShadow1"
                : "sTorderItemBar boxShadow1"
            }
            style={{
              transition: "all 0.3s linear 0s",
              minHeight:
                theme.template.item === "noImgOrder" ? "auto" : undefined,
            }}
          >
            <div className="sTorderItemBarWrap1">
              {theme.template.item !== "noImgOrder" && (
                <div
                  className="sTitemItemPic"
                  onClick={
                    location.pathname.includes("img/")
                      ? () => {
                          history.replace(`${ROUTES.ORDER}`);
                        }
                      : () => {
                          history.push(
                            `${location.pathname}/img/${itemId}${location.search}`
                          );
                        }
                  }
                >
                  <img
                    src={!imageInfo.error ? catalogItem.imageUrl : cat1_img}
                    srcSet={srcSetString(catalogItem.imageUrls)}
                    sizes="7em"
                    alt={catalogItem.name}
                    onLoad={() => {
                      if (!imageInfo.error) {
                        setImageInfo({
                          loaded: true,
                          error: false,
                        });
                      }
                    }}
                    onError={(e) => {
                      setImageInfo({
                        loaded: true,
                        error: true,
                      });
                    }}
                    style={{
                      objectFit: "cover",
                      objectPosition: catalogItem.imageZoom
                        ? `${catalogItem.imageZoom.x}% center`
                        : "center",
                      height: "100%",
                      maxHeight: "7em",
                      maxWidth: "100%",
                      borderRadius: "5px",
                      verticalAlign: "middle",
                      opacity: imageInfo.loaded ? 1 : 0,
                      transition: "all 0.3s linear 0s",
                    }}
                  />
                </div>
              )}
              <div
                className="sTorderItemBarContents"
                style={{
                  width:
                    theme.template.item === "noImgOrder" ? "100%" : undefined,
                  transition: "opacity 0.5s ease 0s",
                  minHeight: theme.template.item === "noImgOrder" ? "0" : "",
                }}
              >
                <div style={{ padding: "0 0.25em" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      transition: "all 0.5s ease 0s",
                      wordBreak: "break-word",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: fullName ? undefined : "nowrap",
                    }}
                    onClick={() => {
                      setFullName(!fullName);
                    }}
                  >
                    {catalogItem.name}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ maxWidth: "100%" }}>
                      <div
                        className="AppFontSizeSmall"
                        style={{
                          marginLeft: "0.25em",
                          color: catalogItem.unavailable ? "red" : "",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {catalogItem.unavailable
                          ? "Unavailable, check back soon!"
                          : categoryName}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {catalogItem.description.replace(/<[^>]*>/g, "").trim()
                      .length > 0 ? (
                      <div
                        style={{}}
                        onClick={() => {
                          setDescriptionActive(!descriptionActive);
                        }}
                      >
                        <Icon
                          name={descriptionActive ? "line_spacing" : "notes"}
                          fill={iconColor}
                        />
                      </div>
                    ) : (
                      <div>&nbsp;</div>
                    )}
                  </div>
                  <div style={{ lineHeight: "1em" }}>
                    <div
                      className=""
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {priceLabel}
                    </div>
                    <div className="AppFontSizeSmall">{priceExtraLabel}</div>
                  </div>
                </div>
              </div>
            </div>
            {descriptionActive && (
              <div
                className="dBorders_anim_description_in"
                style={{
                  textAlign: "justify",
                  opacity: 0,
                  wordBreak: "break-word",
                  overflow: "hidden",
                }}
                dangerouslySetInnerHTML={{
                  __html: Dompurify.sanitize(catalogItem.description.trim()),
                }}
              ></div>
            )}
            <div style={{}} className="sTorderCustomInputBtnWrap">
              {catalogItem.options &&
                catalogItem.options.map((optionRef, i) => {
                  const selection = selectedOptions.find(
                    (selection) => selection.optionId === optionRef.optionId
                  );
                  return (
                    <OptionButton
                      key={optionRef.optionId}
                      optionId={optionRef.optionId}
                      selection={selection}
                      onSelectionChanged={(newSelection: OptionSelection) => {
                        setSelectedOptions((prev: OptionSelection[]) => {
                          const newSelections = [...prev];
                          newSelections[i] = newSelection;
                          if (source === "cart" && cartItem) {
                            cartApi.setItemOptionSelections(
                              cartItem.cartItemId,
                              newSelections
                            );
                          }
                          return newSelections;
                        });
                      }}
                      source="store"
                      allowClick={true}
                    />
                  );
                })}
            </div>
            <div className="sTorderItemActionWrap">
              <div
                onClick={(e) => {
                  databaseAction(catalogItem.itemId);
                  e.stopPropagation();
                }}
                className="sTfavIconBar"
              >
                <Icon
                  name={
                    accountInfo.favorites &&
                    accountInfo.favorites[catalogItem.itemId] &&
                    accountInfo.favorites[catalogItem.itemId]["fav"]
                      ? "favorite"
                      : "unfavorite"
                  }
                  fill={iconColor}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div
                  className="sTtoggleOrLinkButton"
                  onClick={onInstructionsClicked}
                  style={{
                    padding: "0 0.5em",
                    opacity: catalogItem.unavailable ? 0.3 : 1,
                  }}
                >
                  <Icon
                    name={instructions ? "done" : "add_note4"}
                    fill={iconColor}
                  />
                  <div
                    className="AppFontSizeSmall"
                    style={{ fontWeight: "bold", color: iconColor }}
                  >
                    {t("store.orders.addNote")}
                  </div>
                </div>
                <div
                  style={{
                    opacity: catalogItem.unavailable ? 0.3 : 1,
                    padding: 0,
                    width: "4em",
                    height: "4em",
                  }}
                  className="sTorderItemActionBtn"
                >
                  {!countInput ? (
                    <React.Fragment>
                      <div
                        style={{
                          position: "relative",
                          top: "0.7em",
                          fontWeight: "bold",
                        }}
                      >
                        {count}
                      </div>
                      <select
                        value={count}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value === -9) {
                            setCountInput(true);
                            setCount(1);
                          } else {
                            if (source === "cart" && cartItem) {
                              cartApi.setItemCount(cartItem.cartItemId, value);
                            }
                            setCount(value);
                          }
                        }}
                      >
                        {(() => {
                          const options = [];
                          for (let a = 1; a <= 10; a++) {
                            options.push(
                              <option key={a} value={a}>
                                {a}
                              </option>
                            );
                          }
                          options.push(
                            <option key={0} value={-9}>
                              10+
                            </option>
                          );
                          return options;
                        })()}
                      </select>
                    </React.Fragment>
                  ) : (
                    <input
                      type="number"
                      style={{ padding: "0.5em 0.25em", textAlign: "center" }}
                      defaultValue={count === 0 ? "" : count}
                      placeholder={t("common.count")}
                      onChange={(e) => {
                        const value =
                          Number(e.target.value) <= 0
                            ? 1
                            : Number(e.target.value);
                        if (source === "cart" && cartItem) {
                          cartApi.setItemCount(cartItem.cartItemId, value);
                        }
                        setCount(value);
                      }}
                    />
                  )}
                </div>
                {source === "cart" ? (
                  <div
                    className="sTtoggleOrLinkButton"
                    onClick={removeFromCart}
                    style={{ margin: "0 0.5em 0 0" }}
                  >
                    <Icon name="delete_forever" fill={iconColor} />
                    <div
                      className="AppFontSizeSmall"
                      style={{ fontWeight: "bold", color: iconColor }}
                    >
                      &nbsp;
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      padding: "0 0.5em",
                      opacity: catalogItem.unavailable ? 0.3 : 1,
                    }}
                    className="sTtoggleOrLinkButton"
                    onClick={() => {
                      if (!catalogItem.unavailable) {
                        setAdded(true);
                        addToCart();
                      }
                    }}
                  >
                    {added ? (
                      <Icon name="done" fill={iconColor} />
                    ) : (
                      <Icon name="shopping_cart_add" fill={iconColor} />
                    )}
                    <div
                      className="AppFontSizeSmall"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {added ? `+${count}` : t("store.orders.addToOrder")}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  )
);

export default Item;
