import memoize from "memoize-one";
import { Store } from "./../customization/storeContext";
import moment, { Moment } from "moment";
import { DayIndex } from "./../database/storeLocation";
import { defaultOrderIncrement } from "../components/Dashboard/Settings/orderTiming";
import getDateObjectFromString from "./getDateObjectFromString";

type TimeRange = { from: string; to: string };

export const isBetween = memoize(
  (selectedMoment: Moment, timeRanges: TimeRange[], dayOffset = 0) => {
    return timeRanges.some(({ from, to }) => {
      const [fromHours, fromMinutes] = from.split(":");
      const [toHours, toMinutes] = to.split(":");
      const fromMoment = selectedMoment
        .clone()
        .startOf("day")
        .hours(+fromHours)
        .minutes(+fromMinutes)
        .add(dayOffset, "days");
      const toMoment = selectedMoment
        .clone()
        .startOf("day")
        .hours(+toHours)
        .minutes(+toMinutes)
        .add(dayOffset, "days");
      if (toMoment <= fromMoment) {
        // Hours wrap around midnight. Add a day.
        toMoment.add(1, "day");
      }
      return selectedMoment.isBetween(fromMoment, toMoment, undefined, "[)");
    });
  }
);
export const getTimeRanges = (moment: Moment, store: Store): TimeRange[] => {
  const { dailyHours, holidayHours } = store;
  let dayOfWeek = moment.format("d");
  let holidayKey = moment.format("YYYY-MM-DD");
  return holidayHours[holidayKey] || dailyHours[+dayOfWeek as DayIndex];
};
/**
 * Returns true if the store is open at the selected time
 */
export const isOpenAt = memoize(
  (selectedMoment: Moment, store: Store): boolean => {
    let timeRanges = getTimeRanges(selectedMoment, store);
    let isOpen = isBetween(selectedMoment, timeRanges);
    if (!isOpen) {
      // Need to check yesterday's hours too, in case it's the
      //    early morning and they're open past midnight
      const yesterday = selectedMoment.clone().subtract(1, "day");
      let timeRanges = getTimeRanges(yesterday, store);
      isOpen = isBetween(selectedMoment, timeRanges, -1);
    }
    return isOpen;
  }
);
export const nextTimeOpen = memoize(
  (selectedMoment: Moment, store: Store): Moment => {
    // Check if they're open now
    if (isOpenAt(selectedMoment, store)) {
      return selectedMoment;
    }
    // Else check later in the day
    const startOfDay = selectedMoment.clone().startOf("day");
    const timeRanges = getTimeRanges(selectedMoment, store);
    for (const { from } of timeRanges) {
      const [fromHours, fromMinutes] = from.split(":");
      const fromMoment = startOfDay
        .clone()
        .hours(+fromHours)
        .minutes(+fromMinutes);
      if (fromMoment > selectedMoment) {
        return fromMoment;
      }
    }
    // Else check the coming week
    for (let i = 0; i < 7; i++) {
      startOfDay.add(1, "day");
      let timeRanges = getTimeRanges(startOfDay, store);
      if (timeRanges.length > 0) {
        const [fromHours, fromMinutes] = timeRanges[0].from.split(":");
        return startOfDay.hours(+fromHours).minutes(+fromMinutes);
      }
    }

    // Else they're not open any time in the next week. This may mean
    //   they're never open (because they havn't set it up), or there
    //   are some heavy duty holidays going on.
    return moment();
  }
);
export const isOpenU = memoize(
  (selectedMoment: Moment | undefined | null, store: Store) => {
    if (selectedMoment && selectedMoment.isValid()) {
      return isOpenAt(selectedMoment, store);
    } else {
      return isOpenAt(moment(), store);
    }
  }
);
export const isInFutureFromNowU = memoize(
  (selectedMoment: Moment | undefined) => {
    if (selectedMoment) {
      return selectedMoment.isAfter(moment());
    }
  }
);
export const isInFutureU = memoize(
  (selectedMoment: Moment | undefined, store: Store) => {
    if (selectedMoment && selectedMoment.isValid()) {
      return selectedMoment.isAfter(
        moment().add(store.orderIncrement || defaultOrderIncrement, "minutes")
      );
    } else {
      return true;
    }
  }
);
export const checkOrderDate = memoize(
  (
    date: string,
    time: string,
    selectedMoment: Moment | undefined,
    store: Store
  ) => {
    return (
      !isNaN(getDateObjectFromString(date, time).dateObject.getTime()) &&
      isInFutureU(selectedMoment, store) &&
      isOpenU(selectedMoment, store)
    );
  }
);
