import React from "react";
import Icon from "../../Icon/icon";
import { IconName } from "../../Icon/IconName";

export interface DashboardButtonProps {
  text: string;
  textStyle?: React.CSSProperties;
  subtitle?: string;
  onClick: (event: React.MouseEvent) => void;
  style?: React.CSSProperties;
  icon?: IconName;
  iconColor?: string;
  iconStyle?: React.CSSProperties;
  iconContainerColor?: string;
  theme?: string; //LEFT MEANS PLACE BUTTON LEFT, ELSE CENTER
  breakText?: boolean;
  className?: string;
  vertical?: boolean;
}

const DashboardButton = React.forwardRef<HTMLDivElement, DashboardButtonProps>(
  (
    {
      style,
      onClick,
      text,
      textStyle,
      icon = "no_icon",
      iconColor = "white",
      iconContainerColor = "transparent",
      iconStyle,
      subtitle,
      theme,
      breakText,
      className: classNameProp,
      vertical,
    },
    ref
  ) => {
    let className = theme?.includes("white")
      ? theme?.includes("xsmall")
        ? "dBbutton AppFontSizeExtraSmall"
        : "dBbutton AppFontSizeSmall"
      : theme?.includes("xsmall")
      ? "dBbutton dBthemeBGcolor2 AppFontSizeExtraSmall"
      : "dBbutton dBthemeBGcolor2 AppFontSizeSmall";
    if (classNameProp) {
      className = className + " " + classNameProp;
    }
    return (
      <div
        ref={ref}
        style={{
          margin: theme?.includes("left") ? "0.5em" : undefined,
          width: theme?.includes("white") ? "auto" : undefined,
          justifyContent:
            theme?.includes("white") && theme?.includes("left")
              ? "flex-start"
              : theme?.includes("white")
              ? "center"
              : undefined,
          flexDirection: vertical ? "column" : undefined,
          ...style,
        }}
        onClick={onClick}
        className={className}
      >
        <div
          className={
            theme?.includes("white")
              ? undefined
              : "dBthemeBGcolor2 dBbuttonIcon boxShadow1"
          }
          style={{
            ...iconStyle,
            background: iconContainerColor,
            display: "flex",
          }}
        >
          <Icon name={icon} fill={iconColor} />
        </div>
        {!subtitle ? (
          <span
            style={{
              ...textStyle,
              whiteSpace: breakText ? "normal" : undefined,
              padding: vertical ? "0 0.25em" : undefined,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            className="dBbuttonText"
          >
            {text}
          </span>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingBottom: "0.2em",
              fontWeight: "bold",
              lineHeight: "1em",
            }}
          >
            <div
              style={{
                fontSize: "0.75em",
                color: "greenyellow",
              }}
            >
              {subtitle}
            </div>
            <div style={textStyle}>{text}</div>
          </div>
        )}
        <div />
      </div>
    );
  }
);

export default DashboardButton;
