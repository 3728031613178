import {
  SuborderCollection,
  Suborder,
  OptionSelection,
  CartItem
} from "../../database/cart";
import { CatalogItem_Database } from "../../database/catalogItem";
import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext
} from "react";
import * as R from "ramda";
import useStore from "../../customization/useStore";
import { useTranslation } from "react-i18next";
import usePrevious from "../../utilities/usePrevious";
import { getSignature, findEquivalent } from "../../utilities/orderProcessing";
import uuid from "uuid/v4";
import { StripePaymentIntent } from "../Store/Checkout/paymentForm";
import { FirebaseContext } from "../../Firebase";
import { getStripePaymentIntent } from "../../utilities/httpsCallables/httpsCallables";

export interface Cart {
  cartId: string;
  suborders: SuborderCollection;
  activeSuborderId: string;
  justPaid?: boolean;
}

export interface CartApi extends Cart {
  clearCart: (becauseTheyPaid?: boolean) => void;
  restoreCart: (suborders: SuborderCollection) => string | undefined;
  addSuborder: () => void;
  removeSuborder: (suborder: Suborder) => void;
  setSuborderName: (suborder: Suborder, name: string) => void;
  setActiveSuborder: (suborder: Suborder) => void;
  addItem: (
    catalogItem: CatalogItem_Database,
    count: number,
    instructions?: string,
    options?: OptionSelection[],
    suborder?: Suborder
  ) => void;
  removeItem: (cartItemId: string, suborder?: Suborder) => void;
  setItemInstructions: (
    cartItemId: string,
    instructions: string,
    suborder?: Suborder
  ) => void;
  setItemOptionSelections: (
    cartItemId: string,
    selections: OptionSelection[],
    suborder?: Suborder
  ) => void;
  setItemCount: (
    cartItemId: string,
    count: number,
    suborder?: Suborder
  ) => void;
  createOrUpdatePaymentIntent: (
    amount: number
  ) => Promise<StripePaymentIntent | undefined>;
}

// Note: Ids are only unique for a given session, so reloading old meals requires a new id.
export const createUniqueID = (() => {
  let id = 0;
  return () => {
    id += 1;
    return "" + id;
  };
})();

export const makeEmptySuborder = (name = "Meal 1"): Suborder => ({
  name,
  items: {},
  suborderId: createUniqueID()
});

const findEmptySuborder = (cart: Cart) =>
  Object.values(cart.suborders).find(
    subOrder => Object.values(subOrder.items).length === 0
  );

const initialSuborder = makeEmptySuborder();
const emptyCart = {
  cartId: uuid(),
  suborders: {
    [initialSuborder.suborderId]: initialSuborder
  },
  activeSuborderId: initialSuborder.suborderId
};
/**
 * Implements the business logic for the Cart
 */
function useCartApi(restoredCart: Cart | null): CartApi {
  const [cart, setCart] = useState<Cart>(restoredCart || emptyCart);
  const activeSuborder = cart.suborders[cart.activeSuborderId];
  const { items, currency } = useStore();
  const firebase = useContext(FirebaseContext);
  const { t } = useTranslation();

  useEffect(() => {
    // Persist the current cart in session storage
    sessionStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);
  const clearCart = useCallback((becauseTheyPaid: boolean = false) => {
    // console.log("clearCart", becauseTheyPaid);
    setCart({
      ...emptyCart,
      cartId: uuid(),
      justPaid: becauseTheyPaid
    });
  }, []);

  const restoreCart = useCallback(
    (suborders: SuborderCollection) => {
      const populatedSuborders = Object.values(suborders).filter(
        suborder => Object.values(suborder.items).length > 0
      );
      if (populatedSuborders.length === 0) {
        return;
      }

      let missingItems = false;

      const newSuborders = populatedSuborders.map(suborder => {
        const newSuborder = makeEmptySuborder(suborder.name);
        Object.values(suborder.items).forEach(cartItem => {
          const catalogItem = items[cartItem.itemId];
          if (!catalogItem || catalogItem.unavailable) {
            // This item no longer exists
            missingItems = true;
            return;
          }
          newSuborder.items[cartItem.cartItemId] = R.omit(
            ["checked", "unable", "byAdmin"],
            cartItem
          );
        });
        return newSuborder;
      });

      const restoredAtLeastOneItem = newSuborders.some(
        suborder => Object.values(suborder.items).length > 0
      );
      if (!restoredAtLeastOneItem) {
        return t("store.orders.failureToRestoreCart");
      }

      setCart(prevCart => {
        const merged = R.filter(
          suborder => Object.keys(suborder.items).length > 0,
          prevCart.suborders
        );
        newSuborders.forEach(suborder => {
          if (Object.values(suborder.items).length > 0) {
            merged[suborder.suborderId] = suborder;
          }
        });
        const newCart = {
          ...prevCart,
          suborders: merged
        };
        if (!(newCart.activeSuborderId in newCart.suborders)) {
          // Guard against having no active meal. This could happen if
          // they had a single empty meal at the time they hit restore.
          newCart.activeSuborderId = Object.values(
            newCart.suborders
          )[0].suborderId;
        }
        return newCart;
      });

      return missingItems
        ? t("store.orders.partialFailureToRestoreCart")
        : undefined;
    },
    [items, t]
  );

  const addSuborder = useCallback(() => {
    setCart(prevCart => {
      const emptySuborder = findEmptySuborder(prevCart);
      if (emptySuborder) {
        // Reuse the existing empty suborder, just mark it as being the current one.
        return {
          ...prevCart,
          activeSuborderId: emptySuborder.suborderId
        };
      } else {
        // Create a new empty suborder and make it active.
        let number = 1;
        let subOrders = Object.values(prevCart.suborders);
        // eslint-disable-next-line no-loop-func
        while (subOrders.find(sub => sub.name === "Meal " + number)) {
          number++;
        }
        const newSuborder = makeEmptySuborder("Meal " + number);
        return {
          ...prevCart,
          suborders: {
            ...prevCart.suborders,
            [newSuborder.suborderId]: newSuborder
          },
          activeSuborderId: newSuborder.suborderId
        };
      }
    });
  }, []);

  const removeSuborder = useCallback((suborder: Suborder) => {
    setCart(prev => {
      let newCart: Cart = R.evolve(
        {
          suborders: R.omit([suborder.suborderId])
        },
        prev
      );

      if (Object.values(newCart.suborders).length === 0) {
        // Need at least one in the cart, so create a new one
        const newSuborder = makeEmptySuborder();
        newCart = {
          ...newCart,
          suborders: {
            [newSuborder.suborderId]: newSuborder
          },
          activeSuborderId: newSuborder.suborderId
        };
      } else {
        newCart.activeSuborderId = Object.values(
          newCart.suborders
        )[0].suborderId;
      }
      return newCart;
    });
  }, []);

  const setSuborderName = useCallback((suborder: Suborder, name: string) => {
    return setCart(
      R.evolve({
        suborders: {
          [suborder.suborderId]: {
            name: () => name
          }
        }
      })
    );
  }, []);

  const setActiveSuborder = useCallback((suborder: Suborder) => {
    setCart(prevCart => ({
      ...prevCart,
      activeSuborderId: suborder.suborderId
    }));
  }, []);

  const addItem = useCallback(
    (
      catalogItem: CatalogItem_Database,
      count: number,
      instructions?: string,
      options?: OptionSelection[],
      suborder: Suborder = activeSuborder
    ) => {
      setCart(prevCart => {
        let newItems = {
          ...prevCart.suborders[suborder.suborderId].items
        };
        for (let i = 0; i < count; i++) {
          let cartItemId;
          do {
            // Loop in case we restored an old order and need to
            //   increment past the ids it contains.
            cartItemId = createUniqueID();
          } while (newItems[cartItemId]);
          const newItem: CartItem = {
            cartItemId,
            itemId: catalogItem.itemId,
            instructions,
            optionSelections: options
          };
          newItems[cartItemId] = newItem;
        }
        return R.evolve(
          {
            suborders: {
              [suborder.suborderId]: {
                items: () => newItems
              }
            }
          },
          prevCart
        );
      });
    },
    [activeSuborder]
  );

  const removeItem = useCallback(
    (cartItemId: string, suborder: Suborder = activeSuborder) => {
      setCart(prevCart => {
        const existingItem = suborder.items[cartItemId];
        if (!existingItem) {
          // Not in the meal, take no action
          return prevCart;
        } else {
          const signature = getSignature(existingItem);
          const newCart: Cart = R.evolve(
            {
              suborders: {
                [suborder.suborderId]: {
                  items: R.filter(
                    (item: CartItem) => getSignature(item) !== signature
                  )
                }
              }
            },
            prevCart
          );
          return newCart;
        }
      });
    },
    [activeSuborder]
  );

  const setItemInstructions = useCallback(
    (
      cartItemId: string,
      instructions: string,
      suborder: Suborder = activeSuborder
    ) => {
      setCart(prevCart => {
        const existingItem = suborder.items[cartItemId];
        if (!existingItem) {
          // Not in the meal, take no action
          return prevCart;
        } else {
          const signature = getSignature(existingItem);
          return R.evolve(
            {
              suborders: {
                [suborder.suborderId]: {
                  items: R.mapObjIndexed((item: CartItem) => {
                    if (getSignature(item) === signature) {
                      return {
                        ...item,
                        instructions
                      };
                    } else {
                      return item;
                    }
                  })
                }
              }
            },
            prevCart
          );
        }
      });
    },
    [activeSuborder]
  );

  const setItemOptionSelections = useCallback(
    (
      cartItemId: string,
      selections: OptionSelection[],
      suborder: Suborder = activeSuborder
    ) => {
      setCart(prevCart => {
        const existingItem = suborder.items[cartItemId];
        if (!existingItem) {
          // Not in the meal, take no action
          return prevCart;
        } else {
          const signature = getSignature(existingItem);
          return R.evolve(
            {
              suborders: {
                [suborder.suborderId]: {
                  items: R.mapObjIndexed((item: CartItem) => {
                    if (getSignature(item) === signature) {
                      return {
                        ...item,
                        optionSelections: selections
                      };
                    } else {
                      return item;
                    }
                  })
                }
              }
            },
            prevCart
          );
        }
      });
    },
    [activeSuborder]
  );

  const setItemCount = useCallback(
    (
      cartItemId: string,
      count: number,
      suborder: Suborder = activeSuborder
    ) => {
      setCart(prevCart => {
        const existingItem = suborder.items[cartItemId];
        if (!existingItem) {
          // Not in the meal, take no action
          return prevCart;
        } else {
          const signature = getSignature(existingItem);
          const equivalentItems = findEquivalent(existingItem, suborder.items);

          const difference = count - equivalentItems.length;
          if (difference === 0) {
            // Already have the right number
            return prevCart;
          } else if (difference > 0) {
            // Create duplicates (with different ids)
            let newItems = { ...suborder.items };
            for (let i = 0; i < difference; i++) {
              let newId;
              do {
                // Loop in case we restored an old order and need to
                //   increment past the ids it contains.
                newId = createUniqueID();
              } while (newItems[newId]);
              const newItem: CartItem = {
                ...existingItem,
                cartItemId: newId
              };
              newItems[newId] = newItem;
            }
            const newCart: Cart = R.evolve(
              {
                suborders: {
                  [suborder.suborderId]: {
                    items: () => newItems
                  }
                }
              },
              prevCart
            );
            return newCart;
          } else {
            // Filter out equivalent items beyond count
            let accumulated = 0;
            const newCart: Cart = R.evolve(
              {
                suborders: {
                  [suborder.suborderId]: {
                    items: R.filter((item: CartItem) => {
                      if (getSignature(item) !== signature) {
                        return true;
                      }
                      if (accumulated < count) {
                        accumulated++;
                        return true;
                      } else {
                        return false;
                      }
                    })
                  }
                }
              },
              prevCart
            );
            return newCart;
          }
        }
      });
    },
    [activeSuborder]
  );

  const paymentIntentPromiseRef = useRef<Promise<StripePaymentIntent>>();
  const prevCartId = usePrevious(cart.cartId);
  useEffect(() => {
    if (prevCartId && prevCartId !== cart.cartId) {
      paymentIntentPromiseRef.current = undefined;
    }
  }, [cart.cartId, prevCartId]);
  const createOrUpdatePaymentIntent = useCallback(
    async (amount: number): Promise<StripePaymentIntent | undefined> => {
      if (amount === 0) return;
      if (!paymentIntentPromiseRef.current) {
        paymentIntentPromiseRef.current = getStripePaymentIntent(firebase, {
          amount,
          currency: currency.currency,
          cartId: cart.cartId,
          action: "create"
        });
      } else {
        paymentIntentPromiseRef.current = paymentIntentPromiseRef.current.then(
          intent => {
            if (intent.amount === amount) {
              // No update needed
              return intent;
            } else {
              return getStripePaymentIntent(firebase, {
                amount,
                currency: currency.currency,
                cartId: cart.cartId,
                action: "update",
                paymentIntentId: intent.id
              });
            }
          }
        );
      }
      return paymentIntentPromiseRef.current;
    },
    [cart.cartId, currency.currency, firebase]
  );

  return useMemo(() => {
    const api: CartApi = {
      ...cart,
      clearCart,
      restoreCart,
      addSuborder,
      removeSuborder,
      setSuborderName,
      setActiveSuborder,
      addItem,
      removeItem,
      setItemInstructions,
      setItemOptionSelections,
      setItemCount,
      createOrUpdatePaymentIntent
    };
    return api;
  }, [
    addItem,
    addSuborder,
    cart,
    clearCart,
    createOrUpdatePaymentIntent,
    removeItem,
    removeSuborder,
    restoreCart,
    setActiveSuborder,
    setItemCount,
    setItemInstructions,
    setItemOptionSelections,
    setSuborderName
  ]);
}

export default useCartApi;
