import React, { useState, useContext, FC } from "react";
import { useLocation, useHistory, matchPath } from "react-router-dom";
import Icon from "../../Icon/icon";
import useStore from "../../../customization/useStore";
import { FirebaseContext } from "../../../Firebase";
import * as ROUTES from "../../../utilities/constants/routes";
import {
  validateEmail,
  validatePassword,
} from "../../../utilities/validateEmailPassPhone";
import useToast from "../../Main/useToast";
import useLockDimensions from "../../Main/useLockDimensions";
import useSpinner from "../../Main/useSpinner";
import ModalVeil from "../../Modal/modalVeil";
import { useTranslation } from "react-i18next";
import * as APPCONSTANTS from "../../../utilities/constants/appConstants";
import { ModalProps, ModalBackgroundState } from "../../Modal/modalManager";
import { hexToRgbString } from "../../../utilities/colorUtils";
import { useQuery } from "../../../utilities/useQuery";
interface STauthProps extends ModalProps {
  action: "signin" | "signup";
}
const STauth: FC<STauthProps> = React.memo(({ action, closeModal }) => {
  const firebase = useContext(FirebaseContext);

  const location = useLocation<ModalBackgroundState>();
  const history = useHistory();
  const toast = useToast();
  const lockDimensions = useLockDimensions();
  const showSpinner = useSpinner();
  const { t } = useTranslation();
  const store = useStore();
  const { theme } = store;
  const { mainColor, color, iconColor, mainColorDark } = theme;
  const [authEmail, setAuthEmail] = useState("");
  const emailIsValid = validateEmail(authEmail);
  const [newPassword, setNewPassword] = useState("");
  const passwordIsValid = validatePassword(newPassword);
  const [authPassword, setAuthPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [reset, setReset] = useState(false);
  const [actionLocal, setActionLocal] = useState(action);
  const query = useQuery();
  const redirect = query.get("redirect");

  async function signIn(email: string, password: string) {
    console.log("Attempting Sign In...");
    const hideSpinner = showSpinner({ lag: "none" });
    try {
      const { user } = await firebase.signIn(email, password);
      if (!user) {
        throw new Error("null user");
      }
      const { claims } = await user.getIdTokenResult();
      const isOwner = claims.owner;
      const isSuperuser = claims.superuser;
      const isAdmin =
        isOwner || store.locations.some((loc) => loc.id === claims.admin);
      const checkingOut = !redirect
        ? matchPath(location.state.background.pathname, {
            path: `${ROUTES.CHECKOUT}`,
          })
        : false;
      closeModal(false);
      if (redirect) {
        history.replace(redirect);
      } else if (checkingOut) {
        // checkout page does its on logic for routing when authentication changes
      } else if (isAdmin || isSuperuser || isOwner) {
        history.replace(ROUTES.DASHBOARD);
      } else if (
        location.state.background.pathname === ROUTES.STORE ||
        location.state.background.pathname.startsWith(ROUTES.ORDER)
      ) {
        history.replace(ROUTES.TRACK);
      } else {
        history.goBack(); //CLOSE MODAL E.G. ACCOUNT PAGE
      }
    } catch (error) {
      let message;
      if (error.code === "auth/wrong-password") {
        message = t("toast.wrongPassword");
      } else if (error.code === "auth/user-disabled") {
        message = t("toast.disabledAccount");
      } else if (error.code === "auth/user-not-found") {
        message = t("toast.emailNotFound");
      } else if (error.code === "auth/network-request-failed") {
        message = t("toast.noNetwork");
      } else {
        console.log("Sign In Error", error);
        message = t("toast.systemError");
      }
      toast({
        message,
        className: "sTthemeAlert1b",
        duration: APPCONSTANTS.TOASTDURATION_ERROR,
      });
    } finally {
      hideSpinner();
    }
  }
  async function signUp(email: string, password: string) {
    console.log("Attempting Sign Up...");
    const hideSpinner = showSpinner();
    try {
      const { user } = await firebase.createAccount(email, password);
      if (!user) {
        throw new Error("null user");
      }
      const { claims } = await user.getIdTokenResult();
      const isOwner = claims.owner;
      const isSuperuser = claims.superuser;
      const isAdmin =
        isOwner || store.locations.some((loc) => loc.id === claims.admin);
      const checkingOut = matchPath(location.state.background.pathname, {
        path: `${ROUTES.CHECKOUT}`,
      });

      closeModal(false);
      if (redirect) {
        history.replace(redirect);
      } else if (checkingOut) {
        // checkout page does its on logic for routing when authentication changes
      } else if (isAdmin || isSuperuser) {
        history.replace(ROUTES.DASHBOARD);
      } else {
        history.replace(ROUTES.TRACK);
      }

      try {
        firebase.sendVerificationEmail();
      } catch (error) {
        console.log("Email verification error ", error);
      }
    } catch (error) {
      console.log("Create account error ", error);
      let message;
      if (error.code === "auth/email-already-in-use") {
        message = t("toast.emailInUse");
      } else if (error.code === "auth/invalid-email") {
        message = t("toast.badEmail");
      } else {
        message = t("toast.systemFailure");
      }
      toast({
        message,
        className: "sTthemeAlert1b",
        duration: APPCONSTANTS.TOASTDURATION_ERROR,
      });
    } finally {
      hideSpinner();
    }
  }
  async function resetPassword(email: string) {
    console.log("Attempting Password Reset...");
    const hideSpinner = showSpinner();
    try {
      await firebase.resetPassword(email);
      toast({
        message: t("toast.sentEmail"),
        color: color,
        backgroundColor: mainColor,
      });
      closeModal();
    } catch (error) {
      let message;
      if (error.code === "auth/user-not-found") {
        message = t("toast.emailNotFound");
      } else if (error.code === "auth/invalid-email") {
        message = t("toast.badEmail");
      } else {
        message = t("toast.systemFailure");
      }
      toast({
        message,
        className: "sTthemeAlert1b",
        duration: APPCONSTANTS.TOASTDURATION_ERROR,
      });
    } finally {
      hideSpinner();
    }
  }
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (actionLocal === "signin") {
      if (reset) {
        if (!emailIsValid) {
          toast({
            message: t("common.emailFormatInvalid"),
            className: "sTthemeAlert1b",
            duration: APPCONSTANTS.TOASTDURATION_ERROR,
          });
          return;
        } else {
          resetPassword(authEmail);
        }
      } else {
        if (!emailIsValid || authPassword.length < 5) {
          toast({
            message: t("common.emailFormatInvalid"),
            className: "sTthemeAlert1b",
            duration: APPCONSTANTS.TOASTDURATION_ERROR,
          });
          return;
        } else {
          signIn(authEmail, authPassword);
        }
      }
    } else {
      // console.log("authModal.js", authEmail, newPassword);
      if (!emailIsValid) {
        toast({
          message: t("common.emailFormatInvalid"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
        return;
      } else if (!passwordIsValid) {
        toast({
          message: t("common.passFormatInvalid"),
          className: "sTthemeAlert1b",
          duration: APPCONSTANTS.TOASTDURATION_ERROR,
        });
        return;
      } else {
        signUp(authEmail, newPassword);
      }
    }
  };
  const onCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    closeModal();
  };
  return (
    <React.Fragment>
      <form autoComplete={actionLocal === "signup" ? "off" : ""}>
        <div
          style={{
            display: "block",
            color: color,
            zIndex: 1000,
          }}
          className="sTmodal boxShadow5"
        >
          <div
            className="sTmodalHeader"
            style={{
              backgroundColor: mainColorDark,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: color,
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon name={!reset ? "person_si" : "security"} fill={color} />
                <div>
                  &nbsp;
                  {actionLocal === "signin"
                    ? !reset
                      ? t("signIn.signInTitle")
                      : t("password.resetPswdTitle")
                    : t("createAccount.createAccountTitle")}
                  &nbsp;
                </div>
              </div>
              {actionLocal === "signin" && !reset && (
                <div
                  onClick={() => {
                    setAuthEmail("");
                    setNewPassword("");
                    setActionLocal("signup");
                  }}
                  className="AppFontSizeSmall"
                  style={{
                    padding: "0.5em",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Icon name="group" fill={color} />
                  &nbsp;{t("createAccount.createAccount")}
                </div>
              )}
            </div>
            <br />
            <div className="AppFontSizeSmall" style={{ color: color }}></div>
            <div className="AppFontSizeSmall" style={{ color: color }}>
              <span style={{ fontWeight: "bold" }}>
                {actionLocal === "signin"
                  ? reset
                    ? t("password.resetPswdSubTitle")
                    : t("signIn.signInSubTitle")
                  : t("createAccount.createAccountSubTitle")}
              </span>
            </div>
          </div>
          <div className="sTmodalContent">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                marginTop: "1em",
              }}
            >
              <div
                style={{
                  margin: "0.5em 0 0.5em 0",
                  width: "100%",
                }}
              >
                <input
                  autoComplete={actionLocal === "signin" ? "username" : ""}
                  style={{
                    backgroundColor: "transparent",
                    margin: "0",
                    padding: "0 0.25em",
                    borderBottom: "none",
                  }}
                  name="email"
                  value={authEmail.toLowerCase()}
                  onChange={(e) => setAuthEmail(e.currentTarget.value.trim())}
                  id="email"
                  type="email"
                  placeholder={t("signIn.signInPlaceholder")}
                  onFocus={() => lockDimensions(true, "auth")}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      handleSubmit(e);
                    }
                  }}
                />
              </div>
            </div>
            {!reset && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#e5e5e5",
                  marginTop: "1em",
                }}
              >
                <div
                  style={{
                    margin: "0.5em 0 0.5em 0",
                    flex: "1",
                  }}
                >
                  <input
                    style={{
                      backgroundColor: "transparent",
                      padding: "0 0.25em",
                      margin: "0",
                      borderBottom: "none",
                    }}
                    name={actionLocal === "signin" ? "password" : "newpassword"}
                    value={
                      actionLocal === "signin" ? authPassword : newPassword
                    }
                    onChange={(e) =>
                      actionLocal === "signin"
                        ? setAuthPassword(e.currentTarget.value)
                        : setNewPassword(e.currentTarget.value)
                    }
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder={
                      actionLocal === "signin"
                        ? t("password.currentPswdPlaceholder")
                        : t("password.createPswdPlaceholder")
                    }
                    autoComplete={
                      actionLocal === "signup"
                        ? "new-password"
                        : "current-password"
                    }
                    onFocus={() => lockDimensions(true, "auth")}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        handleSubmit(e);
                      }
                    }}
                  />
                </div>
                <div
                  style={{ padding: "0 1em" }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Icon
                    name={!showPassword ? "visibility_off" : "visibility_on"}
                    fill={iconColor}
                  />
                </div>
              </div>
            )}
            {actionLocal === "signup" && (
              <div
                className="AppFontSizeSmall sTthemeBGcolor"
                style={{
                  margin: "2% 2% 2% 5%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    opacity: /[a-z]/.test(newPassword) ? 0.5 : 1,
                    transition: "all 0.5s ease 0s",
                    color: iconColor,
                  }}
                >
                  <span>
                    <Icon
                      name={
                        /[a-z]/.test(newPassword) ? "done" : "arrow_forward"
                      }
                      fill={iconColor}
                    />
                  </span>
                  {t("password.oneLowerCase")}
                </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    opacity: /[A-Z]/.test(newPassword) ? 0.5 : 1,
                    transition: "all 0.5s ease 0s",
                    color: iconColor,
                  }}
                >
                  <span>
                    <Icon
                      name={
                        /[A-Z]/.test(newPassword) ? "done" : "arrow_forward"
                      }
                      fill={iconColor}
                    />
                  </span>
                  {t("password.oneUpperCase")}
                </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    opacity: /\d/.test(newPassword) ? 0.5 : 1,
                    transition: "all 0.5s ease 0s",
                    color: iconColor,
                  }}
                >
                  <span>
                    <Icon
                      name={/\d/.test(newPassword) ? "done" : "arrow_forward"}
                      fill={iconColor}
                    />
                  </span>
                  {t("password.oneNumber")}
                </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    opacity: newPassword.length > 7 ? 0.5 : 1,
                    transition: "all 0.5s ease 0s",
                    color: iconColor,
                  }}
                >
                  <span>
                    <Icon
                      name={newPassword.length < 8 ? "arrow_forward" : "done"}
                      fill={iconColor}
                    />
                  </span>
                  {t("password.eightCharacters")}
                </span>
              </div>
            )}
          </div>
          <div
            className="sTmodalFooter AppFontSizeSmall"
            style={{
              backgroundColor: `rgba(${hexToRgbString(mainColorDark)},0.7)`,
            }}
          >
            {actionLocal === "signin" && !reset && (
              <div
                onClick={() => setReset(true)}
                className="sTmodalFooterItem AppFontSizeSmall"
                style={{ color: color }}
              >
                {t("password.forget")}
              </div>
            )}

            <div
              onClick={(e) =>
                !reset
                  ? actionLocal === "signin"
                    ? onCancel(e)
                    : setActionLocal("signin") //IN SIGNUP
                  : setReset(false)
              }
              className="sTmodalFooterItem AppFontSizeSmall"
              style={{ color: color }}
            >
              {t("common.cancel")}
            </div>
            <div
              style={{
                fontWeight: "bold",
                // opacity:
                //   actionLocal === "signin"
                //     ? !reset
                //       ? emailIsValid && authPassword.length > 5
                //         ? 1
                //         : 0.3
                //       : emailIsValid
                //       ? 1
                //       : 0.3
                //     : emailIsValid && passwordIsValid
                //     ? 1
                //     : 0.3,
                color: color,
              }}
              onClick={(e) => handleSubmit(e)}
              className="sTmodalFooterItem AppFontSizeSmall"
            >
              {t("common.submit")}
            </div>
          </div>
        </div>
      </form>
      <ModalVeil onClick={closeModal} />
    </React.Fragment>
  );
});

export default STauth;
