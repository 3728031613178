import React, { FC, useRef, useEffect } from "react";
import "../css/product.css";
import useStore from "../../../customization/useStore";
import { useHistory, useLocation } from "react-router-dom";
import ProductStandard from "../Templates/productStandard";
import { ProductProps } from "../Templates/productStandard";
import Icon from "../../Icon/icon";
import cat1_img from "../../../img/cat1_img.jpg";
import { ORDER, FAVORITES } from "../../../utilities/constants/routes";
import { useTranslation } from "react-i18next";
import { srcSetString } from "../../Main/main";

interface ProductPageProps {
  itemId: string | null;
}

const Product: FC<ProductPageProps> = React.memo(({ itemId: itemIdProp }) => {
  const location = useLocation();
  const mostRecentItemId = useRef<string | null>(null);
  const itemId = itemIdProp ?? mostRecentItemId.current;
  if (itemIdProp) {
    mostRecentItemId.current = itemIdProp;
  }

  const { t } = useTranslation();
  const history = useHistory();
  const store = useStore();
  const { catalog, items } = store;
  const catalogItem = itemId ? items[itemId] : null;

  const category = catalog.categories.find((category) =>
    category.items.find((item) => item.itemId === itemId)
  );
  const categoryName = category?.name || "";

  const routeToCategory = () => {
    history.push(
      ORDER,
      category && {
        categoryId: category.categoryId,
      }
    );
  };

  let ProductComponent: FC<ProductProps> = ProductStandard;
  const contentsDiv = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (contentsDiv.current === null) return;
      if (itemIdProp === null) contentsDiv.current.scrollTop = 0;
    }, 300);
    return () => clearTimeout(timer);
  }, [itemIdProp]);

  return (
    <React.Fragment>
      {itemIdProp !== null && (
        <div
          onClick={
            location.state !== "favorites"
              ? () => routeToCategory()
              : () => history.replace(`${FAVORITES}`, itemId)
          }
          className="sTprodBackButton"
          style={{
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
        >
          <Icon name="arrow_back" fill="white" />
        </div>
      )}
      <div
        className="sTprodOuterWrap sTthemeBGcolorSoft"
        style={{
          transition: "all 0.5s ease",
          transform: `translateX(${itemIdProp ? 0 : 25}%)`,
        }}
      >
        <div className="sTprodHeader"></div>
        <div ref={contentsDiv} className="sTprodContentsWrap">
          {/* DISPLAY IN LANDSCAPE ONLY
            sTprodContents1Big DOES NOT DISPLAY IN MOBILE
          */}
          <div className="sTprodContents1">
            <div className="sTprodContents1Big">
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  textTransform: "capitalize",
                }}
                onClick={
                  location.state !== "favorites"
                    ? () => routeToCategory()
                    : () => history.replace(`${FAVORITES}`, itemId)
                }
              >
                <Icon name="menu_open" />
                &nbsp;{t("common.menu")}&nbsp;/&nbsp;
                {categoryName.toLowerCase()}
              </div>
              <div
                className="AppFontSizeBig1"
                style={{
                  fontWeight: "bold",
                  textAlign: "justify",
                  wordBreak: "break-word",
                }}
              >
                {catalogItem && catalogItem.name}
              </div>
              {catalogItem && catalogItem.unavailable && (
                <div className="AppFontSizeSmall" style={{ color: "red" }}>
                  {t("store.orders.outOfStock")}
                </div>
              )}
            </div>
            {/* DISPLAY IN MOBILE AND LANDSCAPE 
            sTprodContents1SmallBig DOES DISPLAY IN BOTH MOBILE AND LANDSCAPE
          */}
            <div className="sTprodContents1SmallBig">
              <div className="sTprodContents1Inner">
                <div className="sTprodHeroImg">
                  <img
                    src={catalogItem?.imageUrl ?? cat1_img}
                    srcSet={
                      catalogItem ? srcSetString(catalogItem.imageUrls) : ""
                    }
                    sizes="(min-width:992px) 50vw, 100vw"
                    style={{
                      opacity: itemIdProp ? 1 : 0,
                      borderRadius: "5px",
                      transition: "all 0.5s linear 0s",
                    }}
                    alt={catalogItem?.name}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sTprodContents2">
            <div className="sTprodFavsWrap">
              {/* DISPLAY FOR MOBILE
              sTprodContents1Small DOES NOT GET DISPLAYED IN LANDSCAPE */}
              <div className="sTprodContents1Small" style={{ height: "auto" }}>
                <div
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                  onClick={routeToCategory}
                >
                  <Icon name="menu_open" />
                  &nbsp;{t("common.menu")}&nbsp;/&nbsp;
                  {categoryName.toLowerCase()}
                </div>
                <div
                  className="AppFontSizeBig1"
                  style={{
                    fontWeight: "bold",
                    wordBreak: "break-word",
                    textAlign: "justify",
                  }}
                >
                  {catalogItem && catalogItem.name}
                </div>
                {catalogItem && catalogItem.unavailable && (
                  <div className="AppFontSizeSmall" style={{ color: "red" }}>
                    {t("store.orders.outOfStock")}
                  </div>
                )}
              </div>
              {itemId && (
                <ProductComponent
                  key={itemId} // Remount to reset options and counts
                  itemId={itemId}
                  source="favorites"
                  categoryName={categoryName}
                />
              )}
            </div>
          </div>
        </div>
        <div className="sTorderFooterWrap"></div>
      </div>
    </React.Fragment>
  );
});

export default Product;
