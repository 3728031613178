import { MenuConfig } from "../../database/storeLocation";

export enum menuItemKeys {
  HOME = "home",
  ORDER = "order",
  TRACK = "track",
  FAVORITES = "favorites",
  PROMOTIONS = "promotions",
  LOGIN = "login",
  LOGOUT = "logout",
  ACCOUNT = "account",
  CONTACT = "contact",
}

export enum obsoleteMenuItemKeys {
  /**
   * About is deprecated. The extraPageOptions section now controls it
   */
  ABOUT = "about",
}

export const defaultConfigs: { [key in menuItemKeys]: MenuConfig } = {
  [menuItemKeys.HOME]: {
    icon: "home",
    label: "store.navigation.homeLink",
  },
  [menuItemKeys.ORDER]: {
    icon: "food",
    label: "store.navigation.orderLink",
  },
  [menuItemKeys.TRACK]: {
    icon: "past",
    label: "store.navigation.trackLink",
  },
  [menuItemKeys.FAVORITES]: {
    icon: "favorite",
    label: "store.navigation.favoritesLink",
  },
  [menuItemKeys.PROMOTIONS]: {
    icon: "cooking_fire",
    label: "store.navigation.promotionsLink",
  },
  [menuItemKeys.LOGIN]: {
    icon: "person_si",
    label: "common.signIn",
  },
  [menuItemKeys.LOGOUT]: {
    icon: "person_so",
    label: "common.signOut",
  },
  [menuItemKeys.ACCOUNT]: {
    icon: "settings2",
    label: "store.navigation.settingsLink",
  },
  [menuItemKeys.CONTACT]: {
    icon: "message",
    label: "store.navigation.contactUsLink",
  },
};
