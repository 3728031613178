import React, { FC, useState } from "react";
import { ModalProps } from "../../Modal/modalManager";
import ModalTemplate from "../Modals/modalTemplate";
import { useTranslation } from "react-i18next";
import OkAndCancel from "../Modals/okAndCancel";
import {
  Option,
  optionType,
  isListOption,
  isNumberOption,
} from "../../../database/option";
import { optionFactory } from "../Inventory/customOption";
import useLockDimensions from "../../Main/useLockDimensions";
import Icon from "../../Icon/icon";
import ListOptionSettings from "../CustomOptions/listOptionSettings";
import NumberOptionSettings from "../CustomOptions/numberOptionSettings";
import useToast from "../../Main/useToast";
import { TOASTDURATION_ERROR } from "../../../utilities/constants/appConstants";
import { validateListOption } from "../CustomOptions/listOption";
import { validateNumberOption } from "../CustomOptions/numberOption";

export interface DBeditCustomOptionProps extends ModalProps {
  itemName: string;
  initialOption?: Option;
  source?: "price";
  onSubmit: (option: Option) => void;
}

const DBeditCustomOption: FC<DBeditCustomOptionProps> = ({
  itemName,
  initialOption,
  source,
  closeModal,
  onSubmit,
}) => {
  const toast = useToast();
  const lockDimensions = useLockDimensions();
  const { t } = useTranslation();
  const [option, setOption] = useState(
    initialOption || optionFactory(optionType.NONE_YET)
  );

  const onChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.stopPropagation();
    if (initialOption) {
      // Not allowed to change the type on an existing option.
      return;
    }

    setOption(
      optionFactory(
        event.target.value as optionType,
        option.name,
        option.optionId
      )
    );
  };

  const validate = (): string | undefined => {
    if (option.type === optionType.NONE_YET) {
      return t("dashboard.customFields.errorNoType");
    } else if (!option.name) {
      return t("dashboard.customFields.errorNoName");
    } else if (isListOption(option)) {
      return validateListOption(option, t);
    } else if (isNumberOption(option)) {
      return validateNumberOption(option, t);
    }
    return undefined;
  };

  return (
    <ModalTemplate
      title={t(
        `dashboard.catalog.${initialOption ? "edit" : "create"}CustomInput`
      )}
      icon="input"
      subTitle={itemName || "\xa0"}
      onVeilClick={closeModal}
      content={
        <React.Fragment>
          {!initialOption && (
            <div className="AppFontSizeSmall">
              <div style={{ padding: "0.5em 0 0 0.25em" }}>
                <strong>{t("dashboard.customFields.typeSection")}</strong>
              </div>
              <select
                className="dBthemeBGcolor7"
                style={{ margin: "0 0 1em 0" }}
                disabled={!!initialOption}
                onChange={onChangeType}
                value={option.type}
              >
                <option value={optionType.NONE_YET}>
                  {t("dashboard.customFields.selectType")}
                </option>
                <option value={optionType.LIST}>
                  {t("dashboard.customFields.listType")}
                </option>
                <option value={optionType.NUMBER}>
                  {t("dashboard.customFields.numberType")}
                </option>
              </select>
            </div>
          )}
          {option.type !== optionType.NONE_YET && (
            <div className="AppFontSizeSmall">
              <div style={{ padding: "0.5em 0 0 0.25em" }}>
                <div>
                  <strong>{t("dashboard.customFields.labelSection")}</strong>
                </div>
                {t("dashboard.customFields.labelSectionSubtitle")}
              </div>
              <input
                type="text"
                style={{ marginTop: 0 }}
                placeholder={t("dashboard.customFields.labelPlaceholder")}
                defaultValue={option.name}
                onFocus={() => lockDimensions(true, "DB inventory")}
                onChange={(event) => {
                  const name = event.target.value;
                  setOption((prev) => ({
                    ...prev,
                    name,
                  }));
                }}
              />
              <div style={{ padding: "0.5em 0 0.25em 0.25em" }}>
                <strong>{t("common.settings")}</strong>
              </div>
              <div
                onClick={() =>
                  setOption((prev) => ({
                    ...prev,
                    required: !prev.required,
                  }))
                }
                style={{
                  marginBottom: "0.5em",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon
                  name={option.required ? "checkbox_done" : "checkbox_empty"}
                />
                <div style={{ paddingLeft: "0.25em" }}>
                  <div>{t("dashboard.customFields.required")}</div>
                  <div>{t("dashboard.customFields.settingsRequired")}</div>
                </div>
              </div>
              {isListOption(option) ? (
                <ListOptionSettings option={option} setOption={setOption} />
              ) : isNumberOption(option) ? (
                <NumberOptionSettings option={option} setOption={setOption} />
              ) : null}
            </div>
          )}
        </React.Fragment>
      }
      buttons={
        <OkAndCancel
          onOk={() => {
            const errorMessage = validate();
            if (errorMessage) {
              toast({
                message: errorMessage,
                className: "dBthemeAlert1",
                duration: TOASTDURATION_ERROR,
              });
              return;
            }

            // Incomplete option has been exluded by the validate check
            onSubmit(option as Option);
            closeModal();
          }}
          onCancel={closeModal}
        />
      }
    />
  );
};

export default DBeditCustomOption;
