import React, { FC, useState } from "react";
import { ModalProps } from "../../Modal/modalManager";
import Icon from "../../Icon/icon";
import { IconName } from "../../Icon/IconName";
import ModalTemplate from "./modalTemplate";
import { useTranslation } from "react-i18next";
import OkAndCancel from "./okAndCancel";

const IconTable: Record<Exclude<IconName, "no_icon">, IconName> = {
  account_balance: "account_balance",
  add: "add",
  add_note: "add_note",
  add_note2: "add_note2",
  add_note3: "add_note3",
  add_note4: "add_note4",
  add_sq: "add_sq",
  add_to_list: "add_to_list",
  archive: "archive",
  unarchive: "unarchive",
  arrow_back: "arrow_back",
  arrow_back2: "arrow_back2",
  arrow_down: "arrow_down",
  arrow_forward: "arrow_forward",
  arrow_forward2: "arrow_forward2",
  arrow_up: "arrow_up",
  asap: "asap",
  assignment: "assignment",
  attention: "attention",
  bar_chart: "bar_chart",
  background_image: "background_image",
  book: "book",
  build: "build",
  bullet_list: "bullet_list",
  business: "business",
  cake: "cake",
  calendar: "calendar",
  catalog: "catalog",
  category: "category",
  checkbox_done: "checkbox_done",
  checkbox_empty: "checkbox_empty",
  checkout: "checkout",
  clock: "clock",
  close: "close",
  cloud_download: "cloud_download",
  cloud_upload: "cloud_upload",
  code: "code",
  color_palette: "color_palette",
  communication: "communication",
  contact_email: "contact_email",
  contact_phone: "contact_phone",
  cooking_fire: "cooking_fire",
  copy: "copy",
  dashboard: "dashboard",
  delete: "delete",
  delete_forever: "delete_forever",
  delivery: "delivery",
  description: "description",
  directions: "directions",
  done: "done",
  done_all: "done_all",
  done_outline: "done_outline",
  drag: "drag",
  edit: "edit",
  email: "email",
  email_unread: "email_unread",
  error: "error",
  event_note: "event_note",
  exit_to_app: "exit_to_app",
  expand_less: "expand_less",
  expand_more: "expand_more",
  face: "face",
  favorite: "favorite",
  flag: "flag",
  flip: "flip",
  folder: "folder",
  font: "font",
  food: "food",
  food_menu: "food_menu",
  gift_card: "gift_card",
  group: "group",
  help: "help",
  home: "home",
  hottub: "hottub",
  image: "image",
  in_progress: "in_progress",
  in_queue: "in_queue",
  in_transit: "in_transit",
  information: "information",
  input: "input",
  keyboard_tab: "keyboard_tab",
  launch: "launch",
  laptop: "laptop",
  line_spacing: "line_spacing",
  location: "location",
  lock: "lock",
  membership: "membership",
  menu_open: "menu_open",
  menu_toggle: "menu_toggle",
  menu_component: "menu_component",
  message: "message",
  message_off: "message_off",
  message_unread: "message_unread",
  monetization: "monetization",
  money: "money",
  more_horizontal: "more_horizontal",
  more_vertical: "more_vertical",
  navigation: "navigation",
  next_step: "next_step",
  notes: "notes",
  notification_on: "notification_on",
  notification_off: "notification_off",
  numbered_list: "numbered_list",
  page_view: "page_view",
  past: "past",
  pause: "pause",
  person_si: "person_si",
  person_so: "person_so",
  phone: "phone",
  portrait: "portrait",
  publish: "publish",
  receipt: "receipt",
  receipt_long: "receipt_long",
  refresh: "refresh",
  redo: "redo",
  remove_circle_filled: "remove_circle_filled",
  remove_circle_outline: "remove_circle_outline",
  reply: "reply",
  report: "report",
  responsive: "responsive",
  filter: "filter",
  rewards: "rewards",
  save: "save",
  search: "search",
  security: "security",
  send: "send",
  settings: "settings",
  settings2: "settings2",
  shopping_cart: "shopping_cart",
  shopping_cart_add: "shopping_cart_add",
  store: "store",
  subtract_sq: "subtract_sq",
  sunny: "sunny",
  swap_vert: "swap_vert",
  swap_hor: "swap_hor",
  template: "template",
  thumb_down: "thumb_down",
  thumb_up: "thumb_up",
  timeline: "timeline",
  title: "title",
  translate: "translate",
  triangle_left: "triangle_left",
  triangle_right: "triangle_right",
  tune: "tune",
  undo: "undo",
  unfavorite: "unfavorite",
  view_day: "view_day",
  view_list: "view_list",
  visibility_off: "visibility_off",
  visibility_on: "visibility_on",
  walking: "walking",
  warning: "warning",
  welcome: "welcome",
};

export interface DBIconModalProps extends ModalProps {
  initialValue: IconName;
  onSubmit: (icon: IconName) => void;
}

const DBIconModal: FC<DBIconModalProps> = ({
  closeModal,
  onSubmit,
  initialValue,
}) => {
  const { t } = useTranslation();
  const [selectedIcon, setSelectedIcon] = useState(initialValue);
  return (
    <ModalTemplate
      title={t("dashboard.settings.homePage.iconTitle")}
      icon={selectedIcon}
      onVeilClick={closeModal}
      content={
        <React.Fragment>
          <div
            className=""
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            {Object.values(IconTable).map((name) => (
              <Icon
                key={name}
                name={name}
                fill={selectedIcon === name ? "greenyellow" : ""}
                onClick={() => setSelectedIcon(name)}
                style={{
                  padding: "0.25em",
                  transition: "all 0.3s linear 0s",
                  margin: "0.25em",
                  backgroundColor: selectedIcon === name ? "black" : "",
                }}
              />
            ))}
          </div>
        </React.Fragment>
      }
      buttons={
        <OkAndCancel
          onOk={() => {
            onSubmit(selectedIcon);
            closeModal();
          }}
          onCancel={closeModal}
        />
      }
    />
  );
};

export default DBIconModal;
